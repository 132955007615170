import React from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Form } from "react-final-form";
import { useQuery, useMutation } from "@apollo/client";
import { Typography, Paper, Grid, Button, CssBaseline } from "@mui/material";

import {
  convertPermissionsToGQLInput,
  convertPermissionsFromGQLResponse,
} from "../../services/utils";

import { USER_QUERY } from "../../graphql/queries";
import { UPDATE_USER_PERMISSIONS } from "../../graphql/mutations";

function UserEditForm({ allPermissions }) {
  const { userID } = useParams();
  const history = useHistory();
  const { data } = useQuery(USER_QUERY, {
    variables: { userID },
    fetchPolicy: "no-cache",
  });

  const initialState = () => {
    if (data) {
      let p = convertPermissionsFromGQLResponse(
        data.user.permissions,
        allPermissions
      );
      return {
        permissions: p,
      };
    } else {
      return {};
    }
  };

  const onCompleted = () => {
    console.log("completed");
    history.push("/users/" + userID);
  };

  const [updateUserPermissions] = useMutation(UPDATE_USER_PERMISSIONS, {
    onCompleted: onCompleted,
  });

  async function onSubmit(values) {
    console.log("submitting");
    let p = convertPermissionsToGQLInput(values.permissions, allPermissions);
    console.log("pp:" + p);
    updateUserPermissions({
      variables: {
        userID: userID,
        permissions: p,
      },
    });
  }

  async function validate(values) {
    return null;
  }

  return (
    <div style={{ padding: 16, margin: "auto", maxWidth: 800 }}>
      <CssBaseline />
      <Typography variant="h5" align="center" component="h2" gutterBottom>
        User Edit Form - permissions only
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={initialState}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper style={{ padding: 16 }}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item style={{ marginTop: 16 }}>
                  <Button
                    type="button"
                    className="std-button"
                    variant="contained"
                    onClick={form.reset}
                    disabled={submitting || pristine}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid style={{ marginTop: 16 }}>
                  <Button
                    variant="contained"
                    className="std-button"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      />
    </div>
  );
}

export default UserEditForm;
