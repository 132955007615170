import { createContext } from "react";

interface PropertyFormInterface {
  isProperty: boolean;
  isConfigLevelFieldEditable: boolean;
}

const PropertyFormContext = createContext<PropertyFormInterface>({
  isProperty: false,
  isConfigLevelFieldEditable: false,
});

export default PropertyFormContext;
