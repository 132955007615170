import { Loader } from "@googlemaps/js-api-loader";

export interface Location {
  lat: number;
  lng: number;
}

export let spacer_googleMapsApiLoaded = false;
export let spacer_googleMapsApiError: string | undefined;

export const initializeGoogleMapsAPI = (): void => {
  if (!spacer_googleMapsApiLoaded) {
    new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
      version: "weekly",
      libraries: ["places"],
    })
      .load()
      .then(() => {
        spacer_googleMapsApiLoaded = true;
      })
      .catch((e) => {
        console.error("Google Maps API Load error: ", e);
        spacer_googleMapsApiError = "Error loading Google Maps API.";
      });
  }
};
