import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { buildReferenceURL } from "services/utilities";
import usePagination from "hooks/usePagination";
import { SearchTableProps } from "interfaces/searchTableResults";
import CircleIcon from "@mui/icons-material/Circle";
import { isEmptyString } from "services/utils";
import { EntityType } from "enums";

const rowsPerIncrement = 10;
const rowsToFilter = [
  "id",
  "address",
  "propertyID",
  "name",
  "configID",
  "requestID",
  "orgID",
  "type",
  "configName",
  "requestName",
  "title",
];

const getHeaders = (type) => {
  if (type === EntityType.CONTACTS) return [EntityType.CONTACTS];

  if (type === EntityType.ORGANIZATION) return [EntityType.ORGANIZATION];

  if (type === EntityType.PROPERTY_CONFIG) {
    return [EntityType.PROPERTY, EntityType.PROPERTY_CONFIG];
  }

  if (type === EntityType.REQUEST) return [EntityType.REQUEST];
};

const getText = (row, subType, mainType) => {
  const divider = " / ";
  switch (subType) {
    case EntityType.ORGANIZATION:
      return mainType === EntityType.ORGANIZATION ? row.name : row.orgName;
    case EntityType.CONTACTS:
      return row.name;
    case EntityType.REQUEST:
      return isEmptyString(row.requestName) ? "default" : row.requestName;
    case EntityType.PROPERTY:
      return row.address;
    case EntityType.PROPERTY_CONFIG:
      if (isEmptyString(row.configName) && isEmptyString(row.title)) {
        return "";
      }
      return divider + (isEmptyString(row.title) ? row.configName : row.title);
    default:
      return "N/A";
  }
};

const formatBasedOnKey = (value, key) => {
    return value;
};

export default function SearchTableResults<T>({
  title,
  type,
}: SearchTableProps): JSX.Element {
  const [index, setIndex] = useState(0);
  const [rows, setRows] = useState<T[]>([]);
  const [getMoreRows, setGetMoreRows] = useState(false);

  const data = usePagination<T>({
    index,
    category: type,
    pageSize: rowsPerIncrement,
  });

  const handleMoreClick = () => {
    setIndex(index + rowsPerIncrement);
    setGetMoreRows(true);
  };

  const getHref = (row, type) => {
    const { orgID, requestID, propertyID, configID } = row;
    const orgUrl = buildReferenceURL({
      id: orgID,
      type: EntityType.ORGANIZATION,
    });

    switch (type) {
      case EntityType.ORGANIZATION:
      case EntityType.CONTACTS:
        return orgUrl;
      case EntityType.REQUEST:
        return buildReferenceURL({ id: requestID, type });
      case EntityType.PROPERTY:
        return buildReferenceURL({
          id: propertyID,
          type,
        });
      case EntityType.PROPERTY_CONFIG:
        return buildReferenceURL({
          id: configID,
          type,
        });
      default:
        return "";
    }
  };

  useEffect(() => {
    if (data.loading) return;
    if ((rows.length === 0 || getMoreRows) && data.rows.length !== 0) {
      setRows((rows) => [...rows, ...data.rows]);
    }

    setGetMoreRows(false);
  }, [data.loading, data.rows, getMoreRows, rows.length]);

  const selecttableEntitiesStyle = "text-blue-700 cursor-pointer w-full";
  const headers = getHeaders(type);
  const rowLength = data?.rows?.length;

  return (
    <div className="flex-col mt-3 h-full">
      <>
        <div className="font-bold mb-2 ml-5">
          {data.loading
            ? ""
            : rows.length > 0
            ? "Search Results for " + title
            : "No Search Results Found"}
        </div>
        <div>
          {rows.map((row, index) => (
            <span
              key={index}
              className="hover:bg-secondary block"
              onClick={() => window.open(getHref(row, type))}
            >
              <span role="rowheader" className="font-bold text-lg ml-8">
                {headers?.map((header, idx) => (
                  <span
                    key={idx}
                    className={selecttableEntitiesStyle + " hover:text-primary"}
                    onClick={() => window.open(getHref(row, header))}
                  >
                    {`${getText(row, header, type)}`}
                  </span>
                ))}
              </span>
              <div className="flex ml-3 text-lg ml-12 mb-3" role="row">
                {Object.entries(row)
                  ?.filter(
                    ([key, value]) =>
                      !rowsToFilter.includes(key) &&
                      !isEmptyString(value) &&
                      value !== "NULL"
                  )
                  .map(([key, value]: unknown[], idx, { length }) => {
                    const parsedString = formatBasedOnKey(value as string, key);
                    if (idx + 1 === length) {
                      return parsedString;
                    } else {
                      return (
                        <span key={idx}>
                          {parsedString}
                          <span className="ml-2 mr-2">
                            <CircleIcon
                              fontSize="inherit"
                              style={{ fontSize: 10 }}
                            />
                          </span>
                        </span>
                      );
                    }
                  })}
              </div>
            </span>
          ))}
        </div>
        {data.loading ? (
          <div className="flex items-center justify-center pt-1 pb-1">
            <CircularProgress />
          </div>
        ) : (
          rowLength !== 0 &&
          rowLength === rowsPerIncrement && (
            <div
              className="flex justify-center p-3 cursor-pointer bg-gray-100"
              onClick={handleMoreClick}
            >
              Show More
            </div>
          )
        )}
      </>
    </div>
  );
}
