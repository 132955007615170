import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginPage from "./LoginPage";
import LoggedInApp from "./LoggedInApp";
import SemiPrivateRoute from "../components/SemiPrivateRoute";
import AuthorizedApolloProvider from "../components/AuthorizedApolloProvider";
import initFontAwesome from "../services/init-font-awesome";
import { PRIMARY_COLOR } from "css.config";
import { AppState } from "contexts/AppContext";

initFontAwesome();

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: PRIMARY_COLOR,
      contrastText: "white",
    },
  },
});

function App() {
  return (
    <>
      <div
        id="app"
        className="h-full w-full flex flex-col min-h-screen text-sm"
      >
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <AuthorizedApolloProvider>
              <Switch>
                <Route exact path="/login" component={LoginPage} />
                <AppState>
                  <SemiPrivateRoute path="/" component={LoggedInApp} />
                </AppState>
              </Switch>
            </AuthorizedApolloProvider>
          </ThemeProvider>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
