import React from "react";
import LocationRationaleMap from "./LocationRationaleMap";
import { matchCategories } from "./types";
import { Category, LocationRationale, ScoreWithMax } from "./types";
import { classificationColors, ScoreClassification } from "./utils";

interface Props {
  cat: Category;
  sub: ScoreWithMax;
  scoreClassification: ScoreClassification;

  rat?: string;
  lrat: LocationRationale;
  _rerender: unknown;
}

const ScoreDetail: React.FC<Props> = ({
  cat,
  sub,
  scoreClassification,
  rat,
  lrat,
  _rerender,
}) => {
  const catMeta = matchCategories[cat];
  const scoreColor = classificationColors[scoreClassification];

  return (
    <div className="tooltip-content text-base flex flex-col p-2 max-h-96 overflow-y-auto">
      <div className="flex justify-between w-96">
        <p className="w-max mr-2 text-black text-lg">{catMeta.name} Score</p>

        <div className={"text-" + scoreColor}>
          <div>
            <span className="text-3xl">{sub.Score}</span>
            <span className="text-sm">/{sub.MaxScore}</span>
          </div>
        </div>
      </div>

      <div className="flex">
        {cat === Category.Location && (
          <div className="mx-2">
            <LocationRationaleMap rationale={lrat} _rerender={_rerender} />
          </div>
        )}

        {rat !== undefined && (
          <div
            className="text-sm font-normal text-left"
            dangerouslySetInnerHTML={{ __html: rat }}
          />
        )}
      </div>
    </div>
  );
};

export default ScoreDetail;
