import React from "react";
import { useParams } from "react-router-dom";
import { Query } from "@apollo/client/react/components";
import { USER_QUERY } from "graphql/queries";
import Highlight from "../Highlight";
import Changelogs from "../Changelogs";

function UserDetails() {
  const { userID } = useParams();

  return (
    <Query query={USER_QUERY} variables={{ userID }} fetchPolicy="no-cache">
      {({ loading, error, data }) => {
        if (loading) return <div>Fetching</div>;
        if (error) return <div>Error</div>;

        const user = data.user;

        return (
          <div>
            <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
            <Changelogs objectID={userID} title={"user"} />
          </div>
        );
      }}
    </Query>
  );
}

export default UserDetails;
