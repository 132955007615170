import { useContext } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { useFormState } from "react-final-form";

import Modal from "components/Modal";
import IconButton from "components/IconButton";
import { FormContext } from "contexts";
import { addDisabledStyle } from "services/utils";
import { EmptyResultsCardProps } from "interfaces/emptyResultsCard";
import { FormCommand } from "enums/formEnums";
import { OrganizationChildrenTypes } from "enums/organizationTypes";

export default function EmptyResultsCard({
  type,
  isEmpty,
  onAddClick,
}: EmptyResultsCardProps): JSX.Element {
  const isProperty = type === OrganizationChildrenTypes.Property;
  const name = isProperty ? "Property" : "Property Request";
  const lowerCaseName = name.toLowerCase();
  const pluralName = isProperty ? "properties" : "requests";

  const { setFormCommand, setModalChild, setOpenModal } =
    useContext(FormContext);
  const { invalid } = useFormState();

  const onConfirm = () => {
    onAddClick();
    setFormCommand(
      isProperty ? FormCommand.CREATE_PROPERTY : FormCommand.CREATE_REQUEST
    );
    setOpenModal(false);
  };

  const handleAdd = () => {
    setModalChild(
      <Modal
        title={`Add ${name}`}
        message={`Do you want to create a new ${lowerCaseName}?`}
        onClose={() => setOpenModal(false)}
        onConfirm={onConfirm}
      />
    );
    setOpenModal(true);
  };

  return (
    <Card className="text-center mt-3 bg-zinc-100">
      <CardContent className={addDisabledStyle(invalid)}>
        <div className="flex justify-center mt-6 font-bold ">
          <Typography variant="h6">
            {isEmpty ? `No ${pluralName} are found` : `Add ${name}`}
          </Typography>
          <IconButton
            iconId={"action_create"}
            enabled={!invalid}
            filter="bg-white rounded-full"
            onClick={handleAdd}
            state="green"
            className="ml-2"
            key={"addButton"}
            datacy={`add-${type}`}
          />
        </div>
      </CardContent>
    </Card>
  );
}
