import React from "react";

export default function FileUploader({
  documentType,
  ownerTableType,
  addFile,
  datacy,
}) {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    addFile(event.target.files, documentType, ownerTableType);
  };
  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className="std-button bg-primary"
        data-cy={datacy}
      >
        Upload a file
      </button>
      <input
        type="file"
        accept="image/*"
        multiple="multiple"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        onClick={(event) => {
          event.target.value = null;
        }}
        data-cy={`file-input-${datacy}`}
      />
      {/* Make the file input element invisible */}
    </>
  );
}
