import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Typography, Grid, Button } from "@mui/material";
import { useHistory } from "react-router";

import ConfirmationDialog from "./ConfirmationDialog";

import { CREATE_MFA_QR_CODE, VERIFY_MFA, DISABLE_MFA } from "graphql/mutations";
import { redirectToAuthenticatedApp } from "services/utils";
import { triggerAlert } from "services/alertService";

const row = "flex justify-between";

export default function MfaForm({ enabled, redirectState }) {
  const history = useHistory();
  const [mfaCode, setMfaCode] = useState();
  const [mfaEnabled, setMfaEnabled] = useState(enabled);
  const [qrCode, setQrCode] = useState();
  const [open, setOpen] = useState(false);

  const [createMfaQRCode] = useMutation(CREATE_MFA_QR_CODE, {
    onCompleted: (data) => {
      setQrCode(data.createMfaQRCode);
    },
    onError: (e) => {},
  });

  const [verifyMFA] = useMutation(VERIFY_MFA, {
    onCompleted: (data) => {
      if (data.verifyMFA === true) {
        triggerAlert("success", null, "Verified !");
        setMfaCode("");
        setMfaEnabled(true);
        setQrCode(undefined);
        localStorage.setItem("mfa_enabled", true);
        localStorage.setItem("mfa_verified", true);
        redirectToAuthenticatedApp(redirectState, "/settings", history);
      } else {
        triggerAlert("error", null, "Failed to Verify");
      }
    },
    onError: (e) => {},
  });

  const [disableMFA] = useMutation(DISABLE_MFA, {
    onCompleted: (data) => {
      if (data.disableMFA === true) {
        setMfaEnabled(false);
        history.push("/settings");
      }
    },
    onError: (e) => {},
  });

  const onClickEnableMFA = () => {
    createMfaQRCode();
  };

  const onClickDisableMFA = () => {
    localStorage.removeItem("mfa_enabled");
    localStorage.removeItem("mfa_verified");
    disableMFA();
  };

  const onClickVerifyMFA = () => {
    verifyMFA({
      variables: {
        code: mfaCode,
      },
    });
  };

  const onCodeChange = (e) => {
    setMfaCode(e.target.value);
  };

  //Const for dialog
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Props for dialog
  const dialogTitle = "Confirmation";
  const dialogContent =
    "Are you sure you want to do this? It cannot be undone and may cause a loss of access in Spacer until you go through the MFA setup process again.";

  const mfaInstructions = [
    "To setup Multi Factor Authentication:",
    "1. Open an authenticator app on your mobile device (Authy, Google Authenticator, Microsoft Authenticator, etc).",
    "2. In the app, add a new account and select the option to scan a QR code.",
    "3. Scan the code on this screen.",
    '4. Enter the 6-digit code displayed in your authenticator app in the space below and click "Complete Setup".',
  ];

  return (
    <>
      <Typography id="host" variant="h6" gutterBottom>
        Multi Factor Authentication
      </Typography>

      {mfaEnabled && (
        <div className={row}>
          <div>
            <span className="ml-2">Status:</span>
            <span style={{ color: "green" }} data-cy="mfa-enabled">
              ENABLED
            </span>
          </div>
          <Button variant="contained" size="small" onClick={handleOpen}>
            Disable
          </Button>
          {/* Add a dialog componment to confirm disable MFA */}
          <ConfirmationDialog
            keepMounted
            open={open}
            onClose={handleClose}
            onConfirm={onClickDisableMFA}
            title={dialogTitle}
            content={dialogContent}
          />
        </div>
      )}

      {!mfaEnabled && (
        <>
          <div className={row}>
            <div>
              <span>Status:</span>
              <span style={{ color: "red" }} data-cy="mfa-disabled">
                DISABLED
              </span>
            </div>
            <Button variant="contained" size="small" onClick={onClickEnableMFA}>
              Enable
            </Button>
          </div>

          {qrCode && (
            <>
              <div className="-ml-4">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item>
                    <img
                      src={`data:image/png;base64,${qrCode}`}
                      alt="MFA QR code"
                    />
                  </Grid>
                  <Grid item>
                    <ul>
                      {mfaInstructions.map((instruction) => (
                        <li>{instruction}</li>
                      ))}
                    </ul>
                  </Grid>
                </Grid>
              </div>
              <div className={row}>
                {/* Disable the 'Complete setup' button until there have 6-digit code */}
                <input type="text" onChange={onCodeChange} />
                <Button
                  variant="contained"
                  size="large"
                  disabled={mfaCode ? mfaCode.length !== 6 : true}
                  onClick={onClickVerifyMFA}
                >
                  Complete Setup
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
