import { useEffect } from "react";
import { validAddressFound } from "services/utilities";

let autocomplete;

function initAutocomplete(
  updateAddress,
  requestIndex,
) {
  autocomplete = new window.google.maps.places.Autocomplete(
    document.querySelector(
      `[name='locationSearch-${requestIndex}']`
    ) as HTMLInputElement,
    {
      types: ["address"],
      componentRestrictions: { country: ["CA", "US"] }, // Biased to these Countries
      fields: ["place_id", "geometry", "address_component"], // Components we want returned
    }
  );

  autocomplete.addListener("place_changed", () =>
    onPlaceChanged(
      updateAddress,
    )
  );
}

function onPlaceChanged(
  updateAddress,
) {
  const place = autocomplete.getPlace();
  if (!place === false) {
    if (!place.geometry || !validAddressFound(place.address_components)) {
      // show a little red warning underneath the address bar that it is not a valid geometry
      console.log("Error parsing results from Google Maps", place)
    } else {
      //If there is a valid geometry update the location fields
      updateAddress(
        new google.maps.LatLngBounds({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        })
        );
    }
  } else if (!place === true) {
    console.log("Error parsing results from Google Maps - place is empty")
  }
}

export default function RequestAddressInput(props: {
  setBounds;
  requestIndex;
}): JSX.Element {
  useEffect(() => {
    initAutocomplete(
      //Used to initialize the Autocomplete input
      props.setBounds,
      props.requestIndex,
    );
  }, [
    props.setBounds,
    props.requestIndex,
  ]);

  return (
    <>
      <div className={"p-5px"}>
        <div className={"pr-5px"}>
          <p className={"w-autocomplete-lable font-bold"}>
            Search for Location to add Markers
          </p>
        </div>
        <input
          id={`locationSearch-${props.requestIndex}`}
          name={`locationSearch-${props.requestIndex}`}
          placeholder="Enter Location"
          className={"ml-2 w-autocomplete-input input-default"}
        />
      </div>
    </>
  );
}
