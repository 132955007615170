import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Link,
} from "@mui/material";
import BaseInput from "components/Forms/BaseInput";
import SwitchInput from "components/Forms/SwitchInput";
import TextInput from "components/Forms/TextInput";
import { ProviderOptionProps } from "interfaces/providerOption.interface";
import { Field } from "react-final-form";
import { EnumSpacerLabels } from "services/enums";

function commercialEdgeLinks(listingLink: string | undefined) {
  const providers = [
    ["CommercialSearch", "www.commercialsearch.com"],
    ["CommercialCafe", "www.commercialcafe.com"],
    ["Point2Homes", "www.point2homes.com"],
    ["PropertyShark", "www.propertyshark.com"],
    ["42 Homes", "42floors.com"],
  ];
  let urls: JSX.Element[] | undefined;
  if (!listingLink)
    return urls;

  let provider = "";
  providers.forEach(p => {
    if (listingLink.includes(p[1])) provider = p[1];
  })
  if (provider)
    urls = providers.map((u, key) => {
      if (u[1] === provider) return <></>;
      const link = listingLink.replace(provider, u[1]);
      return <li key={key}><Link href={link}>{u[0]}</Link></li>;
    });

  return urls;
}

export default function ProviderOptions({
  provider,
  fieldName,
  isOpen,
  isPublished,
  onClose: handleClose,
  listingLink,
}: ProviderOptionProps): JSX.Element {
  const listingProvider = provider.value?.toString();
  const listingProviderLowerCase = listingProvider.toLowerCase();

  const listingUrl = (
    <TextInput
      label={`Listing Link`}
      name={`${fieldName}.ListingLink_${listingProviderLowerCase}`}
    />
  );

  const renderOptions = () => {
    switch (listingProvider) {
      case "LP_SPACELIST":
        return (
          <>
            {listingUrl}
            <SwitchInput
              name={`${fieldName}.FeaturedListing_${listingProviderLowerCase}`}
              label="Featured Listing"
            />
          </>
        );
      case "LP_CREXI":
        return (
          <TextInput
            label={`Crexi Title Override (Max 50 Characters)`}
            name={`${fieldName}.AlternateTitle_${listingProviderLowerCase}`}
            maxLength={50}
          />
        );
      case "LP_PLACEHOLDER":
        return (
          <>
            {isPublished && (
              <>
                <SwitchInput
                  name={`${fieldName}.HiddenListing_${listingProviderLowerCase}`}
                  label="Hidden Listing"
                />
                <SwitchInput
                  name={`${fieldName}.RemovedListing_${listingProviderLowerCase}`}
                  label="Remove Listing"
                />
              </>
            )}
            {/*
              manual checkboxes because convertFormToGQLListingExtra cannot
              handle arrays - need to set each field's name independently
            */}
            <BaseInput label={`Labels`}>
              {EnumSpacerLabels.map((kv, idx) => (
                <label key={idx}>
                  <Field
                    name={`${fieldName}.Label${kv.name}_${listingProviderLowerCase}`}
                    component="input"
                    type="checkbox"
                    defaultValue={kv.name === "New" ? true : false}
                  />
                  {` ${kv.name} `}
                </label>
              ))}
            </BaseInput>
            <SwitchInput
              name={`${fieldName}.FeaturedListing_${listingProviderLowerCase}`}
              label="Featured Listing"
            />
          </>
        );
      case "LP_COMMERCIALEDGE":
        const links = commercialEdgeLinks(listingLink);
        return (
          <div>
            <TextInput
              label={`CommercialCafe Listing Link`}
              name={`${fieldName}.ListingLink_${listingProviderLowerCase}`}
            />
            {links && (<div>
              <Typography variant="h6" component="h6">Commercial Edge links</Typography>
              <ul style={{ listStyle: "initial", marginLeft: "1.25rem" }}>
                {links}
              </ul>
            </div>)}
            {listingUrl && !links && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <strong>
                  Warning ⚠️ : CommercialCafe URL seems to be invalid.
                  Failed to generate Commercial Edge links
                </strong>
              </div>
            )}
          </div>
        );
      default:
        return <>{listingUrl}</>;
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ paddingTop: 18, paddingBottom: 10 }}>
        {`Settings: ${provider.name} `}
      </DialogTitle>
      <DialogContent className="ml-2">{renderOptions()}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="error">
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
