import { EnumWarehouseUsage } from "services/enums";

import TextInput from "components/Forms/TextInput";
import DropdownInput from "components/Forms/DropdownInput";
import NullBooleanInput from "components/Forms/NullBooleanInput";
import { GridSection } from "components/CardComponents";
import ActiveCheckbox from "components/Forms/ActiveCheckBox";
import BaseTenantSectionProps from "interfaces/baseTenantSectionProps.interface";

export default function TenantDetails({
  requestName,
}: BaseTenantSectionProps): JSX.Element {
  return (
    <GridSection
      title="Details"
      md={4}
      xs={12}
      HeaderComponent={
        <ActiveCheckbox
          helpText={
            "Active means this guest is looking for a property and should be included in matches"
          }
          name={`${requestName}.active`}
        />
      }
    >
      <TextInput label={`Request Name`} name={`${requestName}.name`} />

      <DropdownInput
        name={`${requestName}.warehouse_usage`}
        sideNoteNamespace={`${requestName}.usage`}
        label="Warehouse Usage"
        options={EnumWarehouseUsage}
        isSideNote
        // remove when dropdown input becomes a ts file
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultShowSideNote={true}
      />

      <label className="input-label ml-1 mt-2">Will Usage Include: </label>

      <NullBooleanInput
        name={`${requestName}.usage_food`}
        label={`Food Preparation`}
        indent={2}
      />

      <NullBooleanInput
        name={`${requestName}.usage_auto`}
        label={`Automotive`}
        indent={2}
      />

      <NullBooleanInput
        name={`${requestName}.usage_cannabis`}
        label={`Cannabis`}
        indent={2}
      />

      <NullBooleanInput
        name={`${requestName}.usage_customer`}
        label={`Customer Facing`}
        indent={2}
      />

      <NullBooleanInput
        name={`${requestName}.usage_sport_complex`}
        label={`Sports Complex`}
        indent={2}
      />

      <NullBooleanInput
        name={`${requestName}.usage_sport_studio`}
        label={`Sports Studio`}
        indent={2}
      />

      <NullBooleanInput
        name={`${requestName}.usage_highvol`}
        label={`High Bay Usage`}
        indent={2}
      />

      <NullBooleanInput
        name={`${requestName}.usage_manufacture`}
        label={`Heavy Manufacturing`}
        indent={2}
      />
    </GridSection>
  );
}
