import { Switch, Typography } from "@mui/material";
import BaseInput from "./BaseInput";
import Error from "./Error";
import { useField } from "react-final-form";
import { SwitchInputProps } from "interfaces/SwitchInput.interface";

export default function SwitchInput({
  isReadOnly = false,
  staticHoverText = "",
  label,
  name,
  ...props
}: SwitchInputProps): JSX.Element {
  const {
    input: { onChange, value },
    meta: { error },
  } = useField(name);

  const handleSwitch = (event) => {
    onChange(event.target.checked);
  };

  return (
    <BaseInput
      {...props}
      label={label}
      readOnly={isReadOnly}
      staticHoverText={staticHoverText}
    >
      <div className="flex items-center">
        <Typography>No</Typography>
        <Switch
          onChange={handleSwitch}
          checked={typeof value == "boolean" ? value : false}
          disabled={isReadOnly}
        />
        <Error
          showError={error}
          error={error}
          style={{ display: "block", marginLeft: 3 }}
        />
        <Typography>Yes</Typography>
      </div>
    </BaseInput>
  );
}
