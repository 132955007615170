import React from "react";
import Icon, { IconId, IconState, IconSize } from "./Icon";

const tooltipPositions = {
  top: "tooltip-top",
  bottom: "tooltip-bottom",
} as const;

export type TooltipPosition = keyof typeof tooltipPositions;

interface Props {
  iconId: IconId;
  state?: IconState;
  size?: IconSize;
  filter?: string;
  tooltipPosition?: TooltipPosition;
  tooltipContent: JSX.Element;
  minWidth?: string;
  datacy?: string;
}

const TooltipedIcon: React.FC<Props> = ({
  iconId,
  state,
  size,
  filter,
  tooltipPosition,
  tooltipContent,
  datacy,
  minWidth = "250px",
}) => {
  let positionClass = "";
  if (tooltipPosition === undefined) {
    positionClass = "tooltip-top-left";
  } else {
    positionClass = tooltipPositions[tooltipPosition];
  }

  return (
    <div
      className="m-1 tooltip-container flex items-center"
      data-cy={`listing-icon-${datacy}`}
    >
      <div className="m-auto">
        <Icon iconId={iconId} state={state} size={size} filter={filter} />
      </div>

      <div style={{ minWidth }} className={"tooltip " + positionClass}>
        <div className="tooltip-content flex flex-col p-2 max-h-96 overflow-y-auto font-normal leading-normal text-left">
          {tooltipContent}
        </div>
      </div>
    </div>
  );
};

export default TooltipedIcon;
