import React from "react";
import { Typography, Button } from "@mui/material";

import GQLForm from "./GQLForm";
import PasswordInput from "./PasswordInput";

import { HOST_QUERY } from "graphql/queries";
import { CHANGE_PASSWORD } from "graphql/mutations";
import { checkPassword } from "services/utils";
import { triggerAlert } from "services/alertService";

export default function ResetPasswordForm() {
  const preSaveFormat = (values) => {
    return values.password1;
  };

  const postSaveFn = () => {
    triggerAlert("success", null, `Changed !`);
  };

  function validate(values) {
    let errors = {};

    if (values.password1 !== values.password2) {
      errors.password1 = "Must Match";
      errors.password2 = "Must Match";
    }

    if (values.password1) {
      if (!checkPassword(values.password1)) {
        errors.password1 = "Must follow rule";
      }
    }

    if (values.password2) {
      if (!checkPassword(values.password2)) {
        errors.password2 = "Must follow rule";
      }
    }

    return errors;
  }

  return (
    <GQLForm
      initDataFn={() => {}}
      loadQuery={HOST_QUERY}
      loadSkip={true}
      postLoadFn={() => {}}
      preSaveFormat={preSaveFormat}
      saveQuery={CHANGE_PASSWORD}
      postSaveFn={postSaveFn}
      validate={validate}
      tabTitleFn={() => {}}
    >
      {({ handleSubmit, form, submitting, pristine, values, invalid }) => (
        <form
          noValidate
          onSubmit={(event) => {
            handleSubmit(event).then(() => {
              form.reset();
            });
          }}
        >
          <Typography id="host" variant="h6" gutterBottom>
            Change Password
          </Typography>

          <div className="ml-3">
            <p>
              <b>Password Rule</b> <br />
              between 8 to 128 characters
              <br />
              at least one lowercase, uppercase, numeric, special character
            </p>

            <div className="w-8/12">
              <PasswordInput name={`password1`} label={`New Password`} />
              <PasswordInput
                name={`password2`}
                label={`Confirm New Password`}
              />
            </div>

            <div className="flex mt-5 justify-end">
              <Button
                variant="contained"
                size="small"
                type="submit"
                disabled={submitting || invalid || pristine}
              >
                Change Password
              </Button>
            </div>
          </div>
        </form>
      )}
    </GQLForm>
  );
}
