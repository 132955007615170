import { useState } from "react";
import { Button, Alert } from "@mui/material";
import { objectListToStringNames, scrollWithinView } from "services/utils";
import { PROPERTIES_KEY, REQUESTS_KEY } from "services/constants";
import { useForm } from "react-final-form";
import { OrganizationChildrenTypes } from "enums/organizationTypes";
import { SubmitBarProps } from "interfaces/submitBar";
import { setListableConfigStatus } from "services/listingPromptService";

export default function SubmitBar({
  disabled,
  showNavBar,
  errors,
  properties,
  selectedType,
  orgID,
}: SubmitBarProps): JSX.Element {
  const [errorIndex, setErrorIndex] = useState(0);
  const { submit, mutators } = useForm();

  if (!showNavBar) return <></>;

  const onErrorObjectClick = () => {
    const isProperty = selectedType === OrganizationChildrenTypes.Property;
    const errorObject = objectListToStringNames(
      isProperty ? errors?.properties : errors?.requests,
      isProperty ? PROPERTIES_KEY : REQUESTS_KEY
    );

    const errorLength = errorObject?.length;

    if (errorLength < 0) return;

    let currentErrorIndex = errorIndex;

    errorIndex + 1 >= errorLength
      ? (currentErrorIndex = 0)
      : (currentErrorIndex += 1);

    const splitErrorLocation = errorObject[currentErrorIndex]?.name?.split(".");
    const isErrorParent = splitErrorLocation?.length === 1;

    !isErrorParent &&
      splitErrorLocation &&
      scrollWithinView(splitErrorLocation[0], 40);
    scrollWithinView(errorObject[currentErrorIndex]?.name, 40);

    setErrorIndex(currentErrorIndex);
  };

  const handleSubmit = () => {
    mutators.setChangedFields();
    if (orgID) setListableConfigStatus(orgID, properties);
    submit();
  };

  return (
    showNavBar && (
      <div className="p-2 border-4 bg-white text-white text-center mt-10 sticky bottom-2 flex justify-between w-full">
        {disabled && (
          <Alert severity="error" className="w-11/12">
            Form has Errors,{" "}
            <span
              className="text-blue-500 cursor-pointer"
              onClick={onErrorObjectClick}
            >
              click{" "}
            </span>
            to go to error
          </Alert>
        )}
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={disabled}
          style={{ marginLeft: "auto" }}
        >
          Submit
        </Button>
      </div>
    )
  );
}
