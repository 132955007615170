import React, { useState } from "react";
import auth0 from "auth0-js";
import { useHistory } from "react-router";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useLazyQuery, useMutation } from "@apollo/client";

import { APP_CONFIG_QUERY } from "graphql/queries";
import { VERIFY_MFA } from "graphql/mutations";

import logo from "assets/logo_long.png";

import { redirectToAuthenticatedApp } from "services/utils";
import { PIPEDRIVE_CONNECTED, TITLE_LOGIN } from "services/constants";
import { triggerAlert } from "services/alertService";

import useTabTitle from "hooks/useTabTitle";

const LoginPage = ({ location }) => {
  const history = useHistory();
  const classes = {
    loginContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      minHeight: "100%",
      width: "100%",
    },
    loginBox: {
      marginTop: "auto",
      marginBottom: "auto",
      width: "300px",
      display: "flex",
      flexDirection: "column",
    },
    loginImg: {
      marginBottom: "10px",
    },
    loginBtn: {
      marginTop: "10px",
    },
  };

  useTabTitle(TITLE_LOGIN);

  var auth0Client = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: "https://" + process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
    redirectUri: "localhost:3000/properties",
    scope: "read:current_user openid profile email",
    responseType: "token id_token",
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showMFA, setShowMFA] = useState(false);
  const [mfaCode, setMfaCode] = useState("");
  const redirectState = location.state;

  const [appConfigs] = useLazyQuery(APP_CONFIG_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      localStorage.setItem(
        PIPEDRIVE_CONNECTED,
        data.appConfigs.pipedrive_connected === true
      );

      if (data.appConfigs.mfa_enabled === true) {
        localStorage.setItem("mfa_enabled", true);
        setShowMFA(true);
      } else {
        triggerAlert("success", "Welcome");
        redirectToAuthenticatedApp(redirectState, "/", history);
      }
    },
  });

  const [verifyMFA] = useMutation(VERIFY_MFA, {
    onCompleted: (data) => {
      if (data.verifyMFA === true) {
        triggerAlert("success", "Welcome");
        localStorage.setItem("mfa_verified", true);
        redirectToAuthenticatedApp(redirectState, "/", history);
      } else {
        triggerAlert("error", null, "Failed to Verify");
      }
    },
    onError: (e) => {},
  });

  const onVerifyHandler = (e) => {
    e.preventDefault();
    verifyMFA({
      variables: {
        code: mfaCode,
      },
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    login(email, password);
  };

  const login = (username, password) => {
    auth0Client.client.login(
      {
        realm: "Username-Password-Authentication",
        username,
        password,
      },
      (err, authResult) => {
        if (err) {
          triggerAlert("error", "Error", "Login Failed. Please Try Again.");
          return;
        }
        if (authResult) {
          let expiresAt = JSON.stringify(
            authResult.expiresIn * 1000 + new Date().getTime()
          );
          localStorage.setItem("access_token", authResult.accessToken);
          localStorage.setItem("id_token", authResult.idToken);
          localStorage.setItem("expires_at", expiresAt);

          appConfigs();
        }
      }
    );
  };

  return (
    <div style={classes.loginContainer}>
      {showMFA === false && (
        <form onSubmit={onSubmitHandler} style={classes.loginBox}>
          <img src={logo} alt="Logo" style={classes.loginImg} />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            size="small"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            size="small"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={classes.loginBtn}
          >
            Log In
          </Button>
        </form>
      )}
      {showMFA === true && (
        <form onSubmit={onVerifyHandler} style={classes.loginBox}>
          <img src={logo} alt="Logo" style={classes.loginImg} />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="mfa_code"
            label="MFA Code"
            name="mfa_code"
            autoFocus
            size="small"
            onChange={(e) => {
              setMfaCode(e.target.value);
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={classes.loginBtn}
          >
            Verify
          </Button>
        </form>
      )}
    </div>
  );
};

export default LoginPage;
