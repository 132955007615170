import { Tabs, Tab } from "@mui/material";
import {
  Home as PropertyIcon,
  Person as ContactIcon,
  CorporateFare as OrganizationIcon,
  Article as RequestIcon,
} from "@mui/icons-material";
import TabPanel from "components/TabPanel";
import { useState } from "react";
import SearchTableResults from "./SearchTableResults";
import { EntityType } from "enums";

export function SearchTabs(): JSX.Element {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="flex border mt-5 min-h-main">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Organizations" icon={<OrganizationIcon />} />
          <Tab label="Contacts" icon={<ContactIcon />} />
          <Tab label="Properties" icon={<PropertyIcon />} />
          <Tab label="Requests" icon={<RequestIcon />} />
        </Tabs>
        <TabPanel index={0} value={value} className="w-full">
          <SearchTableResults
            title="Organizations"
            type={EntityType.ORGANIZATION}
          />
        </TabPanel>
        <TabPanel index={1} value={value} className="w-full">
          <SearchTableResults title="Contacts" type={EntityType.CONTACTS} />
        </TabPanel>
        <TabPanel index={2} value={value} className="w-full">
          <SearchTableResults
            title="Properties"
            type={EntityType.PROPERTY_CONFIG}
          />
        </TabPanel>
        <TabPanel index={3} value={value} className="w-full">
          <SearchTableResults title="Requests" type={EntityType.REQUEST} />
        </TabPanel>
      </div>
    </>
  );
}
