import React, { useEffect, useState } from "react";
import Disqualifiers from "./Disqualifiers";
import Score from "./Score";
import { RequestMatchResult } from "./types";
import { displayPrice, displayAvailability, sqftFromRange } from "./utils";

import { buildReferenceURL } from "services/utilities";
import { EntityType } from "enums";

interface Props {
  config: RequestMatchResult;
  // index of row in table - used to shade alternating rows differently
  rowIdx?: number;
  ignoredDisqualifiers: number;
}

const ConfigListRow: React.FC<Props> = ({
  config,
  rowIdx,
  ignoredDisqualifiers,
}) => {
  const [rowBg, setRowBg] = useState<string>("");
  useEffect(() => {
    if (rowIdx === undefined) return;
    setRowBg(`bg-${rowIdx % 2 ? "gray-50" : "white"}`);
  }, [rowIdx]);

  return (
    <tr className={rowBg + " h-10"}>
      <td>
        <Score
          matchResult={config.match_result}
          ignoredDisqualifiers={ignoredDisqualifiers}
        />
      </td>
      <td className="font-semibold text-blue-500">
        <a
          href={buildReferenceURL({
            id: config.entity_data.pipedrive_org_id,
            type: EntityType.ORGANIZATION,
          })}
          target="_blank"
          rel="noreferrer"
        >
          {config.entity_data.org_name}
        </a>
      </td>
      <td className="font-semibold text-blue-500">
        <a
          href={buildReferenceURL({
            id: config.entity_data.property_id,
            type: EntityType.PROPERTY,
          })}
          target="_blank"
          rel="noreferrer"
        >
          {config.entity_data.address}
        </a>
      </td>
      <td className="font-semibold text-blue-500">
        <a
          href={buildReferenceURL({
            id: config.entity_data.config_id,
            type: EntityType.PROPERTY_CONFIG,
          })}
          target="_blank"
          rel="noreferrer"
        >
          {config.entity_data.num_configs <= 1
            ? ""
            : config.entity_data.config_name || "N/A"}
        </a>
      </td>
      <td>
        {sqftFromRange(
          config.entity_data.square_footage_min,
          config.entity_data.square_footage_max
        )}
      </td>
      <td>
        {displayPrice(config.entity_data.price, config.entity_data.price_min)}
      </td>
      <td className="flex items-center">
        <Disqualifiers
          mr={config.match_result}
          ignored={ignoredDisqualifiers}
        />
      </td>
      <td>
        {config.entity_data.availability
          ? displayAvailability(
              config.entity_data.availability,
              config.entity_data.availability_notice
            )
          : "Immediately"}
      </td>
    </tr>
  );
};

export default ConfigListRow;
