import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useState } from "react";
import moment from "moment";
import {
  BasicPaginationTableProps,
  DataType,
  Order,
} from "interfaces/basicPaginatedTable.interface";

function descendingComparator<T>(a: T, b: T, orderBy: string, type: DataType) {
  const firstElement =
    type === "date" ? moment(a[orderBy], "YYYY MMM DD HH:mma") : a[orderBy];
  const secondElement =
    type === "date" ? moment(b[orderBy], "YYYY MMM DD HH:mma") : b[orderBy];

  if (secondElement < firstElement) {
    return -1;
  }
  if (secondElement > firstElement) {
    return 1;
  }
  return 0;
}

export function BasicPaginationTable<T>({
  rows,
  columns,
  defaultSort,
  defaultType,
}: BasicPaginationTableProps<T>): JSX.Element {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>(defaultSort);
  const [orderType, setOrderType] = useState<DataType>(defaultType);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: string,
    type: DataType
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setOrderType(type);
  };

  const createSortHandler =
    (property: string, type: DataType) =>
    (event: React.MouseEvent<unknown>) => {
      handleSort(event, property, type);
    };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="w-full">
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} className="w-4/12">
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : "asc"}
                  onClick={createSortHandler(column.accessor, column.type)}
                >
                  {column.header}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice()
            .sort((a, b) =>
              order === "desc"
                ? descendingComparator(a, b, orderBy, orderType)
                : -descendingComparator(a, b, orderBy, orderType)
            )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
                {Object.values(row)?.map((value, index) => (
                  <TableCell key={value + index}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
