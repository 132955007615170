export default function ManualPublishNotice(): JSX.Element {
  return (
    <div className="flex flex-col p-2 m-2 border rounded border-gray-200 bg-yellow-100">
      <strong>
        Manual Listing Management
      </strong>
      For a manually-managed listing provider,

      <ul className="list-disc ml-4">
        <li>To mark as manually published, set its listing link</li>
        <li>To mark as manually un-published, remove its listing link</li>
      </ul>
    </div>
  );
}
