import React, { useState, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextAreaInput = (props) => {
  const [value, setValue] = useState(props.input.value);

  const onChange = (newVal) => {
    setValue(newVal);
    props.input.onChange(newVal);
  };

  const modules = useMemo(
    () => ({
      // https://github.com/zenoamaro/react-quill/issues/250
      clipboard: {
        matchVisual: false,
      },
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["clean"],
      ],
    }),
    []
  );

  const formats = useMemo(
    () => [
      "header",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "indent",
      "clean",
    ],
    []
  );

  const style = {
    marginTop: "10px",
    background: "white",
  };

  return !props.hideInput ? (
    <div
      style={{
        ...style,
      }}
    >
      <ReactQuill
        theme="snow"
        style={{
          height: "100%",
          width: "100%",
        }}
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        readOnly={props.disabled}
      />
    </div>
  ) : (
    <div className="bg-gray-300 ml-1 mt-1 h-36"></div>
  );
};

export default RichTextAreaInput;
