type ErrorProps = {
  showError: boolean;
  error: string;
  style?: React.CSSProperties;
  color?: string;
};

export default function Error({
  showError,
  error,
  style,
  color = "red",
}: ErrorProps): JSX.Element {
  return showError ? (
    <span
      style={{
        color: color,
        fontSize: "14px",
        ...style,
      }}
    >
      {error}
    </span>
  ) : (
    <></>
  );
}
