import { GridSection } from "components/CardComponents";
import TextFieldReadOnly from "components/Forms/TextFieldReadOnly";
import { getEnumName } from "services/utils";
import {
  EnumContactMethod,
  EnumContactCategoryEmail,
  EnumContactCategoryPhone,
} from "services/enums";
import TooltipedIcon from "components/TooltipedIcon";
import OpenInPipedrive from "components/OpenInPipedrive";
import { PD_RESOURCE_PERSON } from "services/constants";
import { ContactDetailsProps } from "interfaces/contactDetails";
import { formatPhoneNumber } from "services/stringHelper";
import { ContactInfo } from "interfaces/organization";

const CreateContactLists = (title, contacts: ContactInfo[], lookUpEnumList) => {
  return (
    <div className="ml-2 mt-1">
      <label className="input-label">{title}</label>
      <div className="ml-2">
        {contacts?.length > 0 ? (
          <>
            {contacts?.map((contact, index) =>
              contact.contact_val ? (
                <div key={index} className="flex">
                  {`${contact.contact_val} (${getEnumName(
                    contact.contact_label,
                    lookUpEnumList
                  )})`}{" "}
                  {contact.contact_verified ? (
                    <TooltipedIcon
                      iconId={"action_save"}
                      size="xs"
                      minWidth="125px"
                      tooltipPosition="top"
                      state="green"
                      tooltipContent={<>Verified</>}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div key={index}>N/A</div>
              )
            )}
          </>
        ) : (
          "N/A"
        )}
      </div>
    </div>
  );
};

export default function ContactDetails({
  contact,
}: ContactDetailsProps): JSX.Element {
  const formattedPhoneContacts = contact.phone_numbers?.map((personPhone) => ({
    ...personPhone,
    contact: formatPhoneNumber(personPhone.contact_val),
  }));

  return (
    <GridSection
      title={`${contact.firstname} ${contact.lastname}`}
      md={4}
      xs={4}
      HeaderComponent={
        <OpenInPipedrive
          resourceType={PD_RESOURCE_PERSON}
          personPipedriveID={contact.id}
          showIconButton={true}
        />
      }
    >
      {CreateContactLists("Emails", contact.emails, EnumContactCategoryEmail)}
      {CreateContactLists(
        "Phones",
        formattedPhoneContacts,
        EnumContactCategoryPhone
      )}

      <div className="ml-1">
        <TextFieldReadOnly
          value={getEnumName(contact.preffered_method, EnumContactMethod)}
          label={"Preferred Contact"}
        />
      </div>
    </GridSection>
  );
}
