import React from "react";
import {matchCategories, MatchResult} from "./types";
import {Disqualifier, getDisqualifiers} from "./utils";
import Icon from "../Icon";

interface Props {
  mr: MatchResult;
  ignored: number;
}

const Disqualifiers: React.FC<Props> = ({mr, ignored}) => {
  return (
    <>
      {getDisqualifiers(mr, ignored).map((v: Disqualifier, i) => {
        return (
          <div
            className="m-1 tooltip-container flex items-center"
            key={i}
            title={matchCategories[v.cat].name}
          >
            <div className="m-auto">
              <Icon
                iconId={matchCategories[v.cat].iconId}
                state={v.ignored ? "disabled" : "score-great"}
              />
            </div>

            <div className="tooltip tooltip-top">
              <div
                style={{width: "200px"}}
                className="tooltip-content text-left p-2"
                dangerouslySetInnerHTML={{
                  __html: mr.Rationale[v.cat] ||
                  `<i class="text-gray-600">Sorry, the rationale for this disqualification isn't available.</i>`
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Disqualifiers;
