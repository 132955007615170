import React, { useEffect, useState, useRef } from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function ImageCarouselModal({ images, startIndex }) {
  const getModalStyle = (isPortrait) => {
    const top = 50;
    const left = 50;

    if (isPortrait) {
      return {
        position: "absolute",
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        maxHeight: `90%`,
        maxWidth: `100%`,
        objectFit: `contain`,
        userSelect: `none`,
      };
    } else {
      return {
        position: "absolute",
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        maxHeight: `100%`,
        maxWidth: `90%`,
        objectFit: `contain`,
        userSelect: `none`,
      };
    }
  };

  const [currIndex, setCurrIndex] = useState(startIndex);
  const [modalStyle, setModalStyle] = useState();
  const [size, setSize] = useState([0, 0]);

  const ref = useRef(null);

  const clickLeftSide = () => {
    if (images[currIndex - 1]) {
      setCurrIndex(currIndex - 1);
    } else if (!images[currIndex - 1]) {
      setCurrIndex(images.length - 1);
    }
  };
  const clickRightSide = () => {
    if (images[currIndex + 1]) {
      setCurrIndex(currIndex + 1);
    } else if (!images[currIndex + 1]) {
      setCurrIndex(0);
    }
  };
  const getInitSize = (w, h) => {
    let size = [0, 0];
    let windowHeight = window.innerHeight;
    let windowWidth = window.innerWidth;
    if (w < (window.innerWidth / window.innerHeight) * h) {
      if (h > windowHeight * 0.9) {
        size = [(w / h) * windowHeight * 0.9, windowHeight * 0.9];
      } else {
        size = [w, h];
      }
    } else {
      if (h > windowWidth * 0.9) {
        size = [windowWidth * 0.9, (h / w) * windowWidth * 0.9];
      } else {
        size = [w, h];
      }
    }
    return size;
  };

  useEffect(
    function () {
      if (images[currIndex]) {
        var w;
        var h;
        var img = new Image();
        img.src = images[currIndex].url;
        img.onload = () => {
          w = img.width;
          h = img.height;
          setModalStyle(
            getModalStyle(w < (window.innerWidth / window.innerHeight) * h)
          );

          if (ref?.current?.offsetWidth > 0 && ref?.current?.offsetHeight > 0) {
            setSize([ref.current.offsetWidth, ref.current.offsetHeight]);
          } else {
            var initSize = getInitSize(w, h);
            setSize(initSize);
          }
        };
      }
      return function cleanup() {};
    },
    [currIndex, images]
  );

  const classes = {
    leftIcon: {
      position: "absolute",
      top: "30%",
      left: "2%",
      width: "10%",
      height: "40%",
      zIndex: "2",
    },
    rightIcon: {
      position: "absolute",
      top: "30%",
      right: "0px",
      width: "10%",
      height: "40%",
      zIndex: "2",
    },
  };

  return (
    <div>
      {modalStyle && (
        <div>
          <ArrowBackIosIcon sx={classes.leftIcon} onClick={clickLeftSide} />
          <img
            src={images[currIndex].url}
            style={modalStyle}
            alt=""
            useMap="#workmap"
            ref={ref}
          />
          <ArrowForwardIosIcon
            sx={classes.rightIcon}
            onClick={clickRightSide}
          />
          <map name="workmap">
            <area
              shape="rect"
              coords={"0,0," + size[0] / 2 + "," + size[1]}
              alt=""
              onClick={clickLeftSide}
            />
            <area
              shape="rect"
              coords={size[0] / 2 + ",0," + size[0] + "," + size[1]}
              alt=""
              onClick={clickRightSide}
            />
          </map>
        </div>
      )}
    </div>
  );
}
