import React from "react";

import NumberFormat from "react-number-format";
import Field from "components/Forms/Field";
import BaseInput from "./BaseInput";

import { positive } from "services/forms/validators";
import { filterFieldProps } from "services/utils";
import Error from "./Error";
import StaticModeInput from "./StaticModeInput";

function NumberInput({
  staticHoverText = "",
  isReadOnly = false,
  allowNegative = false,
  ...props
}) {
  const fieldProps = filterFieldProps(props);
  const showErrorStyle = (hasError) => {
    return hasError ? "input-error" : "input-default";
  };
  return (
    <BaseInput
      {...props}
      staticHoverText={staticHoverText}
      readOnly={isReadOnly}
      postInputLabel={props.postInputLabel}
    >
      {({ isInputReadOnly, inputStaticHoverText }) => (
        <>
          {!isInputReadOnly && (
            <Field {...fieldProps} validate={positive}>
              {({ input: { value, onChange }, meta }) => (
                <div className={props.className}>
                  <NumberFormat
                    className={showErrorStyle(meta.error)}
                    value={value}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    allowNegative={allowNegative}
                    onValueChange={({ floatValue }) => onChange(floatValue)}
                  />
                  {props.postInputLabel && (
                    <span
                      style={{
                        marginLeft: "4px",
                        marginRight: "2px",
                        marginTop: "2px",
                      }}
                    >
                      {props.postInputLabel}
                    </span>
                  )}
                  <div className="flex">
                    <Error showError={meta.error} error={meta.error} />
                  </div>
                </div>
              )}
            </Field>
          )}

          {isInputReadOnly && (
            <StaticModeInput
              fieldProps={fieldProps}
              staticHoverText={inputStaticHoverText}
              name={props.name}
            />
          )}
        </>
      )}
    </BaseInput>
  );
}

export default NumberInput;
