import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Form } from "react-final-form";
import _ from "lodash";

import { Prompt } from "react-router";

import useTabTitle from "../../hooks/useTabTitle";

export default function GQLForm({
  loadQuery,
  loadID,
  loadSkip,
  postLoadFn,
  preSaveFormat,
  saveQuery,
  postSaveFn,
  postErrorFn,
  initDataFn,
  initData,
  gqlMutationArgName,
  gqlQueryArgName,
  tabTitleFn,
  ...rest
}) {
  // Set default property names for the JSON payload variable
  if (!gqlMutationArgName) {
    gqlMutationArgName = "input";
  }
  if (!gqlQueryArgName) {
    gqlQueryArgName = "id";
  }

  const [initState, setInitState] = useState(initData);
  const [unsavedChange, setUnsavedChange] = useState(false);
  const [beingSubmitted, setBeingSubmitted] = useState(false);
  const setTabTitle = useTabTitle(tabTitleFn());

  useEffect(function () {
    return function cleanup() {
      window.onbeforeunload = () => undefined;
    };
  }, []);

  const setUnsavedChangeCallback = (val) => {
    if (val === true) {
      setUnsavedChange(true);
      window.onbeforeunload = () => true;
    } else {
      setUnsavedChange(false);
      window.onbeforeunload = () => undefined;
    }
  };

  // TODO: pass this down to child, and when reset click, call initTreeful() and then onLoadComplete(),, maybe this works?!
  const onLoadComplete = (data) => {
    if (data) {
      setInitState(postLoadFn(data));
      setTabTitle(tabTitleFn(data));
    }
  };

  const { loading, error } = useQuery(loadQuery, {
    variables: { [gqlQueryArgName]: loadID },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    skip: loadSkip,
    onCompleted: onLoadComplete,
  });

  const onCompleted = (data) => {
    setUnsavedChange(false);
    window.onbeforeunload = undefined;

    setBeingSubmitted(false);

    postSaveFn(data);
  };

  const onError = (err) => {
    postErrorFn(err);
  };

  const [saveQ] = useMutation(saveQuery, {
    onCompleted: onCompleted, // called with return value
    onError: onError,
  });

  const save = useCallback(
    async (values) => {
      setBeingSubmitted(true);
      values = preSaveFormat ? preSaveFormat(values) : values;
      saveQ({
        variables: { [gqlMutationArgName]: values },
      });
    },
    [preSaveFormat, saveQ, gqlMutationArgName]
  );

  // debounce submit handler so n clicks do not cause n save requests
  const debouncedSaveFn = React.useMemo(
    () =>
      _.debounce(save, 1000, {
        leading: true, // save on first click and ignore subsequent clicks for 1s
        trailing: false,
      }),
    [save]
  );

  if (!loadSkip) {
    if (error) {
      return <div>Server Error !</div>;
    }

    if (loading) {
      return <div>Loading...</div>;
    }
  }

  const updateInitStateFromValues = (values) => {
    setInitState(values);
  };

  return (
    <>
      <Prompt
        when={unsavedChange}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Form
        {...rest}
        initialValues={initState}
        onSubmit={debouncedSaveFn}
        setUnsavedChange={setUnsavedChangeCallback}
        onLoadComplete={onLoadComplete}
        updateInitStateFromValues={updateInitStateFromValues}
        beingSubmitted={beingSubmitted}
        unsavedChange={unsavedChange}
      />
    </>
  );
}
