import { useCallback, useContext, useState, useEffect } from "react";
import { Modal, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { ALL_INFO_QUERY } from "graphql/queries";
import { UPSERT_ALL } from "graphql/mutations";
import { useMutation, useLazyQuery } from "@apollo/client";
import __ from "lodash";

import { initProperties, preSaveProperties } from "services/forms/property";
import { initRequests, preSaveRequests } from "services/forms/guest";

import TabPanel from "components/TabPanel";
import SubmitBar from "components/SubmitBar";

import { mutators as propertyMutators } from "services/forms/property";
import { mutators as guestMutators } from "services/forms/guest";
import { validate } from "services/forms/validators";
import { FormContext } from "contexts";

import OrganizationInfo from "./OrganizationInfo";
import PropertyTenantRequestSelector from "./PropertyTenantRequestSelector";
import Properties from "./Properties";
import TenantRequests from "./TenantRequests";
import { DeleteConfig, ScrollSettings } from "interfaces/formContext";
import { ListingPrompt, OrganizationOverview } from "interfaces/organization";
import { OrganizationChildrenTypes } from "enums/organizationTypes";
import { FormCommand } from "enums/formEnums";
import Changelogs from "components/Changelogs";
import { mutators as sharedMutators } from "services/forms/sharedMutators";
import { AppContext } from "contexts/AppContext";
import ToolTipIconButton from "components/ToolTipIconButton";
import { SESSION_STORAGE_KEYS } from "services/constants";
import { triggerAlert } from "services/alertService";
import ConfirmationDialog from "components/Forms/ConfirmationDialog";
import {
  checkListingStatus,
  clearListableConfigStatus,
} from "services/listingPromptService";
import { UnlistedPromptMessage } from "./UnlistedPromptMessage/UnlistedPromptMessge";
import { useOrganizationParams } from "hooks";
import { OrganizationResponse } from "graphql/interface";
import ListingManagement from "./ListingMangement";

const styles = (theme): React.CSSProperties => {
  return {
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: "flex",
    flexDirection: "column",
    fontSize: "0.875rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  };
};

const changed_fields = [];

export default function Organization(): JSX.Element {
  const {
    required_config_fields,
    required_request_fields,
    required_property_fields,
  } = useContext(AppContext);
  const theme = useTheme();
  const { type: typeParam, id } = useOrganizationParams();

  const [type, setType] = useState<OrganizationChildrenTypes>(
    OrganizationChildrenTypes.Property
  );
  const [currentFormCommand, setFormCommand] = useState<FormCommand>(
    FormCommand.IDLE
  );
  const [deleteEntities, setDeleteEntities] = useState<Array<DeleteConfig>>([]);
  const [data, setData] = useState<OrganizationOverview>({
    org: undefined,
    listing_uploads: [],
    properties: [],
    requests: [],
    isLoaded: false,
  });
  const [shouldScrollWithinView, setShouldScrollWithinView] =
    useState<ScrollSettings>({
      name: null,
      offset: 0,
    });
  const [publishWarning, setPublishWarning] = useState<ListingPrompt>({
    result: false,
    unlistedData: null,
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalChild, setModalChild] = useState<JSX.Element | null>(null);

  const setInitialData = (values) => {
    const allInOneValues = values?.allInfo;
    setData({
      ...allInOneValues,
      org_id: allInOneValues?.org_id,
      listing_uploads: allInOneValues?.listing_uploads,
      properties: initProperties(allInOneValues?.properties),
      requests: initRequests(allInOneValues?.requests),
      isLoaded: true,
    });
  };

  const [getAllInOneData, { loading, error }] = useLazyQuery(ALL_INFO_QUERY, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    onCompleted: setInitialData,
  });

  const { properties, org, requests, listing_uploads } = data;
  const orgID = org?.id;

  const onFormSubmit = (data: OrganizationResponse) => {
    const parts = window.location.href.split("/");
    const lastPath = parts.pop() || parts.pop();

    if (lastPath?.includes("-")) {
      const matchingEntity = data?.upsertPropertiesAndRequests?.find(
        (entity) => entity.entity_type === typeParam?.toString()
      );

      window.location.replace(`/${typeParam}/${matchingEntity?.entity_id}`);
      return;
    }

    deleteEntities.length > 0
      ? window.location.replace(`/org/${orgID}`)
      : window.location.reload();
  };

  const [upsertForm] = useMutation(UPSERT_ALL, {
    onCompleted: onFormSubmit,
  });

  const save = useCallback(
    async (values) => {
      upsertForm({
        variables: { input: values },
      });
    },
    [upsertForm]
  );

  const formStateKey = `${SESSION_STORAGE_KEYS.FORM_STATE}${orgID}`;

  const onSubmit = async (values) => {
    sessionStorage.setItem(formStateKey, JSON.stringify(values));
    save({
      org_id: orgID,
      properties: preSaveProperties(values?.properties),
      requests: preSaveRequests(values?.requests),
      changed_fields: values?.changed_fields,
      deleted: deleteEntities,
    });
  };

  const filterDeleteEntities = (entitesToAdd) => {
    setDeleteEntities([
      ...deleteEntities,
      ...entitesToAdd?.filter((entity) => entity.entity_id > 0),
    ]);
  };

  useEffect(() => {
    if (!data?.isLoaded) {
      getAllInOneData({ variables: { infoRequest: { id, type: typeParam } } });
    }
  }, [data?.isLoaded, getAllInOneData, id, typeParam]);

  useEffect(() => {
    if (!loading && orgID) {
      const unlistedListings = checkListingStatus(orgID, data.properties);
      setPublishWarning({
        result: Object.keys(unlistedListings).length > 0,
        unlistedData: unlistedListings,
      });
    }
  }, [data.properties, loading, orgID]);

  if (error) {
    return <div>Server Error !</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full">
      <Form
        initialValues={{
          properties,
          requests,
          changed_fields,
        }}
        onSubmit={onSubmit}
        mutators={{ ...propertyMutators, ...guestMutators, ...sharedMutators }}
        validate={(values) =>
          validate(
            values,
            required_request_fields,
            required_property_fields,
            required_config_fields,
          )
        }
        render={({
          handleSubmit,
          values,
          invalid,
          errors,
          initialValues,
          form,
        }) => {
          return (
            <FormContext.Provider
              value={{
                formCommand: currentFormCommand,
                setFormCommand,
                propertyLength: values?.properties?.length,
                requestLength: values?.requests?.length,
                setShouldScrollWithinView,
                shouldScrollWithinView,
                setOpenModal,
                setModalChild,
                organizationData: {
                  name: org?.details?.name,
                  org_id: orgID,
                },
                setDeleteEntities: filterDeleteEntities,
                currentFormType: type,
                setType,
                isSelfListingConvertAllowed: listing_uploads?.length > 0,
              }}
            >
              <OrganizationInfo data={org} orgId={orgID} />
              {listing_uploads?.length > 0 && (
                <ListingManagement listingManagementList={listing_uploads} />
              )}
              <form id={"organization-form"} onSubmit={handleSubmit}>
                <PropertyTenantRequestSelector
                  type={type}
                  onChange={setType}
                  propertyLength={values?.properties?.length}
                  tenantRequestLength={values?.requests?.length}
                />
                <div className="mt-3">
                  <div className="flex justify-end">
                    <ToolTipIconButton
                      iconId="action_restore"
                      title="Restore"
                      message="Restore last saved form values"
                      state={
                        sessionStorage.getItem(formStateKey)
                          ? "default"
                          : "disabled"
                      }
                      onClick={() => {
                        form.mutators.setFormState(orgID);
                        triggerAlert(
                          "success",
                          "Restore Form",
                          "Form values have been restored"
                        );
                      }}
                    />
                  </div>
                  <TabPanel
                    value={type}
                    index={OrganizationChildrenTypes.Property}
                  >
                    <Properties
                      properties={values?.properties}
                      propertiesInitialValues={initialValues.properties}
                    />
                  </TabPanel>
                  <TabPanel
                    value={type}
                    index={OrganizationChildrenTypes.TenantRequest}
                  >
                    <TenantRequests tenantRequests={values?.requests} />
                  </TabPanel>
                </div>
                <Changelogs objectID={orgID} title={"org"} />
                <Modal
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div style={styles(theme)}>{modalChild}</div>
                </Modal>
                <ConfirmationDialog
                  title={"WARNING"}
                  content={
                    <UnlistedPromptMessage
                      listingConfigs={publishWarning.unlistedData}
                    />
                  }
                  open={publishWarning.result}
                  onConfirm={() => {}}
                  ignoreConfirm={true}
                  onClose={() => {
                    if (orgID) clearListableConfigStatus(orgID);
                    setPublishWarning({ result: false, unlistedData: null });
                  }}
                />
                <SubmitBar
                  disabled={invalid}
                  showNavBar={!__.isEqual(initialValues, values)}
                  errors={errors}
                  selectedType={type}
                  orgID={orgID}
                  properties={values.properties}
                />
              </form>
            </FormContext.Provider>
          );
        }}
      />
    </div>
  );
}
