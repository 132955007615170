import { useMemo, useContext, useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { Grid } from "@mui/material";
import { OnChange } from "react-final-form-listeners";
import __ from "lodash";
import PlaceFeeDetails from "components/PlaceFeeDetails";
import ConfirmationDialog from "components/Forms/ConfirmationDialog";

import {
  placeFeeDetailed,
  isEmptyString,
  isPropertyStatusMatchable,
  isUSCountry,
} from "services/utils";
import {
  PropertyDetails,
  Location,
  WareHouseConfigs,
  PermittedUsage,
  SpaceAmenities,
  Term,
  PropertyExclusivity,
  Price,
  Image as ImageSection,
  Listing,
  PropertyStatus,
} from "../FormSections";

import {
  PS_AVAILABLE_NOMATCH,
  PS_BOOKED_NOMATCH,
  PS_PENDING,
  PS_UNAVAILABLE,
  PS_REVIEW_REJECTED,
} from "services/constants";

import { placeFeeWarning } from "services/forms/validators";
import NotesSection from "components/NotesSection";
import { fieldNamesToReadableStrings } from "services/stringHelper";
import { usePlaceFee } from "hooks";
import { FormContext } from "contexts";
import { PropertyConfigProps } from "interfaces/propertyConfig.interface";
import { ListingUnpublishWarning } from "components/ListingUnpublishPrompt/ListingUnpublishPrompt";
import { GridSection } from "components/CardComponents";

export function PropertyConfig({
  isProperty,
  mutators,
  propertyIndex,
  property,
  configs,
  configIndex,
  isEditable,
  initialConfigValue,
  setListingPublishWarning,
  setListingUnpublishWarning,
  setStatusNotPublishableWarning,
}: PropertyConfigProps): JSX.Element {
  const [isConfigLost, setIsConfigLost] = useState<boolean>(false);
  const [isConfigRejected, setIsConfigRejected] = useState<boolean>(
    property?.configs[configIndex].status === PS_REVIEW_REJECTED
  );

  const listable = configs?.listable;
  const propertyName = `properties[${propertyIndex}]`;
  const configName = `${propertyName}.configs[${configIndex}]`;
  const isSelfListed =
    property?.configs[configIndex].self_listing !== undefined;
  const [selfListingWarning, setselfListingWarning] = useState(isSelfListed);

  const { setOpenModal, setModalChild, isSelfListingConvertAllowed } =
    useContext(FormContext);

  const setPropertyExclusivity = useMemo(
    () => mutators.setPropertyExclusivity.bind(null, propertyIndex),
    [mutators.setPropertyExclusivity, propertyIndex]
  );

  const placeFee = usePlaceFee({
    configIndex,
    configName: configs?.name,
    configID:
      configs?.config_id != null && configs.config_id > 0
        ? configs?.config_id
        : undefined,
    propertyType: property?.property_type,
    warehouseType: configs?.warehouse_type,
    province: property?.province,
    listFeeBasis: configs?.list_fee_basis,
    listFee: configs?.list_fee,
    squareFootageMin: configs?.square_footage_min,
    squareFootageMax: configs?.square_footage_max,
  });

  return (
    <>
      <Grid container spacing={2} marginTop={"1px"}>
        <ListingUnpublishWarning
          {...configs}
          isListable={configs?.listable?.result}
        />
        <OnChange name={`${configName}.status`} key={`status-${configIndex}`}>
          {(curr, previous) => {
            if (initialConfigValue?.status === PS_UNAVAILABLE) {
              return;
            }
            previous !== PS_UNAVAILABLE &&
            !isEmptyString(previous) &&
            curr === PS_UNAVAILABLE
              ? setIsConfigLost(true)
              : setIsConfigLost(false);

            if (curr === PS_REVIEW_REJECTED) {
              setIsConfigRejected(true);
            }
          }}
        </OnChange>

        <OnChange
          name={`${configName}.term_min`}
          key={`term_min-${configIndex}`}
        >
          {(curr) => {
            if (configs?.term_type === "1") {
              mutators.setTermMax(propertyIndex, curr, configIndex);
            }
          }}
        </OnChange>

        <Grid item xs={9} container spacing={2}>
          {isSelfListed && (
            <ConfirmationDialog
              title={"WARNING"}
              content={`This listing is self listed, Actions are limited, please read the warning label.`}
              open={selfListingWarning}
              onConfirm={() => {}}
              ignoreConfirm={true}
              onClose={() => {
                setselfListingWarning(false);
              }}
            />
          )}

          {isSelfListingConvertAllowed && !isSelfListed && (
            <GridSection md={24} xs={24}>
              <div className="flex">
                <label className="input-label whitespace-normal">
                  Convert to Self Listing:
                </label>
                <div className="ml-1">
                  <Field
                    name={`${configName}.self_listing.convert_to_selflisting`}
                    component="input"
                    type="checkbox"
                  />
                </div>
              </div>
            </GridSection>
          )}

          <PropertyStatus
            propertyName={propertyName}
            configName={configName}
            isPropertyChangedToUnavailable={isConfigLost}
            isConfigRejected={isConfigRejected}
            selfListing={
              isSelfListed ? property?.configs[configIndex].self_listing : null
            }
            configID={property?.configs[configIndex]?.config_id}
          />

          <Location
            isEditable={isProperty}
            address_known={property.address_known}
            property={property}
            updateAddress={mutators.updateAddress}
            clearUnitNumber={mutators.clearUnit}
            propertyIndex={propertyIndex}
            propertyName={propertyName}
          />
          <WareHouseConfigs
            configName={configName}
            propertyName={propertyName}
            warehouseType={configs?.warehouse_type}
          />
          <SpaceAmenities configName={configName} propertyName={propertyName} />
          <PermittedUsage configName={configName} />
          <Price
            configName={configName}
            propertyName={propertyName}
            isEditable={isEditable}
            onClickShowDetail={() => {
              setModalChild(<PlaceFeeDetails feeData={placeFee} />);
              setOpenModal(true);
            }}
            placeFee={placeFeeDetailed(placeFee)}
            effectiveFeePC={placeFee?.effectiveFeePercent}
            warningMsg={placeFeeWarning(
              property.province,
              property.property_type,
              configs?.list_fee_basis,
              configs?.list_fee
            )}
            isUSCountry={isUSCountry(property.country)}
          />
          <Term configName={configName} />
          <PropertyDetails propertyName={propertyName} />
          <PropertyExclusivity
            isEditable={isProperty}
            setPropertyExclusivity={setPropertyExclusivity}
            data={property.exclusives}
            currentExclusiveName={`${propertyName}.exclusives`}
          />
          <ImageSection
            isEditable={isProperty}
            photos={property.photos}
            addImages={mutators.addImages}
            moveImages={mutators.moveImages}
            deleteImages={mutators.deleteImages}
            updateImageHandle={mutators.updateImageHandle}
            floorplans={property.floorplans}
            propertyIndex={propertyIndex}
            propertyName={propertyName}
          />

          <Listing
            configIndex={configIndex}
            propertyIndex={propertyIndex}
            isEditable={isEditable}
            property={property}
            checkImages={mutators.checkImages}
            configName={configName}
            failedReasons={fieldNamesToReadableStrings(listable?.reasons ?? [])}
            isListable={listable?.result ?? false}
          />

          {property && configs && (
            <OnChange
              name={`${configName}.status`}
              key={`matchable-status-${configIndex}`}
            >
              {(curr, previous) => {
                // get any listing published for the configuration
                const configListings = configs.listings.providers;

                // case 1 Prompt if not listed AND status changes from nomatch to matchable
                if (
                  !isPropertyStatusMatchable({ status: previous }) &&
                  isPropertyStatusMatchable({ status: curr })
                ) {
                  // if theres no listing, we promt to add listings
                  if (__.isEmpty(configListings)) {
                    // show the listing publishing warning.
                    setListingPublishWarning(true);
                  }
                  // case 2 Prompt if listed AND status changes from matchable to nomatch
                } else if (
                  isPropertyStatusMatchable({ status: previous }) &&
                  !isPropertyStatusMatchable({ status: curr })
                ) {
                  // if there are listing warn to delete listing
                  if (
                    !__.isEmpty(configListings) &&
                    configListings.length > 0 &&
                    initialConfigValue != null &&
                    initialConfigValue.listings?.providers?.length > 0
                  ) {
                    // show the listing unpublishing warning.
                    setListingUnpublishWarning(true);
                  }
                }
              }}
            </OnChange>
          )}

          <OnChange name={`${configName}.square_footage_min`}>
            {() => {
              if (!configs?.has_range_of_space) {
                mutators.setSquareFootageMax({
                  propertyIndex,
                  configIndex,
                  value: configs?.square_footage_min,
                });
              }
            }}
          </OnChange>

          <FormSpy
            subscription={{
              dirtyFields: true,
              initialValues: true,
              values: true,
            }}
            onChange={({ dirtyFields, values, initialValues }) => {
              // listen for listings change and show warning if status it not appropriate.
              const listingsKey = `${configName}.listings.providers`;
              if (
                listingsKey in dirtyFields &&
                dirtyFields[listingsKey] === true
              ) {
                const initialConfigs =
                  initialValues?.properties[propertyIndex]?.configs;
                const initialConfigValue = initialConfigs
                  ? initialConfigs[configIndex]
                  : null;
                const currentValue =
                  values?.properties[propertyIndex]?.configs[configIndex];
                let previous: null | unknown[] = null;
                if (initialConfigValue == null) {
                  previous = null;
                } else {
                  previous = initialConfigValue?.listings.providers;
                }
                let curr: null | unknown[] = null;
                if (initialConfigValue == null) {
                  curr = null;
                } else {
                  curr = currentValue?.listings.providers;
                }

                if (
                  (!previous || previous === null || previous?.length === 0) &&
                  curr != null &&
                  curr.length > 0 &&
                  [
                    PS_PENDING,
                    PS_AVAILABLE_NOMATCH,
                    PS_BOOKED_NOMATCH,
                    PS_UNAVAILABLE,
                  ].includes(currentValue.status)
                ) {
                  setStatusNotPublishableWarning(true);
                }
              }
            }}
          ></FormSpy>
        </Grid>
        {!isSelfListed && (
          <Grid item xs={3}>
            <NotesSection fieldName={`${propertyName}.notes`} />
          </Grid>
        )}
      </Grid>
    </>
  );
}
