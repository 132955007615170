import React, { useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

function GuestMap({
  locations,
  addLocation,
  removeLocation,
  defaultBounds,
  index,
  mapSize = {
    width: "736px",
    height: "400px",
  },
}) {
  const [map, setMap] = React.useState(null);
  const [recenter, setRecenter] = React.useState(true);

  let update_timeout = null;

  const addPin = (e) => {
    setRecenter(false);
    addLocation({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
  };

  const onClickDelete = (location) => {
    var r = window.confirm("Delete the location?");
    if (r === true) {
      removeLocation(location);
    }
  };

  // allowing again if changed to new config
  useEffect(() => {
    setRecenter(true);
  }, [index]);

  useEffect(() => {
    const recenterAroundMarkers = () => {
      const bounds = defaultBounds;
      if (locations?.length > 0) {
        locations.forEach((p) =>
          bounds.extend({ lat: p.latitude, lng: p.longitude })
        );
      }
      map.fitBounds(bounds);
      if (map.getZoom() > 13) {
        map.setZoom(13);
      }
    };

    // fit markers around bounds onload
    if (map && defaultBounds && recenter) {
      recenterAroundMarkers();
    }
  }, [
    defaultBounds,
    index,
    locations,
    map,
    recenter,
  ]);

  if (!defaultBounds) return null;

  return (
    <GoogleMap
      key={`request-map-${index}`}
      mapContainerStyle={mapSize}
      zoom={12}
      onLoad={(map) => {
        setMap(map);
      }}
      onClick={(e) => {
        update_timeout = setTimeout(function () {
          addPin(e);
        }, 200);
      }}
      onDblClick={() => {
        clearTimeout(update_timeout);
      }}
    >
      {locations &&
        locations.map((location, idx) => {
          if (
            location.latitude &&
            location.latitude !== 0 &&
            location.longitude &&
            location.longitude !== 0
          ) {
            return (
              <Marker
                key={"guest_marker_" + idx}
                position={{ lat: location.latitude, lng: location.longitude }}
                onClick={() => {
                  onClickDelete(location);
                }}
              ></Marker>
            );
          }
          return null;
        })}
    </GoogleMap>
  );
}

export default React.memo(GuestMap);
