import { useState } from "react";
import { Typography, Grid, Tooltip, Alert } from "@mui/material";
import {
  MapsHomeWork as MapsHomeWorkIcon,
  House as HouseIcon,
  Description as DescriptionIcon,
  Person as PersonIcon,
} from "@mui/icons-material";

import {
  DetailSection,
  ContactsSection,
  ContactDetails,
} from "./OrganizationSections";
import { Organization as OrganizationInterface } from "interfaces/organization";
import { GridSection } from "components/CardComponents";

interface DataProps {
  data: OrganizationInterface | undefined;
  orgId: number | undefined;
}

const getOrganizationType = (type) => {
  const className = "ml-2 mb-0.5";

  switch (type) {
    case 1:
      return (
        <Tooltip title="Has Tenant Requests" arrow placement="top-end">
          <DescriptionIcon className={className} />
        </Tooltip>
      );
    case 2:
      return (
        <Tooltip title="Has Properties" arrow placement="top-end">
          <HouseIcon className={className} />
        </Tooltip>
      );
    case 3:
      return (
        <Tooltip
          title="Has Properties and Tenant Requests"
          arrow
          placement="top-end"
        >
          <MapsHomeWorkIcon className={className} />
        </Tooltip>
      );
    default:
      return <></>;
  }
};

export default function Organization({ data, orgId }: DataProps): JSX.Element {
  const details = data?.details;
  const contacts = data?.persons ?? [];

  const [personIndex, setPersonIndex] = useState<number | null>(
    contacts?.length > 0 ? 0 : null
  );

  return (
    <div className="flex-col">
      <div className="flex justify-between w-full mb-5">
        <Typography sx={{ fontWeight: "bold" }} variant="h6">
          {details ? details?.name : "No Organization Found"}
          {getOrganizationType(details?.type)}
        </Typography>
        {details?.owner_name && (
          <div className="flex">
            <Tooltip title="Owner" arrow placement="top-end">
              <PersonIcon />
            </Tooltip>
            <Typography>{details?.owner_name}</Typography>
          </div>
        )}
      </div>

      <Grid container spacing={2}>
        {!details ? (
          <Alert severity="error" className="w-full pd-1">
            {`Error: Organization information is missing from Pipedrive.
            (Org Id: ${orgId}) Please notify the dev team.`}
          </Alert>
        ) : (
          <>
            <DetailSection details={details} />
            <ContactsSection
              contacts={contacts}
              setPersonIndex={setPersonIndex}
              personIndex={personIndex}
            />
            {personIndex != null ? (
              <ContactDetails contact={contacts[personIndex]} />
            ) : (
              <GridSection md={4} xs={4}>
                <div className="w-full"></div>
              </GridSection>
            )}
          </>
        )}
      </Grid>
    </div>
  );
}
