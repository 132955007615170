import React from "react";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";

import ListingImage from "./ListingImage";

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;

  width: 450px;
  height: 150px;
  overflow-y: scroll;
`;
const ImageList = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const HiddenBox = styled.div`
  background-color: #f6f6f6;
  width: 650px;
  height: 133px;
  margin-left: 10px;
`;

export default function ListingImageRow({
  images,
  checkImage,
  staticWhenProperty,
  staticHoverText,
  showFullSizeImage,
  isPhotos,
  configImages,
}) {
  if (staticWhenProperty === true) {
    return (
      <Tooltip title={staticHoverText || ""} placement="top-start" arrow>
        <HiddenBox />
      </Tooltip>
    );
  }

  return (
    <>
      {images && images.length > 0 && (
        <Container>
          <ImageList>
            {images.map((image, index) => {
              return (
                <ListingImage
                  key={index}
                  image={image}
                  onClickCheck={checkImage}
                  showCheckBox={true}
                  configImages={configImages}
                  showFullSizeImage={() => showFullSizeImage(isPhotos, index)}
                />
              );
            })}
          </ImageList>
        </Container>
      )}
    </>
  );
}
