import React, { useContext } from "react";

import { PropertyFormContext, FormContext } from "contexts";
import Field from "components/Forms/Field";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ChatIcon from "@mui/icons-material/Chat";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import Condition from "./Condition";
import { fieldToHelpText } from "services/utils";
import { OrganizationChildrenTypes } from "enums";
import {
  CONFIGS_KEY,
  PROPERTIES_KEY,
  HOVER_PROPERTY_FIELD,
  HOVER_LISTING_FIELD,
} from "services/constants";

const classes = {
  inputContainer: {
    padding: "5px",
    whiteSpace: "pre",
  },
  labelContainer: {
    paddingRight: "5px",
    display: "flex",
  },
  sideNote: {
    display: "absolute",
    marginLeft: 8,
    top: 0,
    left: 0,
    marginTop: 5,
  },
  helpText: {
    fontSize: "26px",
  },
  hide: {
    display: "none",
  },
};

const inputWidthModifier = (className, defaultWidth = "w-9/12") => {
  return className?.includes("w-")
    ? className
    : `${defaultWidth} ${className ?? ""}`;
};

export default function BaseInput(props) {
  let {
    children,
    name,
    label,
    isSideNote,
    sideNoteNamespace,
    hideInput,
    hideTextAreaInput,
    readOnly,
    helpTextModifier,
    isNolabelWithSpacing,
    staticHoverText,
    overRideAutoInputSettings,
    className,
    indent,
    defaultShowSideNote,
  } = props;

  const { isProperty, isConfigLevelFieldEditable } =
    useContext(PropertyFormContext);
  const { currentFormType } = useContext(FormContext);

  const [showSideNote, setShowSideNote] = React.useState(defaultShowSideNote);
  const [firstView, setFirstView] = React.useState(
    defaultShowSideNote == null ? true : false
  );

  const inputVisibilityHandler = () => {
    if (currentFormType === OrganizationChildrenTypes.Property) {
      const nameComponents = name?.split(".");

      const hasPropertiesKey = nameComponents?.[0]?.includes(
        `${PROPERTIES_KEY}[`
      );
      const haseConfigsKey = nameComponents?.[1]?.includes(`${CONFIGS_KEY}[`);

      if (hasPropertiesKey && haseConfigsKey) {
        return {
          hideInput: !isConfigLevelFieldEditable,
          staticHoverText: HOVER_LISTING_FIELD,
          isReadOnly: null,
        };
      }

      if (hasPropertiesKey) {
        return {
          hideInput: null,
          staticHoverText: HOVER_PROPERTY_FIELD,
          isReadOnly: !isProperty,
        };
      }
    }
    return null;
  };

  let siteNoteVar = name + "_nb";
  if (sideNoteNamespace !== undefined) {
    siteNoteVar = sideNoteNamespace + "_nb";
  }

  let helpText = fieldToHelpText(name);
  if (helpText !== undefined && helpTextModifier)
    helpText = helpTextModifier(helpText);

  const isHelp = helpText !== undefined;
  const textAreaSize = { row: 4, col: 26 };
  const inputVisibilityProps = inputVisibilityHandler();

  if (inputVisibilityProps && !overRideAutoInputSettings) {
    hideInput = inputVisibilityProps.hideInput;
    staticHoverText = staticHoverText
      ? staticHoverText
      : inputVisibilityProps.staticHoverText;
    readOnly = readOnly ? readOnly : inputVisibilityProps.isReadOnly;
  }

  const showBubbleIcon = isSideNote && !readOnly;

  return (
    <div className={`p-1 whitespace-pre ${indent ? `ml-${indent}` : ""}`}>
      {label && (
        <div style={classes.labelContainer}>
          <label className="input-label whitespace-normal">{label}</label>
          {isHelp && (
            <Tooltip title={`${helpText}`} placement="top-start" arrow>
              <HelpOutlineIcon className="ml-0.5" fontSize="small" />
            </Tooltip>
          )}
          {isSideNote && readOnly && (
            <Condition when={siteNoteVar} isNot="">
              <ChatIcon fontSize="small" className="ml-1" />
            </Condition>
          )}
          {showBubbleIcon && (
            <div className="ml-1">
              <Condition when={siteNoteVar} is="">
                <ChatBubbleOutlineIcon
                  onClick={() => {
                    setShowSideNote(!showSideNote);
                    setFirstView(false);
                  }}
                  fontSize="small"
                  data-cy={`text-bubble-${siteNoteVar}`}
                />
              </Condition>

              <Condition when={siteNoteVar} isNot="">
                <ChatIcon
                  onClick={() => {
                    if (firstView) {
                      setShowSideNote(false);
                    } else {
                      setShowSideNote(!showSideNote);
                    }
                    setFirstView(false);
                  }}
                  fontSize="small"
                  data-cy={`text-bubble-${siteNoteVar}`}
                />
              </Condition>
            </div>
          )}
        </div>
      )}

      {isNolabelWithSpacing && (
        <div style={classes.labelContainer}>
          <label className="text-base">&nbsp;</label>
        </div>
      )}

      {hideInput && (
        <Tooltip title={staticHoverText} placement="top-start" arrow>
          <div className={`bg-gray-300 ${inputWidthModifier(className)}`}>
            <label className="text-base">&nbsp;</label>
          </div>
        </Tooltip>
      )}

      {!hideInput && !hideTextAreaInput && (
        <div className="flex-col">
          <div
            className={`ml-2 whitespace-normal ${inputWidthModifier(
              className
            )}`}
          >
            {typeof children === "function"
              ? children({
                  isInputReadOnly: readOnly,
                  inputStaticHoverText: staticHoverText,
                })
              : children}
          </div>

          {showBubbleIcon && (
            <div className="flex-1">
              {firstView && (
                <Condition when={siteNoteVar} isNot="">
                  <div style={classes.sideNote}>
                    <Field
                      name={siteNoteVar}
                      component="textarea"
                      rows={textAreaSize.row}
                      cols={textAreaSize.col}
                      style={{ width: "100%" }}
                      className="textarea h-20 w-full"
                    />
                  </div>
                </Condition>
              )}
            </div>
          )}

          {(showSideNote || readOnly) && (
            <Condition when={siteNoteVar} readOnly={readOnly}>
              <div style={classes.sideNote}>
                <Field
                  name={siteNoteVar}
                  component="textarea"
                  className="textarea h-20"
                  style={{ width: "100%" }}
                  rows={textAreaSize.row}
                  cols={textAreaSize.col}
                  disabled={readOnly}
                />
              </div>
            </Condition>
          )}
        </div>
      )}
    </div>
  );
}
