import React from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Redirect } from "react-router-dom";
import { isAuthenticated, isTokenValid, isMFAEnabled, isPipedriveConnected } from '../services/authenticator'

const PrivateRoute = ({ component: Component, location, ...rest }) => {

  const render = props => {
    // add some state to the redirects so that they may take the user to the
    // originally intended page after login/mfa action
    const srcPath = props.location.pathname
    const srcQry = props.location.search

    // if valid Auth0 token, but MFA or Pipedrive connection is missing, redirect to settings page
    if (isTokenValid() && (!isMFAEnabled() || !isPipedriveConnected())) {
      return <Redirect to={{
        pathname: "/settings",
        state: {
          srcPath: srcPath,
          srcQry: srcQry,
        }
      }}/>
    }

    // if all good, render the page component
    if (isAuthenticated()) {
      return <Component {...props} />
    }

    // if else (ie. token is not valid), redirect to the login page
    return <Redirect to={{
      pathname: "/login",
      state: {
        srcPath: srcPath,
        srcQry: srcQry,
      }
    }}/>
  }

  return <Route render={render} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default withRouter(PrivateRoute);
