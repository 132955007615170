import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import RequestList from "components/Match/RequestList";
import ConfigHeaderSummary from "../components/Match/ConfigHeaderSummary";
import {
  ConfigMatchDataRequest,
  ConfigMatchDataResponse,
} from "../components/Match/types";
import OpenInPipedrive from "../components/OpenInPipedrive";
import SectionHeader from "../components/SectionHeader";
import { CONFIG_MATCH_DATA_QUERY } from "../graphql/queries";
import { PD_RESOURCE_ORGANIZATION } from "../services/constants";
import { quickReturnClass } from "../components/Match/common";

const MatchesHost: React.FC = () => {
  const { config_id } = useParams<{ config_id: string }>();
  const { loading, data, error } = useQuery<
    ConfigMatchDataResponse,
    ConfigMatchDataRequest
  >(CONFIG_MATCH_DATA_QUERY, { variables: { configID: parseInt(config_id) } });

  if (loading) return <div className={quickReturnClass}> Loading... </div>;
  if (error !== undefined)
    return (
      <div className={quickReturnClass + "text-state-error"}>
        {error.message.replace(/GraphQL error: ?/, "")}
      </div>
    );
  if (!data?.configMatchData)
    return (
      <div className={quickReturnClass + "text-state-error"}>
        Error: no data received!
      </div>
    );

  const { configMatchData } = data;

  return (
    <div className="mb-4 w-11/12">
      <SectionHeader
        title={`Guest Matches for '${configMatchData.address}'`}
        showTooltip={false}
        tooltipText={""}
      >
        <div className="flex justify-center items-center">
          <OpenInPipedrive
            resourceType={PD_RESOURCE_ORGANIZATION}
            orgPipedriveID={configMatchData.pipedrive_id}
          />
        </div>
      </SectionHeader>

      <ConfigHeaderSummary data={configMatchData} />

      <RequestList configID={parseInt(config_id)} />
    </div>
  );
};

export default MatchesHost;
