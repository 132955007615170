import React from "react";
import styled from "styled-components";

import Field from "components/Forms/Field";
import Tooltip from "@mui/material/Tooltip";
import {
  filterFieldProps,
  isUndefinedOrNull,
  isEmptyString,
} from "services/utils";
import BaseInput from "./BaseInput";
import Error from "./Error";

const HiddenBox = styled.div`
  background-color: white;
  width: 154px;
  height: 20px;
`;

const NullBooleanInputAdapter = ({
  input: { onChange, value },
  label,
  meta,
  inputAltLabel,
  ...rest
}) => (
  <div>
    <input
      type="checkbox"
      style={{ marginTop: "3px" }}
      value="true"
      checked={value}
      {...rest}
      onChange={(event) => {
        if (!event.target.checked) {
          onChange(undefined);
        } else {
          onChange(JSON.parse(event.target.value));
        }
      }}
    />
    <span className="ml-0.5">{inputAltLabel.trueLabel}</span>
    <input
      type="checkbox"
      style={{ marginTop: "3px", marginLeft: "37px" }}
      value="false"
      checked={
        isEmptyString(value) || isUndefinedOrNull(value) ? false : !value
      }
      {...rest}
      onChange={(event) => {
        if (!event.target.checked) {
          onChange(undefined);
        } else {
          onChange(JSON.parse(event.target.value));
        }
      }}
    />
    <span className="ml-0.5 w-full">{inputAltLabel.falseLabel}</span>
    <Error
      showError={meta.error}
      error={meta.error}
      style={{ display: "block", marginLeft: 3 }}
    />
  </div>
);

function NullBooleanInput({
  staticHoverText = "",
  isReadOnly = false,
  inputAltLabel = { trueLabel: "Yes", falseLabel: "No" },
  ...props
}) {
  const fieldProps = filterFieldProps(props);

  return (
    <BaseInput
      {...props}
      readOnly={isReadOnly}
      staticHoverText={staticHoverText}
    >
      {({ isInputReadOnly, inputStaticHoverText }) => (
        <>
          {!isInputReadOnly && (
            <>
              <Field
                name={`${props.name}`}
                component={NullBooleanInputAdapter}
                inputAltLabel={inputAltLabel}
                {...fieldProps}
              />
            </>
          )}

          {isInputReadOnly && (
            <Field
              style={{ height: "20px" }}
              name={`${props.name}`}
              {...fieldProps}
            >
              {({ input: { value } }) => {
                let v;

                if (value === false) {
                  v = inputAltLabel.falseLabel;
                } else if (value === true) {
                  v = inputAltLabel.trueLabel;
                } else {
                  v = "Not Selected";
                }

                return (
                  <Tooltip
                    title={inputStaticHoverText}
                    placement="top-start"
                    arrow
                  >
                    <div>{v ? v : <HiddenBox />}</div>
                  </Tooltip>
                );
              }}
            </Field>
          )}
        </>
      )}
    </BaseInput>
  );
}

export default NullBooleanInput;
