import {
  SESSION_STORAGE_KEYS,
  PROPERTIES_KEY,
  REQUESTS_KEY,
} from "services/constants";
import { EntityType } from "enums";
import { getChangeLogValues } from "services/changeLogsHelpers";
import { ChangeLogField } from "interfaces/changeLogs";
import { MutableState, Tools } from "final-form";
import { OrganizationFormValues } from "interfaces/organizationForm";
import {diff} from "deep-object-diff";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getObjectPaths(obj: any, parentKey: string): Array<string> {
  const result: string[] = [];

  for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          // if key is number add [] to the key and
          if (!isNaN(Number(key))) {
            key = `[${key}]`
          } else if (parentKey) { // dont add . to the first key
            key = `.${key}`
          }
          const currentKey = parentKey ? `${parentKey}${key}` : key;

          if (typeof value === 'object' && !Array.isArray(value)) {
              result.push(...getObjectPaths(value, currentKey));
          } else {
              result.push(currentKey);
          }
      }
  }

  return result;
}


const mutators = {
  setChangedFields: (
    _: unknown[],
    // until other mutators are typescripted also
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: MutableState<any, Partial<any>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    utils: Tools<any, Partial<any>>
  ): void => {
    const changedFields: ChangeLogField[] = [];
    let changeLogs: ChangeLogField | null;

    const initialValues = state.formState
      .initialValues as OrganizationFormValues;
    const values = state.formState.values as OrganizationFormValues;
    const dirtyFields = getObjectPaths(diff(initialValues, values), '')

    const getChangedFields = (
      entityType,
      parentIndex,
      fieldName,
      childIndex: string | undefined = undefined
    ) => {
      return getChangeLogValues(
        entityType,
        parentIndex,
        fieldName,
        values,
        initialValues,
        childIndex
      );
    };

    for (const key of dirtyFields) {
      const splitObject = key.split(".");
      const parent = splitObject[0];
      const fieldName = splitObject[splitObject.length - 1];

      if (parent && parent.includes(`${PROPERTIES_KEY}[`)) {
        const propertyIndex = key.charAt(PROPERTIES_KEY.length + 1);
        const config = splitObject[1];

        if (config && config.includes(`configs[`)) {
          const configIndex = config.charAt("configs".length + 1);
          changeLogs = getChangedFields(
            EntityType.PROPERTY_CONFIG,
            propertyIndex,
            fieldName,
            configIndex
          );
        } else {
          changeLogs = getChangedFields(
            EntityType.PROPERTY,
            propertyIndex,
            fieldName
          );
        }
      } else if (parent.includes(`${REQUESTS_KEY}[`)) {
        const requestIndex = key.charAt(REQUESTS_KEY.length + 1);
        changeLogs = getChangedFields(
          EntityType.REQUEST,
          requestIndex,
          fieldName
        );
      }

      if (changeLogs && !changeLogs.object_id.includes("-"))
        changedFields.push(changeLogs);
    };

    if (changedFields.length > 0) {
      utils.changeValue(state, `changed_fields`, () => changedFields);
    }
  },
  setFormState: (
    args: number[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: MutableState<any, Partial<any>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    utils: Tools<any, Partial<any>>
  ): void => {
    const orgId = args[0];
    const formState =
      sessionStorage.getItem(`${SESSION_STORAGE_KEYS.FORM_STATE}${orgId}`) ??
      null;

    utils.changeValue(state, "", () => JSON.parse(formState ?? ""));
  },
};

export { mutators };
