import React from "react";
import styled from "styled-components";
import __ from "lodash";
import Field from "components/Forms/Field";
import Tooltip from "@mui/material/Tooltip";
import { filterFieldProps } from "../../services/utils";
import BaseInput from "./BaseInput";
import {
  FloatingNotesMinWidth,
  FloatingNotesMaxWidth,
} from "../../services/constants";

const FloatingTextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 75%;
  right: ${(props) => props.rightMargin};
  top: 12%;
  &:before {
    content: "${(props) => props.floatingTitle}";
    font-size: 20px;
    font-weight: 500;
  }
`;

const GenericTextAreaContainer = styled.div``;

const TextAreaBox = styled.textarea`
  border: 1px solid black;
  margin-left: 0px;
  background-color: white;
  resize: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  padding: 0px;
  color: #000000de;
`;

function TextAreaInput({ isReadOnly = false, staticHoverText = "", ...props }) {
  const fieldProps = filterFieldProps(props);

  const rows = props.rows || 4;
  const cols = props.cols || 40;

  let filteredProps = __.cloneDeep(props);
  if (props.hideInput === true) {
    delete filteredProps.hideInput;
    filteredProps.hideTextAreaInput = true;
  }

  // set up for floating textarea
  let styles = {
    fontFamily: "Arial, Roboto, Helvetica, sans-serif",
    width: "100%",
  };
  let floatingTitle = "";
  let TAContainer = GenericTextAreaContainer;
  let TAContainerRightMargin = "2em";
  if (props.floating) {
    let width = props.width;
    if (width >= FloatingNotesMinWidth) {
      if (width >= FloatingNotesMaxWidth) {
        width = FloatingNotesMaxWidth;
        TAContainerRightMargin = `${__.round(
          (props.width - FloatingNotesMaxWidth) * 1.3
        )}px`;
      }
      styles = {
        ...styles,
        height: "100%",
        resize: "none",
        width: width,
      };
      floatingTitle = props.floatingTitle ? props.floatingTitle : "Notes";
      TAContainer = FloatingTextAreaContainer;
    }
  }

  return (
    <BaseInput
      {...filteredProps}
      readOnly={isReadOnly}
      staticHoverText={staticHoverText}
      className={props.className + " w-full"}
    >
      {({ isInputReadOnly, inputStaticHoverText }) => (
        <>
          {!isInputReadOnly && (
            <Field {...fieldProps}>
              {({ input, meta }) => (
                <div>
                  <TAContainer
                    rightMargin={TAContainerRightMargin}
                    floatingTitle={floatingTitle}
                  >
                    <textarea
                      {...input}
                      style={styles}
                      className="textarea"
                      rows={rows}
                      cols={cols}
                      disabled={props.disabled}
                    />
                  </TAContainer>
                  {meta.error && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      &nbsp;&nbsp;{meta.error}
                    </span>
                  )}
                </div>
              )}
            </Field>
          )}

          {isInputReadOnly && (
            <Field name={`${props.name}`} {...fieldProps}>
              {(props) => {
                return (
                  <Tooltip
                    title={inputStaticHoverText}
                    placement="top-start"
                    arrow
                  >
                    <TAContainer
                      rightMargin={TAContainerRightMargin}
                      floatingTitle={floatingTitle}
                    >
                      <TextAreaBox
                        floatingTitle={floatingTitle}
                        style={styles}
                        className="textarea"
                        {...props.input}
                        rows={rows}
                        cols={cols}
                        disabled
                      />
                    </TAContainer>
                  </Tooltip>
                );
              }}
            </Field>
          )}
        </>
      )}
    </BaseInput>
  );
}

export default TextAreaInput;
