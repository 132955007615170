import { useEffect } from "react";
import { validAddressFound, updateLocationFields } from "services/utilities";
import Error from "components/Forms/Error";
import { isEmptyString } from "services/utils";

let autocomplete;

function initAutocomplete(
  setMapError,
  updateAddress,
  propertyIndex,
) {
  autocomplete = new window.google.maps.places.Autocomplete(
    document.querySelector(
      `[name='locationSearch-${propertyIndex}']`
    ) as HTMLInputElement,
    {
      types: ["address"],
      componentRestrictions: { country: ["CA", "US"] }, // Biased to these Countries
      fields: ["place_id", "geometry", "address_component"], // Components we want returned
    }
  );

  autocomplete.addListener("place_changed", () =>
    onPlaceChanged(
      setMapError,
      updateAddress,
    )
  );
}

function onPlaceChanged(
  setMapError,
  updateAddress,
) {
  const place = autocomplete.getPlace();
  if (!place === false) {
    if (!place.geometry || !validAddressFound(place.address_components)) {
      // show a little red warning underneath the address bar that it is not a valid geometry
      console.log("Error parsing results from Google Maps", place)
      setMapError(true);
    } else {
      //If there is a valid geometry update the location fields
      setMapError(false);
      updateLocationFields(
        place,
        updateAddress,
      );
    }
  } else if (!place === true) {
    console.log("Error parsing results from Google Maps - place is empty")
    setMapError(true);
  }
}

export default function PropertyAddressInput(props: {
  setMapError;
  updateAddress;
  propertyIndex;
  hasAddressValue;
}): JSX.Element {
  useEffect(() => {
    initAutocomplete(
      //Used to initialize the Autocomplete input
      props.setMapError,
      props.updateAddress,
      props.propertyIndex,
    );
  }, [
    props.setMapError,
    props.updateAddress,
    props.propertyIndex,
  ]);

  const inputElement = document.getElementById(
    `locationSearch-${props.propertyIndex}`
  ) as HTMLInputElement;

  return (
    <>
      <div className={"p-5px"}>
        <div className={"pr-5px"}>
          <p className={"w-autocomplete-lable font-bold"}>
            Search for Location
          </p>
        </div>
        <input
          id={`locationSearch-${props.propertyIndex}`}
          name={`locationSearch-${props.propertyIndex}`}
          placeholder="Enter Location"
          className={"ml-2 w-autocomplete-input input-default"}
        />
        <Error
          showError={
            isEmptyString(inputElement?.value) && !props.hasAddressValue
          }
          error="Location must be entered"
          style={{ marginLeft: 15 }}
        />
      </div>
    </>
  );
}
