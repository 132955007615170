import React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

export default function ConfirmationDialog(props) {
  const classes = {
    dialog: {
      position: "absolute",
      top: 0,
    },
  };

  var { confirmText } = props;
  const { onConfirm, onClose, open, title, content, ignoreConfirm } = props;

  if (confirmText === undefined) confirmText = "Confirm";

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      {!ignoreConfirm && (
        <Dialog
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              onClose(event, reason);
            }
          }}
          disableEscapeKeyDown
          open={open}
          PaperProps={{ sx: classes.dialog }}
        >
          <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
          <DialogContent dividers>{content}</DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleCancel}
              color="error"
              variant="contained"
              size="small"
            >
              Cancel
            </Button>
            <Button onClick={handleConfirm} size="small" variant="contained">
              {confirmText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {ignoreConfirm && (
        <Dialog
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              onClose(event, reason);
            }
          }}
          disableEscapeKeyDown
          open={open}
          PaperProps={{ sx: classes.dialog }}
        >
          <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
          <DialogContent dividers>{content}</DialogContent>
          <DialogActions>
            <Button onClick={handleConfirm} variant="contained" size="small">
              {confirmText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

ConfirmationDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  ignoreConfirm: PropTypes.bool,
  content: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};
