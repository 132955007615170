import React from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { CHANGELOGS_QUERY } from "../graphql/queries";
import { isUndefinedOrNull, convertToDisplayTime } from "../services/utils";

import { EnumChangelogEvent, EnumChangelogObject } from "../services/enums";

import { resolveEnumValue } from "../services/enum_utils";

import { BasicPaginationTable } from "./BasicPaginationTable/BasicPaginationTable";

export default function Changelogs({ objectID, title }) {
  const variables = {
    objectID: objectID,
    page: title,
    pagination: {
      index: 0,
      size: 100,
    },
  };

  const [getChangelogs, { data }] = useLazyQuery(CHANGELOGS_QUERY, {
    variables: variables,
    fetchPolicy: "no-cache",
  });

  const columns = React.useMemo(
    () => [
      {
        header: "Who",
        accessor: "userName",
      },
      {
        header: "When",
        accessor: "changeTime",
        type: "date",
      },
    ],
    []
  );

  const getColumns = (columns) => {
    return [
      ...columns,
      {
        header: "What",
        accessor: "type",
      },
    ];
  };

  const formatChangelogs = (data) => {
    if (isUndefinedOrNull(data) || isUndefinedOrNull(data.changelogs.logs)) {
      return <Typography variant="subtitle2">No Records Found</Typography>;
    }

    // hash the logs by date
    // currently we show all logs
    const logsHashed = {};
    for (var cl of data.changelogs.logs) {
      if (logsHashed[cl.change_time] === undefined) {
        logsHashed[cl.change_time] = [cl];
      } else {
        logsHashed[cl.change_time].push(cl);
      }
    }

    const logs = [];
    for (var logsT of Object.values(logsHashed)) {
      for (var log of logsT) {
        const cl = {
          userName: `${logsT[0].firstname} ${logsT[0].lastname}`,
          changeTime: convertToDisplayTime(logsT[0].change_time),
        };

        const action = resolveEnumValue(EnumChangelogEvent, log.change_type);
        let objType = resolveEnumValue(EnumChangelogObject, log.object_type);
        const nm = log.name === null ? "" : log.name;

        const clTextObj = { Created: "", Modified: "", Deleted: "" };

        if (clTextObj[action] === "") {
          clTextObj[action] += objType + " " + nm;
        }

        for (var [key, value] of Object.entries(clTextObj)) {
          if (value !== "") {
            cl.type = key + " " + value;
            logs.push(cl);
          }
        }
      }
    }

    return <BasicPaginationTable columns={getColumns(columns)} rows={logs} />;
  };

  return (
    <div className="mt-5 mb-5">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => getChangelogs()}
        >
          <Typography variant="subtitle2">Show History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex w-full">{formatChangelogs(data)}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
