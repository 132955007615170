const IMAGE_PHOTOS = "photos";
const IMAGE_FLOORPLANS = "floorplans";

const CT_PRIMARY = "CT_PRIMARY";
const CT_TOUR = "CT_TOUR";
const CT_SIGNATURE = "CT_SIGNATURE";
const CT_OTHER = "CT_OTHER";

const CM_EMAIL = "CM_EMAIL";
const CM_PHONE = "CM_PHONE";
const CM_SMS = "CM_SMS";

const CT_EMAIL = "CT_EMAIL";
const CT_PHONE = "CT_PHONE";

const CC_WORK = "CC_WORK";

const MKT_CA_TORONTO = "MKT_CA_TORONTO";
const MKT_CA_CALGARY = "MKT_CA_CALGARY";
const MKT_CA_VANCOUVER = "MKT_CA_VANCOUVER";
const MKT_CA_KELOWNA = "MKT_CA_KELOWNA";
const MKT_CA_OTTAWA = "MKT_CA_OTTAWA";
const MKT_CA_MONTREAL = "MKT_CA_MONTREAL";
const MKT_CA_EDMONTON = "MKT_CA_EDMONTON";
const MKT_US_NORTHJERSEY = "MKT_US_NORTHJERSEY";
const MKT_US_ATLANTA = "MKT_US_ATLANTA";
const MKT_US_CHICAGO = "MKT_US_CHICAGO";
const MKT_US_LOSANGELES = "MKT_US_LOSANGELES";
const MKT_US_DALLAS = "MKT_US_DALLAS";
const MKT_US_PHOENIX = "MKT_US_PHOENIX";
const MKT_US_HOUSTON = "MKT_US_HOUSTON";
const MKT_US_AUSTIN = "MKT_US_AUSTIN";
const MKT_US_MIAMI = "MKT_US_MIAMI";
const MKT_US_JACKSONVILLE = "MKT_US_JACKSONVILLE";
const MKT_US_TAMPA = "MKT_US_TAMPA";

const PT_OFFICE = "PT_OFFICE";
const PT_WAREHOUSE = "PT_WAREHOUSE";

const DTB_PROPERTY = "DTB_PROPERTY";

const DOC_IMAGE_PHOTO = "DOC_IMAGE_PHOTO";
const DOC_IMAGE_FLOORPLAN = "DOC_IMAGE_FLOORPLAN";

const CLF_CONFIGSTATUS = "CLF_CONFIGSTATUS";
const CLF_LISTFEE = "CLF_LISTFEE";
const CLF_LISTFEEBASIS = "CLF_LISTFEEBASIS";
const CLF_LISTINGTAGHOT = "CLF_LISTINGTAGHOT";
const CLF_LISTINGTAGNEW = "CLF_LISTINGTAGNEW";
const CLF_LISTINGTAGHIGHLIGHT = "CLF_LISTINGTAGHIGHLIGHT";
const CLF_LISTINGTAGFEATURED = "CLF_LISTINGTAGFEATURED";
const CLF_LISTINGTAGREMOVED = "CLF_LISTINGTAGREMOVED";
const CLF_LISTINGTAGHIDDEN = "CLF_LISTINGTAGHIDDEN";

const LFB_GROSS = "LFB_GROSS";
const LFB_SQFT_MO = "LFB_SQFT_MO";
const LFB_SQFT_YR = "LFB_SQFT_YR";
const LFB_PERSON_MO = "LFB_PERSON_MO";

const TCB_SQFT = "TCB_SQFT";

const HOVER_PROPERTY_FIELD =
  "To edit property-level fields, click on the property name above";
const HOVER_LISTING_FIELD =
  "To edit listing-specific fields, click on a listing above";
const HOVER_PERMISSIONS_FIELD = "User permissions";

//Warehouse Type
const WRH_PRIVATE_BUILDING = "WRH_PRIVATE_BUILDING";
const WRH_SHARED_SPACE = "WRH_SHARED_SPACE";

// Tuning Variables
const FE_MapDefault_ = "FE_MapDefault_";
const FE_Match = "FE_Match";
const FE_DefaultPropertyType = "FE_DefaultPropertyType";
const DOCS_ImagePublicMaxRes = "DOCS_ImagePublicMaxRes";
const DOCS_ImagePublicFormat = "DOCS_ImagePublicFormat";

// Match score quality tuning variables
const FE_MatchGreen = "FE_MatchGreen";
const FE_MatchYellow = "FE_MatchYellow";

const FloatingNotesMinWidth = 100;
const FloatingNotesMaxWidth = 350;

// Pipedrive Resource Type
const PD_RESOURCE_ORGANIZATION = "organization";
const PD_RESOURCE_PRODUCT = "product";
const PD_RESOURCE_PERSON = "person";

// environment types
const APP_ENV_DEV = "development";
const APP_ENV_STAGING = "staging";
const APP_ENV_PROD = "production";

// LocalStorage/treeful keys
const PIPEDRIVE_CONNECTED = "pipedrive_connected";
const MFA_ENABLED = "mfa_enabled";
const USER_PERMISSIONS = "user_permissions";

// Contact input debounce field timeout
const FE_DEBOUNCE_FIELD_TIMEOUT = "FE_DebounceFieldTimeoutMilisecond";

// contact input type
const CONTACT_INPUT_EMAIL = "Email";
const CONTACT_INPUT_PHONE = "Phone";

// user permissions (actions)
const ACT_REGULAR = "ACT_REGULAR";
const ACT_ADMIN = "ACT_ADMIN";

// page titles
const TITLE_DEFAULT = "Spacer";
const TITLE_USER_LIST = "Users";
const TITLE_LOGIN = "Login";
const TITLE_SETTINGS = "Settings";

// listing provider & extra
const LP_PLACEHOLDER = "LP_PLACEHOLDER";
const EMPIRE_URL = "EmpireListingUrl_lp_placeholder";
const CREXI_URL = "CrexiListingUrl_lp_crexi";
const LP_CREXI = "LP_CREXI";

// sweetalert2 constants
const DEFAULT_SUCCESS_TIMER = 3000;
const DEFAULT_ERROR_TIMER = undefined; // ie infinite

// Countries
const COUNTRY_CA = "CA";
const COUNTRY_US = "US";

// property status
const PS_PENDING = "PS_PENDING";
const PS_AVAILABLE_NOMATCH = "PS_AVAILABLE_NOMATCH";
const PS_BOOKED_NOMATCH = "PS_BOOKED_NOMATCH";
const PS_UNAVAILABLE = "PS_UNAVAILABLE";
const PS_BOOKED_MATCH = "PS_BOOKED_MATCH";
const PS_AVAILABLE_MATCH = "PS_AVAILABLE_MATCH";
const PS_REVIEW_REJECTED = "PS_REVIEW_REJECTED";

const PROPERTIES_KEY = "properties";
const REQUESTS_KEY = "requests";
const PROPERTY_KEY = "property";
const CONFIGS_KEY = "configs";

// Property Level Fields for Organization Form
const PROPERTY_LEVEL_FIELD_NAMES = {
  STATUS: "status",
};

// Property Config Level Fields for Organization Form
const PROPERTY_CONFIG_LEVEL_FIELD_NAMES = {
  LIST_FEE: "list_fee",
  LIST_FEE_BASIS: "list_fee_basis",
  TAGS: {
    LabelHot: CLF_LISTINGTAGHOT,
    LabelNew: CLF_LISTINGTAGNEW,
    LabelHighlight: CLF_LISTINGTAGHIGHLIGHT,
    FeaturedListing: CLF_LISTINGTAGFEATURED,
    RemovedListing: CLF_LISTINGTAGREMOVED,
    HiddenListing: CLF_LISTINGTAGHIDDEN,
  },
};

const SESSION_STORAGE_KEYS = {
  FORM_STATE: "FormState",
};

const ALT_NULL_BOOLEAN_INPUT_TEXT = {
  trueLabel: "Strict Yes",
  falseLabel: "No or Nice-to-have",
};

// center of north america
const CENTER_NORTH_AMERICA = {
  lat: 48.3544,
  lng: -99.9981,
};


export {
  CT_PRIMARY,
  CT_TOUR,
  CT_SIGNATURE,
  CT_OTHER,
  MKT_CA_TORONTO,
  MKT_CA_CALGARY,
  MKT_CA_VANCOUVER,
  MKT_CA_KELOWNA,
  MKT_CA_OTTAWA,
  MKT_CA_MONTREAL,
  MKT_CA_EDMONTON,
  MKT_US_NORTHJERSEY,
  MKT_US_ATLANTA,
  MKT_US_CHICAGO,
  MKT_US_LOSANGELES,
  MKT_US_DALLAS,
  MKT_US_PHOENIX,
  MKT_US_HOUSTON,
  MKT_US_AUSTIN,
  MKT_US_MIAMI,
  MKT_US_JACKSONVILLE,
  MKT_US_TAMPA,
  PT_OFFICE,
  PT_WAREHOUSE,
  DTB_PROPERTY,
  DOC_IMAGE_PHOTO,
  DOC_IMAGE_FLOORPLAN,
  FE_MapDefault_,
  FE_Match,
  FE_DefaultPropertyType,
  WRH_PRIVATE_BUILDING,
  WRH_SHARED_SPACE,
  CM_EMAIL,
  CM_PHONE,
  CM_SMS,
  CT_EMAIL,
  CT_PHONE,
  CC_WORK,
  CLF_CONFIGSTATUS,
  CLF_LISTFEE,
  CLF_LISTFEEBASIS,
  CLF_LISTINGTAGHOT,
  CLF_LISTINGTAGNEW,
  CLF_LISTINGTAGHIGHLIGHT,
  CLF_LISTINGTAGFEATURED,
  CLF_LISTINGTAGREMOVED,
  CLF_LISTINGTAGHIDDEN,
  LFB_GROSS,
  LFB_SQFT_MO,
  LFB_SQFT_YR,
  LFB_PERSON_MO,
  TCB_SQFT,
  HOVER_PROPERTY_FIELD,
  HOVER_LISTING_FIELD,
  HOVER_PERMISSIONS_FIELD,
  FE_MatchGreen,
  FE_MatchYellow,
  FloatingNotesMinWidth,
  FloatingNotesMaxWidth,
  PD_RESOURCE_ORGANIZATION,
  PD_RESOURCE_PRODUCT,
  PD_RESOURCE_PERSON,
  DOCS_ImagePublicMaxRes,
  DOCS_ImagePublicFormat,
  PIPEDRIVE_CONNECTED,
  USER_PERMISSIONS,
  APP_ENV_DEV,
  APP_ENV_STAGING,
  APP_ENV_PROD,
  CONTACT_INPUT_EMAIL,
  CONTACT_INPUT_PHONE,
  ACT_REGULAR,
  ACT_ADMIN,
  TITLE_DEFAULT,
  TITLE_USER_LIST,
  TITLE_LOGIN,
  TITLE_SETTINGS,
  LP_PLACEHOLDER,
  EMPIRE_URL,
  CREXI_URL,
  LP_CREXI,
  DEFAULT_SUCCESS_TIMER,
  DEFAULT_ERROR_TIMER,
  COUNTRY_CA,
  COUNTRY_US,
  PS_PENDING,
  PS_BOOKED_MATCH,
  PS_AVAILABLE_MATCH,
  PS_REVIEW_REJECTED,
  PS_AVAILABLE_NOMATCH,
  PS_BOOKED_NOMATCH,
  PS_UNAVAILABLE,
  FE_DEBOUNCE_FIELD_TIMEOUT,
  IMAGE_PHOTOS,
  IMAGE_FLOORPLANS,
  PROPERTIES_KEY,
  REQUESTS_KEY,
  CONFIGS_KEY,
  PROPERTY_KEY,
  PROPERTY_LEVEL_FIELD_NAMES,
  PROPERTY_CONFIG_LEVEL_FIELD_NAMES,
  MFA_ENABLED,
  SESSION_STORAGE_KEYS,
  ALT_NULL_BOOLEAN_INPUT_TEXT,
  CENTER_NORTH_AMERICA,
};
