import { useState } from "react";

// useStorage is a hook which allows the local storage to be interacted with
// using the hooks interface.
export default function useStorage(
  key: string,
  _defaultVal_: unknown
): [string | null, (newVal: unknown) => void] {
  // properly serialize the provided default value to a string
  let defaultVal: string;
  if (typeof _defaultVal_ !== "string")
    defaultVal = JSON.stringify(_defaultVal_);
  else defaultVal = _defaultVal_;
  // determine the initial value of the state
  // if key is not set in local storage, we use the specified default
  let initialValue: string | null = localStorage.getItem(key);
  if (["", null, "undefined", "null"].includes(initialValue))
    initialValue = defaultVal;
  // setup the state
  const [val, _setVal_] = useState(initialValue);
  // value setter wrapper which interacts with the local storage
  const mutator = (newVal: unknown) => {
    // properly serailize the provided new value to a string
    let updatedVal: string;
    if (typeof newVal !== "string") updatedVal = JSON.stringify(newVal);
    else updatedVal = newVal;

    localStorage.setItem(key, updatedVal);
    _setVal_(updatedVal);
  };
  return [val, mutator];
}
