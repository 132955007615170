import React from "react";
import Thumbnail from "./Thumbnail";

interface Props {
  name: string;
  position?: string;
  tags?: string[];
  phone?: string;
  email?: string;
  imgUrl?: string;
  showThumbnail?: boolean;
};

const Contact: React.FC<Props> = ({
  name, imgUrl, showThumbnail, position, phone, email, tags,
}) => {
  return (
    <div className="flex flex-row items-center m-1 p-1.5 w-max">
      {showThumbnail && <Thumbnail imgUrl={imgUrl} name={name} />}

      <div className="flex flex-col font-display justify-center">
        <p className="font-bold text-lg text-blue-500">{name}</p>

        {(position || phone || email) && (
          <div className="leading-3">
            {position && <p className="font-semibold">{position}</p>}
            {phone && <p className="">{phone}</p>}
            {email && <p className="">{email}</p>}
          </div>
        )}
      </div>

      {tags && (
        <div className="flex flex-col">
          {tags.map((t, i) =>
            <p key={i} className="p-0.5 rounded bg-green-300">{t}</p>
          )}
        </div>
      )}
    </div>
  );
}

export default Contact;

