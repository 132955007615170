import __ from "lodash";

import { filterNull, makeid, cloneRequest, createDeleteConfig } from "../utils";

import {
  convertCeilingClearanceToFeetAndInches,
  calculateCeilingClearance,
} from "./helpers";

import { PT_WAREHOUSE } from "../constants";

import { EntityType } from "enums/entityType";

import { addTags, delTags } from "../../components/common/tag-mutators";

const initRequests = (requests) => {
  if (requests == null) return [];

  const clonedRequests = __.cloneDeep(requests);

  clonedRequests?.forEach((request, index) => {
    let { term_max, term_min, movein, locations } = request;

    if (term_max && term_min) {
      if (term_max === term_min) {
        request.term_type = "1";
      } else {
        request.term_type = "2";
      }
    }

    if (movein === undefined || movein === null) {
      request.movein_type = "1";
    } else {
      request.movein_type = "2";
    }

    const clearance = convertCeilingClearanceToFeetAndInches(request.ceiling);

    request.ceiling_clearance_feet = clearance.feet;
    request.ceiling_clearance_inches = clearance.inches;

    delete request.ceiling;

    clonedRequests[index] = filterNull(request);
    clonedRequests[index].locations = locations ?? [];
  });

  return clonedRequests;
};

const preSaveRequests = (requests) => {
  let cloneRequests = __.cloneDeep(requests);

  cloneRequests?.forEach((request) => {
    const {
      term_type,
      term_min,
      movein_type,
      request_id,
      parking_car,
      office,
    } = request;

    request.ceiling = calculateCeilingClearance(
      request.ceiling_clearance_feet,
      request.ceiling_clearance_inches
    );

    delete request.ceiling_clearance_feet;
    delete request.ceiling_clearance_inches;
    delete request.created_at;

    if (term_type) {
      if (term_type === "1") {
        request.term_max = term_min;
      }
      delete request.term_type;
    }

    // movein
    if (movein_type) {
      if (movein_type === "1") {
        delete request.movein;
      }
      delete request.movein_type;
    }

    if (request_id) {
      if (request_id < 0) {
        delete request.request_id;
      }
    }

    if (parking_car == null) {
      delete request.parking_car_number;
    }

    if (office == null) {
      delete request.office_sqft;
    }

    delete request.matchable;
  });

  return cloneRequests.sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at)
  );
};

const mutators = {
  setTermMaxRequest: (args, state, utils) => {
    var index = args[1];
    utils.changeValue(state, `requests[${index}].term_max`, () => args[0]);
  },
  addLocation: (args, state, utils) => {
    const { configIndex, addedLocation } = args[0];
    var currentLocations =
      state.formState.values.requests[configIndex].locations || [];
    utils.changeValue(state, `requests[${configIndex}].locations`, () => [
      ...currentLocations,
      addedLocation,
    ]);
  },
  removeLocation: (args, state, utils) => {
    const { configIndex, removedLocation } = args[0];

    const locations = state.formState.values.requests[
      configIndex
    ].locations.filter(
      (location) =>
        !(
          location.latitude === removedLocation.latitude &&
          location.longitude === removedLocation.longitude
        )
    );
    utils.changeValue(
      state,
      `requests[${configIndex}].locations`,
      () => locations
    );
  },
  setRequestName: (args, state, utils) => {
    const { name, configIndex } = args[0];

    utils.changeValue(state, `requests[${configIndex}].name`, () => name);
  },
  addRequestConfiguration: (args, state, utils) => {
    const { name, cloneId } = args[0];
    let newRequestId = -Math.abs(makeid(10));
    const configsLength = state.formState.values?.requests?.length ?? 0;

    let newRequest = {
      request_id: newRequestId,
      name: name,
      active: true,
      property_type: PT_WAREHOUSE,
      usage_food: false,
      usage_auto: false,
      usage_sport_complex: false,
      usage_sport_studio: false,
      usage_cannabis: false,
      usage_customer: false,
      usage_manufacture: false,
      usage_highvol: false,
    };

    if (cloneId != null) {
      const cloneConfig = state.formState.values.requests.find(
        (data) => data.request_id === cloneId
      );
      newRequest = cloneRequest(cloneConfig, name, newRequestId);
    }

    utils.changeValue(state, `requests[${configsLength}]`, () => newRequest);
  },
  deleteRequest: (args, state, utils) => {
    const setDeleteEntities = args[0];

    const entitiesToDelete = [];

    state.formState.values.requests.forEach((request) => {
      entitiesToDelete.push(
        createDeleteConfig(request.request_id, EntityType.REQUEST)
      );
    });

    setDeleteEntities(entitiesToDelete);

    utils.changeValue(state, `requests`, () => []);
  },
  deleteRequestConfig: (args, state, utils) => {
    const configIndex = args[0];
    const setDeleteEntities = args[1];
    const requests = state.formState.values.requests;

    const request_id = requests.find(
      (_, index) => configIndex === index
    ).request_id;
    const filteredRequests = requests.filter(
      (_, index) => index !== configIndex
    );

    setDeleteEntities([createDeleteConfig(request_id, EntityType.REQUEST)]);

    utils.changeValue(state, `requests`, () => filteredRequests);
  },
  addTags: addTags,
  delTags: delTags,
};

export { mutators, initRequests, preSaveRequests };
