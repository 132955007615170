import React from "react";
import { createRoot } from "react-dom/client";

import App from "./views/App";
import * as serviceWorker from "./serviceWorker";
// import history from "./services/history";

import "./styles/index.css";

// A function that routes the user to the right place after login
// const onRedirectCallback = appState => {
//   history.push(
//     appState && appState.targetUrl
//       ? appState.targetUrl
//       : window.location.pathname
//   );
// };

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
