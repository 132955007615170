import { GridSection } from "components/CardComponents";
import TextAreaInput from "components/Forms/TextAreaInput";
import { NotesSectionProps } from "interfaces/cardSection";

export default function NotesSection({
  fieldName,
}: NotesSectionProps): JSX.Element {
  return (
    <GridSection title="Notes" md={12} className={"sticky top-0"}>
      <div className="mt-3">
        <TextAreaInput
          name={fieldName}
          overRideAutoInputSettings={true}
          rows={24}
        />
      </div>
    </GridSection>
  );
}
