import React, { useContext } from "react";
import ToolTipIconButton from "components/ToolTipIconButton";
import Modal from "components/Modal";
import { FormContext } from "contexts";
import { ModalToolTipButtonProps } from "interfaces/modalToolTipButton";

const ModalToolTipButton: React.FC<ModalToolTipButtonProps> = ({
  onConfirm,
  toolTipOptions,
  modalOptions,
  disabled,
  className,
  datacy,
}) => {
  const { setModalChild, setOpenModal } = useContext(FormContext);

  const handleConfirm = () => {
    onConfirm();
    setOpenModal(false);
  };

  const handOpenModal = (event) => {
    event.stopPropagation();
    setModalChild(
      <Modal
        title={modalOptions.title}
        message={modalOptions.message}
        onClose={() => setOpenModal(false)}
        onConfirm={handleConfirm}
      />
    );
    setOpenModal(true);
  };

  return (
    <ToolTipIconButton
      iconId={toolTipOptions.iconId}
      size={toolTipOptions.size ?? "lg"}
      title={toolTipOptions.title}
      message={toolTipOptions.message}
      filter={toolTipOptions.filter}
      disabled={disabled}
      onClick={handOpenModal}
      className={className}
      datacy={datacy}
    />
  );
};

export default ModalToolTipButton;
