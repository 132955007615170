import React from "react";
import Typography from "@mui/material/Typography";

export default function NotFound() {
  return (
    <React.Fragment>
      <Typography>Not Found</Typography>
    </React.Fragment>
  );
}
