import { ConditionProps } from "interfaces/condition";
import React from "react";

import { Field } from "react-final-form";

export default function Condition({
  when,
  is,
  isNot,
  readOnly,
  children,
}: ConditionProps): JSX.Element {
  if (is === undefined && isNot === undefined)
    return (
      <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => {
          return value === "" && readOnly ? null : children;
        }}
      </Field>
    );

  return (
    <>
      {(is !== undefined || is === "") && (
        <Field name={when} subscription={{ value: true }}>
          {({ input: { value } }) => (value === is ? children : null)}
        </Field>
      )}
      {(isNot !== undefined || isNot === "") && (
        <Field name={when} subscription={{ value: true }}>
          {({ input: { value } }) => (value !== isNot ? children : null)}
        </Field>
      )}
    </>
  );
}
