import React from "react";
import styled from "styled-components";

import { formatCurrency } from "../services/utils";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Label = styled.div``;
const Value = styled.div``;
export default function PlaceFeeDetails({ feeData }) {
  if (!feeData) {
    return <>Not Available</>;
  }

  return (
    <>
      <Row>
        <Label>List Fee</Label>
        <Value>{formatCurrency(feeData.listFee)}</Value>
      </Row>
      <Row>
        <Label>Service Fee</Label>
        <Value>{formatCurrency(feeData.serviceFee)}</Value>
      </Row>
      <Row>
        <Label> </Label>
        {`(${formatCurrency(feeData.serviceFeeNoRound)} unrounded, based on ${
          feeData.serviceFeePC
        }% markup)`}
      </Row>

      <br />

      {feeData.insurance !== 0 && (
        <>
          <Row>
            <Label>Insurance Premium</Label>
            <Value>{formatCurrency(feeData.insurance)}</Value>
          </Row>
          <Row>
            <Label>Insurance Fee</Label>
            <Value>{formatCurrency(feeData.insuranceFee)}</Value>
          </Row>
          <Row>
            <Label> </Label>
            {`(based on ${feeData.insuranceFeePC}% markup)`}
          </Row>

          <br />
        </>
      )}
      {feeData.furniture !== 0 && (
        <>
          <Row>
            <Label>Furniture Cost</Label>
            <Value>{formatCurrency(feeData.furniture)}</Value>
          </Row>
          <Row>
            <Label>Furniture Fee</Label>
            <Value>{formatCurrency(feeData.furnitureFee)}</Value>
          </Row>
          <Row>
            <Label> </Label>
            {`(based on ${feeData.furnitureFeePC}% markup)`}
          </Row>

          <br />
        </>
      )}
      <Row style={{ borderTop: "1px solid black", paddingTop: "10px" }}>
        <Label>Place Fee</Label>
        <Value>{formatCurrency(feeData.placeFeeNoRound)}</Value>
      </Row>

      <br />

      <Row style={{ fontWeight: "bold" }}>
        <Label>Place Fee (rounded)</Label>
        <Value>{formatCurrency(feeData.fee)}</Value>
      </Row>
      <Row style={{ fontWeight: "bold" }}>
        <Label>Effective Fee %</Label>
        <Value>{`${feeData.effectiveFeePercent}%`}</Value>
      </Row>
    </>
  );
}
