import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { CONFIG_MATCH_UNIFIED_QUERY, EXPORT_CONFIG_MATCHES_QUERY } from "../../graphql/queries";
import { quickReturnClass } from "./common";
import DisqualifiedRequestRow from "./DisqualifiedRequestRow";
import IgnoreDisqualifiersRow from "./IgnoreDisqualifiersRow";
import TableHeader from "./TableHeader";
import {
  ConfigMatchUnifiedRequest,
  ConfigMatchUnifiedResponse,
  PaginationInput,
} from "./types";
import { initialPaginationInput, jsonToCsv } from "./utils";

interface Props {
  configID: number;
}

const DisqualifiedRequests: React.FC<Props> = ({ configID }) => {
  const [qry, { data, loading, error }] = useLazyQuery<
    ConfigMatchUnifiedResponse,
    ConfigMatchUnifiedRequest
  >(CONFIG_MATCH_UNIFIED_QUERY);

  const [showDq, setShowDq] = useState<"on" | "off">("off");

  const [pagination, setPagination] = useState<PaginationInput>(
    initialPaginationInput()
  );
  const [search, setSearch] = useState<string>("");
  const [ignoredDisqualifiers, setIgnoredDisqualifiers] = useState<number>(0);

  const [exportMatchCandidates] = useLazyQuery(EXPORT_CONFIG_MATCHES_QUERY, {
    onCompleted: (data) => {
      if (data && data.exportMatchCandidates) {
        jsonToCsv(data.exportMatchCandidates.match_data, data.exportMatchCandidates.object_data,  "config", configID );
      } 
    },
  });

  // must have this secondary function here due to appolo client re fetch
  // https://github.com/apollographql/apollo-client/issues/9317
  const exportMatches = (): void => {
    const isShowDisqualifiers = showDq === "on"
    exportMatchCandidates({
      variables: {
        objectId: configID,
        objectType: "config",
        search: search,
        dqFilters: isShowDisqualifiers ? -1 : ignoredDisqualifiers,
      },
    });
  }

  useEffect(() => {
    qry({
      variables: {
        configID,
        pagination,
        search,
        ignoreDq: showDq === "on" ? -1 : ignoredDisqualifiers,
        mode: "CANDIDATES_AND_DISQUALIFIED",
      },
    });
  }, [qry, configID, pagination, search, showDq, ignoredDisqualifiers]);

  if (loading === true)
    return <div className={quickReturnClass}>Loading... </div>;
  if (error !== undefined)
    return (
      <div className={quickReturnClass + "text-state-error"}>
        {error.message.replace(/GraphQL error: ?/, "")}
      </div>
    );
  if (!data?.configMatchUnified)
    return (
      <div className={quickReturnClass + "text-state-error"}>
        Error: No data received.
      </div>
    );

  const { configMatchUnified: cmu } = data;

  return (
    <div className="flex flex-col font-display">
      <TableHeader
        title=""
        elemCount={cmu.total_records}
        pagination={pagination}
        setPagination={setPagination}
        filter={search}
        searchCallback={(s: string) => setSearch(s)}
        exportCallback={exportMatches}
      />

      <div className="flex items-center">
        <span className="ml-2 mr-4">Include Disqualified</span>
        <input
          type="checkbox"
          checked={showDq === "on"}
          onChange={() => setShowDq((dq) => (dq === "on" ? "off" : "on"))}
        />
      </div>

      <IgnoreDisqualifiersRow
        ignored={ignoredDisqualifiers}
        setIgnored={setIgnoredDisqualifiers}
      />

      {cmu.requests && cmu.requests.length !== 0 ? (
        <table className="text-center">
          <thead>
            <tr className="bg-gray-100 uppercase">
              <th className="p-1 font-medium w-1/12">Score</th>
              <th className="p-1 font-medium">Guest</th>
              <th className="p-1 font-medium">Disqualifiers</th>
              <th className="p-1 font-medium">Square Footage</th>
              <th className="p-1 font-medium">Budget</th>
            </tr>
          </thead>

          <tbody>
            {cmu.requests?.map((c, i) => {
              return (
                <DisqualifiedRequestRow
                  key={i}
                  rowIdx={i}
                  guest={c}
                  ignoredDisqualifiers={ignoredDisqualifiers}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="m-auto text-lg">Nothing to see here!</div>
      )}
    </div>
  );
};

export default DisqualifiedRequests;
