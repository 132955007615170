import React from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import Field from "components/Forms/Field";
import Tooltip from "@mui/material/Tooltip";
import { filterFieldProps } from "../../services/utils";
import BaseInput from "./BaseInput";

import { positive } from "../../services/forms/validators";
import { formatCurrency } from "../../services/utils";

const HiddenBox = styled.div`
  background-color: white;
  width: 154px;
  height: 20px;
`;

function CurrencyInput({ isReadOnly = false, staticHoverText = "", ...props }) {
  const fieldProps = filterFieldProps(props);

  let decimalScale = 2;
  let placeholder = "$0.00";
  if (props.isDollarScale === true) {
    decimalScale = 0;
    placeholder = "$0";
  }

  return (
    <BaseInput {...props} readOnly={isReadOnly}>
      {({ isInputReadOnly, inputStaticHoverText }) => (
        <>
          {!isInputReadOnly && (
            <Field {...fieldProps} validate={positive}>
              {({ input: { value, onChange }, meta }) => (
                <div>
                  <NumberFormat
                    value={value}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={decimalScale}
                    fixedDecimalScale={true}
                    placeholder={placeholder}
                    className="input-default"
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      // formattedValue = $2,223
                      // value ie, 2223
                      // this.setState({profit: formattedValue})
                      onChange(floatValue);
                    }}
                  />
                  {meta.error && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      &nbsp;&nbsp;{meta.error}
                    </span>
                  )}
                </div>
              )}
            </Field>
          )}

          {isInputReadOnly && (
            <Field
              style={{ height: "20px" }}
              name={`${props.name}`}
              {...fieldProps}
            >
              {(props) => {
                return (
                  <Tooltip
                    title={inputStaticHoverText}
                    placement="top-start"
                    arrow
                  >
                    <div>
                      {props.input.value ? (
                        formatCurrency(props.input.value)
                      ) : (
                        <HiddenBox />
                      )}
                      {props.meta.error && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          &nbsp;&nbsp;{props.meta.error}
                        </span>
                      )}
                    </div>
                  </Tooltip>
                );
              }}
            </Field>
          )}
        </>
      )}
    </BaseInput>
  );
}

export default CurrencyInput;
