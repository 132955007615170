/* eslint-disable @typescript-eslint/ban-types */
import { resolveEnumValue } from "../../services/enum_utils";
import { EnumTermShort } from "../../services/enums";
import { Category, MatchResult, PaginationInput } from "./types";
import { IconState } from "../Icon";

export const termFromRange = (
  tMin?: string,
  tMax?: string,
  tEnd?: string
): string => {
  const tMinR =
    tMin !== undefined
      ? (resolveEnumValue(EnumTermShort, tMin) as string)
      : undefined;
  let tMaxR =
    tMax !== undefined
      ? (resolveEnumValue(EnumTermShort, tMax) as string)
      : undefined;
  if (tMax === "TRM_DATE") {
    // operating under the assumption that
    // (tMax === "TRM_DATE") -> (tEnd !== undefined)
    tMaxR = tEnd as string;
  }
  // operating under the assumption that
  // tMin <= tMax
  if (tMinR && tMaxR) {
    if (tMinR !== tMaxR) return `${tMinR} to ${tMaxR}`;
    else return tMinR;
  }
  if (!tMaxR) return `At least ${tMinR}`;
  if (!tMinR) return `At most ${tMaxR}`;
  return "N/A";
};

export const availableByDate = (
  availability?: Date,
  notice?: number
): string | null => {
  if (!notice) return null;

  let res: Date;
  if (availability) res = new Date(availability);
  else res = new Date();

  res.setDate(res.getDate() + notice);
  return res.toDateString();
};

export const sqftFromRange = (sqft_min?: number, sqft_max?: number): string => {
  if (!sqft_min && !sqft_max) return "N/A";
  if (!sqft_min && sqft_max) return `< ${sqft_max.toLocaleString()}`;
  if (sqft_min && !sqft_max) return `> ${sqft_min.toLocaleString()}`;
  if (sqft_min === sqft_max) return `${sqft_min?.toLocaleString()}`;
  return `${sqft_min?.toLocaleString()} - ${sqft_max?.toLocaleString()}`;
};

export const guestName = (org_name: string, req_name?: string): string => {
  const name = org_name;
  if (req_name !== undefined) org_name += ` ${req_name}`;
  return name;
};

export interface Disqualifier {
  cat: Category;
  ignored: boolean;
}

export const getDisqualifiers = (
  m: MatchResult,
  ignore: number
): Disqualifier[] => {
  const ret: Disqualifier[] = [];
  // iterate through the Categories and collect the disqualified, marking the
  // ignored ones.
  for (const _cat in Category) {
    const cat = parseInt(_cat);
    if (isNaN(cat)) continue;
    if ((m.Disqualified & cat) !== 0)
      ret.push({ cat, ignored: (ignore & cat) !== 0 });
  }
  return ret;
};

export const initialPaginationInput = (): PaginationInput => ({
  size: parseInt(localStorage.getItem("PG_SIZE") || "10"),
  index: 0,
});

/*
 * SCORING
 */

// Match score classification lowerbound thresholds.
interface MatchLBThresholds {
  great: Readonly<number>;
  good: Readonly<number>;
  ok: Readonly<number>;
}

let __MatchThresholds: MatchLBThresholds = { great: 75, good: 50, ok: 25 };

// Initialize the match thresholds from a set of tuning variables.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const __initMatchThresholds = (tuning: Object) => {
  let great = tuning["FE_MatchGreatLB"],
    good = tuning["FE_MatchGoodLB"],
    ok = tuning["FE_MatchOkLB"];

  // if any of the (expected) values is undefined, resort to default
  if (great === undefined) great = __MatchThresholds.great;
  if (good === undefined) good = __MatchThresholds.good;
  if (ok === undefined) ok = __MatchThresholds.ok;

  __MatchThresholds = { great, good, ok };
};

export type ScoreClassification = "great" | "good" | "ok" | "poor" | "none";

export const classificationColors: Readonly<
  Record<ScoreClassification, IconState>
> = {
  great: "score-great",
  good: "score-good",
  ok: "score-ok",
  poor: "score-poor",
  none: "score-none",
};

export const scoreClassificationRaw = (s: number): ScoreClassification => {
  if (s <= 0) return "none";
  if (__MatchThresholds.great <= s) return "great";
  if (__MatchThresholds.good <= s) return "good";
  if (__MatchThresholds.ok <= s) return "ok";
  return "poor";
};

export const scoreBreakdownCatClassification = (
  m: MatchResult,
  cat: Category
): ScoreClassification => {
  if ((m.Great & cat) !== 0) return "great";
  if ((m.OK & cat) !== 0) return "ok";
  if ((m.Poor & cat) !== 0) return "poor";
  if ((m.Zero & cat) !== 0) return "none";
  return "none";
};

export const displayPrice = (price?: number, price_min?: number): string => {
  if (!price) return "N/A";
  const ret = `$${(price / 100).toLocaleString()}`;
  if (price_min && price_min !== price)
    return `$${(price_min / 100).toLocaleString()} - ${ret}`;
  return ret;
};

export const displayAvailability = (
  availability: Date,
  notice?: number
): string => {
  const now = new Date();
  const availabilityd = new Date(availability);
  if (now < availabilityd) return `${availability}`;
  if (!notice || notice === 0) return "Immediately";
  return `${notice} days`;
};


export const jsonToCsv = (json: JSON, obj: JSON, title?: string, id?: number): void => {
  const values = Object.values(json);
  const replacer = (key, value) => value === null ? '' : value; // replacer for empty values
  const header = Object.keys(json[0]);
  const csvarr = values.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  csvarr.unshift(header.join(','));

  // if the title is config add 2 blank rows at beginning of array
  if (obj) {
    csvarr.unshift((",").repeat(header.length));
    csvarr.unshift((",").repeat(header.length));
    // add the config data at top replace null values with replacer
    const obj_value = Object.values(obj).map((value) => JSON.stringify(value, replacer));
    const obj_header = Object.keys(obj);
    csvarr.unshift(obj_value.join(','));
    csvarr.unshift(obj_header.join(','));
  }

  const csvstr  = csvarr.join('\r\n');

  const date = Date.now().toString();
  const fileName = [title, id, "matches", date + ".csv"].filter(Boolean).join("_");

  const blob = new Blob([csvstr], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement("a");
  if (link.download !== undefined) { // create a hidden element and simulate a download click
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

}
