import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import axios from "axios";
import Compress from "react-image-file-resizer";

import { PREPARE_ADD_DOCUMENT } from "../../graphql/queries";

import { isUndefinedOrNull } from "../../services/utils";

import {
  DOCS_ImagePublicMaxRes,
  DOCS_ImagePublicFormat,
} from "../../services/constants";

import loading from "../../assets/rolling.svg";

// convert base64 encoded string to a file
const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1;
  }
  return new File([u8arr], filename, { type: mime });
};

export default function UploadImage({
  image,
  imgStyle,
  onClick,
  imgIndex,
  updateFileHandle,
}) {
  // the prop "image" is a file selected from the file input
  // https://developer.mozilla.org/en-US/docs/Web/API/File/type

  // track if image is being uploaded
  const [isUploading, setIsUploading] = useState(false);
  const [processedImage, setProcessedImage] = useState();

  const onComplete = (data) => {
    // spacer-api returns pre-signed url for a document

    let resp = data.prepareAddDocument;

    let isPublic = false;
    let file;
    let signedUrl;
    let handle;

    // check if it's for private or public bucket
    if (resp.privateUrl && resp.privateUrl !== "") {
      signedUrl = resp.privateUrl;
      handle = resp.privateHandle;
      file = image.file;
    } else {
      signedUrl = resp.publicUrl;
      handle = resp.publicHandle;
      file = dataURLtoFile(processedImage, handle);
      isPublic = true;
    }

    // upload file to s3 bucket using pre-signed url
    axios
      .put(signedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      })
      .then(function (result) {
        // upload complete

        // set file handle and key
        // delete public, file, content_type
        updateFileHandle(imgIndex, handle, isPublic);
      })
      .catch(function (err) {
        // upload failed
        console.log("error when uploading photo", err);
      });
  };

  const [uploadPrivateImage] = useLazyQuery(PREPARE_ADD_DOCUMENT, {
    onCompleted: onComplete,
  });

  const [uploadPublicImage] = useLazyQuery(PREPARE_ADD_DOCUMENT, {
    onCompleted: onComplete,
  });

  useEffect(
    function () {
      // if image object doesn't have "handle" and "handle_public", it means it's a newly selected image.
      // but also, check if image is uploaded, so that we don't upload duplicates.
      if (
        isUndefinedOrNull(image.handle) &&
        isUndefinedOrNull(image.handle_public) &&
        isUploading === false
      ) {
        // upload original image to private bucket
        uploadPrivateImage({
          variables: {
            key: image.key,
            public: false,
          },
          fetchPolicy: "no-cache", // explictly say no cache, otherwise Apollo can provide cached response for very close requests. Refer: https://www.apollographql.com/docs/react/data/queries/
        });

        // https://github.com/onurzorluer/react-image-file-resizer#readme
        let maxRes = localStorage.getItem(DOCS_ImagePublicMaxRes);
        let publicFormat = localStorage.getItem(DOCS_ImagePublicFormat);

        Compress.imageFileResizer(
          image.file, // the file from input
          maxRes || 1500, // width ie. 480
          maxRes || 1500, // height ie. 480
          publicFormat || "JPEG", // compress format WEBP, JPEG, PNG
          70, // quality
          0, // rotation
          (resizedImage) => {
            // You upload logic goes here
            setProcessedImage(resizedImage);

            // upload processed image to public bucket
            uploadPublicImage({
              variables: {
                key: image.key,
                public: true,
              },
              fetchPolicy: "no-cache", // explictly say no cache, otherwise Apollo can provide cached response for very close requests. Refer: https://www.apollographql.com/docs/react/data/queries/
            });
          },
          "base64" // blob or base64 default base64
        );

        setIsUploading(true);
      }

      return function cleanup() {};
    },
    [image, isUploading, uploadPrivateImage, uploadPublicImage]
  );

  return (
    <>
      {(isUndefinedOrNull(image.handle) ||
        isUndefinedOrNull(image.handle_public)) && (
        <img src={loading} style={imgStyle} alt="uploading" />
      )}

      {!isUndefinedOrNull(image.handle) &&
        !isUndefinedOrNull(image.handle_public) && (
          <img
            src={image.url}
            style={imgStyle}
            alt="uploaded"
            onClick={onClick}
          />
        )}
    </>
  );
}

// from BE

// {
//   "document_id": 17,
//   "document_type": "DOC_IMAGE_PHOTO",
//   "file_type": "FT_PNG",
//   "handle": "p7c07f16e-9bc1-43e9-8e8f-77576ddeb07d",
//   "url": "https://spacer-public.s3-us-west-2.amazonaws.com/p7c07f16e-9bc1-43e9-8e8f-77576ddeb07d",
//   "owner_table": "DTB_PROPERTY",
//--   "owner_id": 20,
//   "seq": 0,
//   "configs": null,
//   "description": "main thumbnail",
//   "key": "p7c07f16e-9bc1-43e9-8e8f-77576ddeb07d"
// }

// from file input

// {
//   "document_id": null,
//   "document_type": "DOC_IMAGE_PHOTO",
//   "file_type": "FT_PNG",
//   "handle": null,
//   "url": "blob:http://localhost:3000/f7f4b8a4-3a34-4d39-9ae3-be919c2f9515",
//   "owner_table": "DTB_PROPERTY",
//   "seq": 0,
//   "configs": [
//     11
//   ],
//   "description": ""
//   "key": "ahn.png00",

//   "file": {},
//   "public": true,
//   "content_type": "image/png",
// }
