import { GridSection } from "components/CardComponents";
import Condition from "components/Forms/Condition";
import NullBooleanInput from "components/Forms/NullBooleanInput";
import NumberInput from "components/Forms/NumberInput";
import BaseTenantSectionProps from "interfaces/baseTenantSectionProps.interface";
import { ALT_NULL_BOOLEAN_INPUT_TEXT } from "services/constants";

export default function OptionalSpaceRequirements({
  requestName,
}: BaseTenantSectionProps): JSX.Element {
  return (
    <GridSection title="Optional Space Requirements" md={12} xs={12}>
      <NullBooleanInput
        name={`${requestName}.parking_car`}
        label={`Must have car parking?`}
        inputAltLabel={ALT_NULL_BOOLEAN_INPUT_TEXT}
        className="w-full"
      />

      <Condition when={`${requestName}.parking_car`} isNot={""}>
        <NumberInput
          name={`${requestName}.parking_car_number`}
          label={"How many cars/spots?"}
          indent={2}
        />
      </Condition>

      <NumberInput
        name={`${requestName}.power_amps`}
        label={`Min Power Needed (amps)`}
        isSideNote
      />
      <NullBooleanInput
        name={`${requestName}.power_highvoltage`}
        label={`Need High Voltage Power`}
        isSideNote
      />
    </GridSection>
  );
}
