import { Grid, Tooltip } from "@mui/material";
import {
  Person as PersonIcon,
  Tour as TourIcon,
  PersonOutline as PersonOutlineIcon,
  ManageAccounts as PrimaryPerson,
} from "@mui/icons-material";

import { GridSection } from "components/CardComponents";

import { getLengthHeader } from "services/utils";
import { CT_PRIMARY, CT_SIGNATURE, CT_TOUR } from "services/constants";
import { ContactsSectionProps } from "interfaces/contactsSection";

const iconStyling = "mr-3";

export default function ContactsSection({
  contacts,
  setPersonIndex,
  personIndex,
}: ContactsSectionProps): JSX.Element {
  const determinePrority = (contactType) => {
    switch (contactType) {
      case CT_PRIMARY:
        return 1;
      case CT_TOUR:
        return 2;
      case CT_SIGNATURE:
        return 3;
      default:
        return 4;
    }
  };

  const getIcon = (contactType) => {
    switch (contactType) {
      case CT_PRIMARY:
        return (
          <Tooltip title="primary">
            <PrimaryPerson className={iconStyling} />
          </Tooltip>
        );
      case CT_TOUR:
        return (
          <Tooltip title="tour">
            <TourIcon className={iconStyling} />
          </Tooltip>
        );
      case CT_SIGNATURE:
        return (
          <Tooltip title="signature">
            <PersonOutlineIcon className={iconStyling} />
          </Tooltip>
        );
      default:
        return <PersonIcon className={iconStyling} />;
    }
  };

  const sortedPeople = contacts?.sort(
    (a, b) =>
      determinePrority(a?.contact_type) - determinePrority(b?.contact_type)
  );

  return (
    <GridSection
      title={`Contacts ${getLengthHeader(contacts?.length)}`}
      md={3}
      xs={3}
    >
      <Grid className="mt-2">
        {sortedPeople && sortedPeople?.length > 0 ? (
          sortedPeople?.map((contact, index) => (
            <div className="flex mb-1 w-100 text-base pointer" key={index}>
              {getIcon(contact?.contact_type)}
              <span
                onClick={() => setPersonIndex(index)}
                className={
                  index === personIndex
                    ? "text-blue-600 cursor-pointer"
                    : "cursor-pointer"
                }
              >{`${contact.firstname} ${contact.lastname}`}</span>
            </div>
          ))
        ) : (
          <div className="ml-2">No Contacts Found</div>
        )}
      </Grid>
    </GridSection>
  );
}
