import React, { useEffect, useState } from "react";
import styled from "styled-components";

import UploadImage from "./UploadImage";

const Container = styled.div`
  width: 390px;
  height: 292.5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export default function PropertyImage({
  image,
  showFullSizeImage,
  imgIndex,
  updateFileHandle,
}) {
  const getImgStyle = (needBlown, isPortrait) => {
    if (needBlown && isPortrait) {
      return {
        height: `292.5px`,
      };
    } else if (needBlown && !isPortrait) {
      return {
        width: `390px`,
      };
    } else if (isPortrait) {
      return {
        maxHeight: `292.5px`,
      };
    } else {
      return {
        maxWidth: `390px`,
      };
    }
  };

  const [imgStyle, setImgStyle] = useState();

  useEffect(
    function () {
      // This is to fix the "Can't perform a React state update on an unmounted component." error.
      // The fix is track if the component is still mounted, and don't update the state if unmounted.
      // Reference I used: https://www.debuggr.io/react-update-unmounted-component/
      let mounted = true;

      var w;
      var h;
      var img = new Image();
      img.src = image.url;
      img.onload = () => {
        if (mounted === true) {
          w = img.width;
          h = img.height;
          setImgStyle(getImgStyle(w < 390 && h < 292.5, w < (4 / 3) * h));
        }
      };
      return function cleanup() {
        mounted = false;
      };
    },
    [image]
  );

  return (
    <Container>
      {image.url && (
        <UploadImage
          image={image}
          imgIndex={imgIndex}
          imgStyle={imgStyle}
          onClick={showFullSizeImage}
          updateFileHandle={updateFileHandle}
        />
      )}
      {!image.url && (
        <img
          src={require("../../assets/img-not-found.png")}
          alt={"not found"}
        />
      )}
    </Container>
  );
}
