import { Typography, Button } from "@mui/material";

interface ModalProps {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  ConfirmButtonText?: string;
  CancelButtonText?: string;
}

export default function Modal({
  onClose,
  onConfirm,
  title,
  message,
  ConfirmButtonText = "Confirm",
  CancelButtonText = "Cancel",
}: ModalProps): JSX.Element {
  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <div className="mt-2 ml-3">{message}</div>
      <div className="mt-2 flex justify-end">
        <div className="space-between">
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={onClose}
          >
            {CancelButtonText}
          </Button>
          <Button
            variant="contained"
            size="small"
            color="success"
            onClick={onConfirm}
            style={{ marginLeft: 5 }}
          >
            {ConfirmButtonText}
          </Button>
        </div>
      </div>
    </>
  );
}
