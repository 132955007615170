import BaseInput from "./BaseInput";

type TextFieldReadOnlyProps = {
  value: string | undefined;
  label: string;
};

export default function TextFieldReadOnly({
  value,
  label,
}: TextFieldReadOnlyProps): JSX.Element {
  return (
    <BaseInput label={label} isStatic={true}>
      <div>{value ? value : "N/A"}</div>
    </BaseInput>
  );
}
