import { useState, useEffect } from "react";
import styled from "styled-components";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Typography, Button } from "@mui/material";

import { PIPEDRIVE_CONNECT_QUERY } from "graphql/queries";
import {
  INIT_PIPEDRIVE_CONNECT,
  DISCONNECT_PIPEDRIVE,
} from "graphql/mutations";

import { PIPEDRIVE_CONNECTED } from "services/constants";
import { isPipedriveConnected } from "services/authenticator";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function PipedriveForm(__rerender) {
  const [isPipeDriveLinked, setPipeDriveLinked] = useState(false);
  const [userData, setUserData] = useState();
  const isConnected = isPipedriveConnected() && isPipeDriveLinked;

  const [qry] = useLazyQuery(PIPEDRIVE_CONNECT_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.pipedriveConnect?.isConnected) {
        setPipeDriveLinked(true);
        setUserData(
          data.pipedriveConnect.name + " (" + data.pipedriveConnect.email + ")"
        );
      }
    },
  });

  useEffect(() => {
    localStorage.getItem("mfa_enabled") === "true" && qry();
  }, [__rerender, qry]);

  const [initPipedriveConnect] = useMutation(INIT_PIPEDRIVE_CONNECT, {
    onCompleted: (data) => {
      if (data && data.initPipedriveConnect) {
        // open new tab and go to install link
        window.open(data.initPipedriveConnect, "_blank");
      }
    },
    onError: (e) => {},
  });

  const [disconnectPipedrive] = useMutation(DISCONNECT_PIPEDRIVE, {
    onCompleted: () => {
      localStorage.removeItem(PIPEDRIVE_CONNECTED);

      // reload
      window.location.reload();
    },
    onError: (e) => {},
  });

  const onClickConnect = () => {
    // call create pipedrive user endpoint
    initPipedriveConnect();
  };

  const onClickDisconnect = () => {
    // call disconnect endpoint
    disconnectPipedrive();
  };

  return (
    <>
      <Typography id="host" variant="h6" gutterBottom>
        Pipedrive
      </Typography>

      <Row>
        <div>
          <span className="ml-2">Status:</span>

          {isConnected && (
            <span style={{ color: "green" }} data-cy="pipedrive-connected">
              CONNECTED{userData ? " as " + userData : ""}
            </span>
          )}

          {!isConnected && (
            <span style={{ color: "red" }} data-cy="pipedrive-disconnected">
              DISCONNECTED
            </span>
          )}
        </div>

        {isConnected && (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={onClickDisconnect}
            >
              Disconnect
            </Button>
          </>
        )}

        {!isConnected && (
          <Button variant="contained" size="small" onClick={onClickConnect}>
            Connect
          </Button>
        )}
      </Row>
    </>
  );
}
