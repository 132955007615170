import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Tooltip, Modal } from "@mui/material";
import __ from "lodash";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ConfirmationDialog from "components/Forms/ConfirmationDialog";

import { GridSection } from "components/CardComponents";
import Field from "components/Forms/Field";
import PropertyImage from "components/Images/PropertyImage";
import FileUploader from "components/FileUploader";
import ImageCarouselModal from "components/Images/ImageCarouselModal";

import {
  DTB_PROPERTY,
  DOC_IMAGE_PHOTO,
  DOC_IMAGE_FLOORPLAN,
  HOVER_PROPERTY_FIELD,
  IMAGE_PHOTOS,
  IMAGE_FLOORPLANS,
} from "services/constants";

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  max-height: 600px;
  width: 650px;
  overflow-y: auto;
  display: block;
`;
const ImageList = styled.div`
  padding: 4px;
  display: block;
`;

const FormRow = styled.div`
  display: flex;
  margin-top: 6px;
  margin-bottom: 6px;
`;
const FormLabel = styled.div`
  margin-left: 5px;
  width: 250px;
`;
const BtnBox = styled.div``;

const ImgContainer = styled.div`
  max-height: 292.5px;
  width: 390px;
`;

const Box = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  height: 292.5px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
`;

const DeleteBtn = styled.button``; // REMOVE: Syled Components can be removed with Tailwind refactoring

export default function Image({
  photos,
  addImages,
  deleteImages,
  moveImages,
  floorplans,
  updateImageHandle,
  isEditable,
  propertyIndex,
  propertyName,
}) {
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openFloorPlanDialog, setOpenFloorPlanDialog] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [isPhotos, setIsPhotos] = useState(true);
  const [currIndex, setCurrIndex] = useState();
  const [currentImageId, setCurrentImageId] = useState();

  const addPhotoWithIndex = useMemo(
    () => addImages.bind(null, propertyIndex, IMAGE_PHOTOS),
    [addImages, propertyIndex]
  );
  const updatePhotoHandleWithIndex = useMemo(
    () => updateImageHandle.bind(null, propertyIndex, IMAGE_PHOTOS),
    [updateImageHandle, propertyIndex]
  );

  const addFloorplanWithIndex = useMemo(
    () => addImages.bind(null, propertyIndex, IMAGE_FLOORPLANS),
    [addImages, propertyIndex]
  );
  const updateFloorplanHandleWithIndex = useMemo(
    () => updateImageHandle.bind(null, propertyIndex, IMAGE_FLOORPLANS),
    [updateImageHandle, propertyIndex]
  );

  const showFullSizeImage = (isPhoto, index) => {
    setIsPhotos(isPhoto);
    setStartIndex(index);
    setOpenImageModal(true);
  };

  const closeModal = () => {
    setOpenImageModal(false);
    setStartIndex(0);
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      closeModal();
    }
  };

  const onDragEnd = (result, type) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    moveImages(propertyIndex, source.index, destination.index, type);
  };

  const onDragPhoto = (result) => {
    onDragEnd(result, IMAGE_PHOTOS);
  };

  const onDragFloorplan = (result) => {
    onDragEnd(result, IMAGE_FLOORPLANS);
  };

  const handleOpenImageDialog = (index, image_id) => {
    setCurrIndex(index);
    setCurrentImageId(image_id);
    setOpenImageDialog(true);
  };

  const handleOpenFloorPlanDialog = (index, image_id) => {
    setCurrIndex(index);
    setCurrentImageId(image_id);
    setOpenFloorPlanDialog(true);
  };

  const handleClose = () => {
    setOpenImageDialog(false);
    setOpenFloorPlanDialog(false);
  };

  useEffect(function () {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  const dialogTitle = "Confirmation";
  const dialogContent = "Are you sure you want to delete this image?";

  return (
    <GridSection title="Images" md={12} xs={12}>
      {/* photos and floor plans */}
      <FormRow>
        <FormLabel className="font-bold">Photos</FormLabel>
        <BtnBox>
          {isEditable && (
            <FileUploader
              documentType={DOC_IMAGE_PHOTO}
              ownerTableType={DTB_PROPERTY}
              addFile={addPhotoWithIndex}
              datacy={`upload-photo-${propertyIndex}`}
            />
          )}
        </BtnBox>
      </FormRow>

      <Field
        name={`${propertyName}.photos`}
        isEqual={(a, b) => {
          return __.isEqual(__.sortBy(a), __.sortBy(b));
        }}
      >
        {(props) => {
          return (
            <>
              {!isEditable && (
                <>
                  {photos && photos.length > 0 && (
                    <Tooltip
                      title={HOVER_PROPERTY_FIELD}
                      placement="top-start"
                      arrow
                    >
                      <FormRow>
                        <Container>
                          <ImageList>
                            {photos.map((image, index) => {
                              return (
                                <Box key={`static_photo_${index}`}>
                                  <ImgContainer>
                                    <PropertyImage
                                      image={image}
                                      imgIndex={index}
                                      updateFileHandle={
                                        updatePhotoHandleWithIndex
                                      }
                                      showFullSizeImage={() =>
                                        showFullSizeImage(true, index)
                                      }
                                    />
                                  </ImgContainer>
                                  {image.description}
                                </Box>
                              );
                            })}
                          </ImageList>
                        </Container>
                      </FormRow>
                    </Tooltip>
                  )}
                </>
              )}

              {isEditable && (
                <FormRow>
                  {photos && photos.length > 0 && (
                    <DragDropContext onDragEnd={onDragPhoto}>
                      <Container>
                        <Droppable
                          droppableId={"image-row"}
                          direction="vertical"
                        >
                          {(provided) => (
                            <ImageList
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {photos.map((image, index) => {
                                return (
                                  <Draggable
                                    key={
                                      "photo-" +
                                      (image.document_id
                                        ? image.document_id.toString()
                                        : image.key)
                                    }
                                    draggableId={
                                      "photo-" +
                                      (image.document_id
                                        ? image.document_id.toString()
                                        : image.key)
                                    }
                                    index={index}
                                  >
                                    {(provided) => (
                                      <>
                                        <Box
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <ImgContainer>
                                            <PropertyImage
                                              image={image}
                                              imgIndex={index}
                                              updateFileHandle={
                                                updatePhotoHandleWithIndex
                                              }
                                              showFullSizeImage={() =>
                                                showFullSizeImage(true, index)
                                              }
                                            />
                                          </ImgContainer>
                                          <Field
                                            name={`${propertyName}.photos[${index}].description`}
                                          >
                                            {({ input, meta }) => (
                                              <div
                                                style={{ paddingLeft: `10px` }}
                                              >
                                                <p
                                                  style={{
                                                    marginBottom: `5px`,
                                                  }}
                                                >
                                                  Description:
                                                </p>
                                                {
                                                  <textarea
                                                    {...input}
                                                    rows="3"
                                                    cols="25"
                                                    style={{ resize: `none` }}
                                                  />
                                                }
                                                <DeleteBtn
                                                  className="std-button"
                                                  type="button"
                                                  onClick={() =>
                                                    handleOpenImageDialog(
                                                      index,
                                                      image.handle
                                                    )
                                                  }
                                                >
                                                  Delete this photo
                                                </DeleteBtn>
                                              </div>
                                            )}
                                          </Field>
                                        </Box>
                                      </>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                              <ConfirmationDialog
                                open={openImageDialog}
                                onClose={handleClose}
                                onConfirm={() =>
                                  deleteImages(
                                    propertyIndex,
                                    IMAGE_PHOTOS,
                                    currIndex,
                                    currentImageId
                                  )
                                }
                                title={dialogTitle}
                                content={dialogContent}
                              />
                            </ImageList>
                          )}
                        </Droppable>
                      </Container>
                    </DragDropContext>
                  )}
                </FormRow>
              )}
            </>
          );
        }}
      </Field>

      <FormRow>
        <FormLabel className="font-bold">Floorplans</FormLabel>
        <BtnBox>
          {isEditable && (
            <FileUploader
              documentType={DOC_IMAGE_FLOORPLAN}
              ownerTableType={DTB_PROPERTY}
              addFile={addFloorplanWithIndex}
              datacy={`upload-floorplans-${propertyIndex}`}
            />
          )}
        </BtnBox>
      </FormRow>
      <Field
        name={`${propertyName}.floorplans`}
        isEqual={(a, b) => {
          return __.isEqual(__.sortBy(a), __.sortBy(b));
        }}
      >
        {(props) => {
          return (
            <>
              {!isEditable && (
                <>
                  {floorplans && floorplans.length > 0 && (
                    <Tooltip
                      title={HOVER_PROPERTY_FIELD}
                      placement="top-start"
                      arrow
                    >
                      <FormRow>
                        <Container>
                          <ImageList>
                            {floorplans.map((image, index) => {
                              return (
                                <Box key={`static_floorplans_${index}`}>
                                  <ImgContainer>
                                    <PropertyImage
                                      image={image}
                                      imgIndex={index}
                                      updateFileHandle={
                                        updateFloorplanHandleWithIndex
                                      }
                                      showFullSizeImage={() =>
                                        showFullSizeImage(false, index)
                                      }
                                    />
                                  </ImgContainer>
                                  {image.description}
                                </Box>
                              );
                            })}
                          </ImageList>
                        </Container>
                      </FormRow>
                    </Tooltip>
                  )}
                </>
              )}

              {isEditable && (
                <FormRow>
                  {floorplans && floorplans.length > 0 && (
                    <DragDropContext onDragEnd={onDragFloorplan}>
                      <Container>
                        <Droppable
                          droppableId={"image-row"}
                          direction="vertical"
                        >
                          {(provided) => (
                            <ImageList
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {floorplans.map((image, index) => {
                                return (
                                  <Draggable
                                    key={
                                      "floorplan-" +
                                      (image.document_id
                                        ? image.document_id.toString()
                                        : image.key)
                                    }
                                    draggableId={
                                      "floorplan-" +
                                      (image.document_id
                                        ? image.document_id.toString()
                                        : image.key)
                                    }
                                    index={index}
                                  >
                                    {(provided) => (
                                      <>
                                        <Box
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <ImgContainer>
                                            <PropertyImage
                                              image={image}
                                              imgIndex={index}
                                              updateFileHandle={
                                                updateFloorplanHandleWithIndex
                                              }
                                              showFullSizeImage={() =>
                                                showFullSizeImage(false, index)
                                              }
                                            />
                                          </ImgContainer>
                                          <Field
                                            name={`${propertyName}.floorplans[${index}].description`}
                                          >
                                            {({ input, meta }) => (
                                              <div
                                                style={{ paddingLeft: `10px` }}
                                              >
                                                <p
                                                  style={{
                                                    marginBottom: `5px`,
                                                  }}
                                                >
                                                  Description:
                                                </p>
                                                {
                                                  <textarea
                                                    {...input}
                                                    rows="3"
                                                    cols="25"
                                                    style={{ resize: `none` }}
                                                  />
                                                }
                                                <DeleteBtn
                                                  className="std-button"
                                                  type="button"
                                                  onClick={() =>
                                                    handleOpenFloorPlanDialog(
                                                      index,
                                                      image.handle
                                                    )
                                                  }
                                                >
                                                  Delete this floorplan
                                                </DeleteBtn>
                                              </div>
                                            )}
                                          </Field>
                                        </Box>
                                      </>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                              <ConfirmationDialog
                                open={openFloorPlanDialog}
                                onClose={handleClose}
                                onConfirm={() =>
                                  deleteImages(
                                    propertyIndex,
                                    IMAGE_FLOORPLANS,
                                    currIndex,
                                    currentImageId
                                  )
                                }
                                title={dialogTitle}
                                content={dialogContent}
                              />
                            </ImageList>
                          )}
                        </Droppable>
                      </Container>
                    </DragDropContext>
                  )}
                </FormRow>
              )}
            </>
          );
        }}
      </Field>
      {isPhotos && photos && photos.length > 0 && (
        <Modal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <>
            <ImageCarouselModal
              images={photos}
              startIndex={startIndex}
            ></ImageCarouselModal>
          </>
        </Modal>
      )}
      {!isPhotos && floorplans && floorplans.length > 0 && (
        <Modal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <>
            <ImageCarouselModal
              images={floorplans}
              startIndex={startIndex}
            ></ImageCarouselModal>
          </>
        </Modal>
      )}
    </GridSection>
  );
}
