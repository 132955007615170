import __ from "lodash";

const deleteImageFromConfigs = (configToDeleteFrom, deleteId, objectKey) => {
  const configs = __.cloneDeep(configToDeleteFrom);

  configs.forEach((config) => {
    const removePhotoIndex = config.listings?.[objectKey]?.findIndex(
      (photo) => photo === deleteId
    );
    if (removePhotoIndex != null && removePhotoIndex !== -1) {
      config.listings[objectKey].splice(removePhotoIndex, 1);
    }
  });
  return configs;
};

const addImageToConfigs = (configsToAddTo, addId, objectKey) => {
  const configs = __.cloneDeep(configsToAddTo);

  configs.forEach((config) => {
    config?.listings?.[objectKey]?.push(addId);
  });

  return configs;
};

export { deleteImageFromConfigs, addImageToConfigs };
