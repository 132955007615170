import React from "react";
import Icon, { IconId, IconSize, IconState } from "./Icon";

interface Props {
  iconId: IconId;
  text?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  filter?: string; // icon filter
  iconSize?: IconSize;
  enabled?: boolean;
  state?: IconState;
  className?: string;
  datacy?: string;
}

const btnBase =
  "flex rounded-md px-1 mx-2 bg-blue-500 items-center justify-between";
const btnDisabled = "disabled:bg-blue-300 disabled:cursor-not-allowed";
const btnHover = "hover:bg-blue-400";
const btnClass = [btnBase, btnDisabled, btnHover].join(" ");

const IconButton: React.FC<Props> = ({
  iconId,
  text,
  onClick,
  filter,
  iconSize,
  enabled,
  state,
  className,
  datacy,
}) => {
  return (
    <button
      disabled={!enabled}
      className={className ? className : btnClass}
      onClick={onClick}
      type="button"
      data-cy={datacy}
    >
      <Icon
        iconId={iconId}
        size={iconSize || "base"}
        filter={filter}
        state={enabled ? state : "disabled"}
      />
      {text && (
        <p className="pl-1 text-base text-white font-semibold">{text}</p>
      )}
    </button>
  );
};

export default IconButton;
