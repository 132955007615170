export default function BuildVersion() {
  const githubUrl = "https://github.com/rentplaceholder";

  // TODO: These properties want to be auto-discovered and cached for performance
  let version = {
    environment: process.env.REACT_APP_ENV || "⚠️",
    release_date: new Date(process.env.REACT_APP_BUILD_DATE),
    build: process.env.REACT_APP_BUILD_NUM || "⚠️",
    commit: process.env.REACT_APP_BUILD_COMMIT || "⚠️",
    schema: process.env.REACT_APP_SCHEMA_VERSION || "⚠️",
  };

  // suppress output if we are in PRODUCTION
  if (version.environment.toLowerCase() === "production") {
    return null;
  }

  // Not production environment, render output
  return (
    <div className="text-xs">
      <div>
        {version.environment.toUpperCase()}{" "}
        <a
          href={githubUrl + "/spacer-fe-private/tag/v" + version.build}
          className="text-blue-700"
        >
          v{version.build}
        </a>
      </div>
      <div>
        <div>
          Commit:
          <a
            href={githubUrl + "/spacer-fe-private/commit/" + version.commit}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700"
          >
            {" " + version.commit.substring(0, 6)}
          </a>
        </div>
        <div>
          Schema:
          <a
            href={githubUrl + "/spacer-infra/releases/tag/v" + version.schema}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700"
          >
            {" " + version.schema}
          </a>
        </div>
      </div>
    </div>
  );
}
