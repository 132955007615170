import { useForm } from "react-final-form";
import { EmptyResultsCard } from "components/CardComponents";
import PropertyInfo from "./PropertyInfo";
import { OrganizationChildrenTypes } from "enums/organizationTypes";
import { Property } from "interfaces/property";
import { useContext, useState } from "react";
import { PropertiesContext } from "contexts";
import { AppContext } from "contexts/AppContext";

interface PropertiesProps {
  properties: Property[];
  propertiesInitialValues: Property[];
}

export default function Properties({
  properties,
  propertiesInitialValues,
}: PropertiesProps): JSX.Element {
  const { mutators } = useForm();
  const { required_listing_fields } = useContext(AppContext);
  const [selectedPropertyID, setSelectedPropertyID] = useState<number | null>(
    null
  );

  const addProperty = () => {
    mutators.addProperty(required_listing_fields);
  };

  return (
    <PropertiesContext.Provider
      value={{ selectedPropertyID, setSelectedPropertyID }}
    >
      {properties.map((property, index) => (
        <div className="w-full mt-4" key={index}>
          <PropertyInfo
            propertyIndex={index}
            property={property}
            mutators={mutators}
            propertyInitialValues={propertiesInitialValues[index]}
          />
        </div>
      ))}
      <EmptyResultsCard
        isEmpty={false}
        type={OrganizationChildrenTypes.Property}
        onAddClick={addProperty}
      />
    </PropertiesContext.Provider>
  );
}
