import { Typography, Grid, GridDirection, Paper } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";

type GridSectionProps = {
  title?: string;
  md?: number;
  xs?: number;
  sm?: number;
  children: React.ReactNode;
  direction?: ResponsiveStyleValue<GridDirection>;
  HeaderComponent?: React.ReactElement;
  className?: string;
  paperClassName?: string;
  isItem?: boolean;
};

export default function GridSection({
  title,
  md,
  xs,
  sm,
  children,
  direction,
  HeaderComponent,
  className = "",
  paperClassName = "",
}: GridSectionProps): JSX.Element {
  return (
    <Grid
      item
      md={md}
      xs={xs}
      sm={sm}
      direction={direction}
      className={className}
    >
      <Paper className={`p-5 h-full ${paperClassName}`}>
        {title && (
          <div className={`flex justify-between`}>
            <Typography variant="h6">{title}</Typography>
            {HeaderComponent && <div>{HeaderComponent}</div>}
          </div>
        )}
        {children}
      </Paper>
    </Grid>
  );
}
