import { useEffect } from "react";
import { geoCode, reversGeoCode } from "./GoogleMapFunctions";
import { CENTER_NORTH_AMERICA } from "services/constants";

let geocoder;
let dynamicMap;

function InitDynamicMap(
  setMapError,
  updateAddress,
  initialAddress,
  newLat,
  newLong,
  address_known,
  disabled,
  mapNumber,
) {
  geocoder = new google.maps.Geocoder(); // for getting pin from inputed address

  dynamicMap = new google.maps.Map(
    document.querySelector(
      `[id='dynamicGoogleMap-${mapNumber}']`
    ) as HTMLDivElement,
    { zoom: 13 }
  );

  const marker = new google.maps.Marker({
    map: dynamicMap,
  });

  if (!disabled) {
    dynamicMap.addListener("click", (e: google.maps.MapMouseEvent) => {
      if (e !== null) {
        reversGeoCode(
          e.latLng,
          updateAddress,
          setMapError,
          geocoder,
        );
        marker.setPosition(e.latLng); //places marker no matter what want if the place is still not valid
      }
    });
  }

  const addressComponent = initialAddress?.address;

  if (
    (addressComponent?.cache_lat && addressComponent?.cache_long) ||
    (newLat && newLong)
  ) {
    let lat;
    let long;
    let concAddress;
    // handle case when initial vlaue is null but we have new clicked values
    if (newLat && newLong) {
      lat = parseFloat(newLat);
      long = parseFloat(newLong);
    } else {
      concAddress = `${addressComponent.address}, ${addressComponent.city}, ${addressComponent.province}, ${addressComponent.postal}`;
      lat = parseFloat(addressComponent.cache_lat);
      long = parseFloat(addressComponent.cache_long);
    }

    const latlng = new google.maps.LatLng({
      lat: lat,
      lng: long,
    });

    geoCode(
      concAddress,
      latlng,
      dynamicMap,
      marker,
      address_known,
      setMapError,
      geocoder
    );
  } else {
    // get users location
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        const latLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // extend the bounds to include the users location
        geoCode(
          "",
          new google.maps.LatLng(latLng),
          dynamicMap,
          marker,
          address_known,
          setMapError,
          geocoder
        );
      } // if user does not allow location services, just show north america
      , () => {
          dynamicMap.zoom = 3;
          geoCode(
            "",
            new google.maps.LatLng(CENTER_NORTH_AMERICA),
            dynamicMap,
            null,
            address_known,
            setMapError,
            geocoder
          );
        }
    );

  }
}

function Info(valuesFound, mapError) {
  // A function that returns usefull directions for the user on the state of the map
  if (!valuesFound) {
    return <p>Click on the map to get an approximate location</p>;
  } else if (mapError === true && valuesFound === true) {
    return (
      <p className={"text-state-fatal"}>
        Cannot find a marker for this location. Click again to find a new place
      </p>
    );
  } else if (mapError === false && valuesFound === true) {
    return <p>To update the location click on a new place</p>;
  }
}

export default function DynamicGoogleMap(props: {
  property;
  propertyHasValues;
  setMapError;
  mapError;
  updateAddress;
  initialAddress;
  disabled;
  mapNumber;
}): JSX.Element {
  useEffect(() => {
    // For initializing dynamic map
    if (props.property.address_known === false) {
      InitDynamicMap(
        props.setMapError,
        props.updateAddress,
        props.initialAddress,
        props.property.cache_lat,
        props.property.cache_long,
        props.property.address_known,
        props.disabled,
        props.mapNumber,
      );
    }
  }, [
    props.property.address_known,
    props.setMapError,
    props.updateAddress,
    props.initialAddress,
    props.disabled,
    props.mapNumber,
    props.property.cache_lat,
    props.property.cache_long,
  ]);

  return (
    <>
      {!props.property.address_known && (
        <div className={"p-5px"}>
          {Info(props.propertyHasValues(props.property), props.mapError)}
        </div>
      )}
      <div
        className={"w-google-map h-google-map"}
        id={`dynamicGoogleMap-${props.mapNumber}`}
      ></div>
    </>
  );
}
// note do not spam the clicks over a river timeouts can happen
// note maps will be blank if they have no centers
