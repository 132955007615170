import React from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Redirect } from "react-router-dom";
import { isTokenValid } from '../services/authenticator'

const SemiPrivateRoute = ({ component: Component, ...rest }) => {

  const render = props => {
    // console.log("semi private route checking, ", props.location.pathname)
    const srcPath = props.location.pathname
    const srcQry = props.location.search

    if (isTokenValid()) {
      // console.log("valid token, proceed forward")
      return <Component {...props} />
    }

    // add some state to the redirect so that the user can be sent to the page
    // they wanted to originally visit after login
    return <Redirect to={{
      pathname: "/login",
      state: {
        srcPath: srcPath,
        srcQry: srcQry,
      }
    }} />
  }

  return <Route render={render} {...rest} />;
};

SemiPrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default withRouter(SemiPrivateRoute);
