import { useState } from "react";
import {
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { isEmptyString } from "services/utils";
import { AddConfigurationProps } from "interfaces/addConfiguration";

export default function AddConfiguration({
  title,
  values,
  onAddConfiguration: handleAddConfiguration,
  datacy,
}: AddConfigurationProps): JSX.Element {
  const configLength = values?.length;
  const [cloneConfiguration, setCloneConfiguration] = useState<string | null>(
    configLength ? values[0].value : null
  );
  const [selectedOption, setSelectedOption] = useState<string>("0");
  const [configName, setConfigName] = useState<string>("");
  const [existingName, setExistingName] = useState<string>("");

  const isExistingNameEmpty = isEmptyString(existingName);
  const isConfigNameEmpty = isEmptyString(configName);
  const hasExistingName = !isEmptyString(values[0].name);

  const hasOneConfig = configLength === 1;
  const shouldShowExistingNameInput = hasOneConfig && !hasExistingName;

  const createOptions = () => {
    const options = [
      {
        value: 0,
        name: `Create a new blank ${title.toLowerCase()}`,
      },
    ];

    if (values.length !== 0)
      options.push({
        value: 1,
        name: hasOneConfig
          ? `Clone from current`
          : `Clone from existing ${title.toLowerCase()}`,
      });

    return options;
  };

  const showError = (hasError) => {
    return hasError ? "Required" : null;
  };

  return (
    <>
      <Typography id="new_config" variant="h6">
        Add {title}
      </Typography>
      <div className="ml-3">
        {
          <>
            <div className="mt-2">
              {shouldShowExistingNameInput && (
                <>
                  <p>
                    Please add names for your existing and new{" "}
                    {title.toLowerCase()} to distinguish them.
                  </p>
                  <label className="input-label mt-3">Existing Name</label>
                  <TextField
                    value={existingName}
                    inputProps={{
                      "data-cy": "existing-name",
                    }}
                    size="small"
                    onChange={(event) => setExistingName(event.target.value)}
                    error={isExistingNameEmpty}
                    helperText={showError(isExistingNameEmpty)}
                  />
                </>
              )}
              <label className="input-label">New Name</label>
              <TextField
                name={`new_name`}
                value={configName}
                inputProps={{
                  "data-cy": "new-name",
                }}
                onChange={(event) => setConfigName(event.target.value)}
                size="small"
                error={isConfigNameEmpty}
                helperText={showError(isConfigNameEmpty)}
              />
            </div>
            <RadioGroup
              className="mt-2"
              value={selectedOption}
              onChange={(event) => setSelectedOption(event.target.value)}
            >
              {createOptions().map((data, index) => (
                <FormControlLabel
                  key={index}
                  value={data.value}
                  control={<Radio />}
                  label={data.name}
                />
              ))}
            </RadioGroup>
          </>
        }

        {selectedOption === "1" && !hasOneConfig && (
          <FormControl>
            <Select
              value={cloneConfiguration}
              onChange={(event) => setCloneConfiguration(event.target.value)}
              className="h-10"
            >
              {values.map(({ name, value }, index) => (
                <MenuItem key={index} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <br />
        <div className="mt-4 flex justify-end">
          <Button
            type="submit"
            data-cy="submit-add-config"
            variant="contained"
            onClick={() =>
              handleAddConfiguration(
                configName,
                selectedOption === "1" ? cloneConfiguration : null,
                existingName
              )
            }
            disabled={
              shouldShowExistingNameInput
                ? isExistingNameEmpty || isConfigNameEmpty
                : isConfigNameEmpty
            }
          >
            Add {title}
          </Button>
        </div>
      </div>
    </>
  );
}
