import React from "react";
import Contact from "../Contact";
import PropertyAttributes from "./PropertyAttributes";
import { ConfigMatchData } from "./types";
import {
  availableByDate,
  displayPrice,
  sqftFromRange,
  termFromRange,
} from "./utils";
import { buildReferenceURL } from "services/utilities";
import { EntityType } from "enums";

interface Props {
  data: ConfigMatchData;
}

const ConfigHeaderSummary: React.FC<Props> = ({ data }) => {
  return (
    <div className="flex font-display justify-between">
      {/*address & text summary*/}
      <div className="flex flex-col font-medium text-gray-700 mx-1">
        <p className="text-2xl text-blue-500">
          <a
            className="decorated-link"
            href={buildReferenceURL({
              id: data.config_id,
              type: EntityType.PROPERTY_CONFIG,
            })}
            target="_blank"
            rel="noreferrer"
          >
            {data.address}
          </a>
        </p>
        <p className="text-lg">
          {data.city}, {data.province} {data.postal}
        </p>
        <table>
          <tbody>
            <tr>
              <td className="text-right font-bold">Place Fee</td>
              <td className="pl-2">
                {displayPrice(data.price, data.price_min)}
              </td>
            </tr>

            <tr>
              <td className="text-right font-bold">Square Footage</td>
              <td className="pl-2">
                {sqftFromRange(
                  data.square_footage_min,
                  data.square_footage_max
                )}
              </td>
            </tr>

            <tr>
              <td className="text-right font-bold">Term</td>
              <td className="pl-2">
                {termFromRange(data.term_min, data.term_max, data.term_end)}
              </td>
            </tr>

            <tr>
              <td className="flex justify-end text-right font-bold">
                Availability
              </td>
              <td className="pl-2">
                <>
                  {data.availability || (
                    <span className="font-semibold">Immediately</span>
                  )}
                  {data.availability_notice && (
                    <span> + {data.availability_notice} days</span>
                  )}
                  {data.availability_notice && (
                    <p>
                      {availableByDate(
                        data.availability,
                        data.availability_notice
                      )}
                    </p>
                  )}
                </>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/*property attributes summary*/}
      <div className="">
        <p className="ml-1 mb-2">PROPERTIES</p>
        <PropertyAttributes propertyData={data.config_data} />
      </div>

      {/*primary contact*/}
      {data.primary_contact && (
        <div className="flex flex-col mx-1">
          <p className="ml-1 mb-2">CONTACT</p>
          <Contact
            name={
              data.primary_contact.firstname +
              " " +
              data.primary_contact.lastname
            }
            position={data.primary_contact.title}
            email={data.primary_contact.email}
            phone={data.primary_contact.phone}
          />
        </div>
      )}
    </div>
  );
};

export default ConfigHeaderSummary;
