import React from "react";
import { useField } from "react-final-form";
import Error from "./Error";

export default function FieldError({ name }) {
  const {
    meta: { error },
  } = useField(name, { subscription: { error: true } });

  return <Error showError={true} error={error} />;
}
