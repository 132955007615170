export type EnumValT = string | number;
export type EnumEntryT = { value: EnumValT; name: string };
export type EnumT = Array<EnumEntryT>;

export const EnumContactMethod: EnumT = [
  { value: "CM_EMAIL", name: "Email" },
  { value: "CM_PHONE", name: "Phone" },
  { value: "CM_SMS", name: "SMS" },
];

export const EnumContactCategoryPhone: EnumT = [
  { value: "CC_WORK", name: "Work" },
  { value: "CC_HOME", name: "Home" },
  { value: "CC_MOBILE", name: "Mobile" },
  { value: "CC_OTHER", name: "Other" },
];

export const EnumContactCategoryEmail: EnumT = [
  { value: "CC_WORK", name: "Work" },
  { value: "CC_HOME", name: "Home" },
  { value: "CC_OTHER", name: "Other" },
];

export const EnumHostType: EnumT = [
  { value: "HT_LANDLORD", name: "Landlord" },
  { value: "HT_TENANT", name: "Tenant" },
  { value: "HT_BROKER", name: "Broker" },
  { value: "HT_PROPMGR", name: "Property Manager" },
  { value: "HT_UNKNOWN", name: "Unknown" },
];

export const EnumGuestType: EnumT = [
  { value: "GT_TENANT_CORP", name: "Tenant (corporate)" },
  { value: "GT_TENANT_PERSON", name: "Tenant (individual)" },
  { value: "GT_BROKER", name: "Broker" },
  { value: "GT_CONSULTANT", name: "Consultant" },
  { value: "GT_UNKNOWN", name: "Unknown" },
];

export const EnumListFeeBasisWarehouse: EnumT = [
  { value: "LFB_GROSS", name: "per month" },
  { value: "LFB_SQFT_MO", name: "per sq. ft. per month" },
  { value: "LFB_SQFT_YR", name: "per sq. ft. per year" },
];

export const EnumListingProvider: EnumT = [
  { value: "LP_PLACEHOLDER", name: "Placeholder" },
  { value: "LP_KIJIJI", name: "Kijiji" },
  { value: "LP_SPACELIST", name: "Spacelist" },
  { value: "LP_CREXI", name: "Crexi" },
  { value: "LP_CRAIGSLIST", name: "Craigslist" },
  { value: "LP_COMMERCIALEDGE", name: "CommercialEdge" },
  { value: "LP_DIGSY", name: "Digsy" },
  { value: "LP_BREVITAS", name: "Brevitas" },
  { value: "LP_CIMLS", name: "CIMLS" },
  { value: "LP_OFFICESPACE", name: "Officespace" },
];

export const EnumAutoListProvider: EnumT = [
  { value: "LP_PLACEHOLDER", name: "Placeholder" },
  { value: "LP_KIJIJI", name: "Kijiji" },
  { value: "LP_SPACELIST", name: "Spacelist" },
  { value: "LP_CREXI", name: "Crexi" },
];

export const EnumSpacerLabels: EnumT = [
  { name: "Hot", value: "LabelHot" },
  { name: "New", value: "LabelNew" },
  { name: "Highlight", value: "LabelHighlight" },
];

export const EnumCanadaMarket: EnumT = [
  { value: "MKT_CA_TORONTO", name: "Toronto" },
  { value: "MKT_CA_CALGARY", name: "Calgary" },
  { value: "MKT_CA_VANCOUVER", name: "Vancouver" },
  { value: "MKT_CA_KELOWNA", name: "Kelowna" },
  { value: "MKT_CA_OTTAWA", name: "Ottawa" },
  { value: "MKT_CA_MONTREAL", name: "Montreal" },
  { value: "MKT_CA_EDMONTON", name: "Edmonton" },
];

export const EnumUSMarket: EnumT = [
  { value: "MKT_US_NORTHJERSEY", name: "North Jersey" },
  { value: "MKT_US_ATLANTA", name: "Atlanta" },
  { value: "MKT_US_CHICAGO", name: "Chicago" },
  { value: "MKT_US_LOSANGELES", name: "Los Angeles" },
  { value: "MKT_US_PHOENIX", name: "Phoenix" },
  { value: "MKT_US_DALLAS", name: "Dallas" },
  { value: "MKT_US_HOUSTON", name: "Houston" },
  { value: "MKT_US_AUSTIN", name: "Austin / San Antonio" },
  { value: "MKT_US_MIAMI", name: "Miami" },
  { value: "MKT_US_JACKSONVILLE", name: "Jacksonville" },
  { value: "MKT_US_TAMPA", name: "Tampa" },
];

export const EnumCountry: EnumT = [
  { value: "CA", name: "Canada" },
  { value: "US", name: "US" },
];

export const EnumPropertyStatus: EnumT = [
  { value: "PS_PENDING", name: "Pending" },
  { value: "PS_AVAILABLE_MATCH", name: "Available, matchable" },
  { value: "PS_AVAILABLE_NOMATCH", name: "Available, not matchable" },
  { value: "PS_BOOKED_NOMATCH", name: "Booked, not matchable" },
  { value: "PS_BOOKED_MATCH", name: "Booked, matchable " },
  { value: "PS_UNAVAILABLE", name: "Not Available" },
];

export const EnumSelfListingPropertyStatus: EnumT = [
  { value: "PS_PENDING", name: "Pending" },
  { value: "PS_AVAILABLE_MATCH", name: "Available, matchable" },
  { value: "PS_IN_REVIEW", name: "Ready For Review" },
  { value: "PS_REVIEW_REJECTED", name: "Review Rejected" },
];

export const EnumPropertyLostReason: EnumT = [
  { value: "PLR_LANDLORD_PULL", name: "No longer renting out space" },
  { value: "PLR_LANDLORD_BREAK", name: "No longer wants to work with PH" },
  { value: "PLR_LANDLORD_FILLED", name: "Found non-PH tenant" },
  { value: "PLR_CONFIG_FILLED", name: "Filled by other config" },
  { value: "PLR_GHOSTED", name: "Ghosted" },
  { value: "PLR_LEAKED", name: "Leaked" },
  { value: "PLR_DISQUALIFIED", name: "Disqualified by PH" },
  { value: "PLR_OTHER", name: "Other" },
];

export const EnumTerm: EnumT = [
  { value: "TRM_MONTH2MONTH", name: "Month-to-month" },
  { value: "TRM_3M", name: "3 Months" },
  { value: "TRM_6M", name: "6 Months" },
  { value: "TRM_1Y", name: "1 Year" },
  { value: "TRM_2Y", name: "2 Years" },
  { value: "TRM_3Y", name: "3 Years" },
  { value: "TRM_5Y", name: "5 Years" },
  { value: "TRM_7Y", name: "7 Years" },
  { value: "TRM_MORE", name: "> 10 Years" },
  { value: "TRM_DATE", name: "Until Specific Date ..." },
];

export const EnumTermShort: EnumT = [
  { value: "TRM_MONTH2MONTH", name: "Month-to-month" },
  { value: "TRM_3M", name: "3 Months" },
  { value: "TRM_6M", name: "6 Months" },
  { value: "TRM_1Y", name: "1 Year" },
  { value: "TRM_2Y", name: "2 Years" },
  { value: "TRM_3Y", name: "3 Years" },
  { value: "TRM_5Y", name: "5 Years" },
  { value: "TRM_7Y", name: "7 Years" },
  { value: "TRM_MORE", name: "> 10 Years" },
];

export const EnumTrailerParkingType: EnumT = [
  { value: "TPK_CONCRETE", name: "Concrete" },
  { value: "TPK_ASPHALT", name: "Asphalt" },
  { value: "TPK_GRAVEL", name: "Gravel" },
  { value: "TPK_OTHER", name: "Other" },
];

export const EnumWarehouseType: EnumT = [
  { value: "WRH_PRIVATE_BUILDING", name: "Private" },
  { value: "WRH_SHARED_SPACE", name: "Shared" },
];

export const EnumOfficeType: EnumT = [
  { value: "OFFICE_SHARED", name: "Shared" },
  { value: "OFFICE_EXCLUSIVE", name: "Exclusive" },
];

export const EnumChangelogEvent: EnumT = [
  { value: "CLE_CREATE", name: "Created" },
  { value: "CLE_MODIFY", name: "Modified" },
  { value: "CLE_DELETE", name: "Deleted" },
];

export const EnumChangelogObject: EnumT = [
  { value: "CLO_PERSON", name: "Contact" },
  { value: "CLO_ORG", name: "Org" },
  { value: "CLO_PROPERTY", name: "Property" },
  { value: "CLO_USER", name: "User" },
  { value: "CLO_REQUEST", name: "Request" },
  { value: "CLO_CONFIG", name: "Config" },
];

export const EnumTMICostBasis: EnumT = [
  { value: "TCB_YEAR", name: "Annual" },
  { value: "TCB_MONTH", name: "Monthly" },
  { value: "TCB_SQFT", name: "Per sq. ft." },
];

export const EnumLeaseType: EnumT = [
  { value: "LT_FULL", name: "Gross Lease" },
  { value: "LT_NET", name: "Net Lease" },
  { value: "LT_GROSS", name: "Modified Gross Lease" },
  { value: "LT_UNKNOWN", name: "Unknown" },
];

export const EnumCanadaProvinces: EnumT = [
  { value: "AB", name: "Alberta" },
  { value: "BC", name: "British Columbia" },
  { value: "MB", name: "Manitoba" },
  { value: "NB", name: "New Brunswick" },
  { value: "NL", name: "Newfoundland and Labrador" },
  { value: "NS", name: "Nova Scotia" },
  { value: "NT", name: "Northwest Territories" },
  { value: "NU", name: "Nunavut" },
  { value: "ON", name: "Ontario" },
  { value: "PE", name: "Prince Edward Island" },
  { value: "QC", name: "Quebec" },
  { value: "SK", name: "Saskatchewan" },
  { value: "YT", name: "Yukon" },
];

export const EnumUSStates: EnumT = [
  { value: "AL", name: "Alabama" },
  { value: "AK", name: "Alaska" },
  { value: "AZ", name: "Arizona" },
  { value: "AR", name: "Arkansas" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DE", name: "Delaware" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "IA", name: "Iowa" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "ME", name: "Maine" },
  { value: "MD", name: "Maryland" },
  { value: "MA", name: "Massachusetts" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MS", name: "Mississippi" },
  { value: "MO", name: "Missouri" },
  { value: "MT", name: "Montana" },
  { value: "NE", name: "Nebraska" },
  { value: "NV", name: "Nevada" },
  { value: "NH", name: "New Hampshire" },
  { value: "NJ", name: "New Jersey" },
  { value: "NM", name: "New Mexico" },
  { value: "NY", name: "New York" },
  { value: "NC", name: "North Carolina" },
  { value: "ND", name: "North Dakota" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "RI", name: "Rhode Island" },
  { value: "SC", name: "South Carolina" },
  { value: "SD", name: "South Dakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VT", name: "Vermont" },
  { value: "VA", name: "Virginia" },
  { value: "WA", name: "Washington" },
  { value: "WV", name: "West Virginia" },
  { value: "WI", name: "Wisconsin" },
  { value: "WY", name: "Wyoming" },
  { value: "DC", name: "District of Columbia" },
];

export const EnumProvinces: EnumT = EnumCanadaProvinces.concat(EnumUSStates);

export const EnumWarehouseUsage: EnumT = [
  { value: "WUS_DRY_STORAGE", name: "Dry Storage" },
  { value: "WUS_COLD_STORAGE", name: "Cold Storage" },
  { value: "WUS_MANUFACTURING", name: "Manufacturing" },
  { value: "WUS_ASSEMBLY", name: "Assembly" },
  { value: "WUS_DISTRIBUTION", name: "Distribution" },
  { value: "WUS_FOOD", name: "Food" },
  { value: "WUS_PACKAGING", name: "Packaging" },
  { value: "WUS_AGRICULTURE", name: "Agriculture" },
  { value: "WUS_CANNABIS", name: "Cannabis" },
  { value: "WUS_MACHINE_REPAIR", name: "Machine Repair" },
  { value: "WUS_AUTOMOTIVE", name: "Automotive" },
  { value: "WUS_SHOWROOM", name: "Product Showroom" },
  { value: "WUS_FILM", name: "Film Production" },
  { value: "WUS_RECREATION", name: "Recreational" },
  { value: "WUS_CARPENTRY", name: "Carpentry" },
  { value: "WUS_PROCESSING", name: "Processing" },
  { value: "WUS_DATA_CENTRE", name: "Data Centre" },
  { value: "WUS_RD", name: "Research and Development" },
  { value: "WUS_OTHER", name: "Other" },
];

export const EnumSpaceType: EnumT = [
  { value: "PT_WAREHOUSE", name: "Industrial" },
  { value: "PT_RETAIL", name: "Reatil Space" },
  { value: "PT_OFFICE", name: "Office" },
  { value: "PT_LAND", name: "Land" },
];

export const EnumUserPermissions = [
  { value: "ACT_REGULAR", name: "Regular" },
  { value: "ACT_ADMIN", name: "Admin" },
];

export const EnumExclusives = [
  { value: "PEX_VERBAL", name: "Verbal" },
  { value: "PEX_SIGNED", name: "Signed" },
  { value: "PEX_GUARANTEE", name: "Guarantee" },
  { value: "PEX_OTHER", name: "Other" },
];
