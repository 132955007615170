import { OrganizationParamsResults } from "enums/organizationParamsResults";
import { OrganizationParams } from "interfaces/useOrganizationParams.interface";

export default function useOrganizationParams(): OrganizationParams {
  const location = window.location;
  const pathParts = location.pathname?.split("/");
  const param = pathParts[1];

  let type = OrganizationParamsResults.NONE;

  if (
    Object.values(OrganizationParamsResults).includes(
      param as OrganizationParamsResults
    )
  ) {
    type = pathParts[1] as OrganizationParamsResults;
  }

  return {
    type,
    id: parseInt(pathParts[2]),
  };
}
