import React from "react";
import styled from "styled-components";
import __ from "lodash";

import Field from "components/Forms/Field";
import Tooltip from "@mui/material/Tooltip";
import { filterFieldProps } from "../../services/utils";
import BaseInput from "./BaseInput";
import FieldError from "./FieldError";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const HiddenBox = styled.div`
  background-color: white;
  width: 154px;
  height: 20px;
`;

function CheckBoxesInput(props) {
  const fieldProps = filterFieldProps(props);
  const staticHoverText = props.staticHoverText || "";

  const getSingleName = (items, value) => {
    let item = __.find(items, (item) => {
      return item.value === value;
    });
    return item.name;
  };

  const toString = (items, values) => {
    return values.map((v) => getSingleName(items, v)).join(", ");
  };

  return (
    <BaseInput {...props} readOnly={props.static}>
      {props.static !== true && (
        <Container>
          {props.items.map((item, idx) => (
            <React.Fragment key={`${props.name}_r_${idx}`}>
              <label key={`${props.name}_${idx}`}>
                <Field
                  key={`${props.name}_f_${idx}`}
                  name={props.name}
                  component="input"
                  type="checkbox"
                  value={item.value}
                  disabled={props.disabled}
                  isEqual={(a, b) => {
                    return __.isEqual(__.sortBy(a), __.sortBy(b));
                  }}
                />{" "}
                {item.name}{" "}
              </label>
            </React.Fragment>
          ))}
          <FieldError name={`${props.name}`} />
        </Container>
      )}

      {props.static === true && (
        <Field
          style={{ height: "20px" }}
          name={`${props.name}`}
          {...fieldProps}
        >
          {(props) => {
            return (
              <Tooltip title={staticHoverText} placement="top-start" arrow>
                <div>
                  {props.input.value ? (
                    toString(props.items, props.input.value)
                  ) : (
                    <HiddenBox />
                  )}
                  {props.meta.error && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      &nbsp;&nbsp;{props.meta.error}
                    </span>
                  )}
                </div>
              </Tooltip>
            );
          }}
        </Field>
      )}
    </BaseInput>
  );
}

export default CheckBoxesInput;
