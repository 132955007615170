import React from "react";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import Field from "components/Forms/Field";

import { filterFieldProps, showInputErrorStyle } from "services/utils";
import BaseInput from "./BaseInput";
import Error from "./Error";

export default function TextInput({
  isReadOnly = false,
  staticHoverText = "",
  placeholder = "",
  disabled = false,
  maxLength = 65535, // max length of a text field in mysql
  warningText = "",
  ...props
}) {
  const fieldProps = filterFieldProps(props);

  return (
    <BaseInput
      {...props}
      readOnly={isReadOnly}
      staticHoverText={staticHoverText}
    >
      {({ isInputReadOnly, inputStaticHoverText }) => (
        <>
          {!isInputReadOnly && (
            <Field {...fieldProps}>
              {({ input, meta }) => (
                <>
                  {
                    <input
                      className={showInputErrorStyle(meta.error, input.value)}
                      placeholder={placeholder}
                      {...input}
                      type="text"
                      disabled={disabled}
                      maxLength={maxLength}
                    />
                  }
                  <div className="flex">
                    {meta.data.warning && (
                      <Tooltip
                        title={meta.data.warning}
                        placement="top-start"
                        arrow
                      >
                        <div style={{ color: "orange", marginLeft: "3px" }}>
                          <WarningIcon style={{ fontSize: 20 }} />
                        </div>
                      </Tooltip>
                    )}
                    <Error showError={warningText && !meta.error} error={warningText} color="orange" />
                    <Error showError={meta.error} error={meta.error} />
                  </div>
                </>
              )}
            </Field>
          )}
          {isInputReadOnly && (
            <Field style={{ height: "20px" }} name={props.name} {...fieldProps}>
              {({ meta, input }) => (
                <>
                  <Tooltip
                    title={inputStaticHoverText}
                    placement="top-start"
                    arrow
                  >
                    <div>{input.value ? input.value : <br />}</div>
                  </Tooltip>
                  <Error showError={meta.error} error={meta.error} />
                </>
              )}
            </Field>
          )}
        </>
      )}
    </BaseInput>
  );
}
