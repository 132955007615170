import Field from "components/Forms/Field";
import Tooltip from "@mui/material/Tooltip";

import Error from "./Error";

type StaticModeInputProps = {
  name: string;
  staticHoverText: string;
  fieldProps: unknown;
};

export default function StaticModeInput({
  name,
  fieldProps,
  staticHoverText,
}: StaticModeInputProps): JSX.Element {
  return (
    <Field style={{ height: "20px" }} name={name} {...fieldProps}>
      {({ meta, input }) => (
        <Tooltip title={staticHoverText} placement="top-start" arrow>
          <div>
            {input.value ? input.value : <br />}
            <Error showError={meta.error} error={meta.error} />
          </div>
        </Tooltip>
      )}
    </Field>
  );
}
