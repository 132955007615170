import React from "react";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  ApolloProvider,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { DEFAULT_ERROR_TIMER } from "../services/constants";

import Swal from "sweetalert2";

const AuthorizedApolloProvider = ({ children }) => {
  const withTokenLink = setContext(async () => {
    let token = localStorage.getItem("access_token");
    return { auth0Token: token };
  });

  const authLink = setContext((_, { headers, auth0Token }) => ({
    headers: {
      ...headers,
      ...(auth0Token ? { Authorization: `Bearer ${auth0Token}` } : {}),
    },
  }));

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        Swal.fire({
          title: "GraphQL error",
          text: `Message: ${message}, Location: ${locations}, Path: ${path}`,
          icon: "error",
          toast: true,
          timer: DEFAULT_ERROR_TIMER,
          position: "bottom-right",
        });
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      console.log(networkError);
      if (networkError.error !== "login_required") {
        Swal.fire({
          title: "Network error",
          text: `${networkError}`,
          icon: "error",
          toast: true,
          timer: DEFAULT_ERROR_TIMER,
          position: "bottom-right",
        });
      }
    }
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, withTokenLink, authLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
      query: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "none",
      },
    },
  });

  return (
    <React.Fragment>
      {/* { !loading && ( */}
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
      {/* )} */}
    </React.Fragment>
  );
};

export default AuthorizedApolloProvider;
