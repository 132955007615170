import React from "react";
import _ from "lodash";

import TooltipedIcon from "./TooltipedIcon";
import { isActivelyPublished } from "../services/forms/helpers";
import { EnumListingProvider } from "../services/enums";
import {
  LP_PLACEHOLDER,
  EMPIRE_URL,
  CREXI_URL,
  LP_CREXI,
} from "../services/constants";

interface Props {
  listings: Array<string>;
  extra: ListingExtra;
  datacy?: string;
}

interface ListingExtra {
  [_: string]: string | number | boolean | undefined;
}

const ListingIndicator: React.FC<Props> = ({ listings, extra, datacy }) => {
  const activelyPublished = isActivelyPublished(listings);

  const translateListingsToText = () => {
    if (_.isEmpty(listings)) {
      return "This configuration is not published anywhere.";
    }

    const listItems = listings.map((provider) => {
      const lp = _.cloneDeep(_.find(EnumListingProvider, function (p) {
        return p.value === provider;
      }));
      if (lp === undefined) return null;

      const empireUrl = extra[EMPIRE_URL];
      if (typeof empireUrl === "string" && empireUrl === "Not Found") {
        lp.name = "placeholder.com: Url Not Found, Contact Eng";
      }
      if (
        lp.value === LP_PLACEHOLDER &&
        empireUrl &&
        typeof empireUrl === "string"
      ) {
        return (
          <li key={lp.value}>
            {" "}
            •
            <a
              href={empireUrl}
              className="text-blue-500"
              target="placeholder.co"
              data-cy={`url-${datacy}`}
            >
              {" " + lp.name ?? "Placeholder.co"}
            </a>
          </li>
        );
      } else if (
        lp.value === LP_CREXI &&
        extra[CREXI_URL] &&
        typeof extra[CREXI_URL] === "string"
      ) {
        return (
          <li key={lp.value}>
            {" "}
            •
            <a
              href={extra[CREXI_URL] as string}
              className="text-blue-500"
              target="Crexi.com"
            >
              {" " + lp.name ?? "Crexi.com"}
            </a>
          </li>
        );
      } else {
        return <li key={lp.value}>• {lp.name}</li>;
      }
    });

    return (
      <>
        <p>This configuration has listings published on: </p>
        <ul>{listItems}</ul>
      </>
    );
  };

  const buildTooptipContent = () => {
    return (
      <div onClick={(e) => e?.stopPropagation()}>
        <p className="text-base w-max text-lg font-medium">Listings</p>
        <div>{translateListingsToText()}</div>
      </div>
    );
  };

  // when no listing, now showing the icon
  if (!activelyPublished) return null;

  return (
    <TooltipedIcon
      iconId="noun_listing"
      size="lg"
      state={
        activelyPublished && activelyPublished === true ? "default" : "disabled"
      }
      tooltipContent={buildTooptipContent()}
      datacy={datacy}
    />
  );
};

export default ListingIndicator;
