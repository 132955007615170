import React, {useState} from "react";

type TooltipDirecion = "left" | "right";

interface Props {
  // The help/info-text which will be displayed to the user.
  // If this is empty, the tooltip is not rendered as it has nothing to offer to
  // the user.
  text: string;
  // The direction the tooltip box will appear - "left" or "right" of the info
  // icon. If omitted, this defaults to "left".
  direction?: TooltipDirecion;
};

const stateClass = (dir: TooltipDirecion | undefined, hov: boolean) =>
`${dir !== "right" ? " -left-48" : " left-full"} ${hov ? "" : "hidden"}`

const Tooltip: React.FC<Props> = ({text, direction}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  if (text === "") return <></>;
  return (
    <div className="h-6 w-6 overflow-visible">
      <div
        className="bg-yellow-500 h-6 w-6 rounded flex justify-between items-center cursor-pointer"
        onClick={() => setShowTooltip((f: boolean) => !f)}
        title="Click for more information."
      >
        <span className="mx-auto font-display font-extrabold text-white">i</span>
      </div>
      <div className={"relative w-48 bg-black text-white text-xs rounded py-1 px-4 bottom-full"+stateClass(direction, showTooltip)}>
        {text}
      </div>
  </div>
  );
}

export default Tooltip;
