import React from "react";
import styled from "styled-components";

import __ from "lodash";
import Field from "components/Forms/Field";
import Tooltip from "@mui/material/Tooltip";
import { filterFieldProps } from "../../services/utils";
import BaseInput from "./BaseInput";
import FieldError from "./FieldError";

import { isEmptyString } from "../../services/utils";

const HiddenBox = styled.div`
  background-color: white;
  width: 154px;
  height: 20px;
`;

export default function RadioButtonInput({
  staticHoverText = "",
  isReadOnly = false,
  ...props
}) {
  const fieldProps = filterFieldProps(props);

  const getSingleName = (items, value) => {
    if (items.length === 0 || isEmptyString(value)) {
      return "";
    }
    let item = __.find(items, (item) => {
      return item.value === value;
    });
    return item.name;
  };

  return (
    <BaseInput
      {...props}
      readOnly={isReadOnly}
      staticHoverText={staticHoverText}
    >
      {({ isInputReadOnly, inputStaticHoverText }) => (
        <>
          {!isInputReadOnly && (
            <div>
              {props.options.map((option, idx) => (
                <div key={`${props.name}_${idx}`}>
                  <Field
                    name={`${props.name}`}
                    component="input"
                    type="radio"
                    value={`${option.value}`}
                    onClick={option.onClick}
                    {...fieldProps}
                  />{" "}
                  {`${option.name}`}
                </div>
              ))}
              <FieldError name={`${props.name}`} />
            </div>
          )}

          {isInputReadOnly && (
            <Field
              style={{ height: "20px" }}
              name={`${props.name}`}
              {...fieldProps}
            >
              {(props) => {
                return (
                  <Tooltip
                    title={inputStaticHoverText}
                    placement="top-start"
                    arrow
                  >
                    <div>
                      {props.input.value ? (
                        getSingleName(props.options, props.input.value)
                      ) : (
                        <HiddenBox />
                      )}
                      {props.meta.error && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          &nbsp;&nbsp;{props.meta.error}
                        </span>
                      )}
                    </div>
                  </Tooltip>
                );
              }}
            </Field>
          )}
        </>
      )}
    </BaseInput>
  );
}
