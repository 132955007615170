import { Box } from "@mui/material";
import { useParams } from "react-router";
import { SearchTabs } from "./SearchTabs";

type SearchParams = {
  searchTerm: string;
};

export default function SearchList(): JSX.Element {
  const { searchTerm } = useParams<SearchParams>();

  return (
    <Box sx={{ width: 1300 }}>
      <div className="text-xl" role="heading" aria-level={1}>
        <span className="font-bold">Search Results for: </span>
        {searchTerm}
      </div>
      <SearchTabs />
    </Box>
  );
}
