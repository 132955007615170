import { useLazyQuery } from "@apollo/client";
import { EntityType } from "enums";
import { SEARCH_QUERY } from "graphql/queries";
import { UsePaginationProps, PaginationProps } from "interfaces/pagination";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type SearchParams = {
  searchTerm: string;
};

const formatData = (rowInfo, rowKey) => {
  return {
    index: rowInfo.pageInfo.index,
    pageSize: rowInfo.pageInfo.size,
    rows: rowInfo[rowKey] ?? [],
  };
};

const getEntityToPaginationType = (entityType) => {
  switch (entityType) {
    case EntityType.ORGANIZATION:
      return ["org", "orgs", "orgData"];
    case EntityType.CONTACTS:
      return ["person", "persons", "personData"];
    case EntityType.REQUEST:
      return ["requests", "tenant", "tenantData"];
    case EntityType.PROPERTY_CONFIG:
      return ["properties", "properties", "propertyData"];
    default:
      return ["", "", ""];
  }
};

export default function usePagination<T>({
  index,
  category,
  pageSize,
}: UsePaginationProps): PaginationProps<T> {
  const { searchTerm } = useParams<SearchParams>();

  const [pagination, setPagination] = useState<PaginationProps<T>>({
    index: 0,
    pageSize: 0,
    rows: [],
    loading: false,
  });

  const [parsedCategory, dataKey, rowKey] = getEntityToPaginationType(category);

  const [getSearch, { loading }] = useLazyQuery(SEARCH_QUERY, {
    variables: {
      payload: {
        term: searchTerm,
        category: parsedCategory,
        cursor: { size: pageSize, index },
      },
    },
    onCompleted: (data) => {
      const searchData = data.search[dataKey];
      setPagination({ ...formatData(searchData, rowKey), loading: false });
    },
  });

  useEffect(() => {
    getSearch();
  }, [getSearch, searchTerm]);

  if (loading) return { index, pageSize, rows: [], loading: true };

  return pagination;
}
