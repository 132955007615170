import { useState, useEffect } from "react";
import __ from "lodash";

import Field from "components/Forms/Field";
import GuestMap from "components/Forms/Guests/GuestMap";
import TextAreaInput from "components/Forms/TextAreaInput";
import { GridSection } from "components/CardComponents";
import { LocationProps } from "interfaces/location";
import RequestAddressInput from "components/Forms/RequestAddressInput";

export default function Location({
  configIndex,
  request,
  addLocation,
  removeLocation,
  requestName,
}: LocationProps): JSX.Element {
  const [defaultBounds, setDefaultBounds] =
    useState<google.maps.LatLngBounds>();

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();
    if (request.locations?.length > 0) {
      request.locations.forEach((location) => {
        bounds.extend({ lat: location.latitude, lng: location.longitude });
      });
      setDefaultBounds(bounds);
    }
  }, [request.locations]);
  
  
  return (
    <GridSection md={8} xs={12} title={"Ideal Location(s)"}>
      <div className="ml-2">
        Search and Choose one or more locations on the map to specify the most ideal
        location(s) for the guest. <br />
        Click to add a pin. Click on a pin to remove it. Only the country of the first pin will be used for matching.<br />
        If the guest has NO preference for location, leave the map blank.
      </div>
      <RequestAddressInput
        setBounds={setDefaultBounds}
        requestIndex={configIndex}
      />
      <Field
        name={`${requestName}.locations`}
        isEqual={(a, b) => {
          return __.isEqual(__.sortBy(a), __.sortBy(b));
        }}
      >
        {(props) => {
          return (
            <GuestMap
              locations={request.locations || []}
              addLocation={addLocation}
              removeLocation={removeLocation}
              defaultBounds={defaultBounds}
              index={configIndex}
              mapSize={{ height: "400px", width: "100%" }}
            />
          );
        }}
      </Field>
      <TextAreaInput
        name={`${requestName}.location_nb`}
        label={`Location Notes`}
        cols={56}
      />
    </GridSection>
  );
}
