import React from "react";
import iconData from "../services/icon_data";

export type IconId = keyof typeof iconData;
export const ids = Object.keys(iconData) as IconId[];

const stateClasses = {
  default: "text-state-default",
  error: "text-state-error",
  warning: "text-state-warning",
  info: "text-state-info",
  fatal: "text-state-fatal",
  disabled: "text-state-disabled",

  green: "text-green-600",
  white: "text-white",

  "score-great": "text-score-great",
  "score-good": "text-score-good",
  "score-ok": "text-score-ok",
  "score-poor": "text-score-poor",
  "score-none": "text-score-none",
} as const;

export type IconState = keyof typeof stateClasses;

const sizeClasses = {
  xs: "h-3 w-3",
  sm: "h-4 w-4",
  base: "h-5 w-5",
  lg: "h-6 w-6",
  xl: "h-7 w-7",
  "2xl": "h-8 w-8",
  "3xl": "h-9 w-9",
  "4xl": "h-10 w-10",
  "5xl": "h-11 w-11",
  "6xl": "h-12 w-12",
} as const;

export type IconSize = keyof typeof sizeClasses;

interface Props {
  iconId: IconId;
  state?: IconState;
  size?: IconSize; // optional - defaults to "base"
  filter?: string;
}

const svgClass = (state?: IconState): string => {
  let className = "m-auto ";
  if (state !== undefined && state !== "default")
    className += `fill-current ${stateClasses[state]}`;
  return className;
};

const Icon: React.FC<Props> = ({ iconId, size, state, filter }) => {
  size = size || "base";
  state = state || "default";
  const icon = React.cloneElement(iconData[iconId], {
    className: svgClass(state),
  });

  return (
    <div
      className={
        "flex items-center " +
        sizeClasses[size] +
        (filter ? ` filter ${filter}` : "")
      }
    >
      {icon}
    </div>
  );
};

export default Icon;
