import { EntityType } from "enums";
import { AllConfigInfo } from "interfaces/property";
import { BuildReferenceURL } from "interfaces/utils";
import __ from "lodash";
import Treeful from "treeful";
import { PS_AVAILABLE_MATCH, PS_BOOKED_MATCH } from "./constants";

import {
  EnumProvinces,
  EnumCanadaProvinces,
  EnumUSStates,
  EnumEntryT,
  EnumListingProvider,
  EnumCanadaMarket,
  EnumUSMarket,
  EnumAutoListProvider,
} from "./enums";

export type PostalCodeValidatorT = {
  validPrefix: string[];
  validRange: string[];
  invalidPrefix: string[];
  invalidRange: string[];
};

export const buildHostURL = (hostOrgID: number): string => {
  return "/hosts/" + hostOrgID;
};

export const buildGuestURL = (
  guestOrgID: number,
  requestID: number
): string => {
  return "/guests/" + guestOrgID + "?r=" + requestID;
};

export const buildPropertyURL = (_: number, propertyID: number): string => {
  return "/properties/" + propertyID;
};

export const buildConfigURL = (
  _: number,
  propertyID: number,
  configID: number
): string => {
  return "/properties/" + propertyID + "?c=" + configID;
};

export function parseQuery<T extends unknown>(q: string): T {
  const query = {} as T;
  const pairs = (q[0] === "?" ? q.substr(1) : q).split("&");
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

// Return the map's keys as an array, not an iterable.
export function getMapKeys<K, V>(m: Map<K, V>): K[] {
  const keys: K[] = [];
  m.forEach((_, k) => keys.push(k));
  return keys;
}

// Extract the last token for market from Market Enum value
// input: MKT_CA_TORONTO
// output: TORONTO
export const getMarketNameFromEnum = (marketEnum: string): string => {
  if (__.isEmpty(marketEnum)) return "";
  const tokens: string[] = marketEnum.split("_");
  return tokens[tokens.length - 1];
};

// Extract the second token for country from a Market Enum value
// input: MKT_CA_TORONTO
// output: CA
export const getCountryFromMarketEnum = (marketEnum: string): string => {
  if (__.isEmpty(marketEnum)) return "";
  const tokens: string[] = marketEnum.split("_");
  return tokens[1];
};


/*
  Find valid provinces from a MARKET_Region_ tuning value (comma separated string)
  input: IL,IN,WI
  output: [
    { value: IL, name: Illinois },
		{ value: IN, name: Indiana },
		{ value: WI, name: Wisconsin }
  ]
*/
export const buildValidProvincesForMarket = (
  tuningValue: string
): EnumEntryT[] => {
  const provinceCodes: string[] = tuningValue.split(",");
  const enumLists: EnumEntryT[] = [];
  provinceCodes.forEach((code: string, _) => {
    const provinceItem: EnumEntryT | undefined = __.find(EnumProvinces, [
      "value",
      code,
    ]);
    if (provinceItem) {
      enumLists.push(provinceItem);
    }
  });

  return enumLists;
};

/*
  Check the first character of postal/zip code to determine if it's Canadian or US.
  input: V3B
  output: true
*/
export const isCanadianPostalCode = (code: string): boolean => {
  // check the first character is alphabet
  const regex = /(^[A-Z]$)/;
  const match = regex.exec(code.charAt(0).toUpperCase());
  if (match) {
    return true;
  } else {
    return false;
  }
};

/*
  Represent the first 3 characters of Canadian postal code to a number.
  If the input is less than 3 character, fill the lowest values ('a' or '0') or highest values ('z' or '9')
  depending on the 'fillLow' boolean.
  input: A2C
  output: 1 * 260 + 2 * 26 + 3 = 315
 */
export const convertCanadianPostalCodeToNumber = (
  postalCode: string,
  fillLow: boolean
): number => {
  // 1~3 digits input
  const fillCount = 3 - postalCode.length;
  let filledPostal: string;
  const postal = postalCode.toLowerCase();

  if (fillCount === 1) {
    filledPostal = fillLow ? postal + "a" : postal + "z";
  } else if (fillCount === 2) {
    filledPostal = fillLow ? postal + "0a" : postal + "9z";
  } else {
    filledPostal = postal;
  }

  // filledPostal example: a1a
  const charCodeBase = "a".charCodeAt(0) - 1;
  const firstDigitConversion: number =
    (filledPostal.charCodeAt(0) - charCodeBase) * 260;
  const secondDigitConversion: number =
    __.toNumber(filledPostal.charAt(1)) * 26;
  const thirdDigitConversion: number =
    filledPostal.charCodeAt(2) - charCodeBase;

  return firstDigitConversion + secondDigitConversion + thirdDigitConversion;
};

/*
  Represent the first 5 characters of US zip code to a number.
  If the input is less than 5 characters, fill the lowest values ('0') or highest values ('9')
  depending on the 'fillLow' boolean.
  input: 601, false
  output: 60199
 */
export const converUSZipCodeToNumber = (
  zipCode: string,
  fillLow: boolean
): number => {
  // 1~5 digits input
  const fillCount = 5 - zipCode.length;
  if (fillCount === 0) return __.toNumber(zipCode);

  const fillChar = fillLow ? "0" : "9";
  const filledZipCode = zipCode + fillChar.repeat(fillCount);

  return __.toNumber(filledZipCode);
};

/*
  Given two postal/zip codes, build a range of their number representations
  input: A1, A2
  output: A1A, A1Z -> 287-312
 */
export const buildPostalOrZipRange = (start: string, end: string): string => {
  let min: number, max: number;

  if (isCanadianPostalCode(start)) {
    min = convertCanadianPostalCodeToNumber(start, true);
    max = convertCanadianPostalCodeToNumber(end, false);
  } else {
    min = converUSZipCodeToNumber(start, true);
    max = converUSZipCodeToNumber(end, false);
  }

  return min.toString() + "-" + max.toString();
};

// Build the postal/zip code validator structure from a tuning var of a market
export const buildValidPostalCodesForMarket = (
  tuningValue: string
): PostalCodeValidatorT => {
  const tuning = tuningValue.toLowerCase();

  const validPrefix: string[] = [];
  const validRange: string[] = [];
  const invalidPrefix: string[] = [];
  const invalidRange: string[] = [];

  if (__.isEmpty(tuning)) {
    // return an object with empty arrays
    return {
      validPrefix,
      validRange,
      invalidPrefix,
      invalidRange,
    };
  }

  const rules: string[] = tuning.split(",");
  rules.forEach((rule, index) => {
    // check if positive or negative
    if (rule.charAt(0) === "-") {
      // negative
      const ranges: string[] = rule.slice(1).split("-");

      if (ranges.length === 1) {
        // single
        invalidPrefix.push(ranges[0]);
      } else {
        // range
        invalidRange.push(buildPostalOrZipRange(ranges[0], ranges[1]));
      }
    } else {
      // positive
      const ranges: string[] = rule.split("-");

      if (ranges.length === 1) {
        // single
        validPrefix.push(ranges[0]);
      } else {
        // range
        validRange.push(buildPostalOrZipRange(ranges[0], ranges[1]));
      }
    }
  });

  return {
    validPrefix,
    validRange,
    invalidPrefix,
    invalidRange,
  };
};

// Find a market enum from a postal code, or return undefined if not found.
// input: V6B 6B1
// output: MKT_CA_VANCOUVER
export const getMarketFromPostalCode = (
  postal: string,
  postalCodesForMarket: { [key: string]: PostalCodeValidatorT }
): string | undefined => {
  const code: string = postal.toLowerCase();
  const postalsForMarket: { [key: string]: PostalCodeValidatorT } =
    postalCodesForMarket;
  let numberRep: number;
  let markets: EnumEntryT[];

  // For efficiency, filter markets by postal code type
  if (isCanadianPostalCode(postal)) {
    if (postal.length < 3) {
      return;
    }
    markets = EnumCanadaMarket;
    numberRep = convertCanadianPostalCodeToNumber(postal.slice(0, 3), false);
  } else {
    if (postal.length < 5) {
      return;
    }
    markets = EnumUSMarket;
    numberRep = converUSZipCodeToNumber(postal.slice(0, 5), false);
  }

  const m: EnumEntryT | undefined = __.find(
    markets,
    (enumMarket: EnumEntryT): boolean => {
      const market = getMarketNameFromEnum(enumMarket.value.toString());

      if (postalsForMarket.hasOwnProperty(market)) {
        let isValid = false;
        let isInvalid = false;
        const postalCodes: PostalCodeValidatorT = postalsForMarket[market];

        // check valid prefix
        postalCodes.validPrefix.forEach((validPostalCode) => {
          if (
            !__.isEmpty(validPostalCode) &&
            code.startsWith(validPostalCode)
          ) {
            //console.log("postal code is IN valid list! : ", code, validPostalCode)
            isValid = true;
          }
        });

        // check valid range
        postalCodes.validRange.forEach((range) => {
          const ranges = range.split("-");
          const min = __.toNumber(ranges[0]);
          const max = __.toNumber(ranges[1]);

          if (min <= numberRep && numberRep <= max) {
            isValid = true;
          }
        });

        // check invalid prefix
        postalCodes.invalidPrefix.forEach((invalidPostalCode) => {
          if (
            !__.isEmpty(invalidPostalCode) &&
            code.startsWith(invalidPostalCode)
          ) {
            //console.log("postal code is IN invalid list! : ", code, invalidPostalCode)
            isInvalid = true;
          }
        });

        // check invalid range
        postalCodes.invalidRange.forEach((range) => {
          const ranges = range.split("-");
          const min = __.toNumber(ranges[0]);
          const max = __.toNumber(ranges[1]);

          if (min <= numberRep && numberRep <= max) {
            isInvalid = true;
          }
        });

        if (isValid && !isInvalid) {
          return true;
        }
      }
      return false;
    }
  );

  return m ? m.value.toString() : undefined;
};

/*
  Filter the listing publisher checkbox entries by a market
  input: MKT_CA_TORONTO
  output: [
    { value: LP_PLACEHOLDER, name: placeholder.co },
		{ value: LP_KIJIJI, name: Kijiji },
		{ value: LP_SPACELIST, name: Spacelist }
  ]
*/
export const getPublishersForLocation = (
  country: string,
  province: string, // or state
  listingProvidersForCountry: Array<{location: string, values: string[]}>
): EnumEntryT[] => {
  if (__.isEmpty(country)) {
    return [];
  }

  const publishers: EnumEntryT[] = [];
  // loop through the country's listing providers
  for (const listingProvider of listingProvidersForCountry) {
    // check if the province is in the provider's location list
    if ( listingProvider.location === country || listingProvider.location === country + "_" + province) {
      // add the provider to the list
      for (const provider of listingProvider.values) {
        // find the display name for the provider
        const displayName = __.find(EnumListingProvider, ["value", provider]);
        if (displayName)
          publishers.push({ value: provider, name:  displayName.name})
      }
    }
  }

  return publishers;
};

export const getFreeManualPublishersForLocation = (
  country: string,
  province: string, // or state
  listingProvidersForCountry: Array<{location: string, values: string[]}>
): EnumEntryT[] => {
  const providers = getPublishersForLocation(country, province, listingProvidersForCountry);
  return providers.filter((p) => !EnumAutoListProvider.some(pub => pub.value === p.value));
}

// Find if the market enum value is for Canadian market or not
// input: MKT_CA_TORONTO
// output: true
export const isCanadianMarket = (country: string): boolean => {
  return country === "CA";
};

/*
  Filter the province dropdown entries by a market
  input: MKT_US_CHICAGO
  output: [
    { value: IL, name: Illinois },
		{ value: IN, name: Indiana },
		{ value: WI, name: Wisconsin }
  ]
*/
export const filterProvinceByMarket = (marketEnum: string): EnumEntryT[] => {
  const tokens: string[] = marketEnum.split("_");
  const market: string = tokens[tokens.length - 1];
  const provincesForMarket = Treeful.get("provincesForMarket");

  let provinces: EnumEntryT[];

  // check country first
  if (isCanadianMarket(marketEnum)) {
    provinces = EnumCanadaProvinces;
  } else {
    provinces = EnumUSStates;
  }

  // check and filter if there is tuning value for the market
  if (provincesForMarket.hasOwnProperty(market)) {
    provinces = provincesForMarket[market];
  }

  return provinces;
};

export const validAddressFound = (
  address:
    | google.maps.places.AutocompleteOptions
    | google.maps.GeocoderAddressComponent[]
): boolean => {
  //Used to see if the given address is valid and has all the required components
  const addressMap = {};
  for (const component of address as google.maps.GeocoderAddressComponent[]) {
    addressMap[component.types[0]] = component.short_name;
  }

  if (
    !("street_number" in addressMap) &&
    !("route" in addressMap) &&
    !("street_address" in addressMap)
  ) {
    return false;
  }

  if (
    !("locality" in addressMap) &&
    !("administrative_area_level_3" in addressMap)
  ) {
    return false;
  }

  return true;
};

export const buildReferenceURL = ({ id, type }: BuildReferenceURL): string => {
  let url = "";

  switch (type) {
    case EntityType.ORGANIZATION:
      url = "/org";
      break;
    case EntityType.PROPERTY:
      url = "/property";
      break;
    case EntityType.PROPERTY_CONFIG:
      url = "/config";
      break;
    case EntityType.REQUEST:
      url = "/request";
      break;
  }

  return `${url}/${id}`;
};

export const updateLocationFields = (
  result: google.maps.GeocoderResult | google.maps.GeocoderResult,
  updateAddress: (args: unknown) => void,
): void => {
  const addressMap = {};
  for (const component of result.address_components as google.maps.GeocoderAddressComponent[]) {
    addressMap[component.types[0]] = component.short_name;
  }

  let streetAddress = "";
  if ("street_address" in addressMap) {
    streetAddress = addressMap["street_address"];
  } else {
    if ("street_number" in addressMap) {
      streetAddress += addressMap["street_number"];
    }
    streetAddress += " ";
    if ("route" in addressMap) {
      streetAddress += addressMap["route"];
    }
    streetAddress = streetAddress.trim();
  }

  const lat = result.geometry.location.lat();
  const long = result.geometry.location.lng();

  let city = "";
  if ("locality" in addressMap) {
    city = addressMap["locality"];
  } else if ("administrative_area_level_3" in addressMap) {
    city = addressMap["administrative_area_level_3"];
  }

  let postalCode = "";
  if ("postal_code" in addressMap) {
    postalCode = addressMap["postal_code"];
  }

  let province = "";
  if ("administrative_area_level_1" in addressMap) {
    province = addressMap["administrative_area_level_1"];
  }

  let country = "";
  if ("country" in addressMap) {
    country = addressMap["country"];
  }

  updateAddress({
    streetAddress,
    city,
    postalCode,
    country,
    province,
    lat,
    long,
  });
};

export function isMatchable(configs: AllConfigInfo[]): boolean {
  return configs?.some(
    (config) =>
      config.status === PS_AVAILABLE_MATCH || config.status === PS_BOOKED_MATCH
  );
}
