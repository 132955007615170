import React from "react";
import styled from "styled-components";

import Field from "components/Forms/Field";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  padding-top: 6px;
`;

function ActiveCheckBox({ name, helpText, hide = false }) {
  return (
    <div style={hide ? { display: "none" } : null}>
      <Container>
        {"Used in Matching: "}
        <Field name={`${name}`} component="input" type="checkbox"></Field>

        <Tooltip title={`${helpText}`} placement="top-start" arrow>
          <HelpOutlineIcon className="ml-0.5" fontSize="small" />
        </Tooltip>
      </Container>
    </div>
  );
}

export default ActiveCheckBox;
