import { PIPEDRIVE_CONNECTED, APP_ENV_DEV } from "./constants";

const isTokenValid = () => {
  let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
  let token = localStorage.getItem("access_token");
  return (
    new Date().getTime() < expiresAt && (token !== undefined || token !== null)
  );
};

const isMFAEnabled = () => {
  if (JSON.parse(localStorage.getItem("mfa_enabled")) === true) {
    return true;
  }

  return false;
};

const isPipedriveConnected = () => {
  if (process.env.REACT_APP_ENV === APP_ENV_DEV) return true;
  return localStorage.getItem(PIPEDRIVE_CONNECTED) === "true";
};

const isPipeDriveAndMFAEnabled = () => {
  return isMFAEnabled() && isPipedriveConnected();
};

const isAuthenticated = () => {
  if (isMFAEnabled()) {
    let mfaVerified = JSON.parse(localStorage.getItem("mfa_verified"));
    let result = isTokenValid() === true && mfaVerified === true;
    if (result === false) {
      localStorage.removeItem("mfa_verified");
    }
    return result;
  } else {
    return isTokenValid();
  }
};

export {
  isAuthenticated,
  isMFAEnabled,
  isTokenValid,
  isPipedriveConnected,
  isPipeDriveAndMFAEnabled,
};
