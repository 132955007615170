import React from "react";
import { PropertyData } from "./types";
import Icon, { IconId } from "../Icon";
import { resolveEnumValue } from "../../services/enum_utils";
import { EnumWarehouseType } from "../../services/enums";
import _ from "lodash";

interface Props {
  propertyData: PropertyData;
}

const topRow: Array<[keyof PropertyData, IconId, string]> = [
  ["attr_247_access", "attr_247access", "24/7 Access"],
  ["attr_securable", "attr_securability", "Securability"],
  ["attr_office_space", "attr_office_space", "Office Space"],
  ["attr_3_phase_power", "attr_3phase_power", "3 Phase Power"],
  ["attr_53_foot_trailers", "attr_53_foot_trailers", "53' Trailers"],
];

const propertyUsages = [
  "Auto",
  "Food",
  "Cannabis",
  "Customer",
  "Sports Complex",
  "Sports Studio",
  "High Bay Usage",
  "Heavy Manufacturing",
] as const;

const temperatureTypes = [
  "Air Conditioning",
  "Refrigeration",
  "Freezer",
] as const;

const decodeUsage = (
  perm: number,
  kind: "permitted" | "restricted"
): string[] => {
  const usages: string[] = [];

  for (let i = 0; i < propertyUsages.length; ++i) {
    if (perm & (1 << i)) {
      if (kind === "permitted") usages.push(propertyUsages[i]);
    } else {
      if (kind === "restricted") usages.push(propertyUsages[i]);
    }
  }

  return usages;
};

const iconClasses = "mx-2";

const PropertyAttributes: React.FC<Props> = ({ propertyData: pd }) => {
  return (
    <div className="flex flex-col mx-2">
      <div className="flex">
        {topRow.map((k, i) => (
          <div className="m-1 mx-2" title={k[2]} key={i}>
            <Icon
              key={i}
              iconId={k[1]}
              size={"lg"}
              state={pd[k[0]] ? "default" : "disabled"}
            />
          </div>
        ))}
      </div>

      <table className="text-xs mt-2">
        <tbody>
          <tr>
            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Loading Bays">
                  <Icon iconId="attr_loading_bays" size="xl" />
                </div>
                <div className="leading-none">
                  <p className="font-bold">Loading Bays</p>
                  <table>
                    <tbody>
                      <tr>
                        <td className="font-medium pr-2">
                          Truck Level Docks (
                          {(pd.attr_truck_level_ex || 0) +
                            (pd.attr_truck_level_sh || 0)}
                          )
                        </td>
                        <td>
                          {pd.attr_truck_level_ex || 0} exclusive,{" "}
                          {pd.attr_truck_level_sh || 0} shared
                        </td>
                      </tr>
                      <tr>
                        <td className="font-medium">
                          Drive In Doors (
                          {(pd.attr_drive_in_ex || 0) +
                            (pd.attr_drive_in_sh || 0)}
                          )
                        </td>
                        <td>
                          {pd.attr_drive_in_ex || 0} exclusive,{" "}
                          {pd.attr_drive_in_sh || 0} shared
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>

            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Temperature">
                  <Icon
                    iconId="attr_temperature"
                    size="xl"
                    state={
                      pd.attr_temperature_ac ||
                      pd.attr_temperature_refrig ||
                      pd.attr_temperature_refrig
                        ? "default"
                        : "disabled"
                    }
                  />
                </div>
                <p style={{ maxWidth: "170px" }}>
                  {temperatureTypes.map((u, i) => {
                    return (
                      <React.Fragment key={i}>
                        <span
                          className={
                            (!!pd.attr_temperature_ac && i === 0) ||
                            (!!pd.attr_temperature_refrig && i === 1) ||
                            (!!pd.attr_temperature_freeze && i === 2)
                              ? ""
                              : "text-state-disabled"
                          }
                        >
                          {u}
                          {i !== temperatureTypes.length - 1 ? ", " : ""}
                        </span>
                        {i === Math.ceil(propertyUsages.length / 2) - 1 ? (
                          <br />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Ceiling Clearance">
                  <Icon
                    iconId="attr_ceiling_clearance"
                    size="xl"
                    state={pd.attr_ceiling_clearance ? "default" : "disabled"}
                  />
                </div>
                <p className="text-lg font-semibold">
                  {pd.attr_ceiling_clearance
                    ? `${_.floor(pd.attr_ceiling_clearance / 12)}' ${
                        pd.attr_ceiling_clearance % 12
                      }"`
                    : "N/A"}
                </p>
              </div>
            </td>

            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Electrical Amps">
                  <Icon
                    iconId="attr_power_capacity"
                    size="xl"
                    state={pd.attr_electrical_amps ? "default" : "disabled"}
                  />
                </div>
                <p className="text-lg font-semibold">
                  {pd.attr_electrical_amps
                    ? `${pd.attr_electrical_amps} amps`
                    : "N/A"}
                </p>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Warehouse Type">
                  <Icon
                    iconId="attr_warehouse_type"
                    size="xl"
                    state={pd.attr_space_type ? "default" : "disabled"}
                  />
                </div>
                <p>
                  {pd.attr_space_type
                    ? resolveEnumValue(EnumWarehouseType, pd.attr_space_type)
                    : "N/A"}
                </p>
              </div>
            </td>

            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Usage Restrictions">
                  <Icon
                    iconId="attr_restricted_usage"
                    size="xl"
                    state={
                      pd.attr_permitted_usage !== 0 ? "default" : "disabled"
                    }
                  />
                </div>
                <p style={{ maxWidth: "170px" }}>
                  {decodeUsage(pd.attr_permitted_usage, "restricted").join(
                    ", "
                  ) || "N/A"}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PropertyAttributes;
