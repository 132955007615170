import React from "react";
import styled from "styled-components";

import Field from "components/Forms/Field";
import Tooltip from "@mui/material/Tooltip";
import { filterFieldProps } from "../../services/utils";
import BaseInput from "./BaseInput";

const HiddenBox = styled.div`
  background-color: white;
  width: 154px;
  height: 20px;
`;

function DateInput({ isReadOnly = false, staticHoverText = "", ...props }) {
  const fieldProps = filterFieldProps(props);

  return (
    <BaseInput
      {...props}
      readOnly={isReadOnly}
      staticHoverText={staticHoverText}
    >
      {({ isInputReadOnly, inputStaticHoverText }) => (
        <>
          {!isInputReadOnly && (
            <Field {...fieldProps}>
              {({ input, meta }) => (
                <div>
                  <input
                    {...input}
                    type="date"
                    max={props.max}
                    min={props.min}
                  />
                  {meta.error && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      &nbsp;&nbsp;{meta.error}
                    </span>
                  )}
                </div>
              )}
            </Field>
          )}

          {isInputReadOnly && (
            <Field
              style={{ height: "20px" }}
              name={`${props.name}`}
              {...fieldProps}
            >
              {(props) => {
                return (
                  <Tooltip
                    title={inputStaticHoverText}
                    placement="top-start"
                    arrow
                  >
                    <div>
                      {props.input.value ? props.input.value : <HiddenBox />}
                      {props.meta.error && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          &nbsp;&nbsp;{props.meta.error}
                        </span>
                      )}
                    </div>
                  </Tooltip>
                );
              }}
            </Field>
          )}
        </>
      )}
    </BaseInput>
  );
}

export default DateInput;
