import { useState, useEffect, useContext } from "react";
import { useFormState } from "react-final-form";

import FormTree from "components/FormTree";
import { configsToOptions } from "services/forms/helpers";

import {
  buildPropertyName,
  isEmptyString,
  findErrorIndexes,
} from "services/utils";

import { FormContext, PropertiesContext, PropertyFormContext } from "contexts";
import { OrganizationChildrenTypes } from "enums/organizationTypes";
import { FormCommand } from "enums/formEnums";
import { PropertyInfoProps } from "interfaces/propertyInfo.interface";
import { AppContext } from "contexts/AppContext";
import { PropertyConfig } from "./PropertyConfig/PropertyConfig";
import ConfirmationDialog from "components/Forms/ConfirmationDialog";
import { isMatchable } from "services/utilities";

export default function PropertyInfo({
  property,
  propertyIndex,
  mutators,
  propertyInitialValues,
}: PropertyInfoProps): JSX.Element {
  const [configIndex, setConfigIndex] = useState<number | null>(null);
  const [isProperty, setIsProperty] = useState(false);
  const [listingUnpublishWarning, setListingUnpublishWarning] = useState(false);
  const [listingPublishWarning, setListingPublishWarning] = useState(false);
  const [statusNotPublishableWarning, setStatusNotPublishableWarning] =
    useState(false);

  const { formCommand, propertyLength, setDeleteEntities } =
    useContext(FormContext);
  const { setSelectedPropertyID } = useContext(PropertiesContext);
  const { required_listing_fields } = useContext(AppContext);

  const { errors: formErrors } = useFormState();

  const configs = configIndex !== null ? property.configs[configIndex] : null;
  const isEditable = property?.configs?.length === 1 || !isProperty;

  const errors = findErrorIndexes(
    formErrors?.properties ? formErrors.properties[propertyIndex] : null
  );

  const handleAddConfiguration = (name, cloneId, existingName) => {
    setSelectedPropertyID(property.property_id);

    mutators.addConfiguration({
      propertyIndex,
      name,
      cloneId,
      failedReasons: required_listing_fields,
    });

    if (!isEmptyString(existingName)) {
      mutators.setConfigName({
        propertyIndex,
        name: existingName,
        configIndex: 0,
      });
    }

    setConfigIndex(property?.configs?.length);
    setIsProperty(false);
  };

  const deleteConfig = () => {
    mutators.deleteConfig({ propertyIndex, configIndex, setDeleteEntities });
    setConfigIndex(0);
  };

  const deleteProperty = () => {
    mutators.deleteProperty({
      propertyId: property.property_id,
      setDeleteEntities,
    });
  };

  useEffect(() => {
    if (
      formCommand === FormCommand.CREATE_PROPERTY &&
      propertyLength != null &&
      propertyIndex === propertyLength - 1
    ) {
      setIsProperty(true);
      setConfigIndex(0);
      setSelectedPropertyID(property.property_id);
    }
  }, [
    formCommand,
    property.property_id,
    propertyIndex,
    propertyLength,
    setSelectedPropertyID,
  ]);

  return (
    <PropertyFormContext.Provider
      value={{
        isProperty,
        isConfigLevelFieldEditable: isEditable,
      }}
    >
      <FormTree
        configs={property.configs}
        parentID={property.property_id}
        containerId={propertyIndex}
        errors={errors}
        onAddConfiguration={handleAddConfiguration}
        parentName={buildPropertyName(
          property.address,
          property.unit,
          property.city
        )}
        childNames={configsToOptions(property.configs)}
        currentChildIndex={configIndex}
        setCurrentChildIndex={setConfigIndex}
        setIsParent={setIsProperty}
        isParent={isProperty}
        type={OrganizationChildrenTypes.Property}
        deleteConfig={deleteConfig}
        deleteParent={deleteProperty}
        matchability={configs?.matchable}
        entityLength={propertyLength ?? 0}
        isPropertyMatchable={isMatchable(property?.configs)}
      />
      <>
        {property?.configs?.map(
          (config, index) =>
            configIndex != null &&
            configIndex === index && (
              <PropertyConfig
                key={index}
                isProperty={isProperty}
                mutators={mutators}
                propertyIndex={propertyIndex}
                property={property}
                configs={config}
                configIndex={index}
                isEditable={isEditable}
                initialConfigValue={
                  propertyInitialValues?.configs != null
                    ? propertyInitialValues?.configs[configIndex]
                    : null
                }
                setStatusNotPublishableWarning={setStatusNotPublishableWarning}
                setListingUnpublishWarning={setListingUnpublishWarning}
                setListingPublishWarning={setListingPublishWarning}
              />
            )
        )}
        <ConfirmationDialog
          title={"WARNING"}
          content={
            "This configuration is currently listed. Action may be required."
          }
          open={listingUnpublishWarning}
          onConfirm={() => {}}
          ignoreConfirm={true}
          onClose={() => {
            setListingUnpublishWarning(false);
          }}
        />

        <ConfirmationDialog
          title={"WARNING"}
          content={
            "This configuration is not currently listed. Action may be required."
          }
          open={listingPublishWarning}
          onConfirm={() => {}}
          ignoreConfirm={true}
          onClose={() => {
            setListingPublishWarning(false);
          }}
        />

        <ConfirmationDialog
          title={"WARNING"}
          content={
            "Looks like you are trying to list this property; the property status field may need to be changed."
          }
          open={statusNotPublishableWarning}
          onConfirm={() => {}}
          ignoreConfirm={true}
          onClose={() => {
            setStatusNotPublishableWarning(false);
          }}
        />
      </>
    </PropertyFormContext.Provider>
  );
}
