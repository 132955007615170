import { OrganizationParamsResults } from "enums/organizationParamsResults";
import { useEffect } from "react";
import { TITLE_DEFAULT } from "../services/constants";

// useTabTitle sets the html document tile
const useTabTitle = (
  typeParam: OrganizationParamsResults,
  defaultTitle: string,
  title: string,
  selected: boolean
): void => {
  useEffect(() => {
    if (title === "" || !selected) return;

    if (
      typeParam === OrganizationParamsResults.ORGANIZATION ||
      typeParam === OrganizationParamsResults.REQUEST
    ) {
      document.title = defaultTitle;
      return;
    }

    if (
      typeParam === OrganizationParamsResults.PROPERTY ||
      typeParam === OrganizationParamsResults.PROPERTY_CONFIG
    ) {
      document.title = title;
      return;
    }

    return function cleanup() {
      document.title = TITLE_DEFAULT;
    };
  }, [defaultTitle, selected, title, typeParam]);
};

export default useTabTitle;
