import React from "react";
import { Needs } from "./types";
import Icon, { IconId } from "../Icon";
import { EnumWarehouseType } from "../../services/enums";
import _ from "lodash";

interface Props {
  needs: Needs;
}

const topRow: Array<[keyof Needs, IconId, string]> = [
  ["attr_247_access", "attr_247access", "24/7 Access"],
  ["attr_securable", "attr_securability", "Securability"],
  ["attr_office_space", "attr_office_space", "Office Space"],
  ["attr_3_phase_power", "attr_3phase_power", "3 Phase Power"],
  ["attr_bays_53_trailers", "attr_53_foot_trailers", "53' Trailers"],
];

const propertyUsages = [
  "Auto",
  "Food",
  "Cannabis",
  "Customer",
  "Sports Complex",
  "Sports Studio",
  "High Bay Usage",
  "Heavy Manufacturing",
] as const;

const temperatureTypes = [
  "Air Conditioning",
  "Refrigeration",
  "Freezer",
] as const;

const textDisabled = "text-state-disabled";

const iconClasses = "mx-2";

const RequestNeeds: React.FC<Props> = ({ needs: pd }) => {
  return (
    <div className="flex flex-col mx-2">
      <div className="flex">
        {topRow.map((k, i) => (
          <div className="m-1 mx-2" title={k[2]} key={i}>
            <Icon
              key={i}
              iconId={k[1]}
              size={"lg"}
              state={pd[k[0]] === true ? "default" : "disabled"}
            />
          </div>
        ))}
      </div>

      <table className="text-xs mt-2">
        <tbody>
          <tr>
            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Loading Bays">
                  <Icon iconId="attr_loading_bays" size="xl" />
                </div>
                <div className="leading-none flex items-center">
                  <p className="mr-2">
                    {pd.attr_bays !== null ? (
                      <span className="text-lg">{pd.attr_bays}</span>
                    ) : (
                      "N/A"
                    )}
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td className={pd.attr_bays_ex ? "" : textDisabled}>
                          Exclusive use,
                        </td>
                        <td
                          className={
                            pd.attr_bays_53_trailers ? "" : textDisabled
                          }
                        >
                          {"53' trailers,"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={pd.attr_bays_drive_in ? "" : textDisabled}
                        >
                          Drive in doors,
                        </td>
                        <td className={pd.attr_bays_docks ? "" : textDisabled}>
                          Truck level docks
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>

            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Temperature">
                  <Icon
                    iconId="attr_temperature"
                    size="xl"
                    state={
                      pd.attr_temperature_ac ||
                      pd.attr_temperature_refrig ||
                      pd.attr_temperature_freeze
                        ? "default"
                        : "disabled"
                    }
                  />
                </div>
                <p style={{ maxWidth: "170px" }}>
                  {temperatureTypes.map((u, i) => {
                    return (
                      <React.Fragment key={i}>
                        <span
                          className={
                            (!!pd.attr_temperature_ac && i === 0) ||
                            (!!pd.attr_temperature_refrig && i === 1) ||
                            (!!pd.attr_temperature_freeze && i === 2)
                              ? ""
                              : textDisabled
                          }
                        >
                          {u}
                          {i !== temperatureTypes.length - 1 ? ", " : ""}
                        </span>
                        {i === Math.ceil(propertyUsages.length / 2) - 1 ? (
                          <br />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Ceiling Clearance">
                  <Icon
                    iconId="attr_ceiling_clearance"
                    size="xl"
                    state={pd.attr_ceiling_clearance ? "default" : "disabled"}
                  />
                </div>
                <p className="">
                  {pd.attr_ceiling_clearance ? (
                    <span className="text-lg">
                      {"> "}
                      {_.floor(pd.attr_ceiling_clearance / 12) + "'"}{" "}
                      {(pd.attr_ceiling_clearance % 12) + '"'}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            </td>

            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Electrical Amps">
                  <Icon
                    iconId="attr_power_capacity"
                    size="xl"
                    state={pd.attr_electrical_amps ? "default" : "disabled"}
                  />
                </div>
                <p className={pd.attr_electrical_amps ? "" : ""}>
                  {pd.attr_electrical_amps
                    ? `${pd.attr_electrical_amps} amps`
                    : "N/A"}
                </p>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Warehouse Type">
                  <Icon
                    iconId="attr_warehouse_type"
                    size="xl"
                    state={pd.attr_space_type ? "default" : "disabled"}
                  />
                </div>
                <p>
                  {pd.attr_space_type
                    ? EnumWarehouseType.map((v, i) => {
                        return (
                          <React.Fragment key={i}>
                            <span
                              className={
                                pd.attr_space_type.includes(v.value as string)
                                  ? ""
                                  : textDisabled
                              }
                            >
                              {v.name}
                              {i !== EnumWarehouseType.length - 1 ? ", " : ""}
                            </span>
                            {i ===
                            Math.ceil(EnumWarehouseType.length / 2) - 1 ? (
                              <br />
                            ) : null}
                          </React.Fragment>
                        );
                      })
                    : "N/A"}
                </p>
              </div>
            </td>

            <td>
              <div className="flex items-center">
                <div className={iconClasses} title="Usage Restrictions">
                  <Icon
                    iconId="attr_restricted_usage"
                    size="xl"
                    state={pd.attr_usage !== 0 ? "default" : "disabled"}
                  />
                </div>
                <p style={{ maxWidth: "170px" }}>
                  {propertyUsages.map((u, i) => {
                    return (
                      <React.Fragment key={i}>
                        <span
                          className={
                            (pd.attr_usage & (1 << i)) !== 0 ? "" : textDisabled
                          }
                        >
                          {u}
                          {i !== propertyUsages.length - 1 ? ", " : ""}
                        </span>
                        {i === Math.ceil(propertyUsages.length / 2) - 1 ? (
                          <br />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RequestNeeds;
