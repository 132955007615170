import React, { useState, useContext, useEffect } from "react";
import { GridSection } from "components/CardComponents";
import styled from "styled-components";
import { Button, Modal } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import __ from "lodash";
import { validateYouTubeUrl } from "services/forms/validators";
import {
  isPropertyStatusMatchable,
} from "services/utils";
import { OnChange } from "react-final-form-listeners";

import TextInput from "components/Forms/TextInput";
import RichTextAreaInput from "components/Forms/RichTextAreaInput";
import ListingImageRow from "components/Images/ListingImageRow";
import Field from "components/Forms/Field";
import ImageCarouselModal from "components/Images/ImageCarouselModal";
import ConfirmationDialog from "components/Forms/ConfirmationDialog";

import { fieldToHelpText } from "services/utils";
import { getFreeManualPublishersForLocation, getPublishersForLocation } from "services/utilities";
import { AppContext } from "contexts/AppContext";
import PublishOptions from "components/PublishOptions/PublishOptions";
import ManualPublishNotice from "components/PublishOptions/ManualPublishNotice";
import { LP_CREXI } from "services/constants";
import { useMutation } from "@apollo/client";
import { PUBLISH } from "graphql/mutations";

const FormRow = styled.div`
  display: flex;
  margin-top: 6px;
  margin-bottom: 6px;
`;
const FormLabel = styled.div`
  margin-left: 5px;
  width: 250px;
`;

export default function Listing({
  configIndex,
  propertyIndex,
  isEditable,
  checkImages,
  property,
  configName,
  isListable,
  failedReasons,
}) {
  const [startIndex, setStartIndex] = useState(0);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [isPhotos, setIsPhotos] = useState(true);
  const [listingReadyWaring, setListingReadyWarning] = useState(false);
  const [currentProviders, setCurrentProviders] = useState([]);
  const [buttonText, setButtonText] = useState("Copy code");

  const { photos, floorplans, configs } = property;
  const {
    trackcode: configTrackcode,
    config_id: currentConfigID,
    listings: {
      providers,
      extras,
      floorplans: configFloorPlans,
      photos: configPhotos,
    },
    title: configTitle,
    brochure: propertyBrochure,
  } = configs[configIndex];

  const isSelfListed =
    property?.configs[configIndex].self_listing !== undefined;

  const [showTitleLengthWarning, setShowTitleLengthWarning] = useState(false);

  useEffect(() => {
    setCurrentProviders(providers);
  }, [providers]);

  useEffect(() => {
    setShowTitleLengthWarning(configTitle?.length > 50 && currentProviders.includes(LP_CREXI));
  }, [currentProviders, configTitle]);

  useEffect(() => {
    if (buttonText !== "Copy code") {
      setTimeout(() => setButtonText("Copy code"), [2000]);
    }
  }, [buttonText]);

  const { listingProviders } = useContext(AppContext);

  const showFullSizeImage = (isPhoto, configIndex) => {
    setIsPhotos(isPhoto);
    setStartIndex(configIndex);
    setOpenImageModal(true);
  };

  const checkImageByType = (type, image, checked) => {
    checkImages(propertyIndex, currentConfigID, type, image, checked);
  };

  const onCopyClick = () => {
    navigator.clipboard.writeText("ph-" + configTrackcode);
    setButtonText("Copied!");
  };

  const [newProviders, setNewProviders] = useState([]);
  const [pubMut] = useMutation(PUBLISH);
  useEffect(() => {
    if (!newProviders || newProviders.length === 0) return;

    (async () => {
      try {
        await pubMut({
          variables: {
            input: {
              config_id: currentConfigID,
              providers: newProviders,
            },
          }
        });
      } catch (e) {
        console.error("failed to update the manual listing providers on listing publish", e)
      } finally{}
    })();
    setCurrentProviders([...currentProviders, ...newProviders]);
    setNewProviders([]);
  }, [currentProviders, newProviders, currentConfigID, pubMut]);

  return (
    <GridSection title="Listing" md={12} xs={12}>
      <>
        {configs.length > 1 && (
          <TextInput
            label={`Internal Name`}
            name={`${configName}.name`}
            className="max-w-xs ml-1"
          />
        )}

        <TextInput
          label={`Listing Title`}
          name={`${configName}.title`}
          className="max-w-sm ml-1"
          warningText={showTitleLengthWarning ? "WARNING! Title is too long for Crexi, will be truncated to 50 characters" : ""}
        />

        <FormLabel>
          <label className="input-label">
            Listing Description
            <Tooltip
              title={fieldToHelpText("config.description")}
              placement="right"
              arrow
            >
              <HelpOutlineIcon className="ml-0.5" fontSize="small" />
            </Tooltip>
          </label>
        </FormLabel>

        <div className="ml-2">
          <Field
            name={`${configName}.description`}
            component={RichTextAreaInput}
            hideInput={!isEditable}
          />
        </div>
        {configTrackcode && isEditable && (
          <div className="flex mt-5 w-full ml-3">
            <span className="text-base mr-3">
              Trackcode: ph-{configTrackcode}
            </span>
            <Button variant="contained" size="small" onClick={onCopyClick}>
              {buttonText}
            </Button>
          </div>
        )}

        <ManualPublishNotice />
        <div className="flex mt-5 w-full justify-between ml-3">
          <div>
            {isEditable ? (
              getPublishersForLocation(
                property.country,
                property.province,
                listingProviders
              )?.map((publisher, index) => (
                <PublishOptions
                  key={index}
                  provider={publisher}
                  fieldName={`${configName}.listings.extras`}
                  configId={currentConfigID}
                  currentProviders={currentProviders}
                  isListable={isListable}
                  setCurrentProviders={setCurrentProviders}
                  extras={extras}
                />
              ))
            ) : (
              <></>
            )}
          </div>
          {!isListable && (
            <div>
              <div className="form-alert ml-10 w-3/4">
                You cannot publish a listing until all required fields are
                specified.<p></p>
                {failedReasons?.length > 0 && (
                  <span
                    className="learnLink"
                    data-cy={`failed-listing-info-${propertyIndex}${configIndex}`}
                    onClick={() => setListingReadyWarning(true)}
                  >
                    Tell me why
                  </span>
                )}
              </div>
            </div>
          )}

          {failedReasons && (
            <ConfirmationDialog
              title={"Required Fields for Listing Readiness"}
              content={failedReasons?.map((field, idx) => {
                return <div key={idx}>{field}</div>;
              })}
              ignoreConfirm
              confirmText={"Ok"}
              onConfirm={() => {}}
              onClose={() => setListingReadyWarning(false)}
              open={listingReadyWaring}
            />
          )}
        </div>
      </>

      <label className="input-label">
            Video Tour URL
            <Tooltip
              title={fieldToHelpText("config.tour_url")}
              placement="right"
              arrow
            >
              <HelpOutlineIcon className="ml-0.5" fontSize="small" />
            </Tooltip>
          </label>

      <TextInput
          name={`${configName}.tour_url`}
          className="max-w-sm ml-1"
          validate={validateYouTubeUrl}
      />

        {propertyBrochure && isEditable && isListable && (
          <div className="flex mt-5 w-full justify-between">
              <label className="input-label" style={{marginLeft: 0}}>
                <span className="text-base">
                  Property Brochure
                  <Tooltip
                    title={fieldToHelpText("config.brochure")}
                    placement="right"
                    arrow
                  >
                    <HelpOutlineIcon className="ml-0.5 mr-2" fontSize="small" />
                  </Tooltip>
                <Button name={`${configName}.brochure`} variant="contained" size="small" onClick={(e) => {
                    e.preventDefault();
                    if (propertyBrochure)
                      window.open(propertyBrochure, "_blank");
                  }}>
                  View/Download
                </Button>
              </span>
            </label>
          </div>
        )}

      {photos && photos.length > 0 && (
        <>
          <FormRow>
            <FormLabel>Photos to display</FormLabel>
          </FormRow>
          {isEditable && (
            <Field
              name={`${configName}.listings.photos`}
              isEqual={(a, b) => {
                return __.isEqual(__.sortBy(a), __.sortBy(b));
              }}
            >
              {(props) => {
                return (
                  <FormRow>
                    <div>
                      <ListingImageRow
                        images={photos}
                        checkImage={checkImageByType.bind(null, "photos")}
                        currentConfigID={currentConfigID}
                        configImages={configPhotos}
                        isEditable={isEditable}
                        showFullSizeImage={showFullSizeImage}
                        isPhotos={true}
                      />
                    </div>
                  </FormRow>
                );
              }}
            </Field>
          )}
        </>
      )}

      {floorplans && floorplans.length > 0 && (
        <>
          <FormRow>
            <FormLabel>Floorplans to display</FormLabel>
          </FormRow>
          {isEditable && (
            <Field
              name={`${configName}.listings.floorplans`}
              isEqual={(a, b) => {
                return __.isEqual(__.sortBy(a), __.sortBy(b));
              }}
            >
              {(props) => {
                return (
                  <FormRow>
                    <div>
                      <ListingImageRow
                        images={floorplans}
                        checkImage={checkImageByType.bind(null, "floorplans")}
                        isEditable={isEditable}
                        configImages={configFloorPlans}
                        showFullSizeImage={showFullSizeImage}
                        isPhotos={false}
                      />
                    </div>
                  </FormRow>
                );
              }}
            </Field>
          )}
        </>
      )}

      {property && configs && (
        <OnChange
          name={`${configName}.status`}
          key={`matchable-status-${configIndex}`}
        >
          {(curr, previous) => {
            // Add manual listing providers when a self listing is published.
            // This is essentially selecting for the user, and we'll remove this when
            // they have some sort of pre-defined syndication preferences.
            if (
              !isPropertyStatusMatchable({ status: previous }) &&
              isPropertyStatusMatchable({ status: curr }) &&
              isSelfListed
            ) {
              const manualProviders = getFreeManualPublishersForLocation(
                property.country,
                property.province,
                listingProviders
              ).map(p => p.value);

              let newProviders = [];
              if (!!currentProviders)
                for (let i = 0; i < manualProviders.length; i++)
                  if (!currentProviders.includes(manualProviders[i]))
                    newProviders.push(manualProviders[i]);

              (newProviders.length > 0) && setNewProviders(newProviders);
            }
          }
        }
        </OnChange>
      )}

      {isPhotos && photos && photos.length > 0 && (
        <Modal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <>
            <ImageCarouselModal
              images={photos}
              startIndex={startIndex}
            ></ImageCarouselModal>
          </>
        </Modal>
      )}
      {!isPhotos && floorplans && floorplans.length > 0 && (
        <Modal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <>
            <ImageCarouselModal
              images={floorplans}
              startIndex={startIndex}
            ></ImageCarouselModal>
          </>
        </Modal>
      )}
    </GridSection>
  );
}
