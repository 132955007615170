export enum FormErrorType {
  PROPERTY = 0,
  CONFIG = 1,
}

export enum FormCommand {
  IDLE = 0,
  CREATE_PROPERTY = 1,
  CREATE_REQUEST = 2,
}
