import React, { useState, useEffect, useMemo } from "react";
import { OnChange } from "react-final-form-listeners";
import { Grid } from "@mui/material";

import TextInput from "components/Forms//TextInput";
import PropertyAddressInput from "components/Forms//PropertyAddressInput";
import NullBooleanInput from "components/Forms/NullBooleanInput";
import DynamicGoogleMap from "components/Map/DynamicGoogleMap";
import StaticGoogleMap from "components/Map/StaticGoogleMap";
import { GridSection } from "components/CardComponents";

import { formatPostalCode, formatZipCode } from "services/forms/helpers";
import { isUndefinedOrNull, propertyHasValues } from "services/utils";
import { isCanadianMarket } from "services/utilities";

export default function Location({
  isEditable,
  address_known,
  property,
  updateAddress,
  clearUnitNumber,
  propertyIndex,
  propertyName,
}) {
  const [mapError, setMapError] = useState(false); // used to show or not show the map if there is an error
  const [anyvalue, setAnyvalue] = useState(propertyHasValues(property));
  const [initialAddress] = useState({ address: property });

  const selectedProperty = propertyName;
  const addressExists = !isUndefinedOrNull(address_known);

  useEffect(
    function () {
      setAnyvalue(propertyHasValues(property));
    },
    [property]
  );

  const updateAddressWithIndex = useMemo(
    () => updateAddress.bind(null, propertyIndex),
    [updateAddress, propertyIndex]
  );

  const clearUnitNumberWithIndex = useMemo(
    () => clearUnitNumber.bind(null, propertyIndex),
    [clearUnitNumber, propertyIndex]
  );

  const addressInput = () => {
    if (anyvalue) {
      return (
        <>
          <TextInput
            name={`${selectedProperty}.address`}
            label={`Street Address`}
            isReadOnly={true}
            overRideAutoInputSettings={true}
          />
          {address_known && (
            <TextInput
              name={`${selectedProperty}.unit`}
              label={`Unit`}
              isReadOnly={!isEditable}
            />
          )}
          <TextInput
            name={`${selectedProperty}.city`}
            label={`City`}
            isReadOnly={true}
            overRideAutoInputSettings={true}
          />
          <TextInput
            name={`${selectedProperty}.province`}
            label={isCanadianMarket(property.country) ? "Province" : "State"}
            isReadOnly={true}
            overRideAutoInputSettings={true}
          />
          <TextInput
            name={`${selectedProperty}.postal`}
            label={isCanadianMarket(property.country) ? "Postal Code" : "Zip Code"}
            parse={isCanadianMarket(property.country) ? formatPostalCode : formatZipCode}
            isReadOnly={true}
            displayWithWarning={true}
            overRideAutoInputSettings={true}
          />
        </>
      );
    }
  };

  return (
    <GridSection title="Location" md={12} xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className="flex">
            <NullBooleanInput
              name={`${selectedProperty}.address_known`}
              label={`Do you have the address?`}
              disabled={!isEditable}
            />
          </div>
          <OnChange name={`${selectedProperty}.address_known`}>
            {(curr, previous) => {
              if (!curr) clearUnitNumberWithIndex();
            }}
          </OnChange>

          {addressExists && (
            <>
              {address_known && isEditable && (
                <PropertyAddressInput
                  setMapError={setMapError}
                  updateAddress={updateAddressWithIndex}
                  propertyIndex={propertyIndex}
                  hasAddressValue={anyvalue}
                />
              )}
            </>
          )}
        </Grid>

        {addressExists && address_known && (
          <Grid item xs={4}>
            {addressInput()}
          </Grid>
        )}

        {addressExists && (
          <Grid item xs={5}>
            {address_known
              ? propertyHasValues(property) && (
                  <StaticGoogleMap
                    property={property}
                    propertyHasValues={propertyHasValues}
                    setMapError={setMapError}
                    mapError={mapError}
                    mapNumber={propertyIndex}
                  />
                )
              :
                  <DynamicGoogleMap
                    property={property}
                    propertyHasValues={propertyHasValues}
                    setMapError={setMapError}
                    mapError={mapError}
                    updateAddress={updateAddressWithIndex}
                    initialAddress={initialAddress}
                    disabled={!isEditable}
                    mapNumber={propertyIndex}
                  />
                }
          </Grid>
        )}

        {addressExists && !address_known &&  (
          <Grid item xs={3}>
            {addressInput()}
          </Grid>
        )}
      </Grid>
    </GridSection>
  );
}
