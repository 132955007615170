import React from "react";

import { Field } from "react-final-form";
import BaseInput from "./BaseInput";
import { showInputErrorStyle } from "services/utils";
import Error from "./Error";

function PasswordInput(props) {
  const fieldProps = Object.assign({}, props);
  delete fieldProps.isSideNote;
  delete fieldProps.sideNoteNamespace;
  delete fieldProps.indent;

  return (
    <BaseInput {...props}>
      <Field {...fieldProps}>
        {({ input, meta }) => (
          <div>
            <input
              className={showInputErrorStyle(meta.error, input.value)}
              {...input}
              type="password"
            />
            <Error showError={meta.error} error={meta.error} />
          </div>
        )}
      </Field>
    </BaseInput>
  );
}

export default PasswordInput;
