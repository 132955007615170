import { Field as FinalFormField } from "react-final-form";

export default function Field(props) {
  return (
    <span id={props.name}>
      <FinalFormField {...props} key={props.name}>
        {props.children}
      </FinalFormField>
    </span>
  );
}
