import { FormContext } from "contexts";
import { FormCommand } from "enums/formEnums";
import { useContext, useEffect } from "react";
import { scrollWithinView } from "services/utils";

// allows scrolling to properties / requests in form
export default function useFormScroll(): void {
  const { shouldScrollWithinView, setShouldScrollWithinView, setFormCommand } =
    useContext(FormContext);

  useEffect(() => {
    if (shouldScrollWithinView.name) {
      scrollWithinView(
        shouldScrollWithinView.name,
        shouldScrollWithinView.offset
      );
      setShouldScrollWithinView({ name: null });
      setFormCommand(FormCommand.IDLE);
    }
  }, [setFormCommand, setShouldScrollWithinView, shouldScrollWithinView]);
}
