import { useLazyQuery } from "@apollo/client";
import { PLACE_FEE_QUERY } from "graphql/queries";
import { debounce } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { LFB_GROSS, LFB_SQFT_MO, LFB_SQFT_YR } from "services/constants";
import {
  FeeDataDetails,
  FeeParams,
  UsePlaceFeeProps,
} from "interfaces/usePlaceFee";
import { AppContext } from "contexts/AppContext";
import { isSharedProperty } from "services/forms/helpers";

export default function usePlaceFee({
  configID,
  propertyType,
  warehouseType,
  province,
  listFeeBasis,
  listFee,
  squareFootageMin,
  squareFootageMax,
  inputDelay = 1000,
}: UsePlaceFeeProps): null | FeeDataDetails {
  const { feeTunings } = useContext(AppContext);
  const [feeData, setFeeData] = useState<null | FeeDataDetails>(null);
  const [feeParams, setFeeParams] = useState<FeeParams>({
    // values needed to generate place fee in list-fee basis
    sqft: 0,
    sqft_min: 0,
    numPeople: 0,
  });

  const [getPlaceFee] = useLazyQuery(PLACE_FEE_QUERY, {
    onCompleted: (data) => {
      if (data) setFeeData({ ...data.placefee, ...feeParams });
    },
  });

  const onPriceFieldsChangeDebounce = useMemo(
    () =>
      debounce(
        (
          province,
          propertyType,
          listFeeBasis,
          listFee,
          warehouseType,
          square_footage_min,
          square_footage_max
        ) => {
          const tunings = feeTunings;
          if (listFee && listFeeBasis) {
            const sqft = isSharedProperty(warehouseType)
              ? square_footage_max
              : square_footage_min;
            const sqft_min = square_footage_min || 0;

            // collect the values needed to compute the place fee in list-fee basis
            setFeeParams({
              listFeeBasis: listFeeBasis,
              numPeople: 0,
              sqft: sqft,
              sqft_min: sqft_min,
              propertyType,
            });

            if (
              [LFB_GROSS].includes(listFeeBasis) ||
              ([LFB_SQFT_MO, LFB_SQFT_YR].includes(listFeeBasis) &&
                square_footage_min)
            ) {
              getPlaceFee({
                variables: {
                  listFee: listFee,
                  units: listFeeBasis,
                  capacity: 0,
                  squarefeet: sqft,
                  squarefeetmin: sqft_min,
                  furniture: 0,
                  province: province,
                  propertyType: propertyType,
                  tunings: tunings,
                  configID: configID,
                },
              });
            }
          }
        },
        inputDelay
      ),
    [getPlaceFee, inputDelay, feeTunings, configID]
  );

  useEffect(() => {
    if (province != null && listFee && listFeeBasis) {
      onPriceFieldsChangeDebounce(
        province,
        propertyType,
        listFeeBasis,
        listFee,
        warehouseType,
        squareFootageMin,
        squareFootageMax
      );
    }
  }, [
    listFee,
    listFeeBasis,
    onPriceFieldsChangeDebounce,
    propertyType,
    province,
    squareFootageMax,
    squareFootageMin,
    warehouseType,
  ]);

  return feeData;
}
