import gql from "graphql-tag";

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput) {
    createUser(user: $input)
  }
`;

const MATCHABILITY_QUERY = gql`
  mutation matchability($input: MatchabilityInput!) {
    matchability(input: $input) {
      result
      reasons
    }
  }
`;

const LISTABILITY_QUERY = gql`
  mutation listability($input: ListabilityInput!) {
    listability(input: $input) {
      result
      reasons
    }
  }
`;

const UPDATE_USER_PERMISSIONS = gql`
  mutation updateUserPermissions($userID: Int!, $permissions: [String!]) {
    updateUserPermissions(userID: $userID, permissions: $permissions)
  }
`;

const CREATE_ROLE = gql`
  mutation createRole($input: CreateRoleInput) {
    createRole(role: $input)
  }
`;

const UPDATE_ROLE_PERMISSIONS = gql`
  mutation updateRolePermissions(
    $roleID: Int!
    $name: String
    $permissions: [Int]
  ) {
    updateRolePermissions(
      roleID: $roleID
      name: $name
      permissions: $permissions
    )
  }
`;

const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($input: NotificationInput!) {
    updateNotification(notification: $input)
  }
`;

const UPDATE_NOTIFICATION_PREFERENCE = gql`
  mutation updateNotificationPreference($input: NotificationPreferenceInput!) {
    updateNotificationPreference(preference: $input)
  }
`;

const UPSERT_HOST = gql`
  mutation upsertHost($input: UpsertHostInput!) {
    upsertHost(input: $input)
  }
`;

const UPSERT_USER = gql`
  mutation createUser($user: CreateUserInput!) {
    createUser(user: $user)
  }
`;

const UPSERT_ALL = gql`
  mutation upsertPropertiesAndRequests($input: AllInfoInput!) {
    upsertPropertiesAndRequests(input: $input) {
      entity_type
      entity_id
    }
  }
`;

const UPSERT_GUEST = gql`
  mutation upsertGuest($input: UpsertGuestInput!) {
    upsertGuest(input: $input) {
      orgID
      requests
    }
  }
`;

const UPSERT_PROPERTY = gql`
  mutation upsertProperty($input: UpsertPropertyInput!) {
    upsertProperty(input: $input) {
      orgID
      propertyID
      configs
    }
  }
`;

const UPSERT_CONFIGURATION = gql`
  mutation upsertConfiguration($input: UpsertConfigInput!) {
    upsertConfiguration(input: $input)
  }
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($input: String!) {
    changePassword(password: $input)
  }
`;

const CREATE_MFA_QR_CODE = gql`
  mutation createMfaQRCode {
    createMfaQRCode
  }
`;

const VERIFY_MFA = gql`
  mutation verifyMFA($code: String!) {
    verifyMFA(code: $code)
  }
`;

const DISABLE_MFA = gql`
  mutation disableMFA {
    disableMFA
  }
`;

const DISCONNECT_PIPEDRIVE = gql`
  mutation disconnectPipedrive {
    disconnectPipedrive
  }
`;

const INIT_PIPEDRIVE_CONNECT = gql`
  mutation initPipedriveConnect {
    initPipedriveConnect
  }
`;

const DELETE = gql`
  mutation delete($input: DeleteInput!) {
    delete(input: $input)
  }
`;

const PUBLISH = gql`
  mutation publish($input: PublishInput!) {
    publish(input: $input)
  }
`;

const UNPUBLISH = gql`
  mutation unPublish($input: PublishInput!) {
    unPublish(input: $input)
  }
`;

const CLAIM_LISTING = gql`
  mutation claimForUser($empireID: Int!, $configID: Int!) {
    claimForUser(empireID: $empireID, configID: $configID)
  }
`;

const MARK_BULK_UPLOAD_AS_COMPLETE = gql`
  mutation markUploadComplete($input: UploadCompleteInput!) {
    markUploadComplete(input: $input)
  }
`;

export {
  CREATE_USER,
  UPDATE_USER_PERMISSIONS,
  UPSERT_HOST,
  UPSERT_GUEST,
  UPSERT_PROPERTY,
  UPSERT_CONFIGURATION,
  UPSERT_USER,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_PREFERENCE,
  UPDATE_ROLE_PERMISSIONS,
  CREATE_ROLE,
  CHANGE_PASSWORD,
  CREATE_MFA_QR_CODE,
  VERIFY_MFA,
  DISABLE_MFA,
  DISCONNECT_PIPEDRIVE,
  INIT_PIPEDRIVE_CONNECT,
  DELETE,
  UPSERT_ALL,
  MATCHABILITY_QUERY,
  LISTABILITY_QUERY,
  PUBLISH,
  UNPUBLISH,
  CLAIM_LISTING,
  MARK_BULK_UPLOAD_AS_COMPLETE,
};
