import React from "react";
import Contact from "../Contact";
import RequestNeeds from "./RequestNeeds";
import { ReqMatchData } from "./types";
import { availableByDate, sqftFromRange, termFromRange } from "./utils";
import { buildReferenceURL } from "services/utilities";
import { EntityType } from "enums";

interface Props {
  data: ReqMatchData;
}

const ConfigHeaderSummary: React.FC<Props> = ({ data }) => {
  return (
    <div className="flex font-display justify-between">
      {/*address & text summary*/}
      <div className="flex flex-col font-medium text-gray-700 mx-1">
        <p className="text-2xl text-blue-500">
          <a
            className="decorated-link"
            href={buildReferenceURL({
              id: data.req_id,
              type: EntityType.REQUEST,
            })}
            target="_blank"
            rel="noreferrer"
          >
            {data.org_name}
          </a>
        </p>
        <table>
          <tbody>
            <tr>
              <td className="text-right font-bold">Budget</td>
              <td className="pl-2">
                {data.budget
                  ? "$" + (data.budget / 100).toLocaleString()
                  : "N/A"}
              </td>
            </tr>

            <tr>
              <td className="text-right font-bold">Square Footage</td>
              <td className="pl-2">
                {sqftFromRange(
                  data.square_footage_min,
                  data.square_footage_max
                )}
              </td>
            </tr>

            <tr>
              <td className="text-right font-bold">Term</td>
              <td className="pl-2">
                {data.term_min && data.term_max
                  ? termFromRange(data.term_min, data.term_max, undefined)
                  : "N/A"}
              </td>
            </tr>

            <tr>
              <td className="flex justify-end text-right font-bold">
                Availability
              </td>
              <td className="pl-2">
                <>
                  {data.movein || (
                    <span className="font-semibold">Immediately</span>
                  )}
                  {data.movein_notice && (
                    <span> + {data.movein_notice} days</span>
                  )}
                  {data.movein_notice && (
                    <p>{availableByDate(data.movein, data.movein_notice)}</p>
                  )}
                </>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/*property attributes summary*/}
      <div className="">
        <p className="ml-1 mb-2">NEEDS</p>
        <RequestNeeds needs={data.needs} />
      </div>

      {/*primary contact*/}
      {data.primary_contact && (
        <div className="flex flex-col mx-1">
          <p className="ml-1 mb-2">CONTACT</p>
          <Contact
            name={
              data.primary_contact.firstname +
              " " +
              data.primary_contact.lastname
            }
            position={data.primary_contact.title}
            email={data.primary_contact.email}
            phone={data.primary_contact.phone}
          />
        </div>
      )}
    </div>
  );
};

export default ConfigHeaderSummary;
