import React, { useState } from "react";
import useStorage from "../../hooks/useStorage";
import Icon from "../Icon";
import IconButton from "../IconButton";
import Paginator from "../Paginator";
import { PaginationInput } from "./types";

interface Props {
  title?: string;
  elemCount?: number;
  showCount?: boolean;

  pagination?: PaginationInput;
  setPagination?: React.Dispatch<React.SetStateAction<PaginationInput>>;

  // whether the table is open
  open?: boolean;
  // toggle table's open state
  toggleOpen?: () => void;

  filter?: string;
  searchCallback?: (s: string) => void;
  exportCallback?: () => void;
}

const TableHeader: React.FC<Readonly<Props>> = ({
  title,
  elemCount,
  showCount,
  pagination,
  setPagination,
  open,
  toggleOpen,
  filter,
  searchCallback,
  exportCallback,
}) => {
  const [, setPgSize] = useStorage("PG_SIZE", 25);
  const [search, setSearch] = useState<string>(filter || "");

  return (
    <div className="flex items-center text-lg font-semibold p-1 bg-gray-200 border-t-2 border-gray-400 justify-between">
      <div className="flex flex-auto">
        {open !== undefined && toggleOpen !== undefined && (
          <div
            onClick={toggleOpen}
            className="flex items-center justify-center"
            title={open ? "Hide" : "Show"}
          >
            <Icon
              iconId={open ? "attr_section_open" : "attr_section_closed"}
              size="xs"
            />
          </div>
        )}

        {title && (
          <div className="flex">
            <p className="mx-2">{title}</p>
            {showCount && elemCount !== undefined && (
              <div className="text-sm bg-gray-100 rounded-lg px-1 flex flex-col justify-center">
                {elemCount}
              </div>
            )}
          </div>
        )}

        {filter !== undefined && searchCallback && (
          <div className="flex flex-auto text-sm ml-4 mr-20 my-auto">
            <input
              type="text"
              className={
                "w-full px-1 rounded-l-md min-w-max " +
                (search !== "" ? "" : "rounded-r-md")
              }
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && searchCallback(search)}
            />
            {search !== "" && (
              <div
                className="px-1 rounded-r-md bg-gray-300 flex justify-center items-center border border-gray-500 border-l-0"
                onClick={() => {
                  setSearch("");
                  searchCallback("");
                }}
              >
                X
              </div>
            )}
            <IconButton
              iconId="action_search"
              iconSize="base"
              filter="invert"
              text="Search"
              onClick={() => searchCallback(search)}
              enabled
            />

            {exportCallback && (
              <IconButton
                iconId="action_download"
                iconSize="base"
                filter="invert"
                text="Export"
                onClick={exportCallback}
                enabled
              />
            )}
          </div>
        )}
      </div>

      {elemCount !== undefined && pagination && setPagination && (
        <Paginator
          numPages={Math.ceil(elemCount / pagination.size)}
          idx={pagination.index}
          size={pagination.size}
          setPageSize={(size: number) => {
            setPgSize(size);
            setPagination((p) => {
              return { ...p, size };
            });
          }}
          gotoPage={(index: number) => {
            setPagination((p) => {
              return { ...p, index };
            });
          }}
        />
      )}
    </div>
  );
};

export default TableHeader;
