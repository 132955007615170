import { Tab, Tabs, Paper } from "@mui/material";
import { OrganizationChildrenTypes } from "enums/organizationTypes";

type PropertyTenantRequestSelectorProps = {
  type: OrganizationChildrenTypes;
  onChange: (newType: OrganizationChildrenTypes) => void;
  tenantRequestLength: number | null;
  propertyLength: number | null;
};

export default function PropertyTenantRequestSelector({
  type,
  onChange: handleTabChange,
  tenantRequestLength,
  propertyLength,
}: PropertyTenantRequestSelectorProps): JSX.Element {
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: OrganizationChildrenTypes
  ) => {
    handleTabChange(newValue);
  };

  const formatLengthText = (length: number | null): string =>
    length == null || length === 0 ? "" : `(${length})`;

  return (
    <Tabs
      value={type}
      onChange={handleChange}
      variant="fullWidth"
      component={Paper}
      sx={{ mt: 2, borderRadius: 10 }}
    >
      <Tab
        label={
          <div className="w-full">
            <span>{`Properties ${formatLengthText(propertyLength)}`}</span>
          </div>
        }
        value={OrganizationChildrenTypes.Property}
        data-cy="property-tab"
      />
      <Tab
        label={
          <div className="w-full">
            <span>{`Tenant Requests ${formatLengthText(
              tenantRequestLength
            )}`}</span>
          </div>
        }
        value={OrganizationChildrenTypes.TenantRequest}
        data-cy="request-tab"
      />
    </Tabs>
  );
}
