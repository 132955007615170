import React, { useEffect } from "react";
import { geoCode } from "./GoogleMapFunctions";

let geocoder;
let staticMap;

function initStaticMap(
  propertyAddress,
  city,
  province,
  postal,
  address_known,
  setMapError,
  mapNumber,
  cache_lat,
  cache_long
) {
  geocoder = new google.maps.Geocoder(); // for getting pin from inputed address

  // get info for geocoding
  const address = `${propertyAddress}, ${city}, ${province}, ${postal}`;
  let locationLatLng;
  //initialize static map
  staticMap = new google.maps.Map(
    document.querySelector(
      `[id='StaticGoogleMap-${mapNumber}']`
    ) as HTMLDivElement,
    {
      zoom: 13,
      center: locationLatLng,
    }
  );

  // initialize markers and specify the map
  const marker = new google.maps.Marker({
    map: staticMap,
  });
  const latlng = new google.maps.LatLng({
    lat: parseFloat(cache_lat),
    lng: parseFloat(cache_long),
  });

  geoCode(
    address,
    latlng,
    staticMap,
    marker,
    address_known,
    setMapError,
    geocoder
  );
}

function Info(mapError, propertyHasValues) {
  // A function that returns error message if there is one
  if (mapError && propertyHasValues) {
    return (
      <p className={"p-5px text-state-fatal"}>
        Error parsing results from Google Maps.
      </p>
    );
  }
}

export default function StaticGoogleMap(props: {
  property;
  propertyHasValues;
  setMapError;
  mapError;
  mapNumber;
}): JSX.Element {
  useEffect(() => {
    // For initializing static map
    if (props.property.address_known === true && props.mapError === false) {
      initStaticMap(
        props.property.address,
        props.property.city,
        props.property.province,
        props.property.postal,
        props.property.address_known,
        props.setMapError,
        props.mapNumber,
        props.property.cache_lat,
        props.property.cache_long
      );
    }
  }, [
    props.property.address_known,
    props.setMapError,
    props.mapError,
    props.property.address,
    props.property.city,
    props.property.province,
    props.property.postal,
    props.mapNumber,
    props.property.cache_lat,
    props.property.cache_long,
  ]);

  return (
    <>
      {props.mapError ? (
        Info(props.mapError, props.propertyHasValues(props.property))
      ) : (
        <div
          className={"w-google-map h-google-map mb-3"}
          id={`StaticGoogleMap-${props.mapNumber}`}
          key={props.mapNumber}
        ></div>
      )}
    </>
  );
}
