import React, { useEffect, useState } from "react";
import Score from "./Score";
import { ConfigMatchResult } from "./types";
import { guestName, sqftFromRange } from "./utils";
import Disqualifiers from "./Disqualifiers";
import { buildReferenceURL } from "services/utilities";
import { EntityType } from "enums";

interface Props {
  guest: ConfigMatchResult;
  // index of row in table - used to shade alternating rows differently
  rowIdx?: number;
  ignoredDisqualifiers: number;
}

const DisqualifiedRequestRow: React.FC<Props> = ({
  guest,
  rowIdx,
  ignoredDisqualifiers,
}) => {
  const [rowBg, setRowBg] = useState<string>("");
  useEffect(() => {
    if (rowIdx === undefined) return;
    setRowBg(`bg-${rowIdx % 2 ? "gray-50" : "white"}`);
  }, [rowIdx]);

  return (
    <tr className={rowBg + " h-10"}>
      <td className="w-3/12">
        <Score
          matchResult={guest.match_result}
          ignoredDisqualifiers={ignoredDisqualifiers}
        />
      </td>
      <td className="font-semibold text-blue-500">
        <a
          href={buildReferenceURL({
            id: guest.entity_data.req_id,
            type: EntityType.REQUEST,
          })}
          target="_blank"
          rel="noreferrer"
        >
          {guestName(guest.entity_data.org_name, guest.entity_data.req_name)}
        </a>
      </td>
      <td className="flex items-center">
        <Disqualifiers mr={guest.match_result} ignored={ignoredDisqualifiers} />
      </td>
      <td>
        {sqftFromRange(
          guest.entity_data.square_footage_min,
          guest.entity_data.square_footage_max
        )}
      </td>
      <td>
        {guest.entity_data.budget
          ? `$ ${(guest.entity_data.budget / 100).toLocaleString()}`
          : "N/A"}
      </td>
    </tr>
  );
};

export default DisqualifiedRequestRow;
