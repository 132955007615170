import { useContext, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  Box,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Settings as SettingsIcon,
  Menu as MenuIcon,
  ManageAccounts as ManageAccountsIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";

import { isPipeDriveAndMFAEnabled } from "services/authenticator";
import Searchbar from "components/Searchbar/Searchbar";
import { AppContext } from "contexts/AppContext";

export default function Nav(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const history = useHistory();
  const menuItemIconStyling = "mr-2";
  const isMainScreen = location?.pathname.length === 1;
  const { userName } = useContext(AppContext);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHomeNavigation = () => {
    history.replace("/");
  };

  const logoutWithRedirect = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("mfa_enabled");
    localStorage.removeItem("mfa_verified");
    history.push("/");
  };

  return (
    <Box className="grow text-center">
      <AppBar position="relative">
        <Toolbar className="border-b-3 bg-white">
          {!isMainScreen && isPipeDriveAndMFAEnabled() && (
            <>
              <img
                src={
                  "https://cms-cdn.placeholder.co/Logo_f6bbd8b915.svg?width=384"
                }
                alt="Logo"
                width="150"
                className="mt-1 ml-1"
                onClick={handleHomeNavigation}
              />
              <Searchbar size="small" sx={{ ml: 4, width: 300 }} />
            </>
          )}
          <div className="ml-auto flex">
            <IconButton size="large" onClick={handleMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/settings" onClick={handleClose}>
                <SettingsIcon className={menuItemIconStyling} />
                Settings
              </MenuItem>
              <MenuItem component={Link} to="/users" onClick={handleClose}>
                <ManageAccountsIcon className={menuItemIconStyling} />
                User Management
              </MenuItem>
              <MenuItem onClick={() => logoutWithRedirect()}>
                <ExitToAppIcon className={menuItemIconStyling} />
                Log Out
              </MenuItem>
            </Menu>
            <Typography color="black" lineHeight={3} data-cy="nav-username">
              {userName}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
