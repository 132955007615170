import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";

import ChangePasswordForm from "components/Forms/ChangePasswordForm";
import MfaForm from "components/Forms/MfaForm";
import PipedriveConnect from "components/PipedriveConnect";
import Title from "components/Title";
import { TITLE_SETTINGS } from "services/constants";
import {
  isMFAEnabled,
  isPipedriveConnected,
  isPipeDriveAndMFAEnabled,
} from "services/authenticator";
import useTabTitle from "hooks/useTabTitle";

const settingsPanelStyle = "p-8 mb-4";

export default function Settings({ location }) {
  // The boolean value of this state variable has no semantic meaning - it's
  // just used to cause a re-render in the component it is passed to as a prop.
  const [rerender, setRerender] = useState(true);
  const isMfaEnabled = isMFAEnabled();

  // Callback for when the user switches to the tab
  const onFocus = () => setRerender((r) => !r);

  useTabTitle(TITLE_SETTINGS);

  useEffect(() => {
    window.addEventListener("focus", onFocus);

    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  });

  return (
    <div className="max-w-3xl w-11/12">
      <Title>Settings</Title>
      {!isPipeDriveAndMFAEnabled() && (
        <div className="text-lg mb-3" data-testid="warning">
          <p>You’re almost ready to start using Spacer!</p>
          <p>To finish setup of your account you need to:</p>
          <ul style={{ listStyle: "circle", marginLeft: "25px" }}>
            {!isMfaEnabled && (
              <li data-testid="mfa-warning">
                enable multi-factor authentication (MFA)
              </li>
            )}
            {!isPipedriveConnected() && (
              <li data-testid="pipe-drive-warning">connect to pipedrive</li>
            )}
          </ul>
        </div>
      )}

      <Paper className={settingsPanelStyle}>
        <MfaForm redirectState={location.state} enabled={isMfaEnabled} />
      </Paper>
      <Paper className={settingsPanelStyle}>
        <PipedriveConnect __rerender={rerender} />
      </Paper>
      <Paper className={settingsPanelStyle}>
        <ChangePasswordForm />
      </Paper>
    </div>
  );
}
