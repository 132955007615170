import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Copyright() {
  const getYearText = () => {
    const currYear = new Date().getFullYear();
    if (currYear === 2020) {
      return "2020";
    } else {
      return "2020-" + currYear.toString();
    }
  };

  return (
    <div>
      <Typography variant="body2" color="textSecondary" align="center">
        {"© " + getYearText() + " "}
        <Link color="inherit" href="https://placeholder.co/" target="_blank">
          PlaceHolder, Inc.
        </Link>{" "}
      </Typography>
    </div>
  );
}

export default Copyright;
