import { checkPassword } from "services/utils";

const initData = () => {
  let data = {
    permissions: ["ACT_ADMIN", "ACT_REGULAR"],
  };
  return data;
};

const validate = (args) => {
  let errors = {};

  if (args.firstname == null) {
    errors.firstname = "required";
  }

  if (args.lastname == null) {
    errors.lastname = "required";
  }

  if (args.email == null) {
    errors.email = "required";
  }

  if (args.password) {
    if (!checkPassword(args.password)) {
      errors.password = "Must follow rule";
    }
  }

  if (args.password_confirm) {
    if (!checkPassword(args.password_confirm)) {
      errors.password_confirm = "Must follow rule";
    }
  }

  if (args.password !== args.password_confirm) {
    errors.password_confirm = "passwords must match";
    errors.password = "passwords must match";
  }

  return errors;
};

const preSaveFormat = (args) => {
  delete args.password_confirm;
  return args;
};

export { initData, validate, preSaveFormat };
