import { EnumT } from "services/enums";
import BaseInput from "./BaseInput";

type EnumTextFieldReadOnlyProps = {
  value?: string;
  label: string;
  options: EnumT;
};

export default function EnumTextFieldReadOnly({
  value,
  options,
  label,
}: EnumTextFieldReadOnlyProps): JSX.Element {
  return (
    <BaseInput label={label} isStatic={true}>
      <div>
        {value
          ? options?.find((option) => option.value === value)?.name
          : "N/A"}
      </div>
    </BaseInput>
  );
}
