import React from "react";

import BaseInput from "./BaseInput";

export default function TextDisplay({
  label,
  text,
  indent = null,
  warningMsg,
  hideInput = null,
  className = "",
  staticHoverText = "",
}) {
  const classes = {
    textBox: {
      fontSize: "14px",
    },
    warningMsg: {
      color: "orange",
      fontSize: "14px",
    },
  };

  return (
    <BaseInput
      label={label}
      indent={indent}
      hideInput={hideInput}
      staticHoverText={staticHoverText}
      className={className}
    >
      <div style={classes.textBox}>
        {text}
        {(warningMsg !== undefined || warningMsg !== "") && (
          <span style={classes.warningMsg}>{warningMsg}</span>
        )}
      </div>
    </BaseInput>
  );
}
