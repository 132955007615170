import React, { useEffect, useState } from "react";
import Icon, { IconState } from "../Icon";
import {
  matchCategories,
  MatchResult,
  ScoreBreakdownCategories,
} from "./types";
import {
  classificationColors,
  scoreBreakdownCatClassification,
  scoreClassificationRaw,
} from "./utils";

interface FinalScore {
  score: number;
  max: number;
  classificationColor: IconState;
}

const ScoreDetail: React.FC<{ mr: MatchResult }> = ({ mr }) => {
  const [fs, setFs] = useState<FinalScore>({
    score: 0,
    max: 100,
    classificationColor: "score-none",
  });

  useEffect(() => {
    let score = 0,
      max = 0;
    ScoreBreakdownCategories.forEach((c) => {
      score += mr.SubScores[c].Score;
      max += mr.SubScores[c].MaxScore;
    });
    const classificationColor =
      classificationColors[
        scoreClassificationRaw(Math.round((score * 100) / max))
      ];
    setFs({ score, max, classificationColor });
  }, [mr]);

  return (
    <div className="tooltip-content flex flex-col font-normal text-base px-2 py-1">
      <p className="text-base w-max text-lg font-medium">
        Overall MatchScore Assesssment
      </p>

      <div className="flex flex-col w-96 px-4 mt-4">
        <table className="max-w-max m-auto">
          <tbody>
            {ScoreBreakdownCategories.map((c, i) => {
              const mc = matchCategories[c];
              const sub = mr.SubScores[c];
              const classificationColor: IconState =
                classificationColors[scoreBreakdownCatClassification(mr, c)];

              return (
                <tr key={i} className={""}>
                  <td className="text-right text-lg">{mc.name}</td>

                  <td className="px-2">
                    <div className="flex justify-center items-center">
                      <Icon iconId={mc.iconId} state={classificationColor} />
                    </div>
                  </td>

                  <td className={"font-bold text-" + classificationColor}>
                    <div>
                      <span className="text-3xl">{sub.Score}</span>
                      <span className="text-sm">/{sub.MaxScore}</span>
                    </div>
                  </td>

                  <td></td>
                </tr>
              );
            })}

            <tr
              className={"font-semibold text-xl text-" + fs.classificationColor}
            >
              <td></td>
              <td></td>

              <td>
                <div>
                  <span className="text-3xl">{fs.score}</span>
                  <span className="text-sm">/{fs.max}</span>
                </div>
              </td>

              <td>
                <span className="text-3xl">
                  {" "}
                  = {((fs.score * 100) / fs.max).toPrecision(4)}%
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScoreDetail;
