import { useForm } from "react-final-form";

import { EmptyResultsCard } from "components/CardComponents";
import TenantRequestInfo from "./TenantRequestInfo";
import { OrganizationChildrenTypes } from "enums/organizationTypes";
import { TenantRequests as TenantRequestsProps } from "interfaces/tenantRequests";

export default function TenantRequests({
  tenantRequests,
}: TenantRequestsProps): JSX.Element {
  const { mutators } = useForm();

  if (tenantRequests == null || tenantRequests.length === 0)
    return (
      <EmptyResultsCard
        isEmpty={false}
        type={OrganizationChildrenTypes.TenantRequest}
        onAddClick={() => mutators.addRequestConfiguration("")}
      />
    );
  return (
    <>
      <div className="w-full mt-4">
        <TenantRequestInfo
          tenantRequestInfo={tenantRequests}
          mutators={mutators}
        />
      </div>
    </>
  );
}
