import _ from "underscore";
import __ from "lodash";
import setFieldData from "final-form-set-field-data";

import {
  makeid,
  getFileType,
  isUndefinedOrNull,
  getUUID,
  cloneConfiguration,
  initializeEmptyProperty,
  prepareIdForAdding,
  createDeleteConfig,
  filterNull,
  createConfiguration,
} from "../utils";

import { deleteImageFromConfigs, addImageToConfigs } from "../photos-helper";

import {
  convertGQLToFormWarehouse,
  convertGQLToFormConfig,
  convertGQLToFormWarehouseConfig,
  convertFormToGQLWarehouse,
  convertFormToGQLConfig,
  convertFormToGQLWarehouseConfig,
  isSharedProperty,
  convertFormToGQLListingExtra,
  convertGQLToFormListingExtra,
} from "./helpers";
import { propertyLevelName } from "services/mutatorHelper";

import arrayMutators from "final-form-arrays";

import { EntityType } from "enums/entityType";

const initProperties = (properties) => {
  if (properties == null) return [];

  let clonedProperties = __.cloneDeep(properties);

  clonedProperties?.forEach((property, index) => {
    let { configs, photos, floorplans } = property;

    convertGQLToFormWarehouse(property);

    configs?.forEach((config, index) => {
      const { listings } = config;

      convertGQLToFormConfig(config);
      convertGQLToFormWarehouseConfig(config);

      if (config.listings?.extras) {
        config.listings.extras = convertGQLToFormListingExtra(listings?.extras);
      }

      configs[index] = filterNull(config);
    });

    clonedProperties.configs =
      configs?.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      ) ?? [];
    clonedProperties.photos = handleImageOnInit(photos);
    clonedProperties.photos = handleImageOnInit(floorplans);
    clonedProperties[index] = filterNull(property);
  });

  return clonedProperties.sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at)
  );
};

const preSaveProperties = (properties) => {
  let cloneProperties = __.cloneDeep(properties);

  cloneProperties?.forEach((property) => {
    convertFormToGQLWarehouse(property);

    property.property_id = prepareIdForAdding(property.property_id);

    property.configs?.forEach((config) => {
      config.config_id = prepareIdForAdding(config.config_id);

      convertFormToGQLConfig(config);
      convertFormToGQLWarehouseConfig(config);

      !isSharedProperty(config.warehouse_type) && delete config.warehouse_usage;

      delete config.matchable;
      delete config.listable;
      delete config.created_at;

      const listingExtras = config.listings?.extras;

      if (listingExtras) {
        config.listings.extras = convertFormToGQLListingExtra(listingExtras);
      }
    });

    property.exclusives = property.exclusives?.map((ex) => ({
      ...ex,
      property_id: property.property_id,
    }));

    delete property.created_at;
  });

  return cloneProperties;
};

const handleImageOnInit = (imageArray) => {
  let image = [];
  if (imageArray) {
    image = imageArray.map((p) => ({
      ...p,
      key: p.private_handle,
    }));
  }
  return image;
};

const mutators = {
  setSquareFootageMax: (args, state, utils) => {
    const { value, propertyIndex, configIndex } = args[0];
    utils.changeValue(
      state,
      propertyLevelName(
        propertyIndex,
        `configs[${configIndex}].square_footage_max`
      ),
      () => value
    );
  },
  setPropertyExclusivity: (args, state, utils) => {
    utils.changeValue(state, `exclusives[${args[0]}]`, () => args[0]);
  },
  setConfigName: (args, state, utils) => {
    const { name, propertyIndex, configIndex } = args[0];

    utils.changeValue(
      state,
      propertyLevelName(propertyIndex, `configs[${configIndex}].name`),
      () => name
    );
  },
  addProperty: (args, state, utils) => {
    const propertyIndex = state.formState.values.properties.length;
    const newConfigId = -Math.abs(makeid(10));
    const defaultFailedReasons = args[0];

    const property = initializeEmptyProperty(newConfigId, defaultFailedReasons);

    utils.changeValue(state, `properties[${propertyIndex}]`, () => property);
  },
  deleteProperty: (args, state, utils) => {
    const { propertyId, setDeleteEntities } = args[0];

    const initialProperties = state.formState.values.properties;

    const properties = initialProperties.filter(
      (property) => property.property_id !== propertyId
    );

    setDeleteEntities([createDeleteConfig(propertyId, EntityType.PROPERTY)]);

    utils.changeValue(state, `properties`, () => properties);
  },
  addConfiguration: (args, state, utils) => {
    const { propertyIndex, name, cloneId, failedReasons } = args[0];
    let newConfigId = -Math.abs(makeid(10));
    const configsLength =
      state.formState.values.properties[propertyIndex].configs.length;

    let newConfig = createConfiguration(name, newConfigId, failedReasons);

    if (cloneId != null) {
      const cloneConfig = state.formState.values.properties[
        propertyIndex
      ].configs.find((data) => data.config_id === cloneId);
      newConfig = cloneConfiguration(cloneConfig, name, newConfigId);
    } else {
      newConfig.listings.photos = state.formState.values.properties[
        propertyIndex
      ].photos?.map((photo) => photo.handle);
      newConfig.listings.floorplans = state.formState.values.properties[
        propertyIndex
      ].floorplans?.map((floorplan) => floorplan.handle);
    }

    utils.changeValue(
      state,
      propertyLevelName(propertyIndex, `configs[${configsLength}]`),
      () => newConfig
    );
  },
  deleteConfig: (args, state, utils) => {
    const { propertyIndex, configIndex, setDeleteEntities } = args[0];
    const property = state.formState.values.properties[propertyIndex];
    const config_id = property.configs[configIndex].config_id;

    setDeleteEntities([
      createDeleteConfig(config_id, EntityType.PROPERTY_CONFIG),
    ]);

    const configs = property.configs.filter(
      (configs) => configs.config_id !== config_id
    );

    utils.changeValue(
      state,
      propertyLevelName(propertyIndex, `configs`),
      () => configs
    );
  },
  setTermMax: (args, state, utils) => {
    let propertyId = args[0];
    let termMax = args[1];
    let currentConfigIndex = args[2];
    utils.changeValue(
      state,
      `properties[${propertyId}].configs[${currentConfigIndex}].term_max`,
      () => termMax
    );
  },
  addImages: (args, state, utils) => {
    const propertyId = args[0];
    const imageTypes = args[1];
    let files = args[2];
    const documentType = args[3];
    const ownerTableType = args[4];

    const selectedImages =
      state.formState.values.properties[propertyId][imageTypes];

    let images = [];
    if (!isUndefinedOrNull(selectedImages)) {
      images = [...selectedImages];
    }

    const psLength = images.length;

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      // build files state
      images.push({
        key: getUUID(), // key is just for FE list item
        url: URL.createObjectURL(file),
        document_id: null,
        document_type: documentType,
        file_type: getFileType(file.type),
        handle: null,
        handle_public: null,
        owner_table: ownerTableType,
        owner_id: null, // null for now, it'll be filled in later, TODO: or how about now? Or handle in BE always? (cover cases of new prop or existing prop)
        seq: psLength + i, // state length + i
        description: "",
        file: file,
      });
    }

    utils.changeValue(
      state,
      propertyLevelName(propertyId, imageTypes),
      () => images
    );
  },
  checkImages: (args, state, utils) => {
    const propertyId = args[0];
    const currentConfigID = args[1];
    const imageType = args[2];
    const selectedImageId = args[3];
    const checked = args[4];

    const property = state.formState.values.properties[propertyId];
    const currentIndex = property.configs.findIndex(
      (config) => config.config_id === currentConfigID
    );

    let imageIds = __.cloneDeep(
      property.configs[currentIndex].listings[imageType]
    );

    if (isUndefinedOrNull(imageIds)) {
      imageIds = [];
    }

    if (imageIds.includes(selectedImageId)) {
      if (!checked) {
        let i = _.findIndex(imageIds, (v) => v === selectedImageId);
        imageIds.splice(i, 1);
      }
    } else {
      if (checked) {
        imageIds.push(selectedImageId);
      }
    }

    utils.changeValue(
      state,
      propertyLevelName(
        propertyId,
        `configs[${currentIndex}].listings.${imageType}`
      ),
      () => imageIds
    );
  },
  moveImages: (args, state, utils) => {
    const propertyId = args[0];
    const sourceIndex = args[1];
    const destinationIndex = args[2];
    const imageType = args[3];

    let images = __.cloneDeep(
      state.formState.values.properties[propertyId][imageType]
    );
    let item = __.cloneDeep(images[sourceIndex]);
    images.splice(sourceIndex, 1);
    images.splice(destinationIndex, 0, item);

    utils.changeValue(
      state,
      propertyLevelName(propertyId, imageType),
      () => images
    );
  },
  deleteImages: (args, state, utils) => {
    const propertyId = args[0];
    const imageType = args[1];
    const deleteIndex = args[2];
    const imageId = args[3];

    let images = [...state.formState.values.properties[propertyId][imageType]];
    images.splice(deleteIndex, 1);

    const configs = deleteImageFromConfigs(
      state.formState.values.properties[propertyId].configs,
      imageId,
      imageType
    );

    utils.changeValue(
      state,
      propertyLevelName(propertyId, imageType),
      () => images
    );
    utils.changeValue(
      state,
      propertyLevelName(propertyId, "configs"),
      () => configs
    );
  },
  updateImageHandle: (args, state, utils) => {
    const propertyIndex = args[0];
    const imageType = args[1];
    const index = args[2];
    const handle = args[3];
    const isPublic = args[4];

    let images = __.cloneDeep(
      state.formState.values.properties[propertyIndex][imageType]
    );
    const selectedImage = images[index];
    if (isPublic) {
      selectedImage.handle_public = handle;
    } else {
      selectedImage.handle = handle;
    }

    if (
      !isUndefinedOrNull(selectedImage.handle) &&
      !isUndefinedOrNull(selectedImage.handle_public)
    ) {
      delete selectedImage.file;
    }

    const configs = addImageToConfigs(
      state.formState.values.properties[propertyIndex].configs,
      handle,
      imageType
    );

    utils.changeValue(
      state,
      propertyLevelName(propertyIndex, imageType),
      () => images
    );
    utils.changeValue(
      state,
      propertyLevelName(propertyIndex, "configs"),
      () => configs
    );
  },
  setFieldData: (args, state, utils) => {
    setFieldData(args, state, utils);
  },
  updateAddress: (args, state, utils) => {
    //Updates all values for Location on the property form
    let streetAddress = args[1].streetAddress;
    let city = args[1].city;
    let postalCode = args[1].postalCode;
    let country = args[1].country;
    let province = args[1].province;
    let lat = args[1].lat;
    let long = args[1].long;
    let property = __.cloneDeep(state.formState.values.properties[args[0]]);

    property.address = streetAddress;
    property.city = city;
    property.postal = postalCode;
    property.province = province;
    property.cache_lat = lat;
    property.cache_long = long;
    property.country = country;

    utils.changeValue(state, `properties[${args[0]}]`, () => property);
  },
  clearUnit: (args, state, utils) => {
    utils.changeValue(state, `properties[${args[0]}].unit`, () => null);
  },
  ...arrayMutators,
};

export { mutators, initProperties, preSaveProperties };
