import { GridSection } from "components/CardComponents";
import Condition from "components/Forms/Condition";
import CurrencyInput from "components/Forms/CurrencyInput";
import DateInput from "components/Forms/DateInput";
import DropdownInput from "components/Forms/DropdownInput";
import NumberInput from "components/Forms/NumberInput";
import RadioButtonInput from "components/Forms/RadioButtonInput";
import BaseTenantSectionProps from "interfaces/baseTenantSectionProps.interface";
import { EnumTermShort } from "services/enums";

export default function WarehouseRequestDetails({
  requestName,
  dealId,
}: BaseTenantSectionProps & { dealId?: number }): JSX.Element {
  const filteredTermOptions = !dealId
    ? EnumTermShort
    : EnumTermShort.filter((term) => term.value !== "TRM_3M");

  return (
    <GridSection
      title="Warehouse Request Details"
      md={12}
      xs={12}
      paperClassName="pb-16"
    >
      <CurrencyInput
        name={`${requestName}.budget`}
        label={`Budget`}
        isDollarScale
        isSideNote
      />
      <RadioButtonInput
        name={`${requestName}.term_type`}
        sideNoteNamespace={`${requestName}.term`}
        label={`Term`}
        isSideNote
        options={[
          { value: "1", name: "Preferred Term" },
          { value: "2", name: "Range" },
        ]}
      />

      <Condition when={`${requestName}.term_type`} is={"1"}>
        <DropdownInput
          name={`${requestName}.term_min`}
          label={``}
          options={filteredTermOptions}
          indent={2}
        />
      </Condition>

      <Condition when={`${requestName}.term_type`} is={"2"}>
        <DropdownInput
          name={`${requestName}.term_min`}
          label={`Min`}
          options={filteredTermOptions}
          indent={2}
        />
        <DropdownInput
          name={`${requestName}.term_max`}
          label={`Max`}
          options={filteredTermOptions}
          indent={2}
        />
      </Condition>

      <RadioButtonInput
        name={`${requestName}.movein_type`}
        sideNoteNamespace={`${requestName}.movein`}
        label={`Ideal Move In Date`}
        defaultValue="1"
        isSideNote
        options={[
          { value: "1", name: "Immediate" },
          { value: "2", name: "Specific Date" },
        ]}
      />

      <Condition when={`${requestName}.movein_type`} is={"2"}>
        <DateInput name={`${requestName}.movein`} label={``} />
      </Condition>

      <NumberInput
        name={`${requestName}.movein_notice`}
        label={"Days of notice guest requires"}
      />
    </GridSection>
  );
}
