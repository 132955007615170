import {
  ListingConfigInfo,
  ListingStatusDictionary,
} from "interfaces/listingPromptService.interface";
import { Property } from "interfaces/property";

export const setListableConfigStatus = (
  orgID: number | null,
  isListableConfigs: Property[]
): void => {
  if (orgID) {
    sessionStorage.setItem(
      `${orgID}-unlisted-alert`,
      JSON.stringify(createisListableConfigs(isListableConfigs, true))
    );
  }
};

export const clearListableConfigStatus = (orgID: number | null): void => {
  sessionStorage.removeItem(`${orgID}-unlisted-alert`);
};

const createisListableConfigs = (
  properties: Property[],
  searchIsListable: boolean
) => {
  const isListableProperties: ListingStatusDictionary = {};
  properties?.forEach((property) => {
    const listingConfigs: ListingConfigInfo[] = [];
    property.configs?.forEach((config) => {
      const configID = config.config_id.toString();

      const searchCondition = searchIsListable
        ? config.listable?.result
        : !config.listable?.result;

      if (searchCondition && !configID?.includes("new"))
        listingConfigs.push({ configID, name: config.name });
    });

    if (listingConfigs?.length > 0) {
      isListableProperties[property.property_id] = {
        propertyName: property.address,
        listingConfigs,
      };
    }
  });

  return isListableProperties;
};

export const checkListingStatus = (
  orgID: number,
  properties: Property[]
): ListingStatusDictionary => {
  const listingStatusDictionary = sessionStorage.getItem(
    `${orgID}-unlisted-alert`
  );
  const isListableConfigs = createisListableConfigs(properties, false);
  const statusList: ListingStatusDictionary = {};

  if (listingStatusDictionary) {
    for (const [propertyID, isListablePrevious] of Object.entries(
      JSON.parse(listingStatusDictionary) as ListingStatusDictionary
    )) {
      const isListablePreviousList = isListablePrevious?.listingConfigs;
      const configsWithErrors: ListingConfigInfo[] = [];
      const isListableCurrent = isListableConfigs[propertyID];
      const isListableList = isListableCurrent?.listingConfigs;

      if (isListableList != null) {
        isListablePreviousList?.forEach((listing) => {
          const previousConfigID = listing.configID as string;
          const matchedConfig = isListableList.find(
            (config) => config.configID === previousConfigID
          );

          if (matchedConfig) {
            configsWithErrors.push(matchedConfig);
          }
        });
      }
      if (configsWithErrors?.length > 0) {
        statusList[propertyID] = {
          propertyName: isListableCurrent.propertyName,
          listingConfigs: configsWithErrors,
        };
      }
    }
  }

  return statusList;
};
