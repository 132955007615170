import { FormContextInterface } from "interfaces/formContext";
import { createContext } from "react";
import { FormCommand, OrganizationChildrenTypes } from "enums";

const FormContext = createContext<FormContextInterface>({
  formCommand: FormCommand.IDLE,
  setFormCommand: () => {},
  propertyLength: null,
  requestLength: null,
  setShouldScrollWithinView: () => {},
  shouldScrollWithinView: {
    name: null,
    offset: 0,
  },
  setModalChild: () => {},
  setOpenModal: () => {},
  organizationData: { name: "", pipedrive_id: undefined, org_id: undefined },
  setDeleteEntities: (id, any) => {},
  currentFormType: OrganizationChildrenTypes.Property,
  setType: () => {},
  isSelfListingConvertAllowed: false,
});

export default FormContext;
