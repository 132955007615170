import React, { ReactNode } from "react";
import Tooltip from "./Tooltip";

interface Props {
  // Section title.
  title: string;
  // Small desciption of the section, which will appear under the title.
  description?: string;
  // Whether or not to show a tooltip for additional information/help.
  showTooltip: boolean;
  // Text to show in the tooltip.
  // This only takes effect when `showTooltip` is set to `true`.
  // The nuances of the `Tooltip` component apply - if the help/info text is
  // empty, the tooltip is not rendered even if `showTooltip` is true.
  tooltipText: string;
  children?: ReactNode;
}

// Section header.
// See SectionHeader Props for more detailed information.
//
// This component also accepts and renders its children. It is advisable to
// provide a single child node since this component does NO work to properly
// style the children.
const SectionHeader: React.FC<Props> = ({
  title,
  description,
  showTooltip,
  tooltipText,
  children,
}) => {
  return (
    <div className="font-display bg-gray-100 flex flex-row my-2">
      <div className="bg-yellow-600 w-2 mr-3" />

      <div className="flex flex-col w-full">
        <div className="flex justify-between">
          <div className="p-2">
            <h1 className="page-header font-bold text-yellow-600">{title}</h1>
          </div>
          <div className="flex ">
            {children}
            <div className="flex flex-end p-1 items-center mr-3 justify-between">
              {showTooltip && <Tooltip text={tooltipText} />}
            </div>
          </div>
        </div>

        {description !== undefined && description !== "" && (
          <p className="p-3">{description}</p>
        )}
      </div>
    </div>
  );
};

export default SectionHeader;
