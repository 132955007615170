import { IconId } from "../Icon";

export const quickReturnClass = " text-lg flex justify-around p-2 ";

// Cat is a match category (an attribute used in the match algo).
type Cat =
  | "247access"
  | "securability"
  | "office_space"
  | "pets"
  | "accessibility"
  | "meeting_rooms"
  | "furnished"
  | "restricted_usage"
  | "power_capacity"
  | "temperature"
  | "ceiling_clearance"
  | "price"
  | "size"
  | "location"
  | "term"
  | "availability"
  | "type"
  | "loading_bays";

// Attr is an attribute of a property/request. It is used in the property/guest
// needs summary.
type Attr =
  | "247access"
  | "securability"
  | "office_space"
  | "3_phase_power"
  | "53_foot_trailers"
  | "temperature"
  | "ceiling_clearance"
  | "power_capacity"
  | "type"
  | "restricted_usage"
  | "loading_bays";

// Meta is metadata about a category/attribute.
interface Meta {
  // display text
  display: string;
  // corresponding icon
  iconId: IconId;
}

export const matchCategories: Readonly<Record<Cat, Meta>> = {
  "247access": {
    display: "24/7 Access",
    iconId: "attr_247access",
  },
  securability: {
    display: "Securability",
    iconId: "attr_securability",
  },
  office_space: {
    display: "Office Space",
    iconId: "attr_office_space",
  },
  pets: {
    display: "Pets",
    iconId: "attr_pets",
  },
  accessibility: {
    display: "Accessibility",
    iconId: "attr_accessibility",
  },
  meeting_rooms: {
    display: "Meeting Rooms",
    iconId: "attr_meeting_rooms",
  },
  furnished: {
    display: "Furniture",
    iconId: "attr_furnished",
  },
  restricted_usage: {
    display: "Usage Restrictions",
    iconId: "attr_restricted_usage",
  },
  power_capacity: {
    display: "Power Capacity",
    iconId: "attr_power_capacity",
  },
  temperature: {
    display: "Temperature",
    iconId: "attr_temperature",
  },
  ceiling_clearance: {
    display: "Ceiling Clearance",
    iconId: "attr_ceiling_clearance",
  },
  loading_bays: {
    display: "Loading Bays",
    iconId: "attr_loading_bays",
  },
  price: {
    display: "Price",
    iconId: "attr_price",
  },
  size: {
    display: "Size",
    iconId: "attr_size",
  },
  location: {
    display: "Location",
    iconId: "attr_location",
  },
  term: {
    display: "Term",
    iconId: "attr_term",
  },
  availability: {
    display: "Availability",
    iconId: "attr_availability",
  },
  type: {
    display: "Space type",
    iconId: "attr_warehouse_type",
  },
};

export const attributes: Readonly<Record<Attr, Meta>> = {
  "247access": {
    display: "24/7 Access",
    iconId: "attr_247access",
  },
  securability: {
    display: "Securability",
    iconId: "attr_securability",
  },
  office_space: {
    display: "Office Space",
    iconId: "attr_office_space",
  },
  "53_foot_trailers": {
    display: "53 ft. Bays",
    iconId: "attr_53_foot_trailers",
  },
  "3_phase_power": {
    display: "3 Phase Power",
    iconId: "attr_3phase_power",
  },
  temperature: {
    display: "Temperature",
    iconId: "attr_temperature",
  },
  ceiling_clearance: {
    display: "Ceiling Clearance",
    iconId: "attr_ceiling_clearance",
  },
  power_capacity: {
    display: "Power Capacity",
    iconId: "attr_power_capacity",
  },
  type: {
    display: "Space Type",
    iconId: "attr_warehouse_type",
  },
  restricted_usage: {
    display: "Restricted Usage",
    iconId: "attr_restricted_usage",
  },
  loading_bays: {
    display: "Loading Bays",
    iconId: "attr_loading_bays",
  },
};
