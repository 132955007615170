import { useState, useEffect } from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  IconButton,
  Paper,
} from "@mui/material";
import { GridSection } from "components/CardComponents";
import DropdownInput from "components/Forms/DropdownInput";
import DateInput from "components/Forms/DateInput";
import TextAreaInput from "components/Forms/TextAreaInput";
import Icon from "components/Icon";

import { FieldArray } from "react-final-form-arrays";
import { EnumExclusives } from "services/enums";
import {
  PropertyExclusity,
  PropertyExclusivityProps,
} from "interfaces/propertyExclusivity";

export default function PropertyExclusivity({
  data,
  setPropertyExclusivity,
  isEditable,
  currentExclusiveName,
}: PropertyExclusivityProps): JSX.Element {
  const [isEdit, setEdit] = useState(false);
  const [isAdd, setAdd] = useState(false);
  const [initialValues, setInitialValues] = useState<PropertyExclusity[]>([]);

  const handleEditCancelClick = (deleteItem) => {
    setPropertyExclusivity(initialValues);
    if (isAdd) {
      deleteItem(data.length - 1);
      setAdd(false);
    }
    setEdit(false);
  };

  const handleAddClick = (pushExclusivity) => {
    setInitialValues(data);
    pushExclusivity({
      exclusivity: EnumExclusives[0].value,
      start: "",
      end: "",
      notes: "",
    });
    setEdit(true);
    setAdd(true);
  };

  const handleSaveClick = () => {
    setInitialValues(data);
    setEdit(false);
    setAdd(false);
  };

  const handleEditClick = () => {
    setInitialValues(data);
    setEdit(true);
  };

  const handleDeleteClick = (deleteItem) => {
    setInitialValues(initialValues);
    deleteItem();
  };

  useEffect(() => {
    if (isEdit && initialValues?.length === 0) {
      setInitialValues(data);
    }
  }, [isEdit, data, initialValues, isAdd]);

  const validatePropertyExclusivity = (
    propertyExclusivity: PropertyExclusity[]
  ) => {
    for (let indx1 = 0; indx1 < propertyExclusivity?.length; indx1++) {
      const exclusivity1 = propertyExclusivity[indx1];

      if (!exclusivity1.start || !exclusivity1.end) return "Dates invalid";

      for (let indx2 = 0; indx2 < propertyExclusivity.length; indx2++) {
        if (indx1 !== indx2) {
          const exclusivity2 = propertyExclusivity[indx2];
          if (
            exclusivity1.start <= exclusivity2.end &&
            exclusivity1.end >= exclusivity2.start
          ) {
            return `Dates ranges cannot overlap: row ${indx2 + 1}`;
          }
        }
      }
    }
  };

  const noData = data == null || data.length === 0;

  return (
    <GridSection md={12} xs={12}>
      <FieldArray
        name={currentExclusiveName}
        validate={validatePropertyExclusivity}
      >
        {({ fields, meta: { error } }) => {
          const hasError = error !== undefined;
          return (
            <>
              <div
                className="flex justify-between"
                style={{ paddingTop: "14px" }}
              >
                <Typography variant="h6">Exclusivity</Typography>
                {!isEdit ? (
                  <div>
                    <IconButton
                      onClick={() => handleAddClick(fields.push)}
                      disabled={!isEditable}
                      data-cy={`exclusivity-${currentExclusiveName}`}
                    >
                      <Icon
                        iconId="action_create"
                        state={!isEditable ? "disabled" : "green"}
                      />
                    </IconButton>
                    {!noData && (
                      <IconButton
                        onClick={handleEditClick}
                        disabled={!isEditable}
                      >
                        <Icon
                          iconId="action_edit"
                          state={!isEditable ? "disabled" : undefined}
                        />
                      </IconButton>
                    )}
                  </div>
                ) : (
                  <div>
                    <IconButton onClick={handleSaveClick} disabled={hasError}>
                      <Icon
                        iconId="action_save"
                        state={hasError ? "disabled" : "green"}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => handleEditCancelClick(fields.remove)}
                    >
                      <Icon iconId="action_cancel" state="error" />
                    </IconButton>
                  </div>
                )}
              </div>
              {noData ? (
                <Typography>No Property Exclusivity</Typography>
              ) : (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell className="min-w-xxs">Type</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fields.map((name, index) => (
                        <TableRow key={`exclusives${index}`}>
                          <TableCell>
                            <DropdownInput
                              name={`${name}.exclusivity`}
                              isReadOnly={!isEdit}
                              options={EnumExclusives}
                              noLabel
                              noNull={true}
                              overRideAutoInputSettings={true}
                              staticHoverText=""
                            />
                          </TableCell>
                          <TableCell>
                            <DateInput
                              name={`${name}.start`}
                              isReadOnly={!isEdit}
                              noLabel
                              max={data[index]?.end}
                              overRideAutoInputSettings={true}
                              staticHoverText=""
                            />
                          </TableCell>
                          <TableCell>
                            <DateInput
                              name={`${name}.end`}
                              isReadOnly={!isEdit}
                              noLabel
                              min={data[index]?.start}
                              overRideAutoInputSettings={true}
                              staticHoverText=""
                            />
                          </TableCell>
                          <TableCell>
                            <TextAreaInput
                              name={`${name}.notes`}
                              isReadOnly={!isEdit}
                              containerWidth={"100px"}
                              overRideAutoInputSettings={true}
                              staticHoverText=""
                              noLabel
                            />
                          </TableCell>
                          <TableCell align="right">
                            {!!isEditable && (
                              <IconButton
                                onClick={() =>
                                  handleDeleteClick(() => fields.remove(index))
                                }
                              >
                                <Icon iconId="action_delete" />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      {error && (
                        <TableRow key="error">
                          <TableCell colSpan={4}>
                            {
                              <span className="text-sm text-state-error">
                                {error}
                              </span>
                            }
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          );
        }}
      </FieldArray>
    </GridSection>
  );
}
