import { useState } from "react";
import { InputAdornment, SxProps, TextField, Theme } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

type Props = {
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
};

export default function Searchbar({ size, sx }: Props): JSX.Element {
  const [search, setSearch] = useState("");

  const onKeyPress = (e) => {
    if (e.keyCode === 13) {
      window.location.replace(`/search/${search}`);
    }
  };

  return (
    <TextField
      placeholder="search"
      variant="outlined"
      onChange={(event) => setSearch(event.target.value)}
      value={search}
      onKeyDown={onKeyPress}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      size={size}
      sx={sx}
    />
  );
}
