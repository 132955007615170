/*
 * Generic tag mutators which assume that a taggeble entity's tags are
 * represented as an array of Tags in the top level of the form values.
 */

import { Mutator } from "final-form";
import { Tag } from "../../graphql/types";

// Merge the given Tag arrays together in ascending order of id.
const merge = (a1: Tag[], a2: Tag[]): Tag[] => {
  const merged: Tag[] = [];
  let a1idx = 0;
  let a2idx = 0;

  while (a1idx < a1.length || a2idx < a2.length) {
    if (a1idx === a1.length) {
      merged.push(a2[a2idx]);
      a2idx++;
    } else if (a2idx === a2.length) {
      merged.push(a1[a1idx]);
      a1idx++;
    } else {
      if (a1[a1idx].id < a2[a2idx].id) {
        merged.push(a1[a1idx]);
        a1idx++;
      } else {
        merged.push(a2[a2idx]);
        a2idx++;
      }
    }
  }

  return merged;
};

interface FormValues {
  tags: Tag[];
  [key: string]: unknown;
}

export const addTags: Mutator<FormValues> = (args, state, tools) => {
  const toAdd: Tag[] = args[0];

  tools.changeValue(state, "tags", (tags: Tag[]) => merge(tags, toAdd));
};

export const delTags: Mutator<FormValues> = (args, state, tools) => {
  const toDelete: number[] = args[0];
  const toDeleteIds = new Map<number, boolean>();
  toDelete.forEach((id) => toDeleteIds.set(id, true));

  tools.changeValue(state, "tags", (tags: Tag[]) =>
    tags.filter((t) => !toDeleteIds.has(t.id))
  );
};
