import { GridSection } from "components/CardComponents";
import { Tab, Tabs } from "@mui/material";
import { FC, useMemo, useState } from "react";
import ManagementView from "./ManagementView";
import { RowsProps } from "interfaces/managementViewProps";
import { isEmptyString } from "services/utils";
import Swal from "sweetalert2";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { MARK_BULK_UPLOAD_AS_COMPLETE } from "graphql/mutations";
import { DEFAULT_ERROR_TIMER } from "services/constants";

const sortByDateDescending = (a: string, b: string) =>
  new Date(a ?? "").getTime() - new Date(b ?? "").getTime();

const ListingManagement: FC<{ listingManagementList: RowsProps[] }> = ({
  listingManagementList,
}): JSX.Element => {
  const [tabState, setTabState] = useState(0);
  const [listings, setListings] = useState<RowsProps[]>(
    listingManagementList ?? []
  );
  const handleTabChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setTabState(newValue);
  };

  const { unfinishedListings, uploadedListings } = useMemo(() => {
    const uploadedListings: RowsProps[] = [];
    const unfinishedListings: RowsProps[] = [];

    listings?.forEach((list) => {
      if (isEmptyString(list.completed_at)) {
        unfinishedListings.push(list);
      } else {
        uploadedListings.push(list);
      }
    });

    return {
      uploadedListings: uploadedListings.sort((a, b) =>
        sortByDateDescending(a.completed_at, b.completed_at)
      ),
      unfinishedListings: unfinishedListings.sort((a, b) =>
        sortByDateDescending(a.created_at, b.created_at)
      ),
    };
  }, [listings]);

  const [markAsComplete] = useMutation(MARK_BULK_UPLOAD_AS_COMPLETE);

  const completeBulkListing = async (e, id: string) => {
    const text = `Complete bulk listing for ${id}?`;
    // eslint-disable-next-line no-restricted-globals
    if (confirm(text) === true) {
      try {
        await markAsComplete({
          variables: {
            input: {
              upload_id: id,
            },
          },
        });
        const clonedListings = [...listings];
        const index = listings?.findIndex((list) => list.upload_id === id);

        clonedListings[index] = {
          ...clonedListings[index],
          completed_at: new Date().toString(),
        };

        setListings(clonedListings);

        Swal.fire({
          title: "Bulk listing completed",
          text: `Listing has been moved to completed`,
          icon: "success",
          toast: true,
          timer: DEFAULT_ERROR_TIMER,
          position: "bottom-right",
        });
      } catch (err) {
        Swal.fire({
          title: "Bulk listing completion error",
          text: `There was an issue marking your, ${err}`,
          icon: "error",
          toast: true,
          timer: DEFAULT_ERROR_TIMER,
          position: "bottom-right",
        });
      }
    }
  };

  return (
    <GridSection title="Listing Management" md={24}>
      <Tabs value={tabState} onChange={handleTabChange} variant="fullWidth">
        <Tab
          label={`Unfinished Bulk Uploads (${unfinishedListings?.length ?? 0})`}
          value={0}
          data-cy="bulk-upload-unfinished"
        />
        <Tab
          label={`Completed Bulk Uploads (${uploadedListings?.length ?? 0})`}
          value={1}
          data-cy="bulk-upload-finished"
        />
      </Tabs>
      {tabState === 0 && (
        <ManagementView
          showAction={true}
          rows={unfinishedListings}
          onCompletedAction={completeBulkListing}
        />
      )}
      {tabState === 1 && (
        <ManagementView
          showAction={false}
          showCompletedDate={true}
          rows={uploadedListings}
        />
      )}
    </GridSection>
  );
};

export default ListingManagement;
