import { UnlistedPromptMessageProps } from "interfaces/unlistedPromptMessge.interface";

export function UnlistedPromptMessage({
  listingConfigs,
}: UnlistedPromptMessageProps): JSX.Element {
  if (!listingConfigs) <></>;

  return (
    <>
      <div className="mb-2 font-bold">Configurations unpublished: </div>

      {listingConfigs &&
        Object.entries(listingConfigs).map((config, index) => {
          const configStatus = config[1].listingConfigs;
          const configLength = configStatus?.length;
          return (
            <div key={`property${index}}`}>
              <div className="ml-2">{`Property Address: ${config[1].propertyName}`}</div>
              {configLength > 1 && (
                <div className="ml-4">
                  <span>Configurations: </span>
                  {configStatus.map((status, index) =>
                    configLength - 1 === index
                      ? `${status.name}`
                      : `${status.name}, `
                  )}
                </div>
              )}
            </div>
          );
        })}
    </>
  );
}
