/*
 * CONFIG_MATCH_DATA_QUERY
 */

import { IconId } from "../Icon";

export interface PrimaryContact {
  firstname: string;
  lastname: string;
  title?: string;
  phone?: string;
  email?: string;
}

export interface PropertyData {
  attr_247_access?: boolean;
  attr_securable?: boolean;
  attr_office_space?: boolean;
  attr_3_phase_power?: boolean;
  attr_53_foot_trailers?: boolean;
  attr_truck_level_ex?: number; // ex = exclusive
  attr_truck_level_sh?: number; // sh = shared
  attr_drive_in_ex?: number; // ex = exclusive
  attr_drive_in_sh?: number; // sh = shared
  attr_temperature_ac?: boolean;
  attr_temperature_refrig?: boolean;
  attr_temperature_freeze?: boolean;
  attr_ceiling_clearance?: number;
  attr_electrical_amps?: number;
  attr_space_type?: string;
  attr_permitted_usage: number;
}

export interface ConfigMatchData {
  pipedrive_id?: number;
  pipedrive_org_id: number;
  property_id: number;
  config_id: number;
  address: string;
  city: string;
  postal: string;
  province: string;
  price: number;
  price_min: number;
  square_footage_min?: number;
  square_footage_max?: number;
  term_min?: string;
  term_max?: string;
  term_end?: string;
  availability?: Date;
  availability_notice?: number;
  loc_lat?: number;
  loc_lng?: number;
  config_data: PropertyData;
  primary_contact?: PrimaryContact;
}

export interface ConfigMatchDataResponse {
  configMatchData: ConfigMatchData;
}

export interface ConfigMatchDataRequest {
  configID: number;
}

/*
 * CONFIG_MATCH_CANDIDATES_QUERY & CONFIG_MATCH_DISQUALIFIED_QUERY
 */

export enum Category {
  Type = 1 << 0,
  Feature247 = 1 << 1,
  FeaturePets = 1 << 2,
  FeatureAccessible = 1 << 3,
  FeatureSecurable = 1 << 4,
  FeatureOffice = 1 << 5,
  MeetingRooms = 1 << 6,
  Furniture = 1 << 7,
  Usage = 1 << 8,
  Power = 1 << 9,
  Temperature = 1 << 10,
  Ceiling = 1 << 11,
  Loading = 1 << 12,
  Cost = 1 << 13,
  Size = 1 << 14,
  Location = 1 << 15,
  Term = 1 << 16,
  Availability = 1 << 17,
}

// An explicit list of all the enum values for easy iteration.
export const Categories: Readonly<Category[]> = [
  Category.Type,
  Category.Feature247,
  Category.FeaturePets,
  Category.FeatureAccessible,
  Category.FeatureSecurable,

  Category.FeatureOffice,
  Category.MeetingRooms,
  Category.Furniture,
  Category.Usage,
  Category.Power,
  Category.Temperature,
  Category.Ceiling,
  Category.Loading,
  Category.Cost,
  Category.Size,
  Category.Location,
  Category.Term,
  Category.Availability,
] as const;

// A subset of the categories which are used to form the score breakdown.
export const ScoreBreakdownCategories: Readonly<Category[]> = [
  Category.Cost,
  Category.Size,
  Category.Location,
  Category.Term,
  Category.Availability,
];

export interface MatchCat {
  iconId: IconId;
  name: string;
}

// These are the match categories.
export const matchCategories: Record<Category, MatchCat> = {
  [Category.Type]: { iconId: "attr_warehouse_type", name: "Space Type" },
  [Category.Feature247]: { iconId: "attr_247access", name: "24/7 Access" },
  [Category.FeaturePets]: { iconId: "attr_pets", name: "Pets" },
  [Category.FeatureAccessible]: {
    iconId: "attr_accessibility",
    name: "Accessibility",
  },
  [Category.FeatureSecurable]: {
    iconId: "attr_securability",
    name: "Securability",
  },
  [Category.FeatureOffice]: {
    iconId: "attr_office_space",
    name: "Office Space",
  },
  [Category.MeetingRooms]: {
    iconId: "attr_meeting_rooms",
    name: "Meeting Rooms",
  },
  [Category.Furniture]: { iconId: "attr_furnished", name: "Furniture" },
  [Category.Usage]: {
    iconId: "attr_restricted_usage",
    name: "Usage Restrictions",
  },
  [Category.Power]: { iconId: "attr_power_capacity", name: "Power" },
  [Category.Temperature]: { iconId: "attr_temperature", name: "Temperature" },
  [Category.Ceiling]: {
    iconId: "attr_ceiling_clearance",
    name: "Ceiling Clearance",
  },
  [Category.Loading]: { iconId: "attr_loading_bays", name: "Loading Bays" },
  [Category.Cost]: { iconId: "attr_price", name: "Cost" },
  [Category.Size]: { iconId: "attr_size", name: "Size" },
  [Category.Location]: { iconId: "attr_location", name: "Location" },
  [Category.Term]: { iconId: "attr_term", name: "Term" },
  [Category.Availability]: {
    iconId: "attr_availability",
    name: "Availability",
  },
} as const;

export interface ScoreWithMax {
  Score: number;
  MaxScore: number;
}

export interface LocationRationale {
  ThresholdGreat: number; // km distance threshold for Great score
  ThresholdOK: number; // km distance threshold for OK score
  ThresholdPoor: number; // km distance threshold for Poor score
  ThresholdZero: number; // km distance threshold for Zero score
  PropertyLoc: google.maps.LatLng; // Location of the property
  GuestLocs?: google.maps.LatLng[]; // Ideal locations for the guest
  ClosestLocIdx: number; // Index from GuestLocs that is closest to the property and was used for the score
}

export interface MatchResult {
  Score: number;
  Disqualified: number;
  Great: number;
  OK: number;
  Poor: number;
  Zero: number;
  SubScores: Record<Category, ScoreWithMax>;
  Rationale: Record<Category, string>;
  LocInfo: LocationRationale;
}

export interface RequestData {
  pipedrive_org_id: number;
  req_id: number;
  org_name: string;
  req_name?: string;
  square_footage_min?: number;
  square_footage_max?: number;
  budget?: number;
  term_min: string;
  term_max: string;
}

export interface ConfigMatchResult {
  entity_data: RequestData;
  match_result: MatchResult;
}

export interface ConfigMatchCandidatesResponse {
  configMatchCandidates?: {
    candidates: ConfigMatchResult[];
    total_records: number;
  };
}

export interface PaginationInput {
  index: number;
  size: number;
}

export interface ConfigMatchCandidatesRequest {
  configID: number;
  search?: string;
  pagination?: PaginationInput;
}

export interface ConfigMatchDisqualifiedResponse {
  configMatchDisqualified?: {
    disqualified: ConfigMatchResult[];
    total_records: number;
  };
}

export interface ConfigMatchDisqualifiedRequest {
  configID: number;
  search?: string;
  pagination?: PaginationInput;
}

export interface ConfigMatchUnifiedResponse {
  configMatchUnified?: {
    requests: ConfigMatchResult[];
    total_records: number;
  };
}

export interface ConfigMatchUnifiedRequest {
  configID: number;
  search?: string;
  pagination?: PaginationInput;
  mode: string;
  ignoreDq: number;
}

/*
 * REQ_MATCH_DATA_QUERY
 */

export interface Needs {
  attr_247_access?: boolean;
  attr_securable?: boolean;
  attr_office_space?: boolean;
  attr_3_phase_power?: boolean;

  attr_bays?: number;
  attr_bays_docks?: boolean;
  attr_bays_drive_in?: boolean;
  attr_bays_53_trailers?: boolean;
  attr_bays_ex?: boolean;

  attr_temperature_ac?: boolean;
  attr_temperature_refrig?: boolean;
  attr_temperature_freeze?: boolean;
  attr_ceiling_clearance?: number;
  attr_electrical_amps?: number;
  attr_space_type: string[];
  attr_locations?: google.maps.LatLng[];
  attr_usage: number;
}

export interface ReqMatchData {
  pipedrive_org_id: number;
  req_id: number;
  org_name: string;
  req_name?: string;
  pipedrive_id?: number;
  market: string;
  square_footage_min?: number;
  square_footage_max?: number;
  budget?: number;
  term_min?: string;
  term_max?: string;
  movein?: Date;
  movein_notice?: number;
  needs: Needs;
  primary_contact?: PrimaryContact;
}

export interface ReqMatchDataResponse {
  requestMatchData?: ReqMatchData;
}

export interface ReqMatchDataRequest {
  reqID: number;
}

export interface ConfigData {
  pipedrive_org_id: number;
  property_id: number;
  config_id: number;
  num_configs: number;
  org_name: string;
  address: string;
  config_name?: string;
  square_footage_min?: number;
  square_footage_max?: number;
  price?: number;
  price_min?: number;
  availability?: Date;
  availability_notice?: number;
}

export interface RequestMatchResult {
  entity_data: ConfigData;
  match_result: MatchResult;
}

export interface RequestMatchCandidatesResponse {
  requestMatchCandidates?: {
    candidates: RequestMatchResult[];
    total_records: number;
  };
}

export interface RequestMatchCandidatesRequest {
  reqID: number;
  search?: string;
  pagination?: PaginationInput;
}

export interface RequestMatchDisqualifiedResponse {
  requestMatchDisqualified?: {
    disqualified: RequestMatchResult[];
    total_records: number;
  };
}

export interface RequestMatchDisqualifiedRequest {
  reqID: number;
  search?: string;
  pagination?: PaginationInput;
}

export interface RequestMatchUnifiedResponse {
  requestMatchUnified?: {
    configs: RequestMatchResult[];
    total_records: number;
  };
}

export interface RequestMatchUnifiedRequest {
  reqID: number;
  search?: string;
  pagination?: PaginationInput;
  mode: string;
  ignoreDq: number;
}
