import Swal, { SweetAlertIcon } from "sweetalert2";
import { DEFAULT_ERROR_TIMER, DEFAULT_SUCCESS_TIMER } from "./constants";

export const triggerAlert = (
  icon: SweetAlertIcon,
  title?: string,
  message?: string
): void => {
  const timer = icon === "error" ? DEFAULT_ERROR_TIMER : DEFAULT_SUCCESS_TIMER;

  Swal.fire({
    title,
    text: message,
    icon,
    toast: true,
    timer,
    position: "bottom-right",
  });
};
