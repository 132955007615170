import { GridSection } from "components/CardComponents";
import NumberInput from "components/Forms/NumberInput";
import TextAreaInput from "components/Forms/TextAreaInput";
import DropdownInput from "components/Forms/DropdownInput";
import RadioButtonInput from "components/Forms/RadioButtonInput";
import DateInput from "components/Forms/DateInput";
import Condition from "components/Forms/Condition";

import { EnumTermShort, EnumTerm } from "services/enums";
import { CardSectionDefaultProps } from "interfaces/cardSection";

export default function Term({
  configName,
}: CardSectionDefaultProps): JSX.Element {
  return (
    <GridSection title="Term" md={4} xs={6}>
      <RadioButtonInput
        name={`${configName}.availability_type`}
        label={`Availability`}
        options={[
          { value: "1", name: "Immediate" },
          { value: "2", name: "Specific Date" },
        ]}
        defaultValue="1"
      />

      <Condition when={`${configName}.availability_type`} is={"2"}>
        <DateInput name={`${configName}.availability`} label={``} />
      </Condition>

      <NumberInput
        name={`${configName}.availability_notice`}
        label={"Days of notice host requires"}
      />

      <RadioButtonInput
        name={`${configName}.term_type`}
        label={`Term`}
        options={[
          { value: "1", name: "Preferred Term" },
          { value: "2", name: "Range" },
        ]}
      />

      <Condition when={`${configName}.term_type`} is={"1"}>
        <DropdownInput
          name={`${configName}.term_min`}
          label={``}
          options={EnumTermShort}
          indent={2}
        />
      </Condition>

      <Condition when={`${configName}.term_type`} is={"2"}>
        <DropdownInput
          name={`${configName}.term_min`}
          label={`Min`}
          options={EnumTermShort}
          indent={2}
        />

        <DropdownInput
          name={`${configName}.term_max`}
          label={`Max`}
          options={EnumTerm}
          indent={2}
        />

        <Condition when={`${configName}.term_max`} is={"TRM_DATE"}>
          <DateInput
            name={`${configName}.term_end`}
            label={`Term End Date`}
            indent={4}
          />
        </Condition>
      </Condition>

      <TextAreaInput name={`${configName}.term_nb`} label={`Notes`} />
    </GridSection>
  );
}
