import { useMutation } from "@apollo/client";
import {
  Settings as SettingsIcon,
  Check as CheckIcon,
  Warning as ErrorIcon,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import ProviderOptions from "./ProviderOptions";
import { PUBLISH, UNPUBLISH } from "graphql/mutations";
import { PublishOptionProps } from "interfaces/publishOptions.interface";
import { EnumAutoListProvider } from "services/enums";

const iconStyles = {
  style: { marginTop: -2 },
  size: "small" as const,
};

const toolTipStyle = (horizontalOffset = 0, bottomOffset = -11) => ({
  placement: "top-start" as const,
  arrow: true,
  PopperProps: {
    popperOptions: {
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [horizontalOffset, bottomOffset],
          },
        },
      ],
    },
  },
});

const defaultStyle = toolTipStyle();

export default function PublishOptions({
  provider,
  fieldName,
  isListable,
  configId,
  currentProviders,
  setCurrentProviders,
  extras,
}: PublishOptionProps): JSX.Element {
  const [isOpen, setOpen] = useState(false);
  const providerValue = provider?.value.toString();
  const isPublished = currentProviders?.includes(providerValue);

  const disableThirdPartyPublish = !currentProviders?.includes("LP_PLACEHOLDER") &&
    providerValue !== "LP_PLACEHOLDER" && !isPublished;

  const showPublishButton = EnumAutoListProvider.find((provider) => provider.value === providerValue);

  let manualPublishWarning: string|undefined;
  let listingLink: string|undefined;
  if (!showPublishButton) {
    if (extras)
      listingLink = extras[`ListingLink_${(providerValue || "").toLowerCase()}`];

    if (isPublished && !listingLink)
      manualPublishWarning = "Needs manual publish";
    else if (!isPublished &&  !!listingLink)
      manualPublishWarning = "Needs manual un-publish";
  }

  const [onChangePublishState, { loading }] = useMutation(
    isPublished ? UNPUBLISH : PUBLISH,
    {
      variables: {
        input: {
          config_id: configId,
          providers: [provider.value],
        },
      },
    }
  );

  const handlePublishChange = () => {
    onChangePublishState();

    const newProviderState = isPublished
      ? currentProviders.filter((current) => current !== providerValue)
      : [...currentProviders, providerValue];

    setCurrentProviders(newProviderState);
  };

  if (loading) return <></>;

  const statusIconProps = {
    titleAccess: (!isPublished ? "Not " : "") + "Published" +
      (manualPublishWarning ? " (manual update needed)" : ""),
    fontSize: "small",
    className: "mt-0.5 mr-1",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;

  return (
    <div className="flex mb-2">
      {!!manualPublishWarning
        ? <ErrorIcon {...statusIconProps} color={isPublished ? "warning" : "disabled"} />
        : <CheckIcon {...statusIconProps} color={isPublished ? "success" : "disabled"} />
      }
      <div className="text-base">{provider.name}</div>
      <div>
        <Tooltip title="Settings" {...defaultStyle}>
          <div>
            <IconButton
              {...iconStyles}
              onClick={() => setOpen(true)}
              disabled={!isListable}
              data-cy={`settings-${providerValue}-${fieldName}`}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
          </div>
        </Tooltip>
      </div>
      { showPublishButton &&
        <div>
          {disableThirdPartyPublish ? (
            <Tooltip title="An empire listing id is needed">
              <span>
                <Button
                  variant="contained"
                  size="small"
                  disabled={true}
                  data-cy={`publish-${providerValue}-${fieldName}`}
                >
                  {isPublished ? "Unpublish" : "Publish"}
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              variant="contained"
              size="small"
              onClick={() => handlePublishChange()}
              disabled={!isListable || disableThirdPartyPublish}
              data-cy={`publish-${providerValue}-${fieldName}`}
            >
              {isPublished ? "Unpublish" : "Publish"}
            </Button>
          )}
        </div>
      }
      {manualPublishWarning && (
        <div style={{display: "flex", alignItems: "center"}}>
          <strong>{"⚠️ " + manualPublishWarning}</strong>
        </div>
      )}
      <ProviderOptions
        provider={provider}
        fieldName={fieldName}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        isPublished={isPublished}
        listingLink={listingLink}
      />
    </div>
  );
}
