import React, { useState, useEffect, useContext, useCallback } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";

import { useFormScroll, useOrganizationParams, useTabTitle } from "hooks";
import { PD_RESOURCE_PRODUCT } from "services/constants";
import { FormContext, PropertiesContext } from "contexts";
import ToolTipIconButton from "components/ToolTipIconButton";
import AddConfiguration from "components/Forms/AddConfiguration";
import MatchControl from "../Forms/MatchControl";
import OpenInPipedrive from "../OpenInPipedrive";
import ListingIndicator from "../ListingIndicator";
import ModalToolTipButton from "../Forms/Properties/ModalToolTipButton";
import { useFormState } from "react-final-form";
import { OrganizationChildrenTypes } from "enums";
import { PRIMARY_COLOR } from "css.config";
import { OrganizationParamsResults } from "enums/organizationParamsResults";
import { formTreeStrings } from "services/strings";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${PRIMARY_COLOR};
  border-radius: 4px;
  margin-bottom: 5px;
  font-weight: bold;
  width: 100%;
`;
const Parent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5px;
  background-color: ${(props) => (props.selected ? PRIMARY_COLOR : "none")};
  color: ${(props) => (props.selected ? "black" : "black")};
  height: 35px;
  line-height: 35px;
`;
const Listing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 25px;
  background-color: ${(props) => (props.selected ? PRIMARY_COLOR : "none")};
  color: ${(props) => (props.selected ? "black" : "black")};
  height: 35px;
  line-height: 35px;
  cursor: pointer;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const SubTreeVerticalLine = styled.div`
  width: 20px;
  height: 35px;
  border-left: 1px solid black;
  margin-right: 10px;
`;
const SubTreeVerticalLineLast = styled.div`
  width: 20px;
  height: 15px;
  border-left: 1px solid black;
  margin-right: 10px;
`;
const SubTreeHorizontalLine = styled.div`
  width: 20px;
  height: 15px;
  border-bottom: 1px solid black;
`;
const SubRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default function FormTree({
  configs,
  parentID,
  onAddConfiguration,
  parentName,
  childNames,
  currentChildIndex,
  setCurrentChildIndex,
  setIsParent,
  isParent,
  type,
  deleteConfig,
  deleteParent,
  errors,
  matchability,
  containerId,
  isPropertyMatchable = false,
  entityLength = 0,
}) {
  const isRequest = type !== OrganizationChildrenTypes.Property;

  const [isConfigsVisible, setConfigVisible] = useState(isRequest);
  const [isNavigationComplete, setNavigationComplete] = useState(false);
  const { type: typeParam, id } = useOrganizationParams();
  const { invalid } = useFormState();
  const { setSelectedPropertyID, selectedPropertyID } =
    useContext(PropertiesContext);

  useFormScroll();

  const {
    setOpenModal,
    setModalChild,
    setShouldScrollWithinView,
    organizationData,
    setType,
    currentFormType,
  } = useContext(FormContext);

  useTabTitle(
    typeParam,
    organizationData.name,
    currentFormType === type ? parentName : "",
    selectedPropertyID === parentID
  );

  const hasGreaterThanOneConfig = childNames.length > 1;
  const configHasError = errors?.childHasErrors?.length > 0;
  const captionText = !isRequest ? "Property" : "Request";
  const captionTextLower = captionText.toLowerCase();
  const navigationParentText = !isRequest ? "properties" : "requests";

  // navigates user to property / config / request based on entity type
  useEffect(() => {
    if (typeParam === OrganizationParamsResults.ORGANIZATION) {
      if (entityLength === 1) {
        setSelectedPropertyID(parentID);
        setIsParent(true);

        if (configs?.length > 1) {
          setConfigVisible(true);
        }
      }
      setNavigationComplete(true);
      return;
    }

    let isConfigFound = false;
    let configIndex = 0;

    if (
      typeParam === OrganizationParamsResults.PROPERTY_CONFIG ||
      typeParam === OrganizationParamsResults.REQUEST
    ) {
      configIndex = configs?.findIndex((config) => config.config_id === id);
      isConfigFound = configIndex !== -1;
    }
    const isBaseIdFound = isRequest ? isConfigFound : parentID === id;

    if (!isBaseIdFound && !isConfigFound) {
      setNavigationComplete(true);
      return;
    }

    setType(
      OrganizationParamsResults.REQUEST === typeParam
        ? OrganizationChildrenTypes.TenantRequest
        : OrganizationChildrenTypes.Property
    );

    if (isConfigFound && hasGreaterThanOneConfig) {
      setCurrentChildIndex(configIndex);
      setConfigVisible(true);
    }

    if (
      !hasGreaterThanOneConfig ||
      typeParam === OrganizationParamsResults.PROPERTY
    ) {
      setIsParent(true);
      setCurrentChildIndex(0);
    }

    if (typeParam === OrganizationParamsResults.PROPERTY) {
      setSelectedPropertyID(id);
    }

    if (
      typeParam === OrganizationParamsResults.PROPERTY_CONFIG &&
      isConfigFound
    ) {
      setSelectedPropertyID(parentID);
    }

    setNavigationComplete(true);

    setShouldScrollWithinView({
      name: `${navigationParentText}[${containerId}]`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfigVisiblity = (event) => {
    event.stopPropagation();

    setConfigVisible(!isConfigsVisible);

    if (isConfigsVisible) {
      setCurrentChildIndex(null);
      setIsParent(false);
    }
  };

  const onParentSelect = () => {
    if (!isParent) {
      setSelectedPropertyID(parentID);
      setCurrentChildIndex(0);
      setIsParent(true);
    } else {
      resetFormTree();
      window.history.pushState(null, "", `/org/${organizationData.org_id}`);
    }
  };

  const resetFormTree = useCallback(() => {
    setIsParent(false);
    setConfigVisible(false);
    setCurrentChildIndex(null);
  }, [setCurrentChildIndex, setIsParent]);

  const onSelect = (index) => {
    setSelectedPropertyID(parentID);
    setCurrentChildIndex(index);
    setIsParent(false);
  };

  const handleAddConfiguration = (name, cloneId, existingName) => {
    onAddConfiguration(name, cloneId, existingName);
    setOpenModal(false);
    setConfigVisible(true);
    setIsParent(false);
  };

  const openConfigurationModal = (event) => {
    event.stopPropagation();

    setModalChild(
      <AddConfiguration
        title={"configuration"}
        values={configs?.map((data) => ({
          value: data.config_id,
          name: data.name,
        }))}
        onAddConfiguration={handleAddConfiguration}
        setOpenModal={setOpenModal}
        setConfigVisible={setConfigVisible}
        setIsParent={setIsParent}
      />
    );
    setOpenModal(true);
  };

  const getPropertyName = (propertyName, values, currentConfigIndex) => {
    if (values?.length > 1) {
      return propertyName + " / " + values[currentConfigIndex].name;
    } else {
      return propertyName;
    }
  };

  const doesConfigHaveError = (index) =>
    errors?.childHasErrors?.find((value) => value === index) != null;

  // allow only one property to be viewed at a time, navigate user to selected property
  useEffect(() => {
    if (isNavigationComplete) {
      if (selectedPropertyID !== parentID && !isRequest) {
        resetFormTree();
      } else if (parentID !== null) {
        setShouldScrollWithinView({
          name: `${navigationParentText}[${containerId}]`,
        });
      }
    }
  }, [
    currentChildIndex,
    parentID,
    selectedPropertyID,
    resetFormTree,
    isRequest,
    isNavigationComplete,
    setShouldScrollWithinView,
    navigationParentText,
    containerId,
  ]);

  useEffect(() => {
    if (isParent) setCurrentChildIndex(0);
  }, [isParent, setCurrentChildIndex]);

  useEffect(() => {
    if (configs?.length < 2 && isConfigsVisible) {
      setConfigVisible(false);
      setIsParent(true);
    }
  }, [isConfigsVisible, setIsParent, configs?.length, childNames]);

  useEffect(() => {
    const setUrl = (parentName, childName) => {
      const navigate = (url) => {
        if (window.location.pathname !== url) {
          window.history.pushState(null, "", url);
        }
      };

      if (isParent) {
        const url = `/${parentName}/${
          parentName === "property" ? parentID : childNames[0].id
        }`;
        navigate(url);
      } else if (currentChildIndex != null) {
        const url = `/${childName}/${childNames[currentChildIndex].id}`;
        navigate(url);
      }
    };

    if (
      isRequest &&
      currentFormType === OrganizationChildrenTypes.TenantRequest
    ) {
      setUrl("request", "request");
      return;
    }

    if (!isRequest && currentFormType === OrganizationChildrenTypes.Property) {
      setUrl("property", "config");
      return;
    }
  }, [
    childNames,
    currentChildIndex,
    currentFormType,
    isParent,
    isRequest,
    parentID,
  ]);

  const renderConfigLevelIcons = (isVisible, idx) => {
    return isVisible ? (
      !isRequest ? (
        <div className="flex">
          <MatchControl
            pathTo={`/match/config/${configs[currentChildIndex].config_id}`}
            matchability={matchability}
            type={type}
            datacy={`match-open-${type}${containerId}${idx}`}
          />
          <OpenInPipedrive
            resourceType={PD_RESOURCE_PRODUCT}
            prodPipedriveID={configs[currentChildIndex].pipedrive_product_id}
            orgPipedriveID={organizationData.pipedrive_id}
            orgName={organizationData.name}
            productName={getPropertyName(
              parentName,
              configs,
              currentChildIndex
            )}
            setOpenModal={setOpenModal}
            setModalChild={setModalChild}
            datacy={`pipedrive-open-${containerId}${idx}`}
          />
          <ListingIndicator
            listings={configs[currentChildIndex].listings?.providers}
            extra={configs[currentChildIndex].listings?.extras}
            datacy={`listing-${containerId}${idx}`}
          />
        </div>
      ) : (
        <MatchControl
          pathTo={`/match/request/${configs[currentChildIndex].config_id}`}
          isDisabled={configs ? !configs[currentChildIndex].active : false}
          type={type}
          matchability={matchability}
          datacy={`match-open-${type}${containerId}${idx}`}
        />
      )
    ) : (
      <></>
    );
  };

  const renderChilds = () => {
    return childNames.map((listing, idx) => {
      return (
        <Listing
          key={`${type}_${idx}`}
          data-cy={`config-open-${containerId}${idx}`}
          selected={currentChildIndex === idx && !isParent}
          onClick={() => onSelect(idx)}
        >
          <Row>
            {idx !== childNames.length - 1 && (
              <SubTreeVerticalLine>
                <SubTreeHorizontalLine />
              </SubTreeVerticalLine>
            )}
            {idx === childNames.length - 1 && (
              <SubTreeVerticalLineLast>
                <SubTreeHorizontalLine />
              </SubTreeVerticalLineLast>
            )}
            {`${listing.name}`}
            {doesConfigHaveError(idx) && (
              <ErrorIcon color="error" className="mt-2 ml-1" fontSize="small" />
            )}
          </Row>
          {currentChildIndex === idx && !isParent && (
            <SubRow style={{ marginTop: "-1px" }}>
              {renderConfigLevelIcons(currentChildIndex != null, idx)}
              {hasGreaterThanOneConfig && !isParent && (
                <ModalToolTipButton
                  onConfirm={deleteConfig}
                  modalOptions={{
                    title: "Delete Configuration",
                    message:
                      "Are you sure you want to delete this configuration? It cannot be undone.",
                  }}
                  toolTipOptions={{
                    iconId: "action_cancel",
                    title: "Delete Configuration",
                    message: "Click to delete this configuration.",
                    filter: "fill-current text-red-500 bg-white rounded-full",
                  }}
                  datacy={`delete-config-${containerId}${type}${idx}`}
                />
              )}
            </SubRow>
          )}
        </Listing>
      );
    });
  };

  return (
    <Container id={`${navigationParentText}[${containerId}]`}>
      <Parent
        onClick={!isRequest || configs.length === 1 ? onParentSelect : null}
        selected={isParent}
        data-cy={`open-property-${containerId}`}
      >
        <div>
          {parentName}
          {!isRequest && (
            <span className="ml-1 text-gray-500">
              {`(${
                isPropertyMatchable
                  ? configs?.length > 1
                    ? formTreeStrings.isMatchableWithMultipleConfigs
                    : formTreeStrings.isMatchable
                  : formTreeStrings.notMatchable
              })`}
            </span>
          )}
          {(errors.parentHasError || (configHasError && !isConfigsVisible)) && (
            <ErrorIcon color="error" className="ml-1 mb-0.5" fontSize="small" />
          )}
        </div>
        <div className="flex">
          {renderConfigLevelIcons(
            currentChildIndex != null && !hasGreaterThanOneConfig,
            0
          )}
          <ToolTipIconButton
            onClick={openConfigurationModal}
            iconId="action_create"
            state="green"
            filter="bg-white rounded-full"
            size="lg"
            title="Add Configuration"
            disabled={invalid}
            message={`Click to add a configuration to the ${captionTextLower}`}
            datacy={`add-config-${containerId}${type}`}
          />
          {(isParent || isRequest) && (
            <ModalToolTipButton
              onConfirm={deleteParent}
              modalOptions={{
                title: `Delete ${captionText}`,
                message: `Are you sure you want to delete this ${captionTextLower}? It cannot be undone.`,
              }}
              disabled={invalid && !errors.parentHasError}
              toolTipOptions={{
                iconId: "action_cancel",
                title: `Delete ${captionText}`,
                message: `Click to delete this ${captionTextLower}.`,
                filter: "fill-current text-red-500 bg-white rounded-full",
              }}
              datacy={`delete-property-${type}${containerId}`}
            />
          )}
          {hasGreaterThanOneConfig && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open configs"
              onClick={handleConfigVisiblity}
              size="small"
              data-cy={`open-configuration-${containerId}`}
            >
              {isConfigsVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </div>
      </Parent>

      {isConfigsVisible && renderChilds()}
    </Container>
  );
}
