import { EntityType } from "enums";
import { ChangeLogField } from "interfaces/changeLogs";
import { OrganizationFormValues } from "interfaces/organizationForm";

import {
  CLF_CONFIGSTATUS,
  CLF_LISTFEE,
  CLF_LISTFEEBASIS,
  REQUESTS_KEY,
  PROPERTIES_KEY,
  PROPERTY_LEVEL_FIELD_NAMES,
  PROPERTY_CONFIG_LEVEL_FIELD_NAMES,
} from "./constants";
import { isEmptyString } from "./utils";
import _ from "lodash";

const getChangeLogValues = (
  type: EntityType,
  parentIndex: string,
  fieldName: string,
  values: OrganizationFormValues,
  initialValues: OrganizationFormValues,
  childIndex?: string
): ChangeLogField | null => {
  const trackedFieldName = getTrackedFields(type, fieldName);

  if (!trackedFieldName) return null;

  const getValue = (searchData, fieldName) =>
    getFormValues(type, parentIndex, fieldName, searchData, childIndex);
  const idFieldName =
    type === EntityType.PROPERTY
      ? "property_id"
      : type === EntityType.PROPERTY_CONFIG
      ? "config_id"
      : type === EntityType.REQUEST
      ? "request_id"
      : null;

  const object_id = getValue(values, idFieldName);

  if (isEmptyString(object_id)) return null;

  return {
    old_value: getValue(initialValues, fieldName) ?? "",
    new_value: getValue(values, fieldName),
    object_id: getValue(values, idFieldName),
    field: trackedFieldName,
  };
};

const getFormValues = (
  type,
  parentIndex,
  fieldName,
  values,
  childIndex
): string => {
  const parentName =
    type === EntityType.PROPERTY || type === EntityType.PROPERTY_CONFIG
      ? PROPERTIES_KEY
      : REQUESTS_KEY;
  const parent = values[parentName][parentIndex];

  if (!parent) return "";

  switch (type) {
    case EntityType.PROPERTY:
      return parent[fieldName]?.toString() ?? "";
    case EntityType.PROPERTY_CONFIG:
    case EntityType.REQUEST:
      const config = parent?.configs?.[childIndex];
      if (startWithOneOf(fieldName, PROPERTY_CONFIG_LEVEL_FIELD_NAMES.TAGS) !== null)
        return config ? config.listings?.extras[fieldName]?.toString() ?? "" : "";
      return config ? config[fieldName]?.toString() ?? "" : "";
    default:
      return "NO_FIELD_NAME_FOUND";
  }
};

// Function to specify which field in the form corrisponds to what field key in the ChangelogField Table
const getTrackedFields = (entityType, fieldName) => {
  if (fieldName && entityType === EntityType.PROPERTY_CONFIG) {
    if (fieldName === PROPERTY_LEVEL_FIELD_NAMES.STATUS) {
      return CLF_CONFIGSTATUS;
    } else if (fieldName === PROPERTY_CONFIG_LEVEL_FIELD_NAMES.LIST_FEE) {
      return CLF_LISTFEE;
    } else if (fieldName === PROPERTY_CONFIG_LEVEL_FIELD_NAMES.LIST_FEE_BASIS) {
      return CLF_LISTFEEBASIS;
    } else {
      return startWithOneOf(fieldName, PROPERTY_CONFIG_LEVEL_FIELD_NAMES.TAGS);
    }
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const startWithOneOf = (str: string, obj: any ) => {
  const m = new Map<string, string>(Object.entries(obj));
  for (const item of m) {
    if (_.startsWith(str, item[0])) return item[1];
  }
  return null;
}

export { getChangeLogValues };
