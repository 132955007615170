import { PT_WAREHOUSE, TCB_SQFT } from "services/constants";
import { isUndefinedOrNull, isEmptyString } from "services/utils";
import { isCanadianMarket } from "services/utilities";

const required = (value) => {
  if (value === undefined || value === null || value === "") {
    return "Required";
  } else {
    return undefined;
  }
};
const requiredPostalCode = (value) => {
  let req = required(value);
  let post = postalCodeAbbrev(value);
  if (req !== undefined) {
    return req;
  } else {
    return post;
  }
};

// validate a url from youtube
const validateYouTubeUrl = (value) => {
  if (value) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
    var match = regExp.exec(value);
    if (match && match[2].length === 11) {
      return undefined;
    } else {
      return "Not a valid youtube url";
    }
  }
};

const positive = (value) => {
  if (value !== undefined && value < 0) {
    return "Must be > 0";
  } else {
    return undefined;
  }
};

const postalCodeAbbrev = (value) => {
  if (value === undefined || value === null || value === "") {
    return undefined;
  }

  const regex = /^[A-Za-z]\d[A-Za-z]([ ]?\d[A-Za-z]\d)?$/;
  let match = regex.exec(value.toUpperCase());
  if (match) {
    return undefined;
  } else {
    return "Invalid Postal Code";
  }
};

const postalCode = (value) => {
  if (value === undefined || value === null || value === "") {
    return undefined;
  }

  const regex =
    /(^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ][ ]?\d[ABCEGHJKLMNPRSTVWXYZ]\d$)/;
  let match = regex.exec(value.toUpperCase());
  if (match) {
    return undefined;
  } else {
    return "Invalid Postal Code";
  }
};

const requiredZipCode = (value) => {
  let req = required(value);
  let zip = zipCode(value);
  if (req !== undefined) {
    return req;
  } else {
    return zip;
  }
};

const zipCode = (value) => {
  if (value === undefined || value === null || value === "") {
    return undefined;
  }

  const regex = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;
  let match = regex.exec(value);
  if (match) {
    return undefined;
  } else {
    return "Invalid Zip Code";
  }
};

// setFormErr takes an errors object for react-final-form and a dot-notation
// path for a field in the form, as well as an error string to set for that
// field. It handles any undefined sub-objects and sets deep field errors as
// long as no arrays indices are involved in the field path!
const setFormErr = (errors, field, error) => {
  if (!errors) return;

  field = field.split(".");
  let i = 0;
  for (; i < field.length - 1; i++) {
    if (errors[field[i]] === undefined) errors[field[i]] = {};
    errors = errors[field[i]];
  }
  errors[field[i]] = error;
};

const validate = (
  values,
  requiredRequestFields,
  requiredPropertyFields,
  requiredConfigFields
) => {
  const errors = {
    properties: [],
    requests: [],
  };
  const propertyType = PT_WAREHOUSE;

  const { properties, requests } = values;

  properties?.forEach((propertyData, idx) => {
    let { configs } = propertyData;

    const propertyErrors = {
      ...validateObject(propertyData, requiredPropertyFields),
      configs: [],
    };

    // replace with app config validation
    if (
      isEmptyString(propertyData.lease_type)
    ) {
      propertyErrors.lease_type = "Required";
    }

    // validate each config
    configs?.forEach((config, idx) => {
      propertyErrors.configs[idx] = configFormValidator(
        propertyType,
        config,
        requiredConfigFields
      );
      if (config && config.square_footage_min && config) {
        if (config.square_footage_min > config.square_footage_max) {
          // error
          propertyErrors.configs[idx].square_footage_max =
            "Min value need to be less than max value.";
        }
        // maybe we don't need to worry about the max value here, because the max value is not updated in real time.
      }

      // TMI
      if (config.tmi_cost_basis && config.tmi_cost_basis === TCB_SQFT) {
        if (config) {
          if (isEmptyString(config.square_footage_min)) {
            if (!propertyErrors.configs[idx]) {
              propertyErrors.configs[idx] = {};
            }
            if (!propertyErrors.configs[idx]) {
              propertyErrors.configs[idx] = {};
            }
            propertyErrors.configs[idx].square_footage_min = "Required";
            propertyErrors.configs[idx].tmi_cost_basis = "Size is required";
          }
        } else {
          if (!propertyErrors.configs[idx]) {
            propertyErrors.configs[idx] = {};
          }
          if (!propertyErrors.configs[idx]) {
            propertyErrors.configs[idx] = {};
          }
          propertyErrors.configs[idx].square_footage_min = "Required";
          propertyErrors.configs[idx].tmi_cost_basis = "Size is required";
        }
      }

      if (config.office === true && config.is_office_size_included == null) {
        propertyErrors.configs[idx].is_office_size_included = "Required";
      }
    });

    validateAddress(propertyData, propertyErrors);

    errors.properties[idx] = propertyErrors;
  });

  errors.requests = validateFields(requests, requiredRequestFields);

  return errors;
};

const validateAddress = (propertyData, propertyErrors) => {
  if (propertyData.address_known == null) {
    propertyErrors.address_known = "Required";
  }

  if (propertyData.market != null) {
    const message = validatePostalOrZipCode(
      propertyData.postal,
      propertyData.market
    );
    propertyErrors.postal = message;
  }
};

const validateObject = (fields, fieldsToValidate) => {
  const errors = {};

  fieldsToValidate?.forEach((name) => {
    if (fields[name] == null || fields[name]?.length === 0) {
      errors[name] = "Required";
    }
  });

  return errors;
};

const validateFields = (fields, fieldsToValidate) => {
  const errors = [];
  const conditionalField = ["term_type"];

  fields?.forEach((request, index) => {
    const fieldErrors = {};

    const markFieldAsRequired = (name) => {
      if (
        (!conditionalField.includes(name) && request[name] == null) ||
        request[name]?.length === 0
      ) {
        fieldErrors[name] = "Required";
      } else if (conditionalField.includes(name) && request[name] != null) {
        if (request["term_max"] == null) {
          fieldErrors["term_max"] = "Required";
        }
        if (request["term_min"] == null) {
          fieldErrors["term_min"] = "Required";
        }
      }
    };

    fieldsToValidate?.forEach((field) => {
      markFieldAsRequired(field, request);
    });

    errors[index] = fieldErrors;
  });

  return errors;
};

const configFormValidator = (propertyType, config, configFields) => {
  let errors = {};

  if (
    config.has_range_of_space &&
    config.square_footage_min &&
    !config.square_footage_max
  ) {
    setFormErr(errors, "square_footage_max", "Required");
  }

  // listing title length
  if (config.title && config.title.length >= 65)
    setFormErr(errors, "title", "Title must be less than 65 characters");

  // Term validation
  if (config && config.term_type === "1" && config.term_min === undefined)
    setFormErr(errors, "term_min", "Required");

  if (config && config.term_type === "2") {
    if (config.term_min === undefined)
      setFormErr(errors, "term_min", "Required");

    if (config.term_max === undefined)
      setFormErr(errors, "term_max", "Required");

    if (!isValidTermRange(config.term_min, config.term_max)) {
      setFormErr(errors, "term_min", "Must be Min <= Max");
      setFormErr(errors, "term_max", "Must be Min <= Max");
    }
    if (config.term_max === "TRM_DATE" && !config.term_end)
      setFormErr(errors, "term_end", "Required");
  }

  // Price validation
  if (config && config.list_fee_basis) {
    if (
      propertyType === PT_WAREHOUSE &&
      (config.list_fee_basis === "LFB_SQFT_MO" ||
        config.list_fee_basis === "LFB_SQFT_YR") &&
      (!config || !config.square_footage_min)
    ) {
      setFormErr(errors, "list_fee_basis", "Size is required (Guest Space)");
      setFormErr(errors, "square_footage_min", "Required");
    }
  }

  // Ceiling Clearance
  if (propertyType === PT_WAREHOUSE && config) {
    if (
      config.ceiling_clearance_inches > 11 ||
      config.ceiling_clearance_inches < 0
    ) {
      setFormErr(errors, "ceiling_clearance_inches", "Invalid"); // Inches ceiling clearance must be < 12
    }
    if (config.ceiling_clearance_feet < 0) {
      setFormErr(errors, "ceiling_clearance_feet", "Invalid");
    }
  }

  configFields?.forEach((field) => {
    if (config[field] === undefined) {
      setFormErr(errors, field, "Required");
    }
  });

  return errors;
};

const isValidTermRange = (min, max) => {
  const dict = {
    TRM_MONTH2MONTH: 1,
    TRM_3M: 2,
    TRM_1Y: 3,
    TRM_2Y: 4,
    TRM_3Y: 5,
    TRM_5Y: 6,
    TRM_7Y: 7,
    TRM_MORE: 8,
    TRM_DATE: 9,
  };

  if (dict[min] > dict[max]) {
    return false;
  }

  return true;
};

const placeFeeWarning = (province, propertyType, listFeeBasis, listFee) => {
  let reasons = [];
  if (isUndefinedOrNull(province)) {
    reasons.push("Province");
  }

  if (isUndefinedOrNull(propertyType)) {
    reasons.push("Property Type");
  }

  if (isUndefinedOrNull(listFeeBasis)) {
    reasons.push("List Fee Basis");
  }

  if (isUndefinedOrNull(listFee) || isNaN(listFee)) {
    reasons.push("List Fee");
  }

  // // TODO: is furniture fee mandatory?
  // if (propertyType === PT_OFFICE && isUndefinedOrNull(furniture)) {
  //   reasons.push("Furniture Cost")
  // }

  if (reasons.length === 0) {
    return;
  }

  let str = "";
  reasons.forEach((r, idx) => {
    if (idx === reasons.length - 2) {
      str = str + r + " and ";
    } else if (idx === reasons.length - 1) {
      str = str + r;
    } else {
      str = str + r + ", ";
    }
  });

  return "Must specify " + str + " above";
};

const validatePostalOrZipCode = (value, market) => {
  if (isCanadianMarket(market)) {
    return requiredPostalCode(value);
  } else {
    return requiredZipCode(value);
  }
};

export {
  required,
  requiredPostalCode,
  positive,
  postalCode,
  zipCode,
  validateYouTubeUrl,
  requiredZipCode,
  configFormValidator,
  isValidTermRange,
  placeFeeWarning,
  validatePostalOrZipCode,
  validate,
};
