import React from "react";
import TooltipedIcon from "./TooltipedIcon";

import {
  PD_RESOURCE_ORGANIZATION,
  PD_RESOURCE_PRODUCT,
  PD_RESOURCE_PERSON,
} from "../services/constants";
import { Button } from "@mui/material";
import Icon from "./Icon";

interface Props {
  resourceType: string | undefined;
  orgName?: string;
  productName?: string;
  orgPipedriveID?: number;
  prodPipedriveID?: number;
  personPipedriveID?: number;
  style?: React.CSSProperties;
  setModalChild?: (child: JSX.Element) => void;
  setOpenModal?: (flag: boolean) => void;
  showIconButton?: boolean;
  index?: number;
  datacy?: string;
}

const OpenInPipedrive: React.FC<Props> = ({
  resourceType,
  orgName,
  productName,
  orgPipedriveID,
  prodPipedriveID,
  personPipedriveID,
  setModalChild,
  setOpenModal,
  showIconButton,
  index,
  datacy,
}) => {
  // Whether or not the component is enabled depends on if the pipedrive ID is
  // specified properly.
  // The PD_RESOURCE_PRODUCT resource type can use either the product or the org
  // pipedrive ids, while other resource types strictly use the corresponding
  // pipedrive ids.
  const componentEnabled =
    (resourceType === PD_RESOURCE_ORGANIZATION && orgPipedriveID) ||
    (resourceType === PD_RESOURCE_PRODUCT &&
      (prodPipedriveID || orgPipedriveID)) ||
    (resourceType === PD_RESOURCE_PERSON && personPipedriveID);

  const pdBaseURL = process.env.REACT_APP_PIPEDRIVE_DOMAIN;

  const buildOpenInPipedrive = (e) => {
    e.stopPropagation();
    if (!componentEnabled) return;

    let url = pdBaseURL;
    if (resourceType === PD_RESOURCE_ORGANIZATION) {
      url += "/organization/" + orgPipedriveID;
    } else if (resourceType === PD_RESOURCE_PRODUCT) {
      if (prodPipedriveID && !orgPipedriveID) {
        url += "/product/" + prodPipedriveID;
      } else if (!prodPipedriveID && orgPipedriveID) {
        url += "/organization/" + orgPipedriveID;
      } else {
        if (setModalChild && setOpenModal) {
          // for a product, there must be both ids are defined
          // otherwise componentEnabled flag would be false
          setModalChild(
            <div className="flex flex-col text-blue-600">
              <button className="std-button normal-case text-sm">
                <a
                  target="_pipedrive"
                  data-cy={`org-${datacy}`}
                  href={`${pdBaseURL}/organization/${orgPipedriveID}`}
                >
                  Organization {orgName ? `: ${orgName}` : ""}
                </a>
              </button>
              <br />
              <button className="std-button normal-case text-sm">
                <a
                  target="_pipedrive"
                  data-cy={`product-${datacy}`}
                  href={`${pdBaseURL}/product/${prodPipedriveID}`}
                >
                  Product {productName ? `: ${productName}` : ""}
                </a>
              </button>
            </div>
          );
          setOpenModal(true);
          return;
        }
      }
    } else if (resourceType === PD_RESOURCE_PERSON) {
      url += "/person/" + personPipedriveID;
    }

    window.open(url, "_pipedrive");
  };

  const buildTooptipContent = () => {
    return (
      <>
        <p className="text-base w-max text-lg font-medium">Pipedrive</p>
        <p>Click to open in Pipedrive.</p>
      </>
    );
  };

  // when there is no link to pipedrive, not showing the icon
  if (!componentEnabled) return null;

  return !showIconButton ? (
    <button type="button" onClick={buildOpenInPipedrive} data-cy={datacy}>
      <TooltipedIcon
        iconId="noun_pipedrive"
        size="lg"
        state={componentEnabled ? "default" : "disabled"}
        tooltipContent={buildTooptipContent()}
      />
    </button>
  ) : (
    <Button
      type="button"
      onClick={buildOpenInPipedrive}
      variant="contained"
      data-cy={datacy}
      endIcon={
        <Icon
          iconId="noun_pipedrive"
          size="lg"
          state={componentEnabled ? "default" : "disabled"}
        />
      }
    >
      Edit
    </Button>
  );
};

export default OpenInPipedrive;
