import React from "react";

import ThumbnailPerson from "../assets/thumb_person.svg";

type ThumbnailSize = "sm" | "base" | "lg";

const sizeClasses: Record<ThumbnailSize, string> = {
  "sm": "h-11 w-11",
  "base": "h-14 w-14",
  "lg": "h-20 w-20",
};

interface Props {
  // Image to render in the thumbnail.
  imgUrl?: string;
  // Name whose initials will be used in the thumbnail if the `imgUrl` prop is
  // not provided.
  // If `name` is an empty string, then a placeholder thumbnail is used.
  name: string;
  size?: ThumbnailSize;
};

const nameToInitials = (name: string): string => {
  return name.split(" ").filter(s => s.length).slice(0, 2).map(s => s[0]).join("").toUpperCase();
}

const Thumbnail: React.FC<Props> = ({imgUrl, name, size}) => {
  if (size === undefined) size = "base";

  const containerClass = sizeClasses[size] + " rounded-full flex justify-center items-center mx-1";

  if (imgUrl === undefined) {
    if (name === "") return (
      <div className={containerClass}>
        <img src={ThumbnailPerson} alt=""/>
      </div>
    );
    return (
      <div className={`${containerClass} bg-gray-300 text-2xl`}>
        <p>{nameToInitials(name)}</p>
      </div>
    );
  }

  return (
    <div className={containerClass}>
      <img className={containerClass} src={imgUrl} alt=""/>
    </div>
  );
}

export default Thumbnail;
