import { IconId, IconSize, IconState } from "components/Icon";
import TooltipedIcon from "components/TooltipedIcon";

interface ToolTipIconButtonProps {
  iconId: IconId;
  state?: IconState;
  size?: IconSize;
  filter?: string;
  title: string;
  message: string;
  onClick: (event) => void;
  className?: string;
  disabled?: boolean;
  datacy?: string;
}

export default function ToolTipIconButton({
  iconId,
  size,
  filter,
  title,
  message,
  state,
  onClick,
  className,
  disabled,
  datacy,
}: ToolTipIconButtonProps): JSX.Element {
  const buildTooptipContent = () => {
    return (
      <>
        <p className="text-base w-max text-lg font-medium">{title}</p>
        <p>{message}</p>
      </>
    );
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={className}
      disabled={disabled}
      data-cy={datacy}
    >
      <TooltipedIcon
        iconId={iconId}
        size={size}
        filter={filter}
        state={disabled ? "disabled" : state}
        tooltipContent={buildTooptipContent()}
      />
    </button>
  );
}
