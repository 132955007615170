import Copyright from "components/Copyright";
import BuildVersion from "components/theme/BuildVersion";

export default function Footer(): JSX.Element {
  const box = "flex-1";
  return (
    <div className="flex bg-tertiary p-3 mt-auto">
      <div className={box}></div>
      <div className={box + " pt-5"}>
        <Copyright />
      </div>
      <div className={box + " text-right"}>
        <BuildVersion />
      </div>
    </div>
  );
}
