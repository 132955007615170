import React from "react";
import { useFormState } from "react-final-form";
import { useHistory } from "react-router";
import styled from "styled-components";
import TooltipedIcon from "../TooltipedIcon";
import {
  fieldDictionary,
  fieldNamesToReadableStrings,
  matchingFields,
} from "services/stringHelper";
import { OrganizationChildrenTypes } from "enums";
import { formatArrayString } from "services/utils";

const MatchReason = styled.div`
  display: flex;
  flex-direction: column;
  color: red;
  margin-top: 5px;
`;

export default function MatchControl({
  pathTo,
  type,
  matchability,
  isDisabled = false,
  datacy,
}) {
  const history = useHistory();
  const { dirty } = useFormState();
  const isProperty = type === OrganizationChildrenTypes.Property;
  const isMatchable = matchability?.result;
  const reasons = matchability?.reasons ?? [];

  const matchDetails = () => {
    if (dirty) {
      return <p>The form data need to be saved.</p>;
    }

    if (isDisabled) {
      return <p>Request is not active.</p>;
    }

    if (isMatchable) {
      return <p>Click to show matches for this configuration.</p>;
    } else {
      return (
        <MatchReason>
          <b>
            Cannot match this {isProperty ? "property" : "guest"} without the
            following fields:{" "}
          </b>
          <label>
            {formatArrayString(
              fieldNamesToReadableStrings(reasons, {
                ...fieldDictionary,
                ...matchingFields,
              })
            )}
          </label>
        </MatchReason>
      );
    }
  };

  const buildTooptipContent = () => {
    return (
      <>
        <p className="text-base w-max text-lg font-medium">Find Matches</p>
        <div>{matchDetails()}</div>
      </>
    );
  };

  const goToMatch = (e) => {
    e.stopPropagation();
    history.push({
      pathname: pathTo,
    });
  };

  const isMatchReady = () => {
    // check all the fields for matching have values and they are saved
    return isMatchable && !dirty;
  };

  const isFindMatchEnabled = () => {
    // the find match button is enabled when it's match ready and the use-in-match (active field) is set to true
    if (isForGuest()) {
      return isMatchReady() && !isDisabled;
    } else {
      return isMatchReady();
    }
  };

  const isForGuest = () => {
    // TODO: This is temporary code. Currenlty we only moved the active checkbox/slider of the Property form.
    // Once we update Guest form, we should remove this function and its callers.
    return pathTo.includes("matches/request");
  };

  return (
    matchability && (
      <>
        <button
          type="button"
          onClick={goToMatch}
          disabled={!isFindMatchEnabled()}
          className={isFindMatchEnabled() ? "cursor-pointer" : "cursor-default"}
          data-cy={datacy}
        >
          <TooltipedIcon
            iconId="noun_match"
            size="xl"
            state={isFindMatchEnabled() ? "default" : "disabled"}
            tooltipContent={buildTooptipContent()}
          />
        </button>
      </>
    )
  );
}
