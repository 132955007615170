import React, { useEffect, useRef } from "react";
import {
  initializeGoogleMapsAPI,
  spacer_googleMapsApiLoaded,
} from "../Map/googleMaps";
import { LocationRationale } from "./types";
import "../Map/googleMaps";

/*
 * GLOBASL STATE & MUTATORS
 *
 * All identifiers beginning with a double-underscore, operate on the global
 * state. No need for access synchronization since there is only one thread of
 * execution.
 */

// This is the global state used by LocationRationaleMap to allow re-using the
// google.maps.Map object since the `new google.maps.Map(...)` call is what is
// billed (see link).
// https://developers.google.com/maps/documentation/javascript/usage-and-billing#pricing-for-product
const __state: {
  initialized: boolean; // whether this global has been properly initialized
  map?: google.maps.Map; // the global map object
  root?: HTMLDivElement; // the node in which the map is rendered
  mnt?: HTMLDivElement; // the current mount point of the root
  markers: google.maps.Marker[]; // the markers which are being rendered
  circles: google.maps.Circle[]; // the circles which are benig rendered
  selectedGuestLocSym?: google.maps.Symbol; // icon used for the selected guest location
} = {
  initialized: false,
  markers: [],
  circles: [],
};

const __clearMarkers = () => {
  // remove all the markers from the map
  for (let i = 0; i < __state.markers.length; ++i)
    __state.markers[i].setMap(null);
  // lose all references to the markers to permit GC
  __state.markers = [];
};

const __clearCirlces = () => {
  // remove all the circles from the map
  for (let i = 0; i < __state.circles.length; ++i)
    __state.circles[i].setMap(null);
  // lose all references to the circles to permit GC
  __state.circles = [];
};

const __clearState = () => {
  __clearMarkers();
  __clearCirlces();
};

const __addMarker = (opts: google.maps.MarkerOptions): boolean => {
  if (__state.map === undefined) return false;
  __state.markers.push(new google.maps.Marker({ ...opts, map: __state.map }));
  return true;
};

const __addCircle = (opts: google.maps.CircleOptions): boolean => {
  if (__state.map === undefined) return false;
  __state.circles.push(new google.maps.Circle({ ...opts, map: __state.map }));
  return true;
};

const __init = () => {
  // No need to re-initialize the state...
  if (__state.initialized) return;
  // If the GM API is not loaded, try and manually load it.
  // If the API fails to load, give up on initializing the state.
  if (!spacer_googleMapsApiLoaded) {
    initializeGoogleMapsAPI();
    if (!spacer_googleMapsApiLoaded) return;
  }

  __state.root = document.createElement("div");
  __state.root.className += "h-full w-full rounded-md";
  __state.map = new google.maps.Map(__state.root, {
    zoom: 12,
    center: { lat: 0, lng: 0 },
  });
  console.log("__init: new map object");

  // This needs to be initialized here since we are using the google.maps.Point
  // constructor, which is available after the API has been initialized.
  __state.selectedGuestLocSym = {
    path: "m10.92359,24.02432l13.13151,-13.19281l-2.81318,-2.80664l-10.31633,10.38617l-4.12773,-4.20996l-2.81318,2.80664l6.93891,7.0166zm3.0953,-23.95426q5.81444,0 9.89415,4.07023t4.07971,9.87115q0,2.90046 -1.45461,6.64332t-3.51748,7.0186t-4.07971,6.1283t-3.42344,4.53734l-1.50063,1.59096q-0.56224,-0.65475 -1.50063,-1.7307t-3.37742,-4.30378t-4.26779,-6.27003t-3.3294,-6.87687t-1.50063,-6.73714q0,-5.80092 4.07971,-9.87115t9.89415,-4.07023l0.004,0l0.00002,0z",
    fillColor: "red",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    anchor: new google.maps.Point(14, 40),
  };

  __state.initialized = true;
};

// Updates the mount point in the state, taking care of the previous mount
// point, if any.
const __setMnt = (mntNew: HTMLDivElement) => {
  // Initialize the __state global variable (noop if already initialized).
  __init();
  // If the global state initialization failed, then give up.
  if (!__state.initialized) return;
  // If we don't have a root node (which contains the map object), we can't
  // inject it into the mnt node.
  if (!__state.root) return;
  // If the map is already mounted onto some other node, we remove it from
  // that node.
  if (__state.mnt !== undefined) {
    // If the map is mounted on this instance's mount point, we have nothing
    // to do.
    if (__state.mnt === mntNew) return;
    // Iterate through the current mount point's children to find the map root
    // and remove it.
    for (let c = __state.mnt.firstChild; c !== null; c = c.nextSibling) {
      if (c === null) break;
      if (c === __state.root) {
        __state.mnt.removeChild(c);
        break;
      }
    }
  }
  // Place the map in the new mount point.
  mntNew.appendChild(__state.root);
  // Update the state variable to point to the new mount point.
  __state.mnt = mntNew;
};

// Mutates the global map object to render the given LocationRationale object.
const __renderLocationRationale = (r: LocationRationale) => {
  if (__state.map === undefined) return;
  const b = new google.maps.LatLngBounds();

  // add property loc to the bounds, so that it is always in view;
  b.extend(r.PropertyLoc);

  // if we have any guest locations, create markers for them, and extend the
  // bounds to accommodate them;
  if (r.GuestLocs) {
    for (let i = 0; i < r.GuestLocs.length; i++) {
      __addMarker({
        position: r.GuestLocs[i],
        ...(i === r.ClosestLocIdx ? { icon: __state.selectedGuestLocSym } : {}),
      });
      b.extend(r.GuestLocs[i]);
    }
  }

  if (r.GuestLocs && r.GuestLocs.length > 0) {
    // set the map to view all of the markers;
    // don't set zoom/center since that can put some markers off screen
    __state.map.fitBounds(b);
  } else {
    // we don't have any guest markers, so we focus on the property loc;
    __state.map.setZoom(10);
    __state.map.setCenter(r.PropertyLoc);
  }

  // add the circles for distance thresholds
  const baseOpts: google.maps.CircleOptions = {
    center: r.PropertyLoc,
    fillOpacity: 0.45,
    strokeOpacity: 0,
  };

  [
    { fillColor: "rgb(128, 128, 128)", radius: r.ThresholdZero * 1000 },
    { fillColor: "rgb(247,205,111)", radius: r.ThresholdPoor * 1000 },
    { fillColor: "rgb(239,146,079)", radius: r.ThresholdOK * 1000 },
    { fillColor: "rgb(159,031,038)", radius: r.ThresholdGreat * 1000 },
  ].forEach((o) => __addCircle({ ...baseOpts, ...o }));
};

/*
 * COMPONENT
 */

interface Props {
  rationale: LocationRationale;
  // _rerender is a dummy prop to force the component to re-render.
  _rerender: unknown;
}

// LocationRationaleMap renders a `LocationRationale` object.
//
// There are usage constraints for this component, which are now documented.
// - There may be at most one instance of this component at any time.
//   This is because there is a cost to creating a google.maps.Map object, so a
//   single object is created at initialization and re-used.
//   This component is intended to be displayed on-hover and so this is a
//   plausible cosntraint.
// - The caller must provide a dummy render prop `_rerender` which forces the
//   component to re-render and apply the `rationale` prop to the map object, as
//   well as remove the map object from its previous mount point and inject it
//   into the instance's root div.
//   If this component is displayed on-hover, a state variable indicating
//   whether the component is being hovered over would work as a `_rerender`
//   prop.
const LocationRationaleMap: React.FC<Props> = ({ rationale, _rerender }) => {
  __init();

  const mnt = useRef<HTMLDivElement>(null);

  // Set the new mount point to the node pointed to by the mnt ref.
  useEffect(() => {
    // If the ref hasn't been linked yet, then wait until it has been.
    if (mnt.current === null) return;
    __setMnt(mnt.current);
    // No cleanup needed - when a new instance attempts to mount the map using
    // the __setMnt method, it will cleanup then.
  }, [mnt, _rerender]);

  if (!__state.initialized || __state.map === undefined)
    return <div>Error!</div>;

  // This is not in a useEffect because the map object may have been mounted on
  // a different node before this.
  // Clear out the markers from the previous user.
  __clearState();
  __renderLocationRationale(rationale);

  return <div ref={mnt} className="h-48 w-48" />;
};

export default LocationRationaleMap;
