import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GET_SIGNED_URLS } from "graphql/queries";
import {
  FilesProps,
  ManagementViewProps,
} from "interfaces/managementViewProps";
import { DEFAULT_ERROR_TIMER } from "services/constants";
import Swal from "sweetalert2";
import moment from "moment";

const ManagementView = ({
  rows,
  showAction,
  showCompletedDate,
  onCompletedAction = () => undefined,
}: ManagementViewProps): JSX.Element => {
  async function downloadURI(uri: string, name: string) {
    return fetch(uri)
      .then((res) => res.blob())
      .then((file) => {
        const link = document.createElement("a");
        const url = URL.createObjectURL(file);
        link.setAttribute("download", name);
        link.setAttribute("href", url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  const [downloadFiles] = useLazyQuery(GET_SIGNED_URLS);

  async function downloadAll(fileList: FilesProps[], id: string) {
    try {
      const signedUrls: string[] = (
        await downloadFiles({
          variables: { handles: fileList?.map((file) => file.handle) },
        })
      ).data?.getSignedURLs;

      await Promise.all(
        signedUrls?.map((url, index) => downloadURI(url, `file-${id}-${index}`))
      );
    } catch (err) {
      Swal.fire({
        title: "Download error",
        text: `There was an issue with your downloads, ${err}`,
        icon: "error",
        toast: true,
        timer: DEFAULT_ERROR_TIMER,
        position: "bottom-right",
      });
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Created</TableCell>
            {showCompletedDate && <TableCell>Completed</TableCell>}
            <TableCell>Links</TableCell>
            <TableCell>Files</TableCell>
            {showAction && <TableCell>Actions</TableCell>}
          </TableRow>
        </TableHead>
        {rows && (
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.upload_id}
                </TableCell>
                <TableCell>
                  {moment(row.created_at).format("MMM DD YYYY")}
                </TableCell>
                {showCompletedDate && (
                  <TableCell>
                    {moment(row.completed_at).format("MMM DD YYYY")}
                  </TableCell>
                )}
                <TableCell>
                  <div className="flex flex-col gap-1">
                    {row?.links?.map((link, index) => (
                      <div key={index}>
                        <a
                          key={index}
                          href={link}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-700"
                        >
                          {link}
                        </a>
                      </div>
                    ))}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="mb-3">
                    {row?.files?.length > 1 && (
                      <Button
                        onClick={() => downloadAll(row.files, row.upload_id)}
                        variant="outlined"
                        size="small"
                      >
                        Download All
                      </Button>
                    )}
                  </div>
                  {row?.files?.map((file, index) => (
                    <div
                      key={index}
                      className="text-blue-700 cursor-pointer"
                      onClick={() =>
                        downloadAll([row.files[index]], row.upload_id)
                      }
                    >
                      {`${file.handle}_${index}`}
                    </div>
                  ))}
                </TableCell>
                {showAction && (
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={(e) => onCompletedAction(e, row.upload_id)}
                    >
                      Complete
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default ManagementView;
