import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import Icon, { IconState } from "../Icon";
import ScoreDetail from "./ScoreDetail";
import SubScoreDetail from "./SubScoreDetail";
import {
  Category,
  ScoreBreakdownCategories,
  matchCategories,
  MatchResult,
} from "./types";
import {
  classificationColors,
  scoreBreakdownCatClassification,
  ScoreClassification,
  scoreClassificationRaw,
} from "./utils";

export type Mode = "tag" | "default" | "verbose";

interface Props {
  mode?: Mode;
  ignoredDisqualifiers?: number;
  matchResult: MatchResult;
}

const Score: React.FC<Props> = ({ ignoredDisqualifiers, matchResult }) => {
  const [scoreClass, setScoreClass] = useState<ScoreClassification>("none");
  useEffect(() => {
    setScoreClass(scoreClassificationRaw(Math.round(matchResult.Score * 100)));
  }, [matchResult.Score]);

  // if `ignoredDisqualifiers` is specified, then we need to "disable" the
  // component, unless all the disqualifying categories have been ignored
  const [color, setColor] = useState<IconState>("default");
  useEffect(() => {
    if (
      ignoredDisqualifiers !== undefined &&
      (matchResult.Disqualified & ignoredDisqualifiers) !==
        matchResult.Disqualified
    )
      setColor("disabled");
    else setColor(classificationColors[scoreClass]);
  }, [matchResult, ignoredDisqualifiers, scoreClass]);

  // this variable has no semantic meaning - it is used to trigger re-renders
  // for the location SubScoreDetail component.
  const [locHover, setLocHover] = useState<boolean>(true);
  // debounce the hover handler to prevent spamming the map component
  const hoverHandler = useMemo(
    () =>
      _.debounce(
        (cat: Category) => cat === Category.Location && setLocHover((f) => !f),
        100
      ),
    [setLocHover]
  );

  return (
    <div className="flex font-display font-semibold text-xl text-white">
      <div
        className={
          "bg-" +
          (color === "disabled" ? "state-disabled" : color) +
          " px-2 w-12 rounded-l-lg tooltip-container flex items-center"
        }
      >
        <p className="m-auto">{Math.round(matchResult.Score * 100)}</p>

        <div className="tooltip tooltip-top">
          <ScoreDetail mr={matchResult} />
        </div>
      </div>

      <div
        className={
          "border-2 border-" +
          color +
          " flex justify-around items-center rounded-r-lg"
        }
      >
        {ScoreBreakdownCategories.map((cat, idx) => {
          const scoreClassification = scoreBreakdownCatClassification(
            matchResult,
            cat
          );
          return (
            <div
              key={idx}
              className="tooltip-container h-9 w-9 relative flex items-center"
              onMouseEnter={() => hoverHandler(cat)}
              onMouseLeave={() => hoverHandler(cat)}
            >
              <div className="m-auto" title={matchCategories[cat].name}>
                <Icon
                  iconId={matchCategories[cat].iconId}
                  size="base"
                  state={
                    color === "disabled"
                      ? color
                      : classificationColors[scoreClassification]
                  }
                />
              </div>

              {color !== "disabled" && (
                <div className={"tooltip tooltip-top"}>
                  <SubScoreDetail
                    cat={cat}
                    sub={matchResult.SubScores[cat]}
                    scoreClassification={scoreClassification}
                    rat={matchResult.Rationale[cat]}
                    lrat={matchResult.LocInfo}
                    _rerender={locHover}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Score;
