import ConfirmationDialog from "components/Forms/ConfirmationDialog";
import { AppContext } from "contexts/AppContext";
import { useEffect, useState, useMemo, useContext } from "react";
import { fieldDictionary } from "services/stringHelper";
import __ from "lodash";
import { ListingUnpublishedPromptProps } from "interfaces/listingUnpublishPrompt.interface";

const conditionCheckForField = (fieldName, condition) => {
  switch (fieldName) {
    case "description":
      return (
        condition == null ||
        condition === "<p><br></p>" ||
        condition === "<p></p>"
      );
    default:
      return condition == null;
  }
};

const isCeilingCleranceSet = (feet, inches) => {
  return !feet && !inches ? null : true;
};

const filterAndAddList = (fieldNameThatIsIncluded, fieldNameToAdd, list) => {
  if (list.includes(fieldNameThatIsIncluded)) {
    __.remove(list, (item) => item === fieldNameThatIsIncluded);
    if (fieldNameToAdd) list.push(fieldNameToAdd);
  }
};

const listReadableStrings = {
  ...fieldDictionary,
  docks_available: "Exclusive Loading Docks",
  drivein_doors_available: "Exclusive Drive-in Doors",
  shared_docks_available: "Shared Loading Docks",
  shared_drivein_doors_available: "Shared Drive-in Doors",
};

// status is not a part of this since it is handled elsewhere
// TODO: put all unpublish warning logic in one place?
export function ListingUnpublishWarning({
  description,
  title,
  list_fee_basis,
  list_fee,
  term_min,
  warehouse_type,
  ceiling_clearance_feet,
  ceiling_clearance_inches,
  square_footage_min,
  permitted_auto,
  permitted_food,
  permitted_cannabis,
  permitted_customer,
  permitted_sport_complex,
  permitted_sport_studio,
  permitted_highvol,
  permitted_manufacture,
  docks_available,
  drivein_doors_available,
  shared_docks_available,
  shared_drivein_doors_available,
  isListable,
}: ListingUnpublishedPromptProps): JSX.Element {
  const [listingUnpublishWarning, setListingUnpublishWarning] = useState(false);
  const [warningTriggered, setWarningTriggered] = useState(false);
  const [fieldName, setFieldName] = useState(null);
  const { required_listing_fields } = useContext(AppContext);

  const listableConditions = useMemo(
    () => ({
      description,
      title,
      list_fee_basis,
      list_fee,
      term_min,
      warehouse_type,
      ceiling_clearance_feet,
      ceiling_clearance_inches,
      square_footage_min,
      permitted_auto,
      permitted_food,
      permitted_cannabis,
      permitted_customer,
      permitted_sport_complex,
      permitted_sport_studio,
      permitted_highvol,
      permitted_manufacture,
      docks_available,
      drivein_doors_available,
      shared_docks_available,
      shared_drivein_doors_available,
      required_listing_fields,
    }),
    [
      ceiling_clearance_feet,
      ceiling_clearance_inches,
      description,
      docks_available,
      drivein_doors_available,
      list_fee,
      list_fee_basis,
      permitted_auto,
      permitted_cannabis,
      permitted_customer,
      permitted_food,
      permitted_highvol,
      permitted_manufacture,
      permitted_sport_complex,
      permitted_sport_studio,
      required_listing_fields,
      shared_docks_available,
      shared_drivein_doors_available,
      square_footage_min,
      term_min,
      title,
      warehouse_type,
    ]
  );

  const requiredListingFields = useMemo(() => {
    const requiredListingFields = __.cloneDeep(required_listing_fields);

    filterAndAddList(
      "shared_docks",
      "shared_docks_available",
      requiredListingFields
    );
    filterAndAddList(
      "shared_drivein_doors",
      "shared_drivein_doors_available",
      requiredListingFields
    );
    filterAndAddList("docks", "docks_available", requiredListingFields);
    filterAndAddList(
      "drivein_doors",
      "drivein_doors_available",
      requiredListingFields
    );

    return requiredListingFields;
  }, [required_listing_fields]);

  useEffect(() => {
    const setUnPublishWarning = (fieldName, condition) => {
      if (
        conditionCheckForField(fieldName, condition) &&
        !warningTriggered &&
        requiredListingFields.includes(fieldName)
      ) {
        setListingUnpublishWarning(true);
        setWarningTriggered(true);
        setFieldName(fieldName);
      }
      return;
    };

    if (!isListable || warningTriggered) return;

    setUnPublishWarning(
      "ceiling_clearance",
      isCeilingCleranceSet(
        listableConditions.ceiling_clearance_feet,
        listableConditions.ceiling_clearance_inches
      )
    );

    for (const [key, value] of Object.entries(listableConditions)) {
      setUnPublishWarning(key, value);
    }
  }, [listableConditions, warningTriggered, requiredListingFields, isListable]);

  return (
    <ConfirmationDialog
      title={"WARNING"}
      content={`${
        listReadableStrings[fieldName ?? ""]
      } is a required field for listing, if this field is not set the listing will be unpublished.`}
      open={listingUnpublishWarning}
      onConfirm={() => {}}
      ignoreConfirm={true}
      onClose={() => {
        setListingUnpublishWarning(false);
      }}
    />
  );
}
