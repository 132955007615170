import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  REQ_MATCH_UNIFIED_QUERY,
  EXPORT_REQUEST_MATCHES_QUERY,
} from "graphql/queries";
import TableHeader from "./TableHeader";
import {
  RequestMatchUnifiedResponse,
  RequestMatchUnifiedRequest,
  PaginationInput,
} from "./types";
import CandidateConifigRow from "./ConfigListRow";
import { initialPaginationInput, jsonToCsv } from "./utils";
import { quickReturnClass } from "./common";
import IgnoreDisqualifiersRow from "./IgnoreDisqualifiersRow";

interface Props {
  reqID: number;
}

const ConfigList: React.FC<Props> = ({ reqID }) => {
  const [qry, { data, loading, error }] = useLazyQuery<
    RequestMatchUnifiedResponse,
    RequestMatchUnifiedRequest
  >(REQ_MATCH_UNIFIED_QUERY);

  const [showDq, setShowDq] = useState<"on" | "off">("off");

  const [pagination, setPagination] = useState<PaginationInput>(
    initialPaginationInput()
  );
  const [search, setSearch] = useState<string>("");
  const [ignoredDisqualifiers, setIgnoredDisqualifiers] = useState<number>(0);

  const [exportMatchCandidates] = useLazyQuery(EXPORT_REQUEST_MATCHES_QUERY, {
    onCompleted: (data) => {
      if (data && data.exportMatchCandidates) {
        jsonToCsv(
          data.exportMatchCandidates.match_data,
          data.exportMatchCandidates.object_data,
          "request",
          reqID
        );
      }
    },
  });

  // must have this secondary function here due to appolo client re fetch
  // https://github.com/apollographql/apollo-client/issues/9317
  const exportMatches = (): void => {
    const isShowDisqualifiers = showDq === "on";
    exportMatchCandidates({
      variables: {
        objectId: reqID,
        objectType: "request",
        search: search,
        dqFilters: isShowDisqualifiers ? -1 : ignoredDisqualifiers,
      },
    });
  };

  useEffect(() => {
    qry({
      variables: {
        reqID,
        pagination,
        search,
        ignoreDq: showDq === "on" ? -1 : ignoredDisqualifiers,
        mode: "CANDIDATES_AND_DISQUALIFIED",
      },
    });
  }, [qry, reqID, pagination, search, showDq, ignoredDisqualifiers]);

  if (loading === true)
    return <div className={quickReturnClass}>Loading... </div>;
  if (error !== undefined)
    return (
      <div className={quickReturnClass + "text-state-error"}>
        {error.message.replace(/GraphQL error: ?/, "")}
      </div>
    );
  if (!data?.requestMatchUnified)
    return (
      <div className={quickReturnClass + "text-state-error"}>
        Error: No data received.
      </div>
    );

  const { requestMatchUnified: rmu } = data;

  return (
    <div className="flex flex-col font-display">
      <TableHeader
        title=""
        elemCount={rmu.total_records}
        pagination={pagination}
        setPagination={setPagination}
        filter={search}
        searchCallback={(s: string) => setSearch(s)}
        exportCallback={exportMatches}
      />

      <div className="flex items-center">
        <span className="ml-2 mr-4">Include Disqualified</span>
        <input
          type="checkbox"
          checked={showDq === "on"}
          onChange={() => setShowDq((dq) => (dq === "on" ? "off" : "on"))}
        />
      </div>

      <IgnoreDisqualifiersRow
        ignored={ignoredDisqualifiers}
        setIgnored={setIgnoredDisqualifiers}
      />

      {rmu.configs && rmu.configs.length !== 0 ? (
        <table className="text-center">
          <thead>
            <tr className="bg-gray-100 uppercase">
              <th className="p-1 font-medium w-1/12">Score</th>
              <th className="p-1 font-medium">Host</th>
              <th className="p-1 font-medium">Property</th>
              <th className="p-1 font-medium">Config</th>
              <th className="p-1 font-medium">Square Footage</th>
              <th className="p-1 font-medium">Price</th>
              <th className="p-1 font-medium">Disqualifiers</th>
              <th className="p-1 font-medium">Availability</th>
            </tr>
          </thead>

          <tbody>
            {rmu.configs?.map((c, i) => {
              return (
                <CandidateConifigRow
                  key={i}
                  rowIdx={i}
                  config={c}
                  ignoredDisqualifiers={ignoredDisqualifiers}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="m-auto text-lg">Nothing to see here!</div>
      )}
    </div>
  );
};

export default ConfigList;
