import { Button } from "@mui/material";
import TextInput from "components/Forms/TextInput";
import Title from "components/Title";
import PasswordInput from "components/Forms/PasswordInput";
import { UPSERT_USER } from "graphql/mutations";
import GQLForm from "components/Forms/GQLForm";
import { USER_QUERY } from "graphql/queries";
import CheckBoxesInput from "components/Forms/CheckBoxesInput";
import { EnumUserPermissions } from "services/enums";
import { useHistory } from "react-router";
import { initData, validate, preSaveFormat } from "services/forms/user";
import { triggerAlert } from "services/alertService";

const inputWidth = "w-full";

export default function UserFormFields({ userID }) {
  const history = useHistory();

  const onSubmit = () => {
    history.push("/users");
    triggerAlert("success", "User created!");
  };

  return (
    <>
      <GQLForm
        initData={initData}
        loadQuery={USER_QUERY}
        loadID={userID}
        loadSkip={!userID}
        postLoadFn={() => {}}
        preSaveFormat={preSaveFormat}
        saveQuery={UPSERT_USER}
        postSaveFn={onSubmit}
        validate={validate}
        gqlMutationArgName={"user"}
        gqlQueryArgName={"userID"}
        tabTitleFn={() => {}}
      >
        {({ invalid, handleSubmit }) => (
          <div className="w-4/12">
            <form>
              <Title>Add User</Title>
              <div>
                <TextInput
                  name={`firstname`}
                  label={`First Name`}
                  className={inputWidth}
                />
                <TextInput
                  name={`lastname`}
                  label={`Last Name`}
                  className={inputWidth}
                />
                <TextInput
                  name={`email`}
                  label={`Email`}
                  className={inputWidth}
                />
                <PasswordInput
                  name={`password`}
                  label={`New Password`}
                  className={inputWidth}
                />
                <PasswordInput
                  name={`password_confirm`}
                  label={`Confirm New Password`}
                  className={inputWidth}
                />

                <CheckBoxesInput
                  label="Permissions"
                  items={EnumUserPermissions}
                  hideInput={false}
                  staticHoverText={"HOVER_PERMISSIONS_FIELD"}
                  disabled={false}
                  name="permissions"
                />

                <Button
                  type="button"
                  disabled={invalid}
                  variant="contained"
                  onClick={handleSubmit}
                  className="float-right"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        )}
      </GQLForm>
    </>
  );
}
