import React from "react";
import styled from "styled-components";
import WarningIcon from "@mui/icons-material/Warning";
import Field from "components/Forms/Field";
import Tooltip from "@mui/material/Tooltip";
import { filterFieldProps } from "../../services/utils";
import BaseInput from "./BaseInput";
import _ from "underscore";
import Error from "./Error";

const HiddenBox = styled.div`
  background-color: white;
  width: 154px;
  height: 20px;
`;
const WarningBox = styled.div`
  margin-left: 3px;
  color: orange;
`;

const showErrorStyle = (hasError) => {
  return hasError ? "input-error flex" : "input-default flex";
};

const DropdownInputAdapter = ({ input, meta, ...rest }) => (
  <>
    <select
      {...input}
      {...rest}
      className={showErrorStyle(meta.error)}
      onChange={(e, value) => {
        let updatedValue = e.currentTarget.value;

        if (updatedValue !== undefined && updatedValue !== "") {
          try {
            updatedValue = JSON.parse(updatedValue);
          } catch (err) {
            input.onChange(updatedValue);
            return;
          }
        }

        input.onChange(updatedValue);
      }}
    ></select>

    <Error showError={meta.error} error={meta.error} />
    {meta.data.warning && (
      <Tooltip title={meta.data.warning} placement="top-start" arrow>
        <WarningBox>
          <WarningIcon style={{ fontSize: 20 }} />
        </WarningBox>
      </Tooltip>
    )}
  </>
);

function DropdownInput({
  staticHoverText = "",
  isReadOnly = false,
  defaultShowSideNote = null,
  ...props
}) {
  const fieldProps = filterFieldProps(props);

  return (
    <BaseInput
      {...props}
      readOnly={isReadOnly}
      staticHoverText={staticHoverText}
      defaultShowSideNote={defaultShowSideNote}
    >
      {({ isInputReadOnly, inputStaticHoverText }) => (
        <>
          {!isInputReadOnly && (
            <Field
              style={{
                height: "35px",
              }}
              name={`${props.name}`}
              component={DropdownInputAdapter}
              {...fieldProps}
            >
              {props.noNull === undefined &&
                props.placeholder === undefined && <option />}
              {props.noNull === undefined &&
                props.placeholder !== undefined && (
                  <option value={"null"}>{props.placeholder}</option>
                )}
              {props.options.map((option, idx) => (
                <option
                  key={`${props.name}_${idx}`}
                  value={`${option.value}`}
                >{`${option.name}`}</option>
              ))}
            </Field>
          )}

          {isInputReadOnly && (
            <Field
              style={{ height: "20px" }}
              name={`${props.name}`}
              {...fieldProps}
            >
              {(props) => {
                var option = _.find(props.options, function (o) {
                  return o.value === props.input.value;
                });
                if (option) {
                  return (
                    <Tooltip
                      title={inputStaticHoverText}
                      placement="top-start"
                      arrow
                    >
                      <div>
                        {props.input.value ? option.name : <HiddenBox />}
                      </div>
                    </Tooltip>
                  );
                } else {
                  return;
                }
              }}
            </Field>
          )}
        </>
      )}
    </BaseInput>
  );
}

export default DropdownInput;
