import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import ConfigList from "components/Match/ConfigList";
import ReqHeaderSummary from "components/Match/ReqHeaderSummary";
import {
  ReqMatchDataRequest,
  ReqMatchDataResponse,
} from "components/Match/types";
import { guestName } from "components/Match/utils";
import OpenInPipedrive from "components/OpenInPipedrive";
import SectionHeader from "components/SectionHeader";
import { REQ_MATCH_DATA_QUERY } from "graphql/queries";
import { PD_RESOURCE_ORGANIZATION } from "services/constants";
import { quickReturnClass } from "components/Match/common";

const MatchesGuest: React.FC = () => {
  const { req_id } = useParams<{ req_id: string }>();
  const { loading, data, error } = useQuery<
    ReqMatchDataResponse,
    ReqMatchDataRequest
  >(REQ_MATCH_DATA_QUERY, { variables: { reqID: parseInt(req_id) } });

  if (loading) return <div className={quickReturnClass}> Loading... </div>;
  if (error !== undefined)
    return (
      <div className={quickReturnClass + "text-state-error"}>
        {error.message.replace(/GraphQL error: ?/, "")}
      </div>
    );
  if (!data?.requestMatchData)
    return (
      <div className={quickReturnClass + "text-state-error"}>
        Error: no data received!
      </div>
    );

  const { requestMatchData } = data;

  return (
    <div className="mb-4 w-11/12">
      <SectionHeader
        title={`Property Matches for '${guestName(
          requestMatchData.org_name,
          requestMatchData.req_name
        )}'`}
        showTooltip={false}
        tooltipText={""}
      >
        <div className="flex justify-center items-center">
          <OpenInPipedrive
            resourceType={PD_RESOURCE_ORGANIZATION}
            orgPipedriveID={requestMatchData.pipedrive_id}
          />
        </div>
      </SectionHeader>

      <ReqHeaderSummary data={requestMatchData} />

      <ConfigList reqID={parseInt(req_id)} />
    </div>
  );
};

export default MatchesGuest;
