import { GridSection } from "components/CardComponents";
import NullBooleanInput from "components/Forms/NullBooleanInput";
import DropdownInput from "components/Forms/DropdownInput";
import NumberInput from "components/Forms/NumberInput";
import { OnChange } from "react-final-form-listeners";
import { useField } from "react-final-form";

import { isSharedProperty } from "services/forms/helpers";
import {
  EnumOfficeType,
  EnumWarehouseType,
  EnumWarehouseUsage,
  EnumSpaceType,
} from "services/enums";
import { Grid } from "@mui/material";
import Condition from "components/Forms/Condition";
import CheckBoxesInput from "components/Forms/CheckBoxesInput";
import TextAreaInput from "components/Forms/TextAreaInput";

interface WareHouseConfigProps {
  warehouseType: string | undefined;
  configName: string;
  propertyName: string;
}

export default function WareHouseConfigs({
  warehouseType,
  configName,
  propertyName,
}: WareHouseConfigProps): JSX.Element {
  const isShared = isSharedProperty(warehouseType);

  const {
    input: { onChange },
  } = useField(`${configName}.office_sqft`);

  return (
    <GridSection title="Tenant Space" md={8} xs={12}>
      <Grid container>
        <Grid item md={6} xs={6}>
        <DropdownInput
          name={`${configName}.space_type`}
          label={`Space Type`}
          options={EnumSpaceType}
        />

          <DropdownInput
            name={`${configName}.warehouse_type`}
            label={`Accessibility`}
            options={EnumWarehouseType}
            sideNoteNamespace={`${configName}.type`}
            isSideNote
          />

          {isShared && (
            <DropdownInput
              name={`${configName}.warehouse_usage`}
              label={`Warehouse Usage`}
              sideNoteNamespace={`${configName}.usage`}
              isSideNote
              options={EnumWarehouseUsage}
            />
          )}

          <NullBooleanInput
            name={`${configName}.has_range_of_space`}
            label="Range of space available?"
          />

          <Condition when={`${configName}.has_range_of_space`} is={false}>
            <NumberInput
              sideNoteNamespace={`${configName}.square_footage`}
              name={`${configName}.square_footage_min`}
              label={`Total Size (sq. ft.)`}
              // Overrides HelpText contents when warehouse is a private type  (see #514)
              helpTextModifier={(helpText) =>
                "How much square footage is available for the guest"
              }
              isSideNote
              indent={2}
            />
          </Condition>

          <Condition when={`${configName}.has_range_of_space`} is={true}>
            <NumberInput
              name={`${configName}.square_footage_min`}
              label={`Min Total Size (sq. ft.)`}
              isSideNote
              sideNoteNamespace={`${configName}.square_footage`}
              indent={2}
            />
            <NumberInput
              name={`${configName}.square_footage_max`}
              label={`Max Total Size (sq. ft.)`}
              indent={2}
            />
          </Condition>

          <NullBooleanInput
            name={`${configName}.office`}
            label={`Office Space?`}
          />

          <OnChange name={`${configName}.office`} key={`status-${configName}`}>
            {(curr) => {
              if (curr !== true) {
                onChange(null);
              }
            }}
          </OnChange>

          <Condition when={`${configName}.office`} is={true}>
            <NumberInput
              name={`${configName}.office_sqft`}
              label={`Office Size`}
              indent={2}
            />
            <NullBooleanInput
              name={`${configName}.is_office_size_included`}
              label="Office space is part of Total Size?"
              indent={2}
              inputAltLabel={{
                trueLabel: "Yes",
                falseLabel: "No (office is additional)",
              }}
            />
            <CheckBoxesInput
              name={`${configName}.office_types`}
              label="Office Types"
              items={EnumOfficeType}
              isSideNote
              indent={2}
            />
          </Condition>
        </Grid>

        <Grid item md={6} xs={6}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <NumberInput
                name={`${configName}.ceiling_clearance_feet`}
                sideNoteNamespace={`${configName}.ceiling_clearance`}
                label={`Ceiling Clearance`}
                postInputLabel={`ft`}
                className={"w-11"}
                isSideNote
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <NumberInput
                name={`${configName}.ceiling_clearance_inches`}
                postInputLabel={`in`}
                className={"w-11"}
                isNolabelWithSpacing={true}
              />
            </Grid>
          </Grid>

          <NullBooleanInput
            name={`${configName}.securable`}
            label={`Securable`}
            isSideNote
          />

          <NullBooleanInput
            name={`${propertyName}.twentyfourseven`}
            label={`24/7 Access possible?`}
            isSideNote
          />

          <NullBooleanInput
            name={`${configName}.shared_docks_available`}
            label={`Shared Loading Docks`}
          />
          <Condition when={`${configName}.shared_docks_available`} is={true}>
            <NumberInput
              name={`${configName}.shared_docks`}
              label={`How Many`}
              indent={2}
            />
            <NullBooleanInput
              name={`${configName}.shared_docks_53`}
              label={`Can Fit 53 ft Trailer`}
              indent={2}
            />
            <NullBooleanInput
              name={`${configName}.shared_docks_leveler`}
              label={`Plate / Leveler`}
              indent={2}
            />
          </Condition>

          {/* Shared Drive-in Doors */}
          <NullBooleanInput
            name={`${configName}.shared_drivein_doors_available`}
            label={`Shared Drive-in Doors`}
          />
          <Condition
            when={`${configName}.shared_drivein_doors_available`}
            is={true}
          >
            <NumberInput
              name={`${configName}.shared_drivein_doors`}
              label={`How Many`}
              indent={2}
            />
          </Condition>

          {/* Executive Loading Docks */}
          <NullBooleanInput
            name={`${configName}.docks_available`}
            label={`Exclusive Loading Docks`}
          />

          <Condition when={`${configName}.docks_available`} is={true}>
            <NumberInput
              name={`${configName}.docks`}
              label={`How Many`}
              indent={2}
            />

            <NullBooleanInput
              name={`${configName}.docks_53`}
              label={`Can Fit 53 ft Trailer`}
              indent={2}
            />

            <NullBooleanInput
              name={`${configName}.docks_leveler`}
              label={`Plate / Leveler`}
              indent={2}
            />
          </Condition>

          {/* Executive Drive-in Doors */}
          <NullBooleanInput
            name={`${configName}.drivein_doors_available`}
            label={`Exclusive Drive-in Doors`}
          />

          <Condition when={`${configName}.drivein_doors_available`} is={true}>
            <NumberInput
              name={`${configName}.drivein_doors`}
              label={`How Many`}
              indent={2}
            />
          </Condition>

          <TextAreaInput
            name={`${configName}.door_types_nb`}
            label={`Door Types Notes`}
          />
        </Grid>
      </Grid>
    </GridSection>
  );
}
