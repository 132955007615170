import { createContext } from "react";

interface PropertiesContextInterface {
  setSelectedPropertyID: React.Dispatch<React.SetStateAction<number | null>>;
  selectedPropertyID: number | null;
}

const PropertiesContext = createContext<PropertiesContextInterface>({
  selectedPropertyID: null,
  setSelectedPropertyID: () => {},
});

export default PropertiesContext;
