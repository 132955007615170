import { Grid } from "@mui/material";

import { GridSection } from "components/CardComponents";
import CheckBoxesInput from "components/Forms/CheckBoxesInput";
import Condition from "components/Forms/Condition";
import NullBooleanInput from "components/Forms/NullBooleanInput";
import NumberInput from "components/Forms/NumberInput";
import BaseTenantSectionProps from "interfaces/baseTenantSectionProps.interface";
import { ALT_NULL_BOOLEAN_INPUT_TEXT } from "services/constants";
import { EnumWarehouseType } from "services/enums";

export default function TenantWarehouseConfig({
  requestName,
}: BaseTenantSectionProps): JSX.Element {
  return (
    <GridSection title="Space Requirements" md={8} xs={12}>
      <Grid container spacing={6}>
        <Grid item md={6} xs={6}>
          <CheckBoxesInput
            name={`${requestName}.warehouse_types`}
            label="Warehouse Types"
            items={EnumWarehouseType}
            isSideNote
            sideNoteNamespace={`${requestName}.type`}
          />

          <Grid container>
            <Grid item xs={12} md={9}>
              <NumberInput
                name={`${requestName}.ceiling_clearance_feet`}
                label={`Min Ceiling Clearance`}
                postInputLabel={`ft`}
                className="w-11"
                sideNoteNamespace={`${requestName}.ceiling`}
                isSideNote
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <NumberInput
                name={`${requestName}.ceiling_clearance_inches`}
                postInputLabel={`in`}
                className="w-11"
                isNolabelWithSpacing
              />
            </Grid>
          </Grid>

          <NumberInput
            sideNoteNamespace={`${requestName}.size`}
            name={`${requestName}.size_min`}
            label={`Min Size (sq.ft.)`}
            isSideNote
          />

          <NumberInput
            name={`${requestName}.size_max`}
            label={`Max Size (sq.ft.)`}
          />

          <NullBooleanInput
            name={`${requestName}.office`}
            label={`Must have Office?`}
            inputAltLabel={ALT_NULL_BOOLEAN_INPUT_TEXT}
            className="w-full"
            isSideNote
          />

          <Condition when={`${requestName}.office`} isNot={""}>
            <NumberInput
              name={`${requestName}.office_sqft`}
              label={`Office Size`}
              indent={2}
            />
          </Condition>
        </Grid>
        <Grid item md={6} xs={6}>
          <NullBooleanInput
            name={`${requestName}.twentyfourseven`}
            label={`Need 24/7 Access`}
            isSideNote
          />

          <NullBooleanInput
            name={`${requestName}.securable`}
            label={`Must be Securable`}
            isSideNote
          />

          <NullBooleanInput
            name={`${requestName}.temperature_ac`}
            label={`Need Air Conditioning`}
            sideNoteNamespace={`${requestName}.temperature`}
            isSideNote
          />

          <NullBooleanInput
            name={`${requestName}.temperature_refrig`}
            label={`Need Refrigeration Space`}
          />

          <NullBooleanInput
            name={`${requestName}.temperature_freeze`}
            label={`Need Freezer Space`}
          />

          <NumberInput
            name={`${requestName}.bays`}
            label={`Min Loading Bays Required`}
            isSideNote
          />

          <NullBooleanInput
            name={`${requestName}.bays_docks`}
            label={`Bay(s) must include Truck-Level Docks`}
          />

          <NullBooleanInput
            name={`${requestName}.bays_drivein`}
            label={`Bay(s) must include Drive-In Doors`}
          />
          <NullBooleanInput
            name={`${requestName}.bays_53`}
            label={`Bay(s) must support 53' Trailers`}
          />

          <NullBooleanInput
            name={`${requestName}.bays_exclusive`}
            label={`Bay(s) must be for exclusive use`}
          />
        </Grid>
      </Grid>
    </GridSection>
  );
}
