import gql from "graphql-tag";
import * as fragments from "./fragments.js";

const GET_SIGNED_URLS = gql`
  query getSignedURLs($handles: [String!]!) {
    getSignedURLs(handles: $handles)
  }
`;

const APP_CONFIG_QUERY = gql`
  query appconfigs {
    appConfigs {
      menus
      permissions
      mfa_enabled
      pipedrive_connected
      tunings {
        item
        value_string
        value_int
        value_float
        value_bool
      }
      required_request_fields
      required_config_fields
      required_property_fields
      required_listing_fields
      listingProviders {
        location
        values
      }
    }
  }
`;

const USERS_QUERY = gql`
  ${fragments.USER_FIELDS}
  query users {
    users {
      ...UserFields
      auth0_id
    }
  }
`;

const USER_QUERY = gql`
  ${fragments.USER_FIELDS}
  query user($userID: Int!) {
    user(userID: $userID) {
      ...UserFields
      permissions
    }
  }
`;

const HOST_QUERY = gql`
  ${fragments.ORG_FIELDS}
  ${fragments.ORG_CONTACT_FIELDS}
  ${fragments.ORG_OWNER_FIELDS}
  query host($id: Int!) {
    host(orgID: $id) {
      org {
        ...OrgFields
      }
      host {
        org_id
        host_type
      }
      persons {
        ...OrgContactFields
      }
      owners {
        ...OrgOwnerFields
      }
      market
      propertyType
      polymorph_info {
        is_polymorphic
      }
    }
  }
`;

const ALL_INFO_QUERY = gql`
  ${fragments.ORG_DETAIL_FIELDS}
  ${fragments.ORG_PERSON_FIELDS}
  ${fragments.LISTING_UPLOAD_FIELDS}
  ${fragments.TENANT_REQUEST_FEILDS}
  ${fragments.PROPERTY_FIELDS}
  ${fragments.CONFIG_FIELDS}
  query allInfo($infoRequest: AllInfoRequest!) {
    allInfo(infoRequest: $infoRequest) {
      org {
        id
        details {
          ...OrgDetailFields
        }
        persons {
          ...OrgPersonFields
        }
      }
      listing_uploads {
        ...ListingUploadFields
      }
      properties {
        ...PropertyFields
      }
      requests {
        ...RequestFields
      }
    }
  }
`;

const UNREAD_NOTIFICATION_COUNT_QUERY = gql`
  {
    unreadNotificationCounts
  }
`;

const NOTIFICATIONS_QUERY = gql`
  query notifications($filter: NotificationFilter, $input: PaginationInput) {
    notifications(filter: $filter, pagination: $input) {
      data {
        id
        owner_id
        priority_id
        type
        status_id
        destination_id
        param
        param_extra
      }
      pageInfo {
        endCursor
      }
    }
  }
`;

const NOTIFICATION_PREFERENCES_QUERY = gql`
  query notificationPreferences {
    notificationPreferences {
      id
      type
      subscribe
    }
  }
`;

const MATCHES_QUERY = gql`
  query matches($requestID: Int, $configID: Int) {
    matches(requestID: $requestID, configID: $configID) {
      matches {
        request_id
        org_id
        config_id
        property_id
        property_type
        name
        unit
        address
        price
        square_footage
        capacity
        score
        disqualified
        great
        ok
        poor
        zero
        thumbnail_url
      }
    }
  }
`;

const CHANGELOGS_QUERY = gql`
  query changelogs(
    $page: String!
    $objectID: Int!
    $pagination: PaginationInput!
  ) {
    changelogs(page: $page, objectID: $objectID, pagination: $pagination) {
      logs {
        id
        object_type
        change_type
        user_id
        firstname
        lastname
        object_id
        change_time
        name
      }
      offset
    }
  }
`;

const PLACE_FEE_QUERY = gql`
  query placefee(
    $listFee: CurrencyAmount!
    $units: String!
    $capacity: Int
    $squarefeet: Int
    $squarefeetmin: Int
    $furniture: CurrencyAmount
    $province: String!
    $propertyType: String!
    $tunings: [TuningVariableInput!]!
    $configID: Int
  ) {
    placefee(
      listFee: $listFee
      units: $units
      capacity: $capacity
      squarefeet: $squarefeet
      squarefeetmin: $squarefeetmin
      furniture: $furniture
      province: $province
      propertyType: $propertyType
      tunings: $tunings
      configID: $configID
    ) {
      fee
      feeMin
      feeSFMo
      feeSFYr
      listFee
      squareFeet
      squareFeetMin
      serviceFee
      serviceFeeNoRound
      serviceFeePC
      insurance
      insuranceFee
      insuranceFeePC
      furniture
      furnitureFee
      furnitureFeePC
      placeFeeNoRound
      effectiveFeePercent
    }
  }
`;

const CHECK_EMPIRE_USER = gql`
  query findEmpireUser($email: String!) {
    findEmpireUser(email: $email) {
      id
      first_name
      last_name
      email
      ext_id
    }
  }
`;

const PREPARE_ADD_DOCUMENT = gql`
  query prepareAddDocument($key: String!, $public: Boolean!) {
    prepareAddDocument(key: $key, public: $public) {
      privateHandle
      privateUrl
      publicHandle
      publicUrl
    }
  }
`;

const PIPEDRIVE_CONNECT_QUERY = gql`
  query pipedriveConnect {
    pipedriveConnect {
      isConnected
      userID
      name
      email
    }
  }
`;

export const DATA_CONFIGS_QUERY = gql`
  query nonMatchableConfigs(
    $market: String
    $propertyType: String
    $active: Boolean!
  ) {
    nonMatchableConfigs(
      market: $market
      propertyType: $propertyType
      active: $active
    ) {
      configs {
        config_id
        config_name
        property_id
        host_id
        reasons
        last_updated
      }
    }
  }
`;

export const DATA_REQUESTS_QUERY = gql`
  query nonMatchableRequests(
    $market: String
    $propertyType: String
    $active: Boolean!
  ) {
    nonMatchableRequests(
      market: $market
      propertyType: $propertyType
      active: $active
    ) {
      requests {
        request_id
        request_name
        guest_id
        reasons
        last_updated
      }
    }
  }
`;

export const CONFIG_MATCH_DATA_QUERY = gql`
  query nonMatchableRequests($configID: Int!) {
    configMatchData(configID: $configID) {
      pipedrive_id
      pipedrive_org_id
      property_id
      config_id
      address
      city
      postal
      province
      price
      price_min
      square_footage_min
      square_footage_max
      term_min
      term_max
      term_end
      availability
      availability_notice
      loc_lat
      loc_lng
      config_data {
        attr_247_access
        attr_securable
        attr_office_space
        attr_truck_level_ex
        attr_truck_level_sh
        attr_drive_in_ex
        attr_drive_in_sh
        attr_temperature_ac
        attr_temperature_refrig
        attr_temperature_freeze
        attr_ceiling_clearance
        attr_electrical_amps
        attr_space_type
        attr_permitted_usage
      }
      primary_contact {
        firstname
        lastname
        title
        phone
        email
      }
    }
  }
`;

// Exports a list of matching guest requests to a given config
export const EXPORT_CONFIG_MATCHES_QUERY = gql`
  query exportMatchCandidates(
    $objectId: Int!
    $objectType: String!
    $dqFilters: Int!
    $search: String
  ) {
    exportMatchCandidates(
      objectId: $objectId
      objectType: $objectType
      search: $search
      dqFilters: $dqFilters
    ) {
      match_data {
        pipedrive_org_id
        org_name
        request_id
        spacer_url
        last_updated
        created_at
        score
        budget
        term_min
        term_max
        square_footage_min
        square_footage_max
        warehouse_usage
        usage_includes
        usage_notes
      }

      object_data {
        config_id
        spacer_link
        warehouse_usage
        usage_notes

        usage_includes
        usage_excludes
        food_notes
        auto_notes
        cannabis_notes
        customer_notes
        sport_notes
        highvol_notes
        manufacture_notes
      }

      ## disqualifiers
    }
  }
`;

// Exports a list of matching configs to a given guest request
export const EXPORT_REQUEST_MATCHES_QUERY = gql`
  query exportMatchCandidates(
    $objectId: Int!
    $objectType: String!
    $dqFilters: Int!
    $search: String
  ) {
    exportMatchCandidates(
      objectId: $objectId
      objectType: $objectType
      search: $search
      dqFilters: $dqFilters
    ) {
      match_data {
        pipedrive_org_id
        org_name
        spacer_url
        last_updated
        created_at
        score
        property_id
        config_id
        config_name
        address
        price
        price_min
        availability
        availability_notice
        square_footage_min
        square_footage_max
      }
      object_data {
        request_id
        spacer_link
      }
      ## disqualifiers
    }
  }
`;

export const CONFIG_MATCH_CANDIDATES_QUERY = gql`
  query configMatchCandidates(
    $configID: Int!
    $search: String
    $pagination: PaginationInput
  ) {
    configMatchCandidates(
      configID: $configID
      search: $search
      pagination: $pagination
    ) {
      candidates {
        entity_data {
          pipedrive_org_id
          req_id
          org_name
          req_name
          square_footage_min
          square_footage_max
          term_min
          term_max
          budget
        }
        match_result
      }
      total_records
    }
  }
`;

export const CONFIG_MATCH_DISQUALIFIED_QUERY = gql`
  query configMatchDisqualified(
    $configID: Int!
    $search: String
    $pagination: PaginationInput
  ) {
    configMatchDisqualified(
      configID: $configID
      search: $search
      pagination: $pagination
    ) {
      disqualified {
        entity_data {
          pipedrive_org_id
          req_id
          org_name
          req_name
          square_footage_min
          square_footage_max
          term_min
          term_max
          budget
        }
        match_result
      }
      total_records
    }
  }
`;

export const CONFIG_MATCH_UNIFIED_QUERY = gql`
  query configMatchUnified(
    $configID: Int!
    $search: String
    $pagination: PaginationInput
    $mode: String!
    $ignoreDq: Int!
  ) {
    configMatchUnified(
      configID: $configID
      search: $search
      pagination: $pagination
      mode: $mode
      ignoreDq: $ignoreDq
    ) {
      requests {
        entity_data {
          pipedrive_org_id
          req_id
          org_name
          req_name
          square_footage_min
          square_footage_max
          term_min
          term_max
          budget
        }
        match_result
      }
      total_records
    }
  }
`;

export const REQ_MATCH_DATA_QUERY = gql`
  query nonMatchableRequests($reqID: Int!) {
    requestMatchData(reqID: $reqID) {
      pipedrive_id
      pipedrive_org_id
      req_id
      org_name
      req_name
      market
      square_footage_min
      square_footage_max
      budget
      term_min
      term_max
      movein
      movein_notice
      needs {
        attr_247_access
        attr_securable
        attr_office_space

        attr_bays
        attr_bays_docks
        attr_bays_drive_in
        attr_bays_ex
        attr_bays_53_trailers

        attr_temperature_ac
        attr_temperature_refrig
        attr_temperature_freeze
        attr_ceiling_clearance
        attr_space_type
        attr_locations {
          lat
          lng
        }
        attr_usage
      }
      primary_contact {
        firstname
        lastname
        title
        email
        phone
      }
    }
  }
`;

export const REQ_MATCH_CANDIDATES_QUERY = gql`
  query requestMatchCandidates(
    $reqID: Int!
    $pagination: PaginationInput
    $search: String
  ) {
    requestMatchCandidates(
      reqID: $reqID
      pagination: $pagination
      search: $search
    ) {
      candidates {
        entity_data {
          pipedrive_org_id
          property_id
          config_id
          num_configs
          org_name
          config_name
          address
          price
          price_min
          availability
          availability_notice
          square_footage_min
          square_footage_max
        }
        match_result
      }
      total_records
    }
  }
`;

export const REQ_MATCH_DISQUALIFIED_QUERY = gql`
  query requestMatchDisqualified(
    $reqID: Int!
    $pagination: PaginationInput
    $search: String
  ) {
    requestMatchDisqualified(
      reqID: $reqID
      pagination: $pagination
      search: $search
    ) {
      disqualified {
        entity_data {
          pipedrive_org_id
          property_id
          config_id
          num_configs
          org_name
          config_name
          address
          price
          price_min
          availability
          square_footage_min
          square_footage_max
        }
        match_result
      }
      total_records
    }
  }
`;

export const REQ_MATCH_UNIFIED_QUERY = gql`
  query requestMatchUnified(
    $reqID: Int!
    $pagination: PaginationInput
    $search: String
    $mode: String!
    $ignoreDq: Int!
  ) {
    requestMatchUnified(
      reqID: $reqID
      pagination: $pagination
      search: $search
      mode: $mode
      ignoreDq: $ignoreDq
    ) {
      configs {
        entity_data {
          pipedrive_org_id
          property_id
          config_id
          num_configs
          org_name
          config_name
          address
          price
          price_min
          availability
          square_footage_min
          square_footage_max
        }
        match_result
      }
      total_records
    }
  }
`;

export const TAGS_QUERY = gql`
  query tags($input: TagFetchInput) {
    tags(input: $input) {
      tags {
        id
        name
        colour
      }
    }
  }
`;

const SEARCH_QUERY = gql`
  query search($payload: SearchPayload) {
    search(payload: $payload) {
      persons {
        personData {
          id
          orgID
          name
          email
          phone
        }
        pageInfo {
          index
          size
          isMoreItems
        }
      }
      orgs {
        orgData {
          orgID
          name
        }
        pageInfo {
          index
          size
          isMoreItems
        }
      }
      properties {
        propertyData {
          propertyID
          orgID
          address
          type
          configID
          configName
          squareFootage
          price
          title
        }
        pageInfo {
          index
          size
          isMoreItems
        }
      }
      tenant {
        tenantData {
          requestID
          orgID
          requestName
          budget
          squareFootage
          dateModified
        }
        pageInfo {
          index
          size
          isMoreItems
        }
      }
      pageInfo {
        index
        size
        isMoreItems
      }
    }
  }
`;

export {
  SEARCH_QUERY,
  APP_CONFIG_QUERY,
  USERS_QUERY,
  USER_QUERY,
  HOST_QUERY,
  UNREAD_NOTIFICATION_COUNT_QUERY,
  NOTIFICATIONS_QUERY,
  NOTIFICATION_PREFERENCES_QUERY,
  MATCHES_QUERY,
  CHANGELOGS_QUERY,
  PLACE_FEE_QUERY,
  PREPARE_ADD_DOCUMENT,
  PIPEDRIVE_CONNECT_QUERY,
  ALL_INFO_QUERY,
  CHECK_EMPIRE_USER,
  GET_SIGNED_URLS,
};
