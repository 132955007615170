import React, {useEffect} from "react";
import Icon from "./Icon";
import IconButton from "./IconButton";

export const pageSizes: Readonly<number[]> = [10, 25, 50, 75, 100];
export const defaultPageSize: Readonly<number> = 25;

interface Props {
  numPages: number;

  // current page index (zero based)
  idx: number;
  // page size (allowed values in `pageSizes` constant)
  size: number;

  // page index modifier
  gotoPage: (i: number) => void;
  // page size modifier
  setPageSize: (s: number) => void;
};

const genPageOptions = (numPages: number): Readonly<React.ReactElement[]> => {
  if (!(numPages > 0)) return [<option key={0}>N/A</option>];
  const ret = Array<React.ReactElement>(numPages);
  for (let i = 0; i < numPages; i++)
    ret[i] = (
      <option key={i} value={i}>
        {i + 1} of {numPages}
      </option>
    );
  return ret;
}

const Paginator: React.FC<Props> = ({
  numPages,
  idx, size,
  gotoPage, setPageSize,
}) => {
  // set default page size if received one is invalid
  useEffect(() => {
    if (!pageSizes.includes(size)) setPageSize(defaultPageSize)
  }, [size, setPageSize]);
  // set default index if received one is invalid
  useEffect(() => {
    if (idx < 0) gotoPage(0)
  }, [idx, gotoPage]);
  // when numPages changes, make sure page index does not overflow
  useEffect(() => {
    if (idx >= numPages && (numPages !== 0 && idx !== 0)) gotoPage(numPages - 1);
  }, [numPages, gotoPage, idx])


  return (
    <div className="flex text-sm items-center">
      <Icon size="lg" iconId="noun_pages"/>
      <select
        className="rounded-md border-2 ml-1 text-center"
        value={size || 10}
        onChange={(e) => {setPageSize(parseInt(e.target.value))}}
      >
        {pageSizes.map(v =>
          <option key={v} value={v}>{v} per page</option>
        )}
      </select>

      <div className="flex">
        <IconButton
          iconId="attr_section_open"
          filter="contrast-500 transform rotate-90 p-1"
          onClick={() => gotoPage(idx-1)}
          enabled={idx > 0}
        />
        <select
          style={{appearance:"none"}}
          className="rounded-md border-2 text-center"
          value={idx}
          onChange={(e) => {gotoPage(parseInt(e.target.value))}}
        >
          {genPageOptions(numPages)}
        </select>
        <IconButton
          iconId="attr_section_open"
          filter="contrast-500 transform -rotate-90 p-1"
          onClick={() => gotoPage(idx+1)}
          enabled={idx < numPages-1}
        />
      </div>
    </div>
  );
}

export default Paginator;
