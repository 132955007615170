import React from "react";
import loading from "../assets/rolling.svg";

function Loading() {
  const classes = {
    loadingContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      minHeight: "100%",
      width: "100%",
    },
    loadingIcon: {
      marginTop: "auto",
      marginBottom: "auto",
      width: "50px",
    },
  };

  return (
    <div style={classes.loadingContainer}>
      <img src={loading} alt="Loading" style={classes.loadingIcon} />
    </div>
  );
}

export default Loading;
