import React from "react";
import { Tooltip } from "@mui/material";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  margin: 4px;
  min-width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Img = styled.img`
  max-height: 100%;
  max-width: 200px;
`;

export default function ListingImage({
  image,
  onClickCheck,
  showCheckBox,
  showFullSizeImage,
  configImages,
}) {
  const classes = {
    checkBox: {
      position: "absolute",
      top: "2px",
      left: "0px",
      width: "15px",
      height: "15px",
    },
  };

  return (
    <Container>
      <Tooltip title={image.description || ""} placement="top-start" arrow>
        <Img
          src={image.url || require("../../assets/img-not-found.png")}
          onClick={showFullSizeImage}
        />
      </Tooltip>
      {showCheckBox && (
        <input
          type="checkbox"
          data-cy={`select-image-${image.description}`}
          checked={configImages ? configImages.includes(image.handle) : false}
          style={classes.checkBox}
          onChange={(event) => onClickCheck(image.handle, event.target.checked)}
        />
      )}
    </Container>
  );
}
