import {
  validAddressFound,
  updateLocationFields,
} from "../../services/utilities";
/*
interface GeoCodeProps {
  input: string;
  // index of row in table - used to shade alternating rows differently
  map: google.maps.Map;
  marker: google.maps.Marker;
  address_known: boolean;
  setMapError: React.Dispatch<React.SetStateAction<boolean>>;
  marketBounds: google.maps.LatLngBounds | undefined;
  geocoder: google.maps.Geocoder;
};

interface ReversGeoCodeProps {
  input: google.maps.LatLng | null;
  updateAddress:  (args) => void;
  setMapError: React.Dispatch<React.SetStateAction<boolean>>;
  setMarketStatic : React.Dispatch<React.SetStateAction<boolean>>;
  geocoder: google.maps.Geocoder;
};
*/
export async function geoCode(
  input: string,
  latLng: google.maps.LatLng | null,
  map: google.maps.Map,
  marker: google.maps.Marker | null,
  address_known: boolean,
  setMapError: React.Dispatch<React.SetStateAction<boolean>>,
  geocoder: google.maps.Geocoder
): Promise<void> {
  // Takes in the location based on the fields and updates the lat lng of the marker when it is initialized

  if (latLng !== null) {
    map.setCenter(latLng); //center the map
    if (marker !== null) 
      marker.setPosition(latLng); // set marker position
    setMapError(false);
  } else {
    geocoder
      .geocode({ address: input })
      .then((result) => {
        setMapError(false);
        const location = result.results[0].geometry.location;
        map.setCenter(location); //center the map
        if (marker !== null) 
          marker.setPosition(latLng); // set marker position
      })
      .catch((e) => {
        setMapError(true);
        console.log(
          `Geocode was not successful on ${
            address_known ? "static" : "dynamic"
          } map for the following reason: ` + e
        );
      });
  }
}

export async function reversGeoCode(
  input: google.maps.LatLng | null,
  updateAddress: (args) => void,
  setMapError: React.Dispatch<React.SetStateAction<boolean>>,
  geocoder: google.maps.Geocoder,
): Promise<void> {
  //reversegeocode and get information of closest possible location

  geocoder
    .geocode({ location: input })
    .then((result) => {
      //only updates the values of fields if the all information is avalible
      if (validAddressFound(result.results[0].address_components)) {
        // use valid address to update fields
        updateLocationFields(
          result.results[0],
          updateAddress,
        );
        setMapError(false);
      } else {
        setMapError(true);
      }
    })
    .catch((e) => {
      setMapError(true);
      console.log(
        "Reverse Geocode on dynamic map was not successful for the following reason: " +
          e
      );
    });
}
