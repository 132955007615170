import logo from "assets/logo_long.png";
import Searchbar from "components/Searchbar/Searchbar";

export default function Search(): JSX.Element {
  return (
    <div className="flex-col text-center mt-20">
      <img src={logo} alt="Logo" className="m-auto w-5/12" />
      <Searchbar size="medium" sx={{ mt: 10, width: "70%" }} />
    </div>
  );
}
