import React from "react";
import { useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import { useHistory } from "react-router";

import Title from "components/Title";
import { Query } from "@apollo/client/react/components";
import { USERS_QUERY } from "graphql/queries";
import { TITLE_USER_LIST } from "services/constants";
import useTabTitle from "hooks/useTabTitle";

function UserList() {
  useTabTitle(TITLE_USER_LIST);
  const history = useHistory();
  const theme = useTheme();
  const classes = {
    noRecordBox: {
      margin: theme.spacing(1),
    },
    fabButton: {
      position: "fixed",
      right: "35px",
      bottom: "35px",
    },
    tableRow: {
      "&:hover, &:focus": {
        cursor: "pointer",
        backgroundColor: "#F6F6F6",
      },
    },
  };

  const handleUserClick = (id) => {
    history.push("/users/" + id);
  };

  const handleAdd = () => {
    history.push("/users/new");
  };

  return (
    <Query query={USERS_QUERY} fetchPolicy="no-cache">
      {({ loading, error, data }) => {
        if (loading) return <div>Fetching</div>;
        if (error) return <div>Error</div>;

        const rows = data.users;

        return (
          <div className="w-11/12">
            <Title>User Management</Title>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Provider ID</TableCell>
                  </TableRow>
                </TableHead>
                {rows && (
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={classes.tableRow}
                        onClick={() => handleUserClick(row.user_id)}
                      >
                        <TableCell component="th" scope="row">
                          {row.firstname} {row.lastname}
                        </TableCell>
                        <TableCell align="right">{row.email}</TableCell>
                        <TableCell align="right">{row.auth0_id}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            {!rows && (
              <Typography variant="body2" sx={classes.noRecordBox}>
                No record
              </Typography>
            )}

            <Fab
              color="primary"
              aria-label="add"
              sx={classes.fabButton}
              onClick={handleAdd}
            >
              <AddIcon />
            </Fab>
          </div>
        );
      }}
    </Query>
  );
}

export default UserList;
