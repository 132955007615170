interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

export default function TabPanel({
  children,
  value,
  index,
  className,
  ...props
}: TabPanelProps): JSX.Element {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className={className}
      {...props}
    >
      {children}
    </div>
  );
}
