import { Grid } from "@mui/material";
import TextFieldReadOnly from "components/Forms/TextFieldReadOnly";
import EnumTextFieldReadOnly from "components/Forms/EnumTextFieldReadOnly";
import { GridSection } from "components/CardComponents";
import { EnumHostType, EnumGuestType } from "services/enums";
import OpenInPipedrive from "components/OpenInPipedrive";
import { PD_RESOURCE_ORGANIZATION } from "services/constants";
import { DetailsSectionProps } from "interfaces/detailsSection";
import { useContext } from "react";
import { FormContext } from "contexts";

export default function DetailsSection({
  details,
}: DetailsSectionProps): JSX.Element {
  const { organizationData } = useContext(FormContext);
  return (
    <GridSection
      title={"Organization Details"}
      md={5}
      xs={5}
      HeaderComponent={
        <OpenInPipedrive
          resourceType={PD_RESOURCE_ORGANIZATION}
          orgPipedriveID={organizationData?.org_id}
          showIconButton={true}
          datacy={"open-org-details-pipedrive"}
        />
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextFieldReadOnly label="Address" value={details?.address} />
          <EnumTextFieldReadOnly
            label="Host Type"
            value={details?.host_type}
            options={EnumHostType}
          />
          <EnumTextFieldReadOnly
            label="Guest Type"
            value={details?.guest_type}
            options={EnumGuestType}
          />
        </Grid>
      </Grid>
    </GridSection>
  );
}
