import React from "react";
import Icon from "../Icon";
import {Category, Categories, ScoreBreakdownCategories, matchCategories} from "./types";

interface Props {
  ignored: number;
  setIgnored: React.Dispatch<React.SetStateAction<number>>;
}

const ignorableCats: Readonly<Category[]> = Categories.filter(
  c => !ScoreBreakdownCategories.includes(c)
);

const IgnoreDisqualifiersRow: React.FC<Props> = ({
  ignored, setIgnored,
}) => {
  return (
    <div className="flex items-center">
      <p className="ml-2 mx-4">
        Ignore Disqualifiers
      </p>

      <div className="flex justify-end">
        {ignorableCats.map((c, i) => {
          const cat = matchCategories[c];
          return (
            <div
              className="m-1 cursor-pointer"
              key={i}
              title={cat.name}
              onClick={() => setIgnored(i => i ^ c)}
            >
              <Icon
                iconId={cat.iconId}
                state={(ignored & c) === 0 ? "disabled" : "default"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default IgnoreDisqualifiersRow;
